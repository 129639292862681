import { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { verifyEmailExistence } from '../../../utils/validation';

// components
import Email from '../../../components/common/inputs/Email/Email';

// styles
import css from './index.module.scss';

type Props = {
  name: string;
  watch: any;
  register: any;
  setError: any;
  clearErrors: any;
  redirectToPreviousRoute?: boolean;
  customRedirectRoute?: string;
};

const EmailField = ({
  name,
  watch,
  setError,
  clearErrors,
  register,
  redirectToPreviousRoute,
  customRedirectRoute,
}: Props) => {
  const [emailExists, setEmailExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const email = watch(name);

  // location
  const { pathname } = useLocation();

  const urlFriendlyRedirectPath = encodeURIComponent(pathname);
  const createErrorJsx = useCallback((email: string) => {
    let loginLink = `/loginwithemail?email=${email}`;
    if (customRedirectRoute) {
      loginLink += `&redirect=${encodeURIComponent(customRedirectRoute)}`;
    } else if (redirectToPreviousRoute) {
      loginLink += `&redirect=${urlFriendlyRedirectPath}`;
    }

    return (
      <p className="error__message">
        Vi ser att du redan har ett konto hos oss.
        <Link className={css['error-link']} to={loginLink}>
          Logga in
        </Link>
      </p>
    );
  }, []);

  const emailRegex = useMemo(
    () => new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
    []
  );

  const setIsTypingError = () => {
    setError('isTyping', {
      type: 'custom',
      message: 'email validation incomplete',
    });
  };

  const setValidationError = () => {
    setError('emailValidation', {
      type: 'custom',
      message: 'email validation incomplete',
    });
  };

  const checkIfUserExists = useCallback(
    async (signal) => {
      try {
        setIsLoading(true);
        const { userExists }: any = await verifyEmailExistence(email, signal);
        setEmailExists(userExists);
        if (userExists) {
          setValidationError();
        } else {
          clearErrors(['emailValidation', 'isTyping']);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [email]
  );

  const isRegexMatch = useCallback(() => emailRegex.test(email), [email]);

  useEffect(() => {
    if (!isRegexMatch()) return;
    setEmailExists(false);
    setIsTypingError();
    const controller = new AbortController();
    const signal = controller.signal;
    const debounce = setTimeout(async () => {
      checkIfUserExists(signal);
    }, 250);

    return () => {
      clearTimeout(debounce);
      controller.abort();
    };
  }, [email]);

  // set email field as invalid  when component loads.
  useEffect(() => setValidationError(), []);

  return (
    <div className="error">
      <Email
        name={name}
        register={register}
        validationSchema={{
          required: true,
          pattern: emailRegex,
        }}
        isLoading={isLoading}
      />
      {!isLoading && emailExists && createErrorJsx(email)}
    </div>
  );
};

export default EmailField;
