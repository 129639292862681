import { useState } from 'react';

import DashboardContentHeader from './DashboardContentHeader';
import CreateCandidateModal from '../../../components/CreateCandidateModal/CreateCandidateModal';
import Copyright from '../../../components/Copyright/Copyright';
import css from './DashboardContent.module.scss';

type Props = {
  children: React.ReactNode;
  heading: string;
};

const DashboardContent = (props: Props) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const onOpenModalHandler = () => {
    setIsOpen(true);
  };

  return (
    <div className={css.dashboardRight}>
      <div className={css.mainContentContainer}>
        <CreateCandidateModal
          modalIsOpen={modalIsOpen}
          setIsOpen={(bool: boolean) => setIsOpen(bool)}
        />
        <DashboardContentHeader
          heading={props.heading}
          onOpenModal={onOpenModalHandler}
        />

        {props.children}
      </div>

      <Copyright />
    </div>
  );
};

export default DashboardContent;
