import { NavLink } from 'react-router-dom';

// styles
import css from './NavigationTabs.module.scss';

type Props = {
  tabs: {}[];
};

const NavigationTabs = ({ tabs }: Props) => {
  return (
    <nav className={css['tabs']}>
      {tabs.map((tab: any, idx: number) => (
        <NavLink
          key={idx}
          to={tab.to}
          className={({ isActive }) => (isActive ? css['selected'] : '')}
        >
          <h2 className="no-vertical-margin">{tab.title}</h2>
          {tab.notifications && (
            <div className={css['circle']}>
              <span>{tab.notifications}</span>
            </div>
          )}
        </NavLink>
      ))}
    </nav>
  );
};

export default NavigationTabs;
