import { useState } from 'react';
import { ColumnList, EditableListItem } from '../../../ColumnList/ColumnList';
import EducationForm from '../../../Candidate/Forms/EducationForm';
import BaseModal from '../../../common/BaseModal/BaseModal';
import SmallButton from '../../../common/buttons/SmallButton/SmallButton';

const EducationList = ({
  educations,
  onUpdateEducation,
  onAddEducation,
  onDeleteEducation,
  isLoading,
}: any) => {
  const [formType, setFormType] = useState<'add' | 'edit' | null>(null);
  const [itemInEdit, setItemInEdit] = useState<any>(null);

  const clearFormState = () => {
    setFormType(null);
    setItemInEdit(null);
  };

  const modalIsOpen = !!formType && !isLoading;

  const hasEducations = !!educations?.length;

  const listJsx =
    hasEducations &&
    educations.map(({ data, id }: any) => (
      <EditableListItem
        key={id}
        title={`${data.degree}, ${data.school}`}
        text={`${data.startDate} - ${data.endDate || 'Nuvarande'}`}
        onTriggerEdit={() => {
          setItemInEdit({ data, id });
          setFormType('edit');
        }}
      />
    ));

  return (
    <>
      <BaseModal
        isOpen={modalIsOpen}
        onClose={clearFormState}
        heading="Redigera utbildning"
      >
        {formType === 'edit' && (
          <EducationForm
            education={itemInEdit}
            buttonText="spara"
            onSaveNestedObject={(data: any) => {
              const { id } = itemInEdit;
              onUpdateEducation({ id, data });
              clearFormState();
            }}
            onRemoveEducation={() => {
              const { id } = itemInEdit;
              onDeleteEducation(id);
              clearFormState();
            }}
          />
        )}
        {formType === 'add' && (
          <EducationForm
            buttonText="skapa"
            onSaveNestedObject={(data: any) => {
              onAddEducation(data);
              clearFormState();
            }}
          />
        )}
      </BaseModal>
      <ColumnList title="Utbildning">
        {listJsx}
        <SmallButton
          styleType="text"
          title="Lägg till"
          onClick={() => setFormType('add')}
        />
      </ColumnList>
    </>
  );
};

export default EducationList;
