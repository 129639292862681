import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// helpers
import { readFromCache } from '../../../utils/cache';

import InputAutocomplete from '../../../components/common/inputs/InputAutocomplete/InputAutocomplete';
import BaseCard from '../../../components/common/BaseCard/BaseCard';
import BaseInput from '../../../components/common/inputs/BaseInput/BaseInput';
import InputAcceptTerms from '../../../components/common/inputs/InputAcceptTerms/InputAcceptTerms';
import css from './SpecificJobFormStep.module.scss';

const propMatch: any = {
  firstName: {
    _uid: 'fcd74e93-d608-4864-9272-9ed529b00b23',
    component: 'text',
    alias: 'firstName',
    attributes: {
      label: 'Förnamn',
      type: 'text',
      placeholder: 'Semawi',
    },
    required: true,
  },
  lastName: {
    _uid: '1ee4513a-cf9a-4ddd-a8df-d4fa0b92d22a',
    component: 'input_email',
    alias: 'lastName',
    attributes: {
      label: 'Efternamn',
      type: 'text',
      placeholder: 'Olson',
    },
    required: true,
  },
  email: {
    _uid: '8c11542a-2f1d-40ef-b970-da5b426b11d5',
    component: 'text',
    alias: 'email',
    attributes: {
      autocomplete: 'email',
      label: 'Mejladress',
      type: 'email',
      placeholder: 'semawi@hotmail.se',
    },
    validation: 'regExpEmail',
    required: true,
  },
  phone: {
    _uid: '767b07e9-2797-46de-bd20-c7109238e902',
    component: 'phone',
    alias: 'phone',
    attributes: {
      label: 'Telefonnummer',
      type: 'tel',
      autocomplete: 'tel',
      id: 'tel',
      placeholder: '070-XXX XX XX',
    },
    validation: 'regExpPhone',
    required: true,
  },
  location: {
    _uid: '1f309083-346b-42fd-a23f-bd63065544ef',
    component: 'location_autocomplete',
    alias: 'location',
    attributes: {
      label: 'Adress',
      type: 'text',
      placeholder: 'Sveavägen 15, Stockholm',
    },
    required: true,
  },
  acceptedTerms: {
    _uid: '776bc806-a9c6-477e-8674-27348589e5f0',
    component: 'accept_terms',
    alias: 'acceptedTerms',
    attributes: {
      label: 'accept our terms',
      type: 'checkbox',
    },

    required: true,
  },
};

const additionalRegisterProps: any = {
  regExpEmail: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'invalid email address',
  },
  regExpPhone: {
    value:
      /^(\+\d{1,2}(\s)?)?\(?\d{2,3}(\s)?([-])?\)?\d{3}(\s)?\d{2}(\s)?\d{2}$/,
    message: 'invalid phone number format',
  },
};

const SpecificJobForm = (props: any) => {
  const { register, setValue, getValues } = useFormContext();
  const { id }: any = useParams();
  const firstToUpper = (str: string) => {
    if (!str?.length) return str;
    return str[0].toUpperCase() + str.slice(1, str.length);
  };

  const storeSelectedLocationHandler = (locationObj: {
    formatted: '';
    geoData: {
      coordinates: {
        lat: number;
        long: number;
      };
    };
  }) => {
    localStorage.setItem('location', JSON.stringify(locationObj));
    setValue('applicantInfo.location', locationObj);
  };

  const prepareRegisterRef = ({ uid, label }: any) => {
    setValue(`answers.${uid}.question`, label);
  };

  const cachedLocation = readFromCache('location');
  let inputList;

  inputList = props.fields.map((field: any) => {
    if (props?.component === 'collect_user_data_snippet') {
      const fieldProps = propMatch[field];
      if (fieldProps.component === 'accept_terms') {
        return (
          <InputAcceptTerms
            key={fieldProps._uid}
            {...register(`applicantInfo.${field}`, {
              required: fieldProps?.required,
              pattern: additionalRegisterProps[fieldProps.validation] || null,
            })}
          />
        );
      }
      if (fieldProps.component === 'location_autocomplete') {
        return (
          // <InputAutocomplete key={fieldProps._uid} fieldProps={fieldProps} />
          <InputAutocomplete
            key={fieldProps._uid}
            // allowAutomaticDetection
            onSelectLocation={storeSelectedLocationHandler}
            defaultLocation={cachedLocation}
            label="Vart bor du?"
            types={['address']}
          />
        );
      }

      return (
        <BaseInput
          key={fieldProps._uid}
          label={fieldProps.attributes.label}
          type={fieldProps.attributes.type}
          inputid={fieldProps.alias}
          autoComplete={fieldProps?.attributes?.autocomplete}
          min={fieldProps?.attributes.type === 'number' ? '0' : null}
          hidestep={fieldProps.alias === 'phone' ? 1 : 0}
          id={fieldProps.attributes.id || undefined}
          placeholder={firstToUpper(fieldProps.attributes.placeholder)}
          {...register(`applicantInfo.${field}`, {
            required: fieldProps?.required,
            pattern: additionalRegisterProps[fieldProps.validation] || null,
            ...(fieldProps?.noNegative && {
              onChange: (e: any) => (e.target.value = Math.abs(e.target.value)),
            }),
          })}
        />
      );
    }

    const isRadio = field?.child_type === 'radio';
    const fieldGroupLabel = field?.label;
    const isGroupRequired = isRadio ? field.required : false;

    prepareRegisterRef({ uid: field._uid, label: field.label });
    return !field.fields ? (
      <BaseInput
        key={field._uid}
        label={field.label}
        type={field?.type}
        inputid={field.label}
        autoComplete={field?.autocomplete}
        min={field?.type === 'number' ? '0' : null}
        step={field?.step}
        placeholder={firstToUpper(field?.placeholder)}
        {...register(`answers.${field._uid}.answer`, {
          required: field?.required,
          pattern:
            additionalRegisterProps[field?.validation?.regExpRef] || null,
          ...(field?.noNegative && {
            onChange: (e: any) => (e.target.value = Math.abs(e.target.value)),
          }),
        })}
      />
    ) : (
      <div
        key={field._uid}
        className={`${css.formControl} ${isRadio ? css.flexCol : ''}`}
      >
        <fieldset>
          {fieldGroupLabel && <legend>{fieldGroupLabel}</legend>}

          {field.fields.map((input: any) => {
            return (
              <BaseInput
                key={input._uid}
                label={input.label}
                type={input?.type}
                inputid={input._uid}
                autoComplete={input?.autocomplete}
                step={input?.step}
                min={input?.type === 'number' ? '0' : null}
                placeholder={firstToUpper(input?.placeholder)}
                defaultValue={
                  isRadio
                    ? input.label
                    : JSON.parse(localStorage.getItem(id) as any)[
                        field.label
                      ] || null
                }
                {...register(
                  `answers.${field._uid}.answer`,

                  {
                    required: isRadio ? isGroupRequired : input?.required,
                    pattern:
                      additionalRegisterProps[input?.validation?.regExpRef] ||
                      null,
                    ...(input?.noNegative && {
                      onChange: (e: any) =>
                        (e.target.value = Math.abs(e.target.value)),
                    }),
                  }
                )}
              />
            );
          })}
        </fieldset>
      </div>
    );
  });
  return (
    <BaseCard {...props?.card} alignContentEnd minHeight="530px">
      {inputList}
      {props.children}
    </BaseCard>
  );
};

export default SpecificJobForm;
