import { firstToUpper } from '../../../../utils/convert/typography';

// styles
import css from './SectionTitle.module.scss';

type Props = {
  title: string;
  faIcon?: string;
  hoverTitle?: string;
};

const SectionTitle = ({ title, faIcon, hoverTitle }: Props) => {
  return (
    <div className={css['container']} title={hoverTitle}>
      <div className={css['section-title']}>
        <h2 className="no-vertical-margin h5">{firstToUpper(title)}</h2>
      </div>
      {faIcon && <i className={`fa-solid fa-${faIcon}`} />}
    </div>
  );
};

export default SectionTitle;
