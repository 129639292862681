import classNames from 'classnames';
import { FC } from 'react';
import { firstToUpper } from '../../utils/convert/typography';

// components
import EditPen from '../EditPen/EditPen';

// styles
import css from './ColumnList.module.scss';

type ItemProps = {
  title: string;
  text: string;
};

type EditableItemProps = {
  title: string;
  text: string;
  onTriggerEdit: () => void;
};

type ListProps = {
  title?: any;
  noGap?: boolean;
};

export const ColumnListItem = ({ title, text }: ItemProps) => {
  return (
    <div className={css['column-list__item']}>
      <div className={css['item__content']}>
        <h3 className="no-vertical-margin">{firstToUpper(title)}</h3>
        <p className="no-vertical-margin">{text}</p>
      </div>
    </div>
  );
};

export const EditableListItem = ({
  title,
  text,
  onTriggerEdit,
}: EditableItemProps) => {
  return (
    <div className={css['editable-item-container']}>
      <ColumnListItem title={title} text={text} />

      <EditPen onTriggerEdit={onTriggerEdit} />
    </div>
  );
};

export const ColumnList: FC<ListProps> = ({ children, title, noGap }) => {
  const classes = classNames({
    [css['column-list']]: true,
    [css['no-gap']]: !!noGap,
  });
  return (
    <div className={classes}>
      <h2 className="no-vertical-margin">{title}</h2>
      <div className={css['column-list__items']}>{children}</div>
    </div>
  );
};
