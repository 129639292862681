import { EffectCallback, useEffect, useRef } from 'react'

function useEffectOnce(effect: EffectCallback) {
  
  const isFirstMount = useRef(true);
  useEffect(() => {
    if(!isFirstMount.current) return;
    isFirstMount.current = false
    effect()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useEffectOnce