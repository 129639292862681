import { RegisterOptions } from 'react-hook-form';
import BaseInput from '../BaseInput/BaseInput';

type Props = {
  name: string;
  register: any;
  validationSchema: RegisterOptions;
  isLoading: boolean;
};

const Email = ({ name, register, validationSchema, isLoading }: Props) => {
  return (
    <div className="error__input">
      <BaseInput
        label="Mejladress"
        placeholder="tony_start@gmail.com"
        {...register(name, validationSchema)}
        customProps={{ isLoading: isLoading }}
      />
    </div>
  );
};

export default Email;
