import { FC } from 'react';

// styles
import css from './index.module.scss';

type Props = {};

const RatingsList: FC<Props> = ({ children }) => {
  return <ul className={css['ratings-list']}>{children}</ul>;
};

export default RatingsList;
