import { useState } from 'react';
import EventsListItem from './EventsListItem/EventsListItem';
import { toLocaleString } from '../../utils/dates';
import { auth } from '../../firebase';

// loading placeholder
import LoadingPlaceholder from './EventsListItem/LoadingPlaceholder/LoadingPlaceholder';

// styles
import css from './EventsList.module.scss';

type Props = {
  events: {}[] | null;
  editState: { id: string | null };
  setEditState: ({ id }: { id: string }) => void;
  onUpdateEvent: ({ id, data }: any) => void;
  onDeleteEvent: (id: string) => void;
};

const EventsList = ({
  events,
  editState,
  setEditState,
  onUpdateEvent,
  onDeleteEvent,
}: Props) => {
  const [toolboxParent, setToolboxParent]: any = useState<string | null>(null);
  const getEventProps = (id: any, event: any = {}) => {
    // TODO: clean up this mess and make it easier to read.
    const { type, title, body, createdAt, author, newStatus, previousStatus } =
      event;
    const isOwnEvent = event?.author?.uid === auth.currentUser?.uid;
    const isStatusUpdate = type === 'status_update';
    const parsedDate = toLocaleString(createdAt?.toDate());
    let newStatusTag = newStatus;
    if (previousStatus) {
      newStatusTag = `${previousStatus} => ${newStatus}`;
    }
    const tags: any = isStatusUpdate && [newStatusTag];

    return {
      key: id,
      eventType: type,
      title: title,
      body: body,
      date: parsedDate,
      author: author,
      tags: tags,
      isOwnEvent: isOwnEvent,
      showToolbox: toolboxParent === id,
      onOpenToolbox: () => setToolboxParent(id),
      onCloseToolbox: () => setToolboxParent(null),
      setEditState: () => {
        setEditState({ id });
        setToolboxParent(false);
      },
      inEditState: editState.id === id,
      onUpdateEvent: (data: any) => onUpdateEvent({ id, data }),
      onDeleteEvent: () => onDeleteEvent(id),
    };
  };
  return (
    <div className={css['comments']}>
      <ul className={`scrollable-container ${css['comments__feed']}`}>
        {events?.length ? (
          events.map(({ id, data: event }: any) => {
            return <EventsListItem {...getEventProps(id, event)} />;
          })
        ) : (
          <>
            <LoadingPlaceholder />
            <LoadingPlaceholder />
            <LoadingPlaceholder />
          </>
        )}

        <div className={css['comments__start']}>
          <div className={css['comments__start-content']}>
            <span>
              <b>The botten is nådd!</b>
              <br></br> Men hälsa gärna på Gilbert innan du skrollar upp igen
            </span>{' '}
            <i className="fa-solid fa-kiwi-bird"></i>
          </div>
        </div>
      </ul>
      <span className={css['fade']}></span>
    </div>
  );
};

export default EventsList;
