import { useState, useEffect, useRef, FC } from 'react';

// components
import TooltipComponent from '../../components/Tooltip/Tooltip';

type Props = {
  type: 'success' | 'warning' | 'danger';
  position: 'left' | 'right';
  title: string;
};

const Tooltip: FC<Props> = ({ children, type, position, title }) => {
  const tooltipRef = useRef<any>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const timeout = useRef<any>(null);

  const showTooltipHandler = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => setShowTooltip(true), 500);
  };

  const hideTooltipHandler = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setShowTooltip(false);
  };

  useEffect(() => {
    if (!tooltipRef.current) return;

    const tooltipSibling = tooltipRef.current.nextSibling;

    tooltipSibling.addEventListener('mouseenter', showTooltipHandler);
    tooltipSibling.addEventListener('mouseleave', hideTooltipHandler);
    return () => {
      tooltipSibling.removeEventListener('mouseenter', showTooltipHandler);
      tooltipSibling.removeEventListener('mouseleave', hideTooltipHandler);
    };
  }, [tooltipRef]);

  return (
    <TooltipComponent
      ref={tooltipRef}
      type={type}
      showTooltip={showTooltip}
      position={position}
      title={title}
    >
      {children}
    </TooltipComponent>
  );
};

export default Tooltip;
