import css from './CandidatePrediction.module.scss';
import BaseButton from '../../common/BaseButton/BaseButton';
const { Highlight } = require('react-instantsearch-dom');

const CandidatePrediction = ({
  hit,
  isAdded,
  onAddCandidate,
  onRemoveCandidate,
  onKeyDown,
}: any) => {
  const { lastName, email, phone, objectID } = hit;
  const onAddCandidateHandler = () => {
    onAddCandidate(hit);
  };
  const onRemoveCandidateHandler = () => {
    onRemoveCandidate(objectID);
  };
  return (
    <li className={css['prediction']} onKeyDown={onKeyDown}>
      <div className={css['prediction__content']}>
        <div>
          <Highlight attribute="firstName" hit={hit} />
          <p className="no-vertical-margin">{lastName}</p>
        </div>
        <div className={css['icon-text-wrapper']}>
          <i className="fa fa-phone"></i>
          <p className="no-vertical-margin">{phone}</p>
        </div>
        <div className={css['icon-text-wrapper']}>
          <i className="fa fa-envelope"></i>
          <p className="no-vertical-margin">{email}</p>
        </div>
      </div>
      <div className={css['prediction__ctas']}>
        {!isAdded ? (
          <BaseButton
            title="Lägg till"
            styleType="text--solid button--small button--add"
            onClick={onAddCandidateHandler}
          />
        ) : (
          <BaseButton
            title="Ta bort"
            styleType="text--solid button--small button--delete"
            onClick={onRemoveCandidateHandler}
          />
        )}

        <BaseButton
          title="Visa"
          styleType="cta button--small button--warning"
        />
      </div>
    </li>
  );
};

export default CandidatePrediction;
