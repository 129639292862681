import { useEffect, useState, forwardRef, useRef } from 'react';
import useScript from '../../../../hooks/useScript';
import { usePlacesAutocomplete } from '../../../../hooks/usePlacesAutocomplete';
import BaseInput from '../BaseInput/BaseInput';

import css from './InputAutocomplete.module.scss';
import PredictionList from '../../../PredictionList/PredictionList';
import PredictionItem from '../../../PredictionList/PredictionItem/PredictionItem';

const apiKey = process.env.REACT_APP_GMAP_API_KEY;
const baseApiURL = 'https://maps.googleapis.com/maps/api/js';
const restOfParams = '&libraries=places,geocode&v=weekly&language=sv';
const gMapSource = `${baseApiURL}?key=${apiKey}${restOfParams}`;

const InputAutocomplete = forwardRef(
  (
    {
      informOfChange,
      allowAutomaticDetection,
      defaultLocation,
      onSelectLocation,
      label,
      componentRestrictions,
      types,
    }: any,
    ref: any
  ) => {
    const scriptStatus = useScript(gMapSource);

    const [searchValue, setSearchValue] = useState<string>(
      defaultLocation?.formatted || ''
    );

    const [predictions, getGeolocation] = usePlacesAutocomplete(
      searchValue,
      200,
      { componentRestrictions, types }
    );

    const [selectedPrediction, setSelectedPrediction] = useState<any>(
      defaultLocation || null
    );

    const [showPrediction, setShowPredictions] = useState<boolean>(false);
    const predictionsList = useRef<any>(null);

    const onChangeHandler = (e: any) => {
      setSearchValue(e.target.value);
      if (informOfChange) informOfChange();
    };

    const onSelectPredictionHandler = async (prediction: any) => {
      const { description, place_id } = prediction;
      const coordinates = await getGeolocation(place_id);
      setSelectedPrediction({ formatted: description, geoData: coordinates });
    };

    // detect if click occured outside of predictionsList, if so, hide list.
    const detectClickRegionHandler = function (
      this: HTMLUListElement,
      { target: elClicked }: any
    ) {
      const potentialParent = this as any;
      const clickOccurredOutside = !potentialParent.contains(elClicked);

      if (clickOccurredOutside) {
        setShowPredictions(false);
      }
    };

    const navigatorPositionSuccess = (position: any) => {
      console.log(position);
    };

    const navigatorPositionError = (error: any) => {
      console.error(error);
    };

    const predictClientsGeolocation = () => {
      const geoOptions = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        navigatorPositionSuccess,
        navigatorPositionError,
        geoOptions
      );
    };

    // handle searchValue change and execute onSelectLocation when visitor selects prediction.
    useEffect(() => {
      if (!selectedPrediction?.formatted || !selectedPrediction?.geoData) {
        return;
      }

      setSearchValue(selectedPrediction?.formatted);
      onSelectLocation(selectedPrediction);
    }, [selectedPrediction]);

    // add click listener to document and bind predictionsList to fn param  .
    useEffect(() => {
      const predictionsListRef = predictionsList?.current as HTMLUListElement;
      if (!predictionsListRef) return;

      const detectClickRegion =
        detectClickRegionHandler.bind(predictionsListRef);

      document.addEventListener('click', detectClickRegion);
      return () => {
        document.removeEventListener('click', detectClickRegion);
      };
    }, [predictionsList.current]);

    useEffect(() => {
      if (selectedPrediction && selectedPrediction.formatted !== searchValue) {
        setSelectedPrediction(null);
        onSelectLocation(null);
      }

      setShowPredictions(!selectedPrediction && searchValue.length > 0);
    }, [searchValue]);

    // this useEffect is resetting some state back to its original data to React no-op memory leak.
    useEffect(() => {
      return () => {
        setSearchValue('');
        setSelectedPrediction(null);
      };
    }, []);

    const predictionsArray = showPrediction && !!predictions?.length && (
      <PredictionList isLocationAutocomplete>
        {predictions?.map((prediction: any, idx: number) => {
          const { main_text, secondary_text } =
            prediction?.structured_formatting;
          return (
            <PredictionItem
              key={idx}
              mainText={main_text}
              secondaryText={secondary_text}
              onClick={() => onSelectPredictionHandler(prediction)}
              onKeyDown={() => onSelectPredictionHandler(prediction)}
            />
          );
        })}
      </PredictionList>
    );

    const automaticPredictionIcon = allowAutomaticDetection && (
      <i
        onClick={predictClientsGeolocation}
        className="fa-solid fa-location-crosshairs"
      ></i>
    );

    return (
      <div className={css['input-autocomplete']}>
        <BaseInput
          disabled={scriptStatus !== 'ready'}
          name="predictionSearch"
          label={label}
          inputid="autocomplete"
          value={searchValue}
          autoComplete="off"
          placeholder="Stockholm, Sverige"
          onChange={onChangeHandler}
          customProps={{ faIcon: automaticPredictionIcon, isLoading: false }}
        />
        {predictionsArray}
      </div>
    );
  }
);

InputAutocomplete.displayName = 'InputAutocomplete';
export default InputAutocomplete;
