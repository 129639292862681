/**
 * @author Phil Teare
 * using wikipedia data
 * @translator Maximilian Olson
 * 
 */
export const ISO_LANGS = [
  { code: 'ab', name: 'Abkhaziska', nativeName: 'аҧсуа' },
  { code: 'aa', name: 'Afar', nativeName: 'Afaraf' },
  { code: 'af', name: 'Afrikanska', nativeName: 'Afrikaans' },
  { code: 'ak', name: 'Akan', nativeName: 'Akan' },
  { code: 'sq', name: 'Albanska', nativeName: 'Shqip' },
  { code: 'am', name: 'Amharic', nativeName: 'አማርኛ' },
  { code: 'ar', name: 'Arabiska', nativeName: 'العربية' },
  { code: 'an', name: 'Aronesiska', nativeName: 'Aragonés' },
  { code: 'hy', name: 'Armeniska', nativeName: 'Հայերեն' },
  { code: 'as', name: 'Assamiska', nativeName: 'অসমীয়া' },
  { code: 'av', name: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { code: 'ae', name: 'Avestan', nativeName: 'avesta' },
  { code: 'ay', name: 'Aymara', nativeName: 'aymar aru' },
  { code: 'az', name: 'Azerbajdzjanska', nativeName: 'azərbaycan dili' },
  { code: 'bm', name: 'Bambara', nativeName: 'bamanankan' },
  { code: 'ba', name: 'Bashkir', nativeName: 'башҡорт теле' },
  { code: 'eu', name: 'Baskiska', nativeName: 'euskara, euskera' },
  { code: 'be', name: 'Vitryska', nativeName: 'Беларуская' },
  { code: 'bn', name: 'Bengali', nativeName: 'বাংলা' },
  { code: 'bh', name: 'Bihari', nativeName: 'भोजपुरी' },
  { code: 'bi', name: 'Bislama', nativeName: 'Bislama' },
  { code: 'bs', name: 'Bosniska', nativeName: 'bosanski jezik' },
  { code: 'br', name: 'Bretonska', nativeName: 'brezhoneg' },
  { code: 'bg', name: 'Bulgariska', nativeName: 'български език' },
  { code: 'my', name: 'Burmesiska', nativeName: 'ဗမာစာ' },
  { code: 'ca', name: 'Katalanska; Valencianska', nativeName: 'Català' },
  { code: 'ch', name: 'Chamorro', nativeName: 'Chamoru' },
  { code: 'ce', name: 'Tjetjenska', nativeName: 'нохчийн мотт' },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { code: 'zh', name: 'Kinesiska', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { code: 'cv', name: 'Tjuvasjiska', nativeName: 'чӑваш чӗлхи' },
  { code: 'kw', name: 'Cornish', nativeName: 'Kernewek' },
  { code: 'co', name: 'Korniska', nativeName: 'corsu, lingua corsa' },
  { code: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { code: 'hr', name: 'Kroatiska', nativeName: 'hrvatski' },
  { code: 'cs', name: 'Tjeckiska', nativeName: 'česky, čeština' },
  { code: 'da', name: 'Danska', nativeName: 'dansk' },
  { code: 'dv', name: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
  { code: 'nl', name: 'Nederländska', nativeName: 'Nederlands, Vlaams' },
  { code: 'en', name: 'Engelska', nativeName: 'English' },
  { code: 'eo', name: 'Esperanto', nativeName: 'Esperanto' },
  { code: 'et', name: 'Estniska', nativeName: 'eesti, eesti keel' },
  { code: 'ee', name: 'Ewe', nativeName: 'Eʋegbe' },
  { code: 'fo', name: 'Färöiska', nativeName: 'føroyskt' },
  { code: 'fj', name: 'Fijianska', nativeName: 'vosa Vakaviti' },
  { code: 'fi', name: 'Finska', nativeName: 'suomi, suomen kieli' },
  { code: 'fr', name: 'Franska', nativeName: 'français, langue française' },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { code: 'gl', name: 'Galiciska', nativeName: 'Galego' },
  { code: 'ka', name: 'Georgiska', nativeName: 'ქართული' },
  { code: 'de', name: 'Tyska', nativeName: 'Deutsch' },
  { code: 'el', name: 'Grekiska, Modern', nativeName: 'Ελληνικά' },
  { code: 'gn', name: 'Guaraní', nativeName: 'Avañeẽ' },
  { code: 'gu', name: 'Gujarati', nativeName: 'ગુજરાતી' },
  { code: 'ht', name: 'Haitisk kreol', nativeName: 'Kreyòl ayisyen' },
  { code: 'ha', name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { code: 'he', name: 'Hebreiska (modern)', nativeName: 'עברית' },
  { code: 'hz', name: 'Herero', nativeName: 'Otjiherero' },
  { code: 'hi', name: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
  { code: 'ho', name: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { code: 'hu', name: 'Ungerska', nativeName: 'Magyar' },
  { code: 'ia', name: 'Interlingua', nativeName: 'Interlingua' },
  { code: 'id', name: 'Indonesiska', nativeName: 'Bahasa Indonesia' },
  {
    code: 'ie',
    name: 'Interlingue',
    nativeName: 'Ursprungligen kallad Occidental; sedan interling efter andra världskriget',
  },
  { code: 'ga', name: 'Iriska', nativeName: 'Gaeilge' },
  { code: 'ig', name: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { code: 'ik', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { code: 'io', name: 'Ido', nativeName: 'Ido' },
  { code: 'is', name: 'Isländska', nativeName: 'Íslenska' },
  { code: 'it', name: 'Italienska', nativeName: 'Italiano' },
  { code: 'iu', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { code: 'ja', name: 'Japanska', nativeName: '日本語 (にほんご／にっぽんご)' },
  { code: 'jv', name: 'Javanesiska', nativeName: 'basa Jawa' },
  {
    code: 'kl',
    name: 'Grönländska',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { code: 'kn', name: 'Kannada, Kanaresiska', nativeName: 'ಕನ್ನಡ' },
  { code: 'kr', name: 'Kanuri', nativeName: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { code: 'kk', name: 'Kazakiska', nativeName: 'Қазақ тілі' },
  { code: 'km', name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { code: 'ki', name: 'Kikuyu', nativeName: 'Gĩkũyũ' },
  { code: 'rw', name: 'Rwanda, Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { code: 'ky', name: 'Kirgiziska, kirgisiska', nativeName: 'кыргыз тили' },
  { code: 'kv', name: 'Komi', nativeName: 'коми кыв' },
  { code: 'kg', name: 'Kongo', nativeName: 'KiKongo' },
  {
    code: 'ko',
    name: 'Koreanska',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { code: 'ku', name: 'Kurdiska', nativeName: 'Kurdî, كوردی‎' },
  { code: 'kj', name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { code: 'la', name: 'Latin', nativeName: 'latine, lingua latina' },
  {
    code: 'lb',
    name: 'Luxemburgiska',
    nativeName: 'Lëtzebuergesch',
  },
  { code: 'lg', name: 'Luganda', nativeName: 'Luganda' },
  {
    code: 'li',
    name: 'Limburgska',
    nativeName: 'Limburgs',
  },
  { code: 'ln', name: 'Lingala', nativeName: 'Lingála' },
  { code: 'lo', name: 'Lao', nativeName: 'ພາສາລາວ' },
  { code: 'lt', name: 'Litauiska', nativeName: 'lietuvių kalba' },
  { code: 'lu', name: 'Luba-Katanga', nativeName: '' },
  { code: 'lv', name: 'Lettiska', nativeName: 'latviešu valoda' },
  { code: 'gv', name: 'Manx, Manniska', nativeName: 'Gaelg, Gailck' },
  { code: 'mk', name: 'Makedonska', nativeName: 'македонски јазик' },
  { code: 'mg', name: 'Malagassiska', nativeName: 'Malagasy fiteny' },
  { code: 'ms', name: 'Malajiska', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { code: 'ml', name: 'Malayalam', nativeName: 'മലയാളം' },
  { code: 'mt', name: 'Maltesiska', nativeName: 'Malti' },
  { code: 'mi', name: 'Māori', nativeName: 'te reo Māori' },
  { code: 'mr', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { code: 'mh', name: 'Marshallesiska', nativeName: 'Kajin M̧ajeļ' },
  { code: 'mn', name: 'Mongoliska', nativeName: 'монгол' },
  { code: 'na', name: 'Nauriska', nativeName: 'Ekakairũ Naoero' },
  { code: 'nv', name: 'Navajo, Navaho', nativeName: 'Diné bizaad, Dinékʼehǰí' },
  { code: 'nb', name: 'Nynorska', nativeName: 'Norsk bokmål' },
  { code: 'nd', name: 'Norra Ndebele', nativeName: 'isiNdebele' },
  { code: 'ne', name: 'Nepali', nativeName: 'नेपाली' },
  { code: 'ng', name: 'Ndonga', nativeName: 'Owambo' },
  { code: 'no', name: 'Norska', nativeName: 'Norsk' },
  { code: 'ii', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { code: 'nr', name: 'Sydndebele', nativeName: 'isiNdebele' },
  { code: 'oc', name: 'Occitanska', nativeName: 'Occitan' },
  { code: 'oj', name: 'Ojibwe, Anishinaabemowin', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    code: 'cu',
    name: 'Fornkyrkoslaviska, fornslaviska eller gammalmakedonska',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { code: 'om', name: 'Oromo', nativeName: 'Afaan Oromoo' },
  { code: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { code: 'os', name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { code: 'pa', name: 'Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { code: 'pi', name: 'Pali', nativeName: 'पाऴि' },
  { code: 'fa', name: 'Persiska', nativeName: 'فارسی' },
  { code: 'pl', name: 'Polska', nativeName: 'polski' },
  { code: 'ps', name: 'Pashto', nativeName: 'پښتو' },
  { code: 'pt', name: 'Portugisiska', nativeName: 'Português' },
  { code: 'qu', name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { code: 'rm', name: 'Rätoromanska', nativeName: 'rumantsch grischun' },
  { code: 'rn', name: 'Kirundi', nativeName: 'kiRundi' },
  { code: 'ro', name: 'Rumänska', nativeName: 'română' },
  { code: 'ru', name: 'Ryska', nativeName: 'русский язык' },
  { code: 'sa', name: 'Sanskrit', nativeName: 'संस्कृतम्' },
  { code: 'sc', name: 'Sardiska', nativeName: 'sardu' },
  { code: 'sd', name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { code: 'se', name: 'Nordsamiska', nativeName: 'Davvisámegiella' },
  { code: 'sm', name: 'Samoanska', nativeName: 'gagana faa Samoa' },
  { code: 'sg', name: 'Sango', nativeName: 'yângâ tî sängö' },
  { code: 'sr', name: 'Serbiska', nativeName: 'српски језик' },
  { code: 'gd', name: 'Skotsk gaeliska', nativeName: 'Gàidhlig' },
  { code: 'sn', name: 'Shona', nativeName: 'chiShona' },
  { code: 'si', name: 'Singalesiska', nativeName: 'සිංහල' },
  { code: 'sk', name: 'Slovakiska', nativeName: 'slovenčina' },
  { code: 'sl', name: 'Slovenska', nativeName: 'slovenščina' },
  { code: 'so', name: 'Somaliska', nativeName: 'Soomaaliga, af Soomaali' },
  { code: 'st', name: 'Sesotho', nativeName: 'Sesotho' },
  { code: 'es', name: 'Spanska', nativeName: 'español, castellano' },
  { code: 'su', name: 'Sundanesiska', nativeName: 'Basa Sunda' },
  { code: 'sw', name: 'Swahili', nativeName: 'Kiswahili' },
  { code: 'ss', name: 'Siswati', nativeName: 'SiSwati' },
  { code: 'sv', name: 'Svenska', nativeName: 'svenska' },
  { code: 'ta', name: 'Tamil', nativeName: 'தமிழ்' },
  { code: 'te', name: 'Telugu', nativeName: 'తెలుగు' },
  { code: 'tg', name: 'Tadzjikiska', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { code: 'th', name: 'Thailändska', nativeName: 'ไทย' },
  { code: 'ti', name: 'Tigrinska', nativeName: 'ትግርኛ' },
  {
    code: 'bo',
    name: 'Tibetanska',
    nativeName: 'བོད་ཡིག',
  },
  { code: 'tk', name: 'Turkmeniska', nativeName: 'Türkmen, Түркмен' },
  { code: 'tl', name: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { code: 'tn', name: 'Setswana', nativeName: 'Setswana' },
  { code: 'to', name: 'Tonganska', nativeName: 'faka Tonga' },
  { code: 'tr', name: 'Turkiska', nativeName: 'Türkçe' },
  { code: 'ts', name: 'Tsonga', nativeName: 'Xitsonga' },
  { code: 'tt', name: 'Tatariska', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { code: 'tw', name: 'Twi', nativeName: 'Twi' },
  { code: 'ty', name: 'Tahitiska', nativeName: 'Reo Tahiti' },
  { code: 'ug', name: 'Uiguriska', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { code: 'uk', name: 'Ukrainska', nativeName: 'українська' },
  { code: 'ur', name: 'Urdu', nativeName: 'اردو' },
  { code: 'uz', name: 'Uzbekiska', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { code: 've', name: 'Venda', nativeName: 'Tshivenḓa' },
  { code: 'vi', name: 'Vietnamesiska', nativeName: 'Tiếng Việt' },
  { code: 'vo', name: 'Volapük', nativeName: 'Volapük' },
  { code: 'wa', name: 'Vallonska', nativeName: 'Walon' },
  { code: 'cy', name: 'Kymriska', nativeName: 'Cymraeg' },
  { code: 'wo', name: 'Wolof', nativeName: 'Wollof' },
  { code: 'fy', name: 'Västfrisiska', nativeName: 'Frysk' },
  { code: 'xh', name: 'Xhosa', nativeName: 'isiXhosa' },
  { code: 'yi', name: 'Jiddisch', nativeName: 'ייִדיש' },
  { code: 'yo', name: 'Yoruba', nativeName: 'Yorùbá' },
  { code: 'za', name: 'Zhuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];
