import ListHeader from '../../../common/ListHeader/ListHeader';

import css from './Header.module.scss';
import BaseButton from '../../../../common/BaseButton/BaseButton';

type Props = {};

const Header = ({
  nSelectedCandidates,
  candidates,
  toggleAllCandidates,
  openSmsModal,
  openAddCandidateModal,
}: any) => {
  const selectedText = 'markerade';
  const isSlectedCandidates = !!nSelectedCandidates;
  const allIsSelected =
    isSlectedCandidates && nSelectedCandidates === candidates.length;
  const sCandidatesSelected = allIsSelected
    ? 'Alla markerade'
    : `${nSelectedCandidates} ${selectedText}`;

  const buttonTitle = `Nytt jobbutskick till ${nSelectedCandidates} kandidater`;
  return (
    <ListHeader>
      <input
        id="allCandidates"
        type="checkbox"
        checked={nSelectedCandidates === candidates.length}
        onChange={toggleAllCandidates}
      />
      <label htmlFor="allCandidates">
        <h3>Kandidater</h3>
      </label>
      <div className={css['header__ctas']}>
        <BaseButton
          title={buttonTitle}
          onClick={openSmsModal}
          styleType="cta button--small button--add"
          disabled={nSelectedCandidates < 1}
        />
        <BaseButton
          title="Lägg till kandidat"
          onClick={openAddCandidateModal}
          styleType="text--solid button--small button--add"
        />
      </div>

      <p className={css['tab-content-header__selected-count']}>
        {sCandidatesSelected}
      </p>
    </ListHeader>
  );
};

export default Header;
