import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import useWindowWidth from '../../hooks/UI/useWindowWidth';

import Copyright from '../../components/Copyright/Copyright';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import BaseInput from '../../components/common/inputs/BaseInput/BaseInput';
import Header from '../../components/Header/Header';

import InputAutocomplete from '../../components/common/inputs/InputAutocomplete/InputAutocomplete';

import css from './Apply.module.scss';

const ApplyDone = () => {
  const [width] = useWindowWidth();
  const navigate = useNavigate();

  type FormValues = {
    firstName: string | null;
    lastName: string | null;
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
    },
  });

  const locationInLs = JSON.parse(localStorage.getItem('location') as any);
  const [location, setLocation] = useState<any>(locationInLs || null);

  const onSubmit = (data: any) => {
    localStorage.setItem('firstName', data.firstName);
    localStorage.setItem('lastName', data.lastName);
    localStorage.setItem('location', JSON.stringify(location));

    navigate('/apply-email');
  };

  const storeSelectedLocationHandler = (locationObj: {
    formatted: '';
    geoData: {
      coordinates: {
        lat: number;
        long: number;
      };
    };
  }) => {
    setLocation(locationObj);
  };

  return (
    <>
      {width < 481 && <ProgressBar filler="33" />}

      <div className={css.root}>
        <Header showLogoOnly hasDarkText isStatic />
        <div className={css.formBox}>
          {width > 481 && <ProgressBar filler="33" />}
          <section className={css.section}>
            <h3>Vad heter du?</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <BaseInput
                label="Förnamn"
                placeholder="Zlatan"
                type="text"
                options={{
                  hasError: errors.firstName,
                }}
                {...register('firstName', {
                  required: true,
                })}
              />
              <BaseInput
                label="Efternamn"
                placeholder="Ibrahimovic"
                type="text"
                options={{
                  hasError: errors.lastName,
                }}
                {...register('lastName', {
                  required: true,
                })}
              />
              <InputAutocomplete
                // allowAutomaticDetection
                onSelectLocation={storeSelectedLocationHandler}
                defaultLocation={location}
                label="Vart bor du?"
                componentRestrictions={{ country: 'se' }}
                types={['address']}
              />
              <button
                className="button--cta button--full-width"
                disabled={!isValid || !location}
                type="submit"
              >
                Nästa
              </button>
            </form>
          </section>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default ApplyDone;
