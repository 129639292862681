import css from './BaseButton.module.scss';

type Props = {
  styleType?: string;
  type?: any;
  title: string;
  isLoading?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
};

const LoadingEllipsis = () => {
  return (
    <div className={css['lds-ellipsis']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const BaseButton = ({
  disabled,
  styleType = 'cta',
  title,
  isLoading,
  type = 'button',
  noMargin,
  onClick,
  onMouseDown,
}: Props) => {
  return (
    <button
      onClick={onClick ? onClick : undefined}
      onMouseDown={onMouseDown ? onMouseDown : undefined}
      style={{ overflow: 'hidden', ...(!!noMargin && { margin: 0 }) }}
      disabled={disabled}
      type={type}
      className={`${css['button']} button--${styleType}`}
    >
      {!isLoading ? (
        title
      ) : (
        <div className={css['loading-wrapper']}>
          <LoadingEllipsis />
        </div>
      )}
    </button>
  );
};

export default BaseButton;
