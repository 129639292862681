import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PoolService } from '../../services/database-service';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallableWrapper } from '../../firebase';
import { Timestamp, doc, getDoc } from 'firebase/firestore';

const defaultValues = {
  pools: [],
  candidates: [],
  nSelectedCandidates: 0,
  selectedCandidates: [{}],
  candidateIds: [],
  addCandidatesToPool: (candidates: any) => {},
  confirmRecipient: (dispatchId: string, candidateId: string) => {},
  unconfirmRecipient: (dispatchId: string, candidateId: string) => {},
  disqualifyRecipient: (dispatchId: string, candidateId: string) => {},
  removeCandidateFromPool: (id: string) => {},
  toggleCandidate: (id: string) => {},
  isSelectedCandidate: (id: string) => false,
  toggleAllCandidates: () => {},
  currentPoolTitle: '',
  addNewPool: (data: any) => {},
  createPoolMessage: (data: any) => {},
  deletePool: () => {},
};

export const PoolContext = createContext(defaultValues);

const PoolContextProvider: FC = ({ children }) => {
  const [pools, setPools] = useState<any>([]);
  const [candidatesMap, setCandidatesMap] = useState(Object.create(null));
  const [selectedCandidateIds, setSelectedCandidateIds] = useState(
    Object.create(null)
  );

  // poolId from URL
  const { poolId } = useParams();
  const navigate = useNavigate();

  const currentPoolTitle = useMemo(
    () => pools.find(({ id }: any) => id === poolId)?.data?.title,
    [poolId, pools]
  );

  const candidates = useMemo(
    () => (poolId && candidatesMap[poolId] ? candidatesMap[poolId] : []),
    [candidatesMap, poolId]
  );

  const candidateIds = useMemo(
    () => candidates.map((candidate: any) => candidate.id),
    [candidates]
  );

  const selectedCandidates = useMemo(() => {
    return candidates.filter(
      (candidate: any) => selectedCandidateIds[candidate.id]
    );
  }, [candidates, selectedCandidateIds]);

  const nSelectedCandidates = useMemo(
    () => Object.keys(selectedCandidateIds).length,
    [selectedCandidateIds, poolId]
  );

  const selectCandidate = (id: string) => {
    setSelectedCandidateIds((ids: any) => {
      return { ...ids, [id]: true };
    });
  };
  const selectAllCandidates = () => {
    candidates.forEach(({ id }: any) => {
      selectCandidate(id);
    });
  };

  const deselectCandidate = (id: string) => {
    setSelectedCandidateIds((ids: any): any => {
      const newIds = { ...ids };
      delete newIds[id];
      return newIds;
    });
  };

  const isSelectedCandidate = (id: string) => {
    return !!selectedCandidateIds[id];
  };

  const toggleCandidate = (id: string) => {
    isSelectedCandidate(id) ? deselectCandidate(id) : selectCandidate(id);
  };

  const toggleAllCandidates = () => {
    !Object.keys(selectedCandidateIds).length
      ? selectAllCandidates()
      : setSelectedCandidateIds(Object.create(null));
  };

  // add new pool to db and state
  const addNewPool = async ({ title, companyName }: any) => {
    try {
      const { id } = await PoolService.create({ title, companyName });
      setPools((prev: any) => {
        const newPool = { id, data: { title, companyName } };
        return [...prev, newPool];
      });
    } catch (err) {
      console.error(err);
    }
  };

  // remove pool from state and db
  const deletePool = async () => {
    const callableFn = httpsCallableWrapper('pools-deletePool');
    try {
      await callableFn({ id: poolId });
      setPools((prev: any) => {
        return prev.filter(({ id }: any) => id !== poolId);
      });
      navigate('/dashboard/pools');
    } catch (err) {
      console.error(err);
    }
  };

  // data should come from react-hook-form, which will be stored in modal.
  const createPoolMessage = async (data: any) => {
    const callableFn = httpsCallableWrapper('pools-initMessagePool');

    try {
      const messageData = {
        ...data,
        poolId,
        recipientIds: Object.keys(selectedCandidateIds),
        createdAt: Timestamp.now(),
      };
      await callableFn(messageData);
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedCandidateIds(Object.create(null));
    }
  };

  const addCandidatesToPool = async (candidateIds: any) => {
    const callableFn = httpsCallableWrapper('pools-addCandidatesToPool');
    console.log(poolId, candidateIds);
    try {
      if (!poolId) {
        throw new Error('PoolId not found.');
      }
      if (!candidatesMap[poolId]) {
        throw new Error('Candidates in pool not found');
      }
      const res = await callableFn({ poolId, candidateIds });
      console.log(res);

      const newCandidates = JSON.parse(res.data as string);

      setCandidatesMap((prev: any) => {
        const updatedPoolCandidates = [...prev[poolId], ...newCandidates];
        return { ...prev, [poolId]: updatedPoolCandidates };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const confirmRecipient = async (dispatchId: string, candidateId: string) => {
    try {
      if (!poolId) {
        throw new Error('PoolId not found.');
      }
      if (!candidatesMap[poolId]) {
        throw new Error('Candidates in pool not found');
      }

      await PoolService.go(poolId, 'messages', dispatchId, 'recipients').update(
        candidateId,
        {
          isConfirmed: true,
        }
      );

      setCandidatesMap((prev: any) => {
        const updatedPoolCandidates = prev[poolId].map((candidate: any) => {
          if (candidate.id === candidateId) {
            return { ...candidate, isConfirmed: true };
          } else {
            return candidate;
          }
        });
        return { ...prev, [poolId]: updatedPoolCandidates };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const unconfirmRecipient = async (
    dispatchId: string,
    candidateId: string
  ) => {
    try {
      if (!poolId) {
        throw new Error('PoolId not found.');
      }
      if (!candidatesMap[poolId]) {
        throw new Error('Candidates in pool not found');
      }

      await PoolService.go(poolId, 'messages', dispatchId, 'recipients').update(
        candidateId,
        {
          isConfirmed: false,
        }
      );

      setCandidatesMap((prev: any) => {
        const updatedPoolCandidates = prev[poolId].map((candidate: any) => {
          if (candidate.id === candidateId) {
            return { ...candidate, isConfirmed: false };
          } else {
            return candidate;
          }
        });
        return { ...prev, [poolId]: updatedPoolCandidates };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const disqualifyRecipient = async (
    dispatchId: string,
    candidateId: string
  ) => {
    const callableFn = httpsCallableWrapper('pools-disqualifyRecipient');
    try {
      if (!poolId) {
        throw new Error('PoolId not found.');
      }
      if (!candidatesMap[poolId]) {
        throw new Error('Candidates in pool not found');
      }

      await callableFn({
        poolId,
        messageId: dispatchId,
        recipientId: candidateId,
      });

      setCandidatesMap((prev: any) => {
        const updatedPoolCandidates = prev[poolId].map((candidate: any) => {
          if (candidate.id === candidateId) {
            return { ...candidate, isDisqualified: true };
          } else {
            return candidate;
          }
        });
        return { ...prev, [poolId]: updatedPoolCandidates };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const removeCandidateFromPool = async (id: string) => {
    try {
      if (!poolId) {
        throw new Error('PoolId not found.');
      }
      if (!candidatesMap[poolId]) {
        throw new Error('Candidates in pool not found');
      }
      await PoolService.go(poolId, 'candidates').remove(id);

      setCandidatesMap((prev: any) => {
        const updatedPoolCandidates = prev[poolId].filter(
          (candidate: any) => candidate.id !== id
        );
        return { ...prev, [poolId]: updatedPoolCandidates };
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!!pools.length) return;

    PoolService.getAll().then(setPools);
  }, []);

  useEffect(() => {
    setSelectedCandidateIds(Object.create(null));
    if (!poolId || !!candidatesMap[poolId]) return;

    PoolService.go(poolId, 'candidates')
      .getAll()
      .then((data: any) => {
        setCandidatesMap((prev: any) => {
          return { ...prev, [poolId]: data };
        });
      });
  }, [poolId]);

  return (
    <PoolContext.Provider
      value={{
        pools,
        candidates,
        nSelectedCandidates,
        selectedCandidates,
        candidateIds,
        addCandidatesToPool,
        removeCandidateFromPool,
        confirmRecipient,
        unconfirmRecipient,
        disqualifyRecipient,
        toggleAllCandidates,
        toggleCandidate,
        isSelectedCandidate,
        currentPoolTitle,
        addNewPool,
        createPoolMessage,
        deletePool,
      }}
    >
      {children}
    </PoolContext.Provider>
  );
};

export default PoolContextProvider;
