import { useFormContext } from 'react-hook-form';

// components
import TextareaAutosize from 'react-textarea-autosize';

const Other = ({ interviewExists }: any) => {
  const { register, getValues } = useFormContext();
  const renderOverallImpression = () => {
    let text = getValues('criterias.other.comment');
    return !text ? <p>-- ingen anteckning --</p> : <q>{text}</q>;
  };
  return !interviewExists ? (
    <TextareaAutosize
      minRows={5}
      {...register('criterias.other.comment', { required: false })}
    />
  ) : (
    renderOverallImpression()
  );
};

export default Other;
