import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import {
  getFirestore,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  collection,
} from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import { getApp } from 'firebase/app';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import {
  getAuth,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  connectAuthEmulator,
} from '@firebase/auth';

import { useState, useEffect, useContext, createContext } from 'react';

// breakout
let firebaseConfig = {
  apiKey: 'AIzaSyDdg6AkJ_Ypi8CSyCqOKhgtYgjkzzRGeLA',
  authDomain: 'kila-4457f.firebaseapp.com',
  databaseURL:
    'https://kila-4457f-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'kila-4457f',
  storageBucket: 'kila-4457f.appspot.com',
  messagingSenderId: '452595453942',
  appId: '1:452595453942:web:30eaba9bdbb56104d201d7',
  measurementId: 'G-L29419XWR5',
};

firebase.initializeApp(firebaseConfig);

// Returns the existing Firestore instance
export const db = getFirestore();

// export const storage = firebase.storage();
export const database = firebase.database();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = getFunctions(getApp(), 'europe-west1');
export const AuthContext = createContext();

export const httpsCallableWrapper = (fnName) =>
  httpsCallable(functions, fnName);

// connect firestore emulator
if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  const auth = getAuth();
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        setIsAdmin(user?.reloadUserInfo.customAttributes);
        setUser(user);
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);
  return (
    <AuthContext.Provider value={{ user, error, isAdmin }} {...props}>
      {!loading ? props.children : null}
    </AuthContext.Provider>
  );
};

export const savePicture = (file) => {
  const storage = firebase.storage();
  if (!file) return;
  const storageRef = ref(storage, `/resumes/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      console.log('Progress', prog);
    },
    (err) => console.log(err),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) =>
        console.log('url', url)
      );
    }
  );
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return {
    ...auth,
    isAuthenticated: auth.user != null,
    isAdmin: !!auth.isAdmin,
  };
};

export const getFirestoreCollection = (collectionName) => {
  return new Promise(async (resolve, reject) => {
    const collectionResult = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionResult);
    if (!querySnapshot.docs.length) return reject('No documents available');
    resolve(
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }))
    );
  });
};

export const getDocOfCollection = async (collectionName, docId) => {
  const docRef = doc(db, collectionName, docId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) return;
  return docSnap.data();
};

export const sendEmailVerification = async (candidateId, email) => {
  if (!email) {
    throw new Error('Mejl saknas, vänligen kontakta Kila Support');
  }
  const auth = getAuth();
  const devEnv = window.location.hostname === 'localhost';
  const environmentUrl = devEnv ? 'http://localhost:3000' : 'https://kila.se';
  const urlString = `${environmentUrl}/apply-verified?uid=${candidateId}&email=${email}`;

  try {
    await sendSignInLinkToEmail(auth, email, {
      url: urlString,
      handleCodeInApp: true,
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const signInWithEmailAndRedirect = async (email, queryParams = '') => {
  if (!email) {
    throw new Error('Mejl saknas, vänligen kontakta Kila Support');
  }
  const auth = getAuth();
  const devEnv = window.location.hostname === 'localhost';
  const environmentUrl = devEnv ? 'http://localhost:3000' : 'https://kila.se';
  const urlString = `${environmentUrl}/loginwithemail${
    queryParams ? `?${queryParams}` : ''
  }`;

  try {
    await sendSignInLinkToEmail(auth, email, {
      url: urlString,
      handleCodeInApp: true,
    });
  } catch (err) {
    throw new Error(err);
  }
};
