import { FC, useRef, useEffect } from 'react';
// import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';
import css from './Toolbox.module.scss';

type Props = {
  onDeleteItem?: () => void;
  closeToolbox?: () => void;
};

export const ToolboxItem = ({ title, onClick }: any) => {
  return (
    <button type="button" className="button--text--plain" onClick={onClick}>
      <span>{title}</span>
    </button>
  );
};

const Toolbox: FC<Props> = ({ closeToolbox, children }) => {
  const toolboxRef = useRef(null);
  // detect if click occured outside of predictionsList, if so, hide list.
  const detectClickRegionHandler = function (
    this: HTMLUListElement,
    { target: elClicked }: any
  ) {
    const potentialParent = this as any;
    const clickOccurredOutside = !potentialParent.contains(elClicked);

    if (clickOccurredOutside && closeToolbox) {
      closeToolbox();
    }
  };

  // add click listener to document and bind predictionsList to fn param  .
  useEffect(() => {
    if (!toolboxRef.current) return;

    const detectClickRegion = detectClickRegionHandler.bind(toolboxRef.current);

    document.addEventListener('click', detectClickRegion);
    return () => {
      document.removeEventListener('click', detectClickRegion);
    };
  }, []);

  return (
    <div className={css.toolbox} ref={toolboxRef}>
      <div className={css['square-glyph']}></div>
      {children}
    </div>
  );
};

export default Toolbox;
