import css from './Cta.module.scss';
import classNames from 'classnames';

import BackgroundImage from '../../../website/assets/images/for-clients-desktop.webp';

import Heading from '../../../website/components/atoms/Heading/Heading';
import Button from '../../../website/components/atoms/Button/Button';


type Props = {
  copy: {
    heading: string;
    buttonCta?: {
      text: string;
      link: string;
    };
    buttonDefault?: {
      text: string;
      link: string;
    };
  },
};

const Cta = ({ copy }: Props) => {
  const { heading, buttonCta, buttonDefault } = copy;

  const sectionClasses = classNames(
    'website__section--less-padding',
    css['root'],
  );

  const styles = {
    root: {
      'backgroundImage': `url(${BackgroundImage})`,
    }
  };

  return (
    <section className={sectionClasses} style={styles.root}>
      <div className={css['section__inner']}>
        <Heading level="h2" looksLike="h4" customClasses={css['cta__heading']}>{heading}</Heading>
        <div className={css['button-wrapper']}>
          {buttonDefault && <Button label={buttonDefault.text} link={buttonDefault.link} type="default" customClasses={css['cta__button--default']} />}
          {buttonCta && <Button label={buttonCta.text} link={buttonCta.link} type="cta" />}
        </div>
      </div>
    </section>
  );
};

export default Cta;

