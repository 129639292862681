import { FC, useContext } from 'react';
import { CandidateProfileContext } from '../../../context/candidate-profile-context';
import CandidateCardHeader from './CandidateCardHeader';
import LoadingText from '../../../components/Progress/LoadingText/LoadingText';

import css from './CandidateCard.module.scss';

type Props = {
  isProfileCard?: boolean;
  heading?: any;
  buttons: any;
  children: React.ReactNode;
};

const CandidateCard: FC<Props> = ({
  isProfileCard,
  heading,
  buttons,
  children,
}) => {
  const ctx = useContext(CandidateProfileContext);

  return (
    <article className={`card ${css['card']}`}>
      {/* {isProfileCard && (
        <header className={css['candidate__header']}>
          <Avatar />
        </header>
      )} */}
      <div className={css['card__content']}>
        <CandidateCardHeader
          isProfileCard={isProfileCard}
          heading={
            isProfileCard ? (
              <LoadingText style={{ maxWidth: '100%' }} className="h1">
                {!ctx.isLoading && (
                  <h3 className={`h1 ${css['name-capitalized']}`}>
                    Hej, {ctx?.candidate?.firstName}! &#128075;
                  </h3>
                )}
              </LoadingText>
            ) : (
              heading
            )
          }
          buttons={buttons}
        />
        {/* CARD CONTENT. E.G LIST */}
        {children}
      </div>
    </article>
  );
};

export default CandidateCard;
