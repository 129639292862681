import { useMemo, useState } from 'react';

// components
import UpdateStatus from '../../components/UpdateStatus';
import StatusForm from '../../components/UpdateStatus/Form';
import Comment from '../../components/UpdateStatus/Comment';
import StatusSelect from '../../components/UpdateStatus/StatusSelect';
import SaveButton from '../../components/UpdateStatus/SaveButton';
import SubmitButton from '../../components/UpdateStatus/SubmitButton';

type Props = {
  currentStatus?: string;
  onSetStatus: ({ status, comment }: any) => Promise<any>;
  label?: string;
  buttonTitle?: string;
  isForm: boolean;
  options: string[];
};

const SetStatus = ({
  currentStatus,
  onSetStatus,
  label,
  buttonTitle,
  isForm,
  options,
}: Props) => {
  const [status, setStatus] = useState(currentStatus);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (e: any) => {
    e.preventDefault();

    setIsLoading(true);
    onSetStatus({ status, comment })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setComment('');
        setIsLoading(false);
      });
  };

  const setCommentHandler = (e: any) => {
    setComment(e.target.value);
  };

  // jsx
  const button = isForm ? (
    <SubmitButton
      isLoading={isLoading}
      buttonTitle={buttonTitle}
      isDisabled={currentStatus === status}
    />
  ) : (
    <SaveButton
      isLoading={isLoading}
      buttonTitle={buttonTitle}
      onClick={onSubmitHandler}
      isDisabled={currentStatus === status}
    />
  );

  const setStatusJsx = (
    <UpdateStatus label={label}>
      <StatusSelect
        setStatus={setStatus}
        currentStatus={currentStatus}
        options={options}
      />
      <Comment comment={comment} setComment={setCommentHandler} />
      {button}
    </UpdateStatus>
  );

  return isForm ? (
    <StatusForm onSubmit={onSubmitHandler}>{setStatusJsx}</StatusForm>
  ) : (
    setStatusJsx
  );
};

export default SetStatus;
