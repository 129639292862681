// styles
import css from './BaseBadge.module.scss';

type Props = {
  icon?: string;
  title?: string;
  color?: {
    background?: string;
    text?: string;
  };
  className?: string;
};

const BaseBadge = ({ icon, title, color, className }: Props) => {
  const iconJsx = icon && (
    <i className={`fa-solid ${icon}`} style={{ color: color?.text }} />
  );
  const titleJsx = title && (
    <p className="no-vertical-margin" style={{ color: color?.text }}>
      {title}
    </p>
  );

  return (
    <div
      className={`${css['badge']} ${className ? className : ''}`}
      style={{ background: color?.background }}
    >
      {iconJsx}
      {titleJsx}
    </div>
  );
};

export default BaseBadge;
