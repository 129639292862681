import { FC, ReactNode } from 'react';
import css from './TagList.module.scss';
import { firstToUpper } from '../../../utils/convert/typography';

// components
import EditPen from '../../EditPen/EditPen';

type TagItemProps = {
  title?: string;
  level?: any;
  children?: ReactNode;
};

type EditableTagProps = {
  title: string;
  level?: any;
  onTriggerEdit: () => void;
};

const TagListItem: FC<TagItemProps> = ({ title, level, children }) => {
  return (
    <li className={css['rating-wrapper']}>
      {children ? children : title && firstToUpper(title)}
      {level && <span className={css['rating']}>({firstToUpper(level)})</span>}
    </li>
  );
};

export const EditableTag = ({
  title,
  level,
  onTriggerEdit,
}: EditableTagProps) => {
  return (
    <div className={css['editable-item-container']}>
      <TagListItem title={title} level={level} />

      <EditPen onTriggerEdit={onTriggerEdit} />
    </div>
  );
};

export default TagListItem;
