import { useState } from 'react';
import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';
import BaseInput from '../../common/inputs/BaseInput/BaseInput';
import DateOfBirth from '../../DateOfBirth/DateOfBirth';
import InputAutocomplete from '../../common/inputs/InputAutocomplete/InputAutocomplete';

const PersonalInfoForm = ({
  candidate,
  updatePersonalInfo,
  setPotentiallyChangedFields,
}: any) => {
  const {
    firstName,
    lastName,
    phone,
    dateOfBirth,
    location: candidateLocation,
  } = candidate;
  const [location, setLocation] = useState<any>(null);
  const [birthday, setBirthday] = useState<any>(null);
  const [isValidBirthday, setIsValidBirthday] = useState<any>(false);
  const [isValid, setIsValid] = useState<any>(false);

  const onSelectLocationHandler = (location: any) => {
    setLocation(location);
    setIsValid(true);
  };

  const onSetBirthdayHandler = ({ year, month, day }: any) => {
    if (
      !String(year)?.length ||
      !String(month)?.length ||
      !String(day)?.length
    ) {
      return setIsValidBirthday(false);
    }

    const dateOfBirth = new Date(
      year,
      Number(month) - 1,
      Number(day)
    ).toISOString();

    setBirthday(dateOfBirth);
    setIsValidBirthday(true);
  };

  return (
    <FormGroup
      buttonText="Spara"
      FormIsValid={isValid && isValidBirthday}
      onSaveData={(data: any) => {
        if (!location) return;
        if (!birthday) return;

        updatePersonalInfo({
          newValues: { ...data, location, dateOfBirth: birthday },
        });
      }}
      setPotentiallyChangedFields={setPotentiallyChangedFields}
    >
      <div className="adaptable-flex-direction no-mobile-gap">
        <GroupInstance
          warnOnUnsavedData
          validation={{ pattern: /^[a-z]+$/i, isRequired: true }}
          onValidateInstance={setIsValid}
        >
          <BaseInput
            customProps={{ noBottomMargin: true }}
            type="text"
            label="Förnamn"
            inputid="firstName"
            name="firstName"
            defaultValue={firstName}
          />
        </GroupInstance>
        <GroupInstance
          validation={{ pattern: /^.{1,}$/, isRequired: true }}
          onValidateInstance={setIsValid}
        >
          <BaseInput
            customProps={{ noBottomMargin: true }}
            type="text"
            label="Efternamn"
            inputid="lastName"
            name="lastName"
            defaultValue={lastName}
          />
        </GroupInstance>
      </div>
      <div className="adaptable-flex-direction no-mobile-gap">
        <GroupInstance
          warnOnUnsavedData
          validation={{
            pattern:
              /^(\+\d{1,2}(\s)?)?\(?\d{2,3}(\s)?([-])?\)?\d{3}(\s)?\d{2}(\s)?\d{2}$/,
            isRequired: true,
          }}
          onValidateInstance={setIsValid}
        >
          <BaseInput
            customProps={{ noBottomMargin: true }}
            type="tel"
            label="Telefonnummer"
            placeholder="070-XXX XX XX"
            inputid="phone"
            name="phone"
            defaultValue={phone}
          />
        </GroupInstance>
        <DateOfBirth
          onSetDate={onSetBirthdayHandler}
          birthday={dateOfBirth}
          setIsValid={setIsValidBirthday}
        />
      </div>

      <InputAutocomplete
        label="Adress"
        informOfChange={() => setIsValid(false)}
        defaultLocation={candidateLocation}
        onSelectLocation={onSelectLocationHandler}
        componentRestrictions={{ country: 'se' }}
        types={['address']}
      />
    </FormGroup>
  );
};

export default PersonalInfoForm;
