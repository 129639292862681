import BaseBadge from '../../components/common/BaseBadge/BaseBadge';
import { hsla_col_perc } from '../../utils/colors';

type Props = {
  distance: number;
};

const DistanceBadge = ({ distance }: Props) => {
  const RECOMMENDED_DISTANCE = 32; // 10 km
  const colorPercentage = (RECOMMENDED_DISTANCE / distance) * 100;
  const color = hsla_col_perc({
    percent: distance > RECOMMENDED_DISTANCE ? colorPercentage : 100,
    start: {
      h: 0,
      s: 100,
      l: 50,
      a: 1,
    }, // red
    end: {
      h: 159,
      s: 100,
      l: 22,
      a: 1,
    }, // green
  });

  return (
    <BaseBadge
      icon="fa-location-arrow"
      title={`${distance} km`}
      color={{ background: color, text: 'white' }}
    />
  );
};

export default DistanceBadge;
