import { FC } from 'react';
import css from './ColoredBackground.module.scss';

type Props = {
  children: React.ReactNode;
  scssColor: string;
};

const ColoredBackground: FC<Props> = ({ scssColor, children }) => {
  return (
    <div
      className={css['colored-background']}
      style={{ backgroundColor: scssColor }}
    >
      {children}
    </div>
  );
};

export default ColoredBackground;
