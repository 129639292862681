import React from 'react';

import css from './ModalHeader.module.scss';

type Props = {
  children: React.ReactNode;
  title?: string;
};

const ModalHeader: React.FC<Props> = (props) => {
  return (
    <header className={css.modalHeader}>
      <h2 id={css.userDataHeading}>{props.title}</h2>
      <div className={css.headerCtas}>{props.children}</div>
    </header>
  );
};

export default ModalHeader;
