// components
import { useState } from 'react';
import { BaseAccordionItem } from '../../../../components/common/BaseAccordion/BaseAccordion';
import {
  ApplicationExpanded,
  ApplicationPreview,
} from '../../../../components/dashboard/candidate/applications/Item';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { InterviewService } from '../../../../services/database-service';
import ApplicationStatusForm from '../../../ApplicationStatusForm';

const ApplicationsItem = ({
  id,
  data,
  setLocalStatus,
  onNavigateInterview,
  appendParamId,
}: any) => {
  const [isExistingInterview, setIsExistingInterview] = useState<any>(false);

  useEffectOnce(() => {
    InterviewService.exists(id).then((exists) => {
      if (exists) setIsExistingInterview(true);
    });
  });
  return (
    <BaseAccordionItem
      uuid={id}
      preview={
        <ApplicationPreview
          jobTitle={data.title}
          location={data?.location?.geoData?.postal_town}
          locationDistance={data.distanceInKm}
          dateOfSubmission={data.date}
          tags={data.tags}
          onNavigateInterview={onNavigateInterview}
          appendParamId={appendParamId}
          interviewExists={isExistingInterview}
        />
      }
      expanded={
        <>
          <ApplicationExpanded answers={data.candidateAnswers} />
          <ApplicationStatusForm
            candidateId={data.candidateId}
            appId={id}
            currentStatus={data?.status?.name || data?.status}
            onSetStatus={setLocalStatus}
          />
        </>
      }
    />
  );
};

export default ApplicationsItem;
