import { useState } from 'react';
import { useForm } from 'react-hook-form';

// components
import BaseButton from '../../common/BaseButton/BaseButton';
import BaseInput from '../../common/inputs/BaseInput/BaseInput';

type Props = {
  onAddComment: (data: { title: string; body: string }) => Promise<any>;
};

const CommentsForm = ({ onAddComment }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await onAddComment(data);
    setIsLoading(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseInput
        placeholder="Titel"
        {...register('title', { required: true })}
      />

      <textarea
        placeholder="Kommentar"
        rows={7}
        {...register('body', { required: true })}
      ></textarea>
      <BaseButton
        isLoading={isLoading}
        disabled={!isValid || isLoading}
        type="submit"
        title="Spara"
      />
    </form>
  );
};

export default CommentsForm;
