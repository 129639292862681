import classNames from 'classnames';
import { ReactNode } from 'react';

// styles
import css from './index.module.scss';

type Props = {
  title: string;
  infoText?: string;
  isError?: boolean;
  dark?: boolean;
  C: ReactNode;
};

const RatingsListItem = ({ title, infoText, isError, dark, C }: Props) => {
  const liClasses = classNames({
    [css['list-item']]: true,
    [css['dark-theme']]: !!dark,
  });
  const infoTextClasses = classNames({
    h6: true,
    [css['text']]: true,
    error__summary: isError,
    [css['default-color']]: !isError,
  });
  return (
    <>
      <li className={liClasses}>
        <p className="no-vertical-margin">{title}</p>
        {C}
      </li>
      {!!infoText && <h2 className={infoTextClasses}>{infoText}</h2>}
    </>
  );
};

export default RatingsListItem;
