/**
 *
 * @param map
 * @returns
 */

export const disassembleFirestoreMap = (map: any): {}[] => {
  const storage = [];
  for (let key in map) {
    const value = map[key];
    storage.push({ data: value, id: key });
  }

  return storage;
};

export const reassembleFirestoreMap = (arr: any) => {
  const map = Object.create(null);
  arr.forEach(({ data, id }: any) => (map[id] = data));
  return map;
};
