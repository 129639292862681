
import copy from './aboutUsCopy.json';

// Images
import ImageSubpageHeroDesktop from '../../../website/assets/images/shelves-mobile.webp';
import ImageSubpageHeroTablet from '../../../website/assets/images/shelves-tablet.webp';
import ImageSubpageHeroMobile from '../../../website/assets/images/shelves-desktop.webp';

// Shared header with dashboard
import Header from '../../../components/Header/Header';

// Components
import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';

// Sections
import SubpageHeroSection from '../../../website/sections/SubpageHeroSection/SubpageHeroSection';
import TextBlocksSection from '../../../website/sections/TextBlocksSection/TextBlocksSection';
import Quote from '../../../website/sections/Quote/Quote';
import Footer from '../../../website/sections/Footer/Footer';

const Internship = () => {
  const imagesSubpageHero = {
    mobile: ImageSubpageHeroMobile,
    tablet: ImageSubpageHeroTablet,
    desktop: ImageSubpageHeroDesktop
  }

  return (
    <>
      <Header/>
      <SubpageHeroSection copy={copy.subpageHero} textColor="white" backgroundImages={imagesSubpageHero}/>
      <TextBlocksSection copy={copy.textBlocks} />
      {/*<Quote copy={copy.quote} />*/}
      <Footer />
    </>
  );
};

export default Internship;
