import { Link } from 'react-router-dom';
import css from './Heading.module.scss';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  looksLike?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label';
  customClasses?: string;
  noHorizontalMargin?: boolean;
};

const Heading = ({
  children,
  level,
  looksLike,
  customClasses,
  noHorizontalMargin
}: Props) => {

  const CustomTag = `${level}` as keyof JSX.IntrinsicElements;

  const headingClasses = classNames(
    customClasses,
    `${looksLike? css[looksLike] : css[level] }`,
    {[css['noHorizontalMargin']]: noHorizontalMargin},
    );

  return (
    <CustomTag className={headingClasses}>{children}</CustomTag>
  );
};

export default Heading;
