import { FC } from 'react';

type Props = {
  onSubmit: (e?: any) => void;
};

const Form: FC<Props> = ({ onSubmit, children }) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

export default Form;
