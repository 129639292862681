import { FC, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// components
import InterviewForm from '../../../../components/interview/InterviewSection/Form';
import SubmitFooter from './SubmitFooter';

type Props = {
  onSubmit: () => void;
  defaultValues: any;
  isExistingInterview: boolean;
};

const Form: FC<Props> = ({
  children,
  onSubmit,
  defaultValues,
  isExistingInterview,
}) => {
  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: defaultValues,
    shouldFocusError: false,
  });
  const {
    formState: { errors },
  } = methods;

  const isError = useMemo(() => !!Object.values(errors).length, [errors]);

  return (
    <FormProvider {...methods}>
      <InterviewForm onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        {!isExistingInterview && <SubmitFooter isValid={!isError} />}
      </InterviewForm>
    </FormProvider>
  );
};

export default Form;
