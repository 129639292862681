import { useNavigate } from 'react-router';

import { useAuthState } from '../../../firebase';

import BaseCard from '../../../components/common/BaseCard/BaseCard';
import LogoRound from '../../../assets/images/kila-logo-round.png';

import css from './SpecificFormApplied.module.scss';

type Props = {
  applicantName: string;
  applicantEmail: string;
};

const SpecificFormApplied = (props: Props) => {
  const navigate = useNavigate();
  const { applicantEmail, applicantName } = props;
  const { user } = useAuthState();
  const bodyTitle = `Tack för din ansökan${
    applicantName && ', ' + applicantName
  }!`;

  return (
    <BaseCard
      alignContentEnd
      minHeight="530px"
      progressFil="100"
      bodyTitle={bodyTitle}
      logo={LogoRound}
    >
      <p className={css.cardParagraph}>
        Din ansökan kommer inom kort att granskas av någon av våra medarbetare,
        och vi återkopplar till dig om du är bland de som gått vidare för
        intervju.
      </p>
      {!user?.emailVerified && (
        <p className={css.cardParagraph}>
          Under tiden skickar vi ett mejl med instruktioner till{' '}
          <b>{applicantEmail}</b> om hur du går tillväga för att bekräfta din
          mejladress, glöm inte att kolla skräpposten!
        </p>
      )}
      <p className={css.bodyRegards}>
        Mvh, <br />
        <span>Kila-teamet</span>
      </p>
      <button
        className="button--full-width button--cta"
        onClick={() => navigate('/')}
      >
        Kila vidare
      </button>
    </BaseCard>
  );
};

export default SpecificFormApplied;
