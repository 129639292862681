import css from './ContactsListItem.module.scss';
import { ReactComponent as PhoneIcon } from '../../../../../../../assets/icons/phone-line.svg';
import { ReactComponent as MailIcon } from '../../../../../../../assets/icons/email.svg';
type Props = {
  contactData: {
    name: string;
    role: string;
    email: string;
    phone: string;
  };
  onDeleteContact: (position: number) => void;
  onEditContact: (position: number) => void;
  position: number;
};

const ContactsListItem = ({
  contactData,
  onDeleteContact,
  onEditContact,
  position,
}: Props) => {
  const { name, role, email, phone } = contactData;
  return (
    <li className={css['contacts-list__item']}>
      <div className="item-content">
        <div className={css['flex-item']}>
          <p className={css['name']}>
            {name}, <span>{role ? role : 'Ospecificerad roll'}</span>
          </p>
        </div>
        <div className={css['flex-item']}>
          {email && (
            <div className={css['icon-text']}>
              <MailIcon />
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
          {phone && (
            <div className={css['icon-text']}>
              <PhoneIcon />
              <p>{phone}</p>
            </div>
          )}
        </div>
      </div>

      <div className={css['list-item__ctas']}>
        <button
          className="button--icon button--icon--edit"
          onClick={() => onEditContact(position)}
        >
          <span>Redigera</span>
        </button>
        <button
          className="button--text--delete button--small"
          onClick={() => onDeleteContact(position)}
        >
          Ta bort
        </button>
      </div>
    </li>
  );
};

export default ContactsListItem;
