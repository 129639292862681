import classNames from 'classnames';
import css from './IconButton.module.scss';

type Props = {
  styleTypes?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  faIcon: string;
  text: string;
  onClick?: () => void;
  form?: string;
};

const IconButton = ({ styleTypes, type, faIcon, text, ...rest }: Props) => {
  const classes = classNames(
    'button--icon',
    css['button--icon'],
    css[`button--icon--${faIcon}`],
    styleTypes
  );

  return (
    <button type={type || 'button'} className={classes} title={text} {...rest}>
      <i className={`fa fa-${faIcon}`} />
      <span>{text}</span>
    </button>
  );
};

export default IconButton;
