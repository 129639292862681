import { useState, useEffect } from 'react';
import { firstToUpper } from '../../../utils/convert/typography';
import classNames from 'classnames';

import css from './BaseSelect.module.scss';

type Props = {
  label?: string;
  value?: any;
  defaultValue?: string;
  onChange: any;
  options: any;
  flexCol?: boolean;
  warnBeforeChange?: boolean;
  defaultSelected?: string | number;
  selectByIndex?: boolean;
  disabled?: boolean;
  disableOptionsPrior?: number;
  noVerticalMargin?: boolean;
  accessibilityLabel?: boolean;
  optionKey?: string | null;
  defaultOption?: {
    option: string;
    value: string | null;
  };
};

// TODO: refactor component and make it strictly presentational.

const BaseSelect = ({
  label,
  onChange,
  options,
  flexCol,
  defaultOption,
  defaultSelected,
  disabled,
  disableOptionsPrior,
  noVerticalMargin,
  accessibilityLabel,
  optionKey,
}: Props) => {
  const [selected, setSelected] = useState<any>('');
  const onSelectHandler = (value: string | null) => {
    setSelected(value);
    onChange(value || undefined);
  };

  useEffect(() => {
    if (
      !defaultSelected ||
      (typeof defaultSelected === 'string' && defaultSelected.toLowerCase()) ===
        selected
    )
      return;
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const optionsList = options.map((option: any, i: number) => {
    let value = !!optionKey ? option[optionKey] : option;
    if (typeof value === 'string') {
      value = value.toLowerCase();
    }

    return (
      <option key={i} value={value} disabled={i < (disableOptionsPrior as any)}>
        {typeof value === 'string' ? firstToUpper(value) : value}
      </option>
    );
  });

  const defaultOptionJsx = defaultOption && (
    <option hidden value={defaultOption.value?.toLowerCase() as any}>
      {typeof defaultOption.option === 'string'
        ? firstToUpper(defaultOption.option)
        : defaultOption.option}
    </option>
  );

  const wrapperClasses = classNames(css['baseSelect'], {
    [css['flexCol']]: flexCol,
    [css['accessibility-label']]: accessibilityLabel,
  });

  const selectValue = selected || defaultSelected;
  const insensitiveValue =
    typeof selectValue === 'string' ? selectValue.toLowerCase() : selectValue;

  return (
    <div className={wrapperClasses}>
      <label htmlFor={label}>{label}</label>

      <select
        className={noVerticalMargin ? 'no-vertical-margin' : ''}
        name={label}
        value={insensitiveValue}
        disabled={disabled}
        onChange={(e) => {
          onSelectHandler(
            e.target.value === defaultOption?.value ? null : e.target.value
          );
        }}
      >
        {defaultOptionJsx}
        {optionsList}
      </select>
    </div>
  );
};

export default BaseSelect;
