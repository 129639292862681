import {
  useState,
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
  useEffect,
  FC,
  createContext,
  useContext,
  useRef,
} from 'react';
import classNames from 'classnames';

import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import css from './FormGroup.module.scss';

type Props = {
  onSaveData: (data: any) => void;
  triggerDiscard?: boolean;
  children: any;
  setPotentiallyChangedFields?: (fields: any) => void;
  warnOnUnsavedData?: boolean;
  buttonText: string;
  removable?: boolean;
  onDeleteData?: (event: any) => void;
  FormIsValid: any;
  smallButtons?: boolean;
};

const FormGroupContext = createContext({
  registeredInstances: {},
  onValueChangeHandler: (e: any, name: string): void => {},
  initFieldsChanged: (_1: any) => {},
  storeOriginalValue: (_1: any, _2: any) => {},
});

type GroupInstanceProps = {
  children: any;
  warnOnUnsavedData?: boolean;
  onValidateInstance?: (validity: boolean) => void;
  validation?: {
    pattern: RegExp;
    isRequired: boolean;
  };
};

export const GroupInstance: FC<GroupInstanceProps> = ({
  children,
  warnOnUnsavedData,
  validation,
  onValidateInstance,
}): any => {
  const { onValueChangeHandler } = useContext(FormGroupContext);
  const {
    register,

    formState: { isValid, errors }, // don't remove even though the var is not being used. Breaking
  } = useFormContext();

  useEffect(() => {
    if (!!onValidateInstance) onValidateInstance(isValid);
  }, [isValid]);

  const registeredInstance = useMemo(
    () =>
      Children.map(children, (child) => {
        const {
          props: { name, placeholder },
        } = child;

        const modifiedChild = cloneElement(child, {
          ...register(name, {
            // optional warn on unsaved data
            ...(warnOnUnsavedData && {
              onChange: (e: any) => {
                onValueChangeHandler(e, name);
              },
            }),
            // optional validation, triggers on change.
            ...(!!validation && {
              validate: (value) => {
                const validity = validation.pattern.test(value);
                return validity;
              },
              // trim value if value is typeof string
              setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
            }),

            required: validation?.isRequired,
          }),
          placeholder,
        });

        return isValidElement(modifiedChild) ? (
          modifiedChild
        ) : (
          <p>Aj, aj.. Nu hände det något.. Kontakta support.</p>
        );
      }),
    [children]
  );

  return <>{registeredInstance}</>;
};

const FormGroup = ({
  children,
  onSaveData,
  triggerDiscard,
  setPotentiallyChangedFields,
  buttonText,
  removable,
  onDeleteData,
  FormIsValid,
  smallButtons,
}: Props) => {
  const isMounted = useRef(false);
  const [fieldsChanged, setFieldsChanged] = useState<any>(null);
  const [originalData, setOriginalData] = useState<any>({});

  const methods = useForm({ mode: 'onChange' });
  const { reset, handleSubmit } = methods;

  const initFieldsChanged = (key: any) => {
    setFieldsChanged((prev: any) => {
      const prevCopy = { ...prev };
      prevCopy[key] = false;
      return prevCopy;
    });
  };

  const storeOriginalValue = useCallback((key: any, value: any) => {
    setOriginalData((prev: any) => {
      const prevCopy = { ...prev };
      prevCopy[key] = value;
      return prevCopy;
    });
  }, []);

  const onValueChangeHandler = (e: any, key: any) => {
    const { value } = e.target;

    setFieldsChanged((prevChanged: any) => {
      return { ...prevChanged, [key]: originalData[key] !== value };
    });

    !!setPotentiallyChangedFields &&
      setPotentiallyChangedFields({
        ...fieldsChanged, // works, but not a good practice since it's not guaranteed that fieldsChanged is up to date when being called.
        [key]: originalData[key] !== value,
      });
  };

  const discardEnteredData = useCallback(() => {
    reset(originalData);
  }, [originalData, reset]);

  const onSaveDataHandler = (data: any) => {
    onSaveData(data);
    reset();
  };

  const onDeleteDataHandler = (e: any) => {
    e.preventDefault();
    const hasConfirmedDelete = window.confirm(
      'Är du säker på att du vill du ta bort informationen?'
    );
    if (hasConfirmedDelete) {
      onDeleteData && onDeleteData(e);
    }
  };

  useEffect(() => {
    if (triggerDiscard) discardEnteredData();
  }, [triggerDiscard, discardEnteredData]);

  //JSX ------
  const buttonSaveClasses = classNames({
    'button--cta': true,
    'no-vertical-margin': true,
    'button--small': smallButtons,
  });
  const buttonSave = (
    <button
      disabled={!FormIsValid}
      form="hook-form"
      className={buttonSaveClasses}
      onClick={handleSubmit((data) => onSaveDataHandler(data))}
    >
      {buttonText}
    </button>
  );

  const buttonRemove = (
    <button
      className={`button--text button--delete no-vertical-margin ${css['button--delete']}`}
      type="button"
      onClick={onDeleteDataHandler}
    >
      Ta bort
    </button>
  );

  const buttons = (
    <div className={`adaptable-flex-direction space-between ${css['buttons']}`}>
      {removable && buttonRemove} {buttonSave}
    </div>
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <FormGroupContext.Provider
      value={{
        onValueChangeHandler,
        registeredInstances: {},
        initFieldsChanged,
        storeOriginalValue,
      }}
    >
      <FormProvider {...methods}>
        <form>
          {children}
          <footer>{buttons}</footer>
        </form>
      </FormProvider>
    </FormGroupContext.Provider>
  );
};

export default FormGroup;
