import { ReactNode } from 'react';

import css from './IconTooltip.module.scss';

type Props = {
  faIcon: string;
  tooltip: ReactNode;
};

const IconTooltip = ({ faIcon, tooltip }: Props) => {
  return (
    <div className={css['icon-tooltip']}>
      {tooltip}
      <i className={`fa-solid fa-${faIcon}`} />
    </div>
  );
};

export default IconTooltip;
