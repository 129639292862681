import React from 'react';

import css from './PredictionItem.module.scss';

type Props = {
  mainText?: string;
  secondaryText?: string;
  onClick?: () => void;
  onKeyDown?: () => void;
};

const PredictionItem = ({
  mainText,
  secondaryText,
  onClick,
  onKeyDown,
}: Props) => {
  return (
    <li className={css['prediction']} onClick={onClick} onKeyDown={onKeyDown}>
      {mainText && (
        <span className={css['prediction__main-text']}>{mainText}</span>
      )}

      {secondaryText && (
        <span className={css['prediction__secondary-text']}>
          {`, ${secondaryText}`}
        </span>
      )}
    </li>
  );
};

export default PredictionItem;
