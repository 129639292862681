import { memo } from 'react';
import NavLinks from '../NavLinks/NavLinks';
import css from '../Header.module.scss';
import classNames from 'classnames';

type HamburgerMenuProps = {
  mobileMenuIsOpen: null | boolean;
  setMobileMenuIsOpen: (bool: any) => void;
  isDashboardHeader?: boolean;
};

const HamburgerMenu = ({ ...props }: HamburgerMenuProps) => {
  const toggleHamburgerHandler = () => {
    props.setMobileMenuIsOpen((prevValue: any) => !prevValue);
  };

  const menuClasses = classNames(css['mobile-menu'], {
    [css['mobile-menu__stationary']]: props.mobileMenuIsOpen === null,
    [css['mobile-menu__open']]: !!props.mobileMenuIsOpen,
    [css['mobile-menu__closed']]: !props.mobileMenuIsOpen,
  });

  return (
    <header className={css['hamburger-menu-wrapper']}>
      <div
        onClick={toggleHamburgerHandler}
        className={`${css['hamburger-menu__icon']}`}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      {
        <div className={menuClasses}>
          <NavLinks isDashboardHeader={props.isDashboardHeader} />
        </div>
      }
    </header>
  );
};

export default memo(HamburgerMenu);
