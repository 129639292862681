import { useNavigate } from 'react-router-dom';
import { firstToUpper } from '../../utils/convert/typography';

// styles
import css from './GoRoute.module.scss';

type Props = {
  title: string;
  confirmText?: string;
  path: any;
  previous?: boolean;
};

const GoRoute = ({ title, confirmText, path }: Props) => {
  const navigate = useNavigate();
  const confirmClose = () => {
    return window.confirm(confirmText);
  };
  const executeNavigation = () => {
    if (path === -1) {
      navigate(path);
    } else {
      navigate(path, { replace: true });
    }
  };
  const onClickHandler = () => {
    if (confirmText && !confirmClose()) return;
    executeNavigation();
  };
  return (
    <button onClick={onClickHandler}>
      <div className={css['navigation']}>
        <i className="fa-solid fa-arrow-left-long"></i>
        <h2 className="h5 no-vertical-margin">{firstToUpper(title)}</h2>
      </div>
    </button>
  );
};

export default GoRoute;
