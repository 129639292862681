import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import BaseInput from '../../../../../common/inputs/BaseInput/BaseInput';
// import { ReactComponent as TickIcon } from '../../../../../../assets/icons/tick.svg';

import css from './AddContact.module.scss';

type Props = {
  position: number;
  onUpdateContact: (position: number) => void;
  onAddContact: () => void;
  onCloseAddContact: () => void;
};

const AddContact = (props: Props) => {
  const { position, onUpdateContact, onAddContact, onCloseAddContact } = props;
  const { register, unregister, trigger, formState, getValues } =
    useFormContext();

  const setOrUpdateContact = async () => {
    const invalidFields = await trigger('contact.data');

    if (!invalidFields) return;

    const isEdit = getValues('contact').isEdit;
    if (isEdit) {
      onUpdateContact(position);
    } else {
      onAddContact();
    }
  };

  const errors = formState.errors?.contact;
  const isValid = (property: string) => {
    if (!errors) return true;
    return errors?.data && !errors.data[property];
  };

  const errorMessage = (property: string) => {
    return errors?.data && errors.data[property]?.message;
  };

  useEffect(() => {
    return () => {
      unregister('contact.data.name');
      unregister('contact.data.role');
      unregister('contact.data.email');
      unregister('contact.data.phone');
    };
  }, [unregister]);

  return (
    <div className={css['add-contact']}>
      <BaseInput
        type="text"
        htmlFor="contact.data.name"
        label="För- och efternamn"
        placeholder="Maximilian Olson"
        className={`${!isValid('name') ? 'input--error' : ''}`}
        error={{ text: errorMessage('name') }}
        {...register('contact.data.name', {
          required: { value: true, message: 'Ange ett namn för kontakten.' },
        })}
      />
      <BaseInput
        type="text"
        htmlFor="contact.data.role"
        label="Roll"
        placeholder="Utvecklare"
        className={`${!isValid('role') ? 'input--error' : ''}`}
        error={{ text: errorMessage('role') }}
        {...register('contact.data.role', {
          required: { value: true, message: 'Ange en roll för kontakten.' },
        })}
      />
      <div className={css['form-group']}>
        <BaseInput
          type="email"
          htmlFor="contact.data.email"
          label="Mejladress"
          placeholder="Maximilian@kila.se"
          className={`${!isValid('email') ? 'input--error' : ''}`}
          error={{ text: errorMessage('email') }}
          {...register('contact.data.email', {
            required: 'Ogiltigt mejladress.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '',
            },
          })}
        />

        <BaseInput
          type="tel"
          htmlFor="contact.data.phone"
          label="Telefonnummer"
          placeholder="070-733 73 57"
          {...register('contact.data.phone', {
            required: false,
          })}
        />
      </div>
      <div className={css['cta-group']}>
        <button
          type="button"
          className="button--text button--small"
          onClick={onCloseAddContact}
        >
          Avbryt
        </button>
        <button
          type="button"
          className="button--cta button--small"
          onClick={setOrUpdateContact}
        >
          Spara
        </button>
      </div>
    </div>
  );
};

export default AddContact;
