import { HashLink } from 'react-router-hash-link';
import css from './Button.module.scss';
import classNames from 'classnames';

type Props = {
  type?: 'default' | 'cta' | 'warning' | 'delete' | 'text' | 'icon';
  small?: boolean;
  label: string;
  link?: string;
  icon?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isDoneLoading?: boolean;
  onClick?: () => void;
  customClasses?: string;
};

const LoadingEllipsis = () => {
  return (
    <span className={`loading-ellipsis ${css['ellipsis-wrapper']}`}>
      <span></span>
      <span></span>
      <span></span>
    </span>
  );
};

const Button = ({
  type = 'default',
  small = false,
  label,
  link,
  icon,
  disabled = false,
  isLoading = false,
  isDoneLoading = false,
  onClick,
  customClasses,
}: Props) => {
  const buttonIsLoading = isLoading && !isDoneLoading && !disabled;
  const buttonIsDoneLoading = !isLoading && isDoneLoading && !disabled;

  const buttonClasses = classNames(customClasses, css[`button--${type}`], {
    [css['button-size--small']]: small,
    // General class needed to be able to use :not(.button--is-loading) in the scss.
    ['button--is-loading']: buttonIsLoading,
    [css['button--is-loading']]: buttonIsLoading,
    // General class needed to be able to use :not(.button--is-done-loading) in the scss.
    ['button--is-done-loading']: buttonIsDoneLoading,
    [css['button--is-done-loading']]: buttonIsDoneLoading,
  });

  const buttonAttributes = {
    // Add title attribute if it's a icon button without text.
    title: type == 'icon' ? label : '',
  };

  const linkWithButtonStyling = (
    <>
      {link && (
        <HashLink
          to={link}
          className={buttonClasses}
          onClick={() => false}
          {...buttonAttributes}
        >
          {/* Content */}
          {icon && <i className={`fa-solid fa-${icon}`}></i>}
          {label}
        </HashLink>
      )}
    </>
  );

  const regularButton = (
    <>
      {!link && onClick && (
        <button
          type="button"
          className={buttonClasses}
          onClick={typeof onClick === 'function' ? onClick : undefined}
          disabled={disabled || isLoading || isDoneLoading}
          {...buttonAttributes}
        >
          {/* Content */}
          <span className={css['content-wrapper']}>
            {icon && <i className={`fa-solid fa-${icon}`}></i>}
            {label}
          </span>

          {/* Processing */}
          {buttonIsLoading ? <LoadingEllipsis /> : ''}
          {buttonIsDoneLoading && (
            <svg
              className={`button__checkmark ${css['button__checkmark']}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <path
                className={css['button__checkmark__check']}
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          )}
        </button>
      )}
    </>
  );

  return (
    <>
      {linkWithButtonStyling}
      {regularButton}
    </>
  );
};

export default Button;
