import css from './index.module.scss';

import BaseButton from '../common/BaseButton/BaseButton';
import LoadingSpinner from '../Progress/LoadingSpinner/LoadingSpinner';

type Props = {
  isLoading: boolean;
  buttonTitle?: string;
  isDisabled: boolean;
};

const SubmitButton = ({ isLoading, buttonTitle, isDisabled }: Props) => {
  const title = buttonTitle ? buttonTitle : 'Spara';
  return (
    <div className={css['button-wrapper']}>
      {!isLoading ? (
        <BaseButton
          type="submit"
          title={title}
          styleType="cta"
          disabled={isDisabled}
          noMargin
        />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default SubmitButton;
