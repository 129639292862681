export const OCCUPATION_OPTIONS = [
  'Administratör i offentlig förvaltning',
  'Administrativ assistent',
  'Bokförings- och redovisningsassistent',
  'Lagerassistent mfl',
  'Lastbils- och långtradarförare',
  'Köks- och restaurangbiträde',
  'Skötare och vårdare',
  'Försäljare, dagligvaror',
  'Hotell- och kontorsstädare mfl',
  'Grundskollärare',
  'Systemerare eller programmerare',
  'Övrig kontorspersonal',
  'Förskollärare eller fritidspedagog',
  'Företagssäljare',
  'Barnskötare mfl',
  'Försäljare, fackhandel',
  'Vårdbiträde, personliga assistent mfl',
  'Undersköterska, sjukvårdsbiträde mfl',
  'Fastighetsskötare',
  'Kock / kokerska',
  'Universitets- eller högskollärare',
  'Motorfordonsmekaniker / motorfordonsreparatörer',
  'Övriga servicearbetare',
];
