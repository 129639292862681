import { useState } from 'react';
import { arraySort } from '../../../utils/sort/arraySort';
import { OCCUPATION_OPTIONS } from '../../../constants/options/available-occupations';
import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';
import BaseInput from '../../common/inputs/BaseInput/BaseInput';
import BaseSelect from '../../common/BaseSelect/BaseSelect';
import InputAutocomplete from '../../common/inputs/InputAutocomplete/InputAutocomplete';
import StartEndDate from '../../StartEndDate/StartEndDate';

const ExperienceForm = ({
  isEdit,
  onSaveNestedObject,
  onRemoveExperience,
  experience,
  setPotentiallyChangedFields,
  buttonText,
}: any) => {
  const [location, setLocation] = useState<any>(null);
  const [selectedExperience, setSelectedExperience] = useState<any>(
    experience?.data.title || null
  );
  const [selectedEmploymentType, setSelectedEmploymentType] = useState<any>(
    experience?.data.typeOfEmployment || null
  );
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const [isValid, setIsValid] = useState<any>(false);
  const [isValidExperience, setIsValidExperience] = useState<any>(
    !!selectedExperience
  );
  const [isValidEmploymentType, setIsValidEmploymentType] = useState<any>(
    !!selectedEmploymentType
  );
  const [isValidExperienceDate, setIsValidExperienceDate] = useState<any>(
    !!startEndDate?.startDate
  );

  const onSelectLocationHandler = (location: any) => {
    setLocation(location);
    setIsValid(true);
  };

  const onSaveDataHandler = (data: any) => {
    // temporary validation.
    if (!location || !selectedExperience || !selectedEmploymentType) return;

    const { startDate, endDate } = startEndDate;

    onSaveNestedObject({
      ...data,
      location,
      title: selectedExperience,
      typeOfEmployment: selectedEmploymentType,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const onDeleteDataHandler = () => {
    onRemoveExperience(experience.id);
  };

  const onSelectExperience = (experience: string) => {
    setIsValidExperience(experience !== '---');
    setSelectedExperience(experience);
  };

  const onSelectEmploymentType = (type: string) => {
    setIsValidEmploymentType(type !== '---');
    setSelectedEmploymentType(type);
  };

  return (
    <FormGroup
      removable={isEdit}
      buttonText={buttonText}
      setPotentiallyChangedFields={setPotentiallyChangedFields}
      onSaveData={onSaveDataHandler}
      onDeleteData={onDeleteDataHandler}
      FormIsValid={
        isValid &&
        isValidExperience &&
        isValidEmploymentType &&
        !!location &&
        isValidExperienceDate
      }
    >
      <BaseSelect
        label="Typ av jobb"
        options={arraySort(OCCUPATION_OPTIONS)}
        defaultOption={{ option: '---', value: null }}
        defaultSelected={selectedExperience}
        onChange={onSelectExperience}
      />

      <div className="adaptable-flex-direction no-mobile-gap">
        <GroupInstance
          warnOnUnsavedData
          validation={{ pattern: /^.{2,}$/, isRequired: true }}
          onValidateInstance={setIsValid}
        >
          <BaseInput
            customProps={{ noBottomMargin: true }}
            type="text"
            label="Företag"
            inputid="companyName"
            name="companyName"
            defaultValue={experience?.data.companyName}
          />
        </GroupInstance>
        <BaseSelect
          label="Typ av anställning"
          options={['Heltid', 'Deltid', 'Extrajobb', 'Annan titel']}
          defaultOption={{ option: '---', value: null }}
          defaultSelected={selectedEmploymentType}
          onChange={onSelectEmploymentType}
        />
      </div>

      <InputAutocomplete
        label="Adress"
        informOfChange={() => setIsValid(false)}
        defaultLocation={experience?.data.location}
        onSelectLocation={onSelectLocationHandler}
        types={['(cities)']}
      />

      <StartEndDate
        setDate={setStartEndDate}
        defaultDate={{
          startDate: experience?.data.startDate,
          endDate: experience?.data.endDate,
        }}
        checkboxLabel="Nuvarande jobb"
        setIsValid={setIsValidExperienceDate}
      />
    </FormGroup>
  );
};

export default ExperienceForm;
