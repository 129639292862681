import { useContext, useState } from 'react';
import { PoolContext } from '../../../../../context/dashboard/pool-context';

// components
import CandidateList from '../../../../../components/Pools/lists/CandidateList/CandidateList';
import Header from '../../../../../components/Pools/lists/CandidateList/Header/Header';
import SendSmsModal from '../../../../../components/Pools/SelectedPool/modals/SendSmsModal/SendSmsModal';
import AddCandidateModal from './AddCandidateModal';

type Props = {};

const Candidates = (props: Props) => {
  const {
    candidates,
    nSelectedCandidates,
    selectedCandidates,
    candidateIds,
    isSelectedCandidate,
    toggleCandidate,
    toggleAllCandidates,
    createPoolMessage,
    addCandidatesToPool,
    removeCandidateFromPool,
  } = useContext(PoolContext);

  const [smsModalIsOpen, setSmsModalIsOpen] = useState(false);
  const [addCandidateModalIsOpen, setAddCandidateModalIsOpen] = useState(false);
  const openSmsModal = () => {
    setSmsModalIsOpen(true);
  };
  const closeSmsModal = () => {
    setSmsModalIsOpen(false);
  };

  const openAddCandidateModal = () => {
    setAddCandidateModalIsOpen(true);
  };
  const closeAddCandidateModal = () => {
    setAddCandidateModalIsOpen(false);
  };

  return (
    <>
      <SendSmsModal
        isOpen={smsModalIsOpen}
        onClose={closeSmsModal}
        selectedCandidates={selectedCandidates}
        isSelectedCandidates={false}
        isAllSelected={false}
        onSend={(data: any) => {
          createPoolMessage(data);
          setSmsModalIsOpen(false);
        }}
      />
      <AddCandidateModal
        isOpen={addCandidateModalIsOpen}
        onClose={closeAddCandidateModal}
        poolCandidateIds={candidateIds}
        addSelectedCandidates={addCandidatesToPool}
      />
      <Header
        candidates={candidates}
        nSelectedCandidates={nSelectedCandidates}
        toggleAllCandidates={toggleAllCandidates}
        openSmsModal={openSmsModal}
        openAddCandidateModal={openAddCandidateModal}
      />
      <CandidateList
        candidates={candidates}
        isSelectedCandidate={isSelectedCandidate}
        toggleCandidate={toggleCandidate}
        removeCandidate={removeCandidateFromPool}
      />
    </>
  );
};

export default Candidates;
