import React, { useState, createContext } from 'react';

// import LoadingDots from '../../components/Progress/LoadingDots/LoadingDots';

type StateTypes = {
  [key: string]: any;
  isLoading: boolean;
  isAuthenticated: boolean | null;
};

const initialCtxValues: any = {
  loadingState: Object.create(null),
  isAuthenticated: null,
};

type Props = {
  children?: React.ReactNode;
};

export const GlobalContext = createContext(initialCtxValues);

export const GlobalContextProvider: React.FC<Props> = (props) => {
  const [globalState, setGlobalState] = useState<StateTypes>(initialCtxValues);

  const getValue = (target: string): any => {
    const globalTarget: any = globalState[target];
    return globalTarget;
  };

  const setValue = (target: string, value: any): void => {
    const globalTarget: any = {};
    globalTarget[target] = value;
    setGlobalState((globals) => {
      return { ...globals, ...globalTarget };
    });
  };

  const setLoading = (target: string, value: any): void => {
    setGlobalState((globals) => {
      const globalsCopy = { ...globals };
      globalsCopy.loadingState[target] = value;
      return globalsCopy;
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        ...globalState,
        getValue: getValue,
        setValue: setValue,
        setLoading: setLoading,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
