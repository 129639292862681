import { useContext, useEffect, useRef, useState } from 'react';
import ApplicantOverview from '../../../components/interview/ApplicantOverview/ApplicantOverview';
import ApplicationOverview from '../../../components/interview/ApplicationOverview/ApplicationOverview';
import { InterviewContext } from '../../../context/dashboard/interview-context';
import useScrollY from '../../../hooks/UI/useScrollY';

type Props = {};

const AppOverview = () => {
  const {
    candidate,
    candidateId,
    job: { title },
    application: { date, distanceInKm },
  } = useContext<any>(InterviewContext);
  const [scrollY] = useScrollY();
  const [isSticking, setIsSticking] = useState(false);
  const [overviewType, setOverviewType] = useState('APPLICATION');

  const applicantTitleRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getElementsRect = (el: HTMLElement) => {
    return el.getBoundingClientRect();
  };

  const isCurrentlySticking = () => {
    if (!containerRef.current) return false;

    const { top } = getElementsRect(containerRef.current);
    return top === 0;
  };

  const hasScrolledPastName = () => {
    if (!applicantTitleRef.current || !containerRef.current) return false;

    const { top } = getElementsRect(containerRef.current);
    const { bottom } = getElementsRect(applicantTitleRef.current);

    return bottom <= top;
  };

  useEffect(() => {
    const scolledPastName = hasScrolledPastName();
    setOverviewType(scolledPastName ? 'APPLICANT' : 'APPLICATION');
    setIsSticking(isCurrentlySticking());
  }, [scrollY]);

  return (
    <>
      <ApplicationOverview
        ref={containerRef}
        dateOfSubmission={new Date(date)}
        jobTitle={title}
        title={
          overviewType === 'APPLICANT'
            ? `${candidate.firstName} ${candidate.lastName}`
            : 'intervju'
        }
        jobDistance={distanceInKm}
        isSticky={overviewType === 'APPLICANT'}
        isSticking={isSticking}
      />
      <ApplicantOverview
        ref={applicantTitleRef}
        candidateId={candidateId}
        fullName={`${candidate.firstName} ${candidate.lastName}`}
        dateOfBirth={candidate.dateOfBirth}
        email={candidate.email}
        phone={candidate.phone}
        formattedLocation={candidate?.location?.formatted}
        jobDistance={distanceInKm}
        showDistance={overviewType !== 'APPLICANT'}
      />
    </>
  );
};

export default AppOverview;
