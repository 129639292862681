import classNames from 'classnames';

type Props = {
  title: string;
  styleType?: string;
  onClick: () => void;
};

const SmallButton = ({ title, styleType, onClick }: Props) => {
  const classes = classNames({
    [`button--${styleType}`]: !!styleType,
    'button--small': true,
    'button--min-width': true,
    'no-vertical-margin': true,
  });
  return (
    <button type="button" className={classes} onClick={onClick}>
      {title}
    </button>
  );
};

export default SmallButton;
