import { useState } from 'react';
import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';
import BaseInput from '../../common/inputs/BaseInput/BaseInput';
import BaseSelect from '../../common/BaseSelect/BaseSelect';
import StartEndDate from '../../StartEndDate/StartEndDate';
import { DEGREE_OPTIONS } from '../../../constants/options/degree-options';

const EducationForm = ({
  isEdit,
  onSaveNestedObject,
  onRemoveEducation,
  education,
  setPotentiallyChangedFields,
  buttonText,
}: any) => {
  const [selectedDegree, setSelectedDegree] = useState<string | null>(
    education?.data.degree || null
  );
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: new Date(),
    endDate: null,
  });

  const [isValid, setIsValid] = useState(false);
  const [isValidDegree, setIsValidDegree] = useState(!!education?.data.degree);
  const [isValidExperienceDate, setIsValidExperienceDate] =
    useState<any>(false);

  const onSaveDataHandler = (data: any) => {
    if (!selectedDegree) return;

    const { startDate, endDate } = startEndDate;
    onSaveNestedObject({
      ...data,
      degree: selectedDegree,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const onDeleteDataHandler = () => {
    onRemoveEducation(education.id);
  };

  const onSelectDegreeHandler = (degree: string) => {
    setIsValidDegree(degree !== '---');
    setSelectedDegree(degree);
  };

  return (
    <FormGroup
      removable={isEdit}
      buttonText={buttonText}
      setPotentiallyChangedFields={setPotentiallyChangedFields}
      onSaveData={onSaveDataHandler}
      onDeleteData={onDeleteDataHandler}
      FormIsValid={isValid && isValidExperienceDate && isValidDegree}
    >
      <div className="adaptable-flex-direction no-mobile-gap">
        <GroupInstance
          warnOnUnsavedData
          validation={{ pattern: /^.{2,}$/, isRequired: true }}
          onValidateInstance={setIsValid}
        >
          <BaseInput
            customProps={{ noBottomMargin: true }}
            type="text"
            label="Skola"
            placeholder="T.ex Hampnäs Gymnasium"
            inputid="school"
            name="school"
            defaultValue={education?.data.school}
          />
        </GroupInstance>
        <BaseSelect
          label="Utbildningsnivå"
          options={DEGREE_OPTIONS}
          defaultOption={{
            option: '---',
            value: null,
          }}
          defaultSelected={selectedDegree as any}
          onChange={onSelectDegreeHandler}
        />
      </div>

      <StartEndDate
        setDate={setStartEndDate}
        defaultDate={{
          startDate: education?.data.startDate,
          endDate: education?.data.endDate,
        }}
        checkboxLabel="Pågående utbildning"
        setIsValid={setIsValidExperienceDate}
      />
    </FormGroup>
  );
};

export default EducationForm;
