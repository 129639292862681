import { useMemo } from 'react';
import Modal from 'react-modal';
import { firstToUpper } from '../../../utils/convert/typography';
import BaseButton from '../../common/BaseButton/BaseButton';
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton';

// styles
import css from './ConfirmationBox.module.scss';

type Props = {
  type: 'danger' | 'warning' | 'success' | 'default';
  isOpen: boolean;
  title: string;
  body: string;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmationBox = ({
  type,
  title,
  body,
  isOpen,
  onConfirm,
  onClose,
}: Props) => {
  const iconOptions: any = useMemo(() => {
    return {
      warning: {
        icon: 'fa-exclamation',
        iconWrapperClass: 'warning',
      },
      danger: {
        icon: 'fa-exclamation',
        iconWrapperClass: 'danger',
      },
      success: {
        icon: 'fa-thumbs-up',
        iconWrapperClass: 'success',
      },
    };
  }, []);

  const chosenIconOptions = useMemo(() => {
    return iconOptions[type];
  }, [type]);

  const iconCircle = (
    <div
      className={`${css['icon-circle']} ${
        css[chosenIconOptions.iconWrapperClass]
      }`}
    >
      <i className={`fa-solid ${chosenIconOptions.icon}`}></i>
    </div>
  );

  return (
    <Modal
      className={css['confirmation-box']}
      overlayClassName={css['confirmation-box__overlay']}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="User Modal"
      ariaHideApp={false}
      parentSelector={(): any => document.querySelector('#modal-root')}
      preventScroll={true}
    >
      {iconCircle}
      <div className={css['confirmation-box__content']}>
        <h2 className="h4 no-vertical-margin">{firstToUpper(title)}</h2>
        <p className="no-vertical-margin">{firstToUpper(body)}</p>
        <footer className="no-vertical-margin">
          <BaseButton
            onClick={onClose}
            styleType="text--plain"
            title="Avbryt"
            noMargin
          />
          <ConfirmationButton
            type={type}
            title="Ta bort"
            countdown={{ title: 'Tar bort om', seconds: 4 }}
            onConfirm={onConfirm}
          />
        </footer>
      </div>
    </Modal>
  );
};

export default ConfirmationBox;
