import { useContext } from 'react';
import { toLocaleString } from '../../../../utils/dates';

// components
import Card, { CardSection } from '../../../Card/Card';
import Rating from '../../../Rating/Rating';
import TagListItem from '../../../Candidate/TagList/TagListItem';
import PersonalInfoForm from '../../../../components/Candidate/Forms/PersonalInfoForm';
import BaseModal from '../../../../components/common/BaseModal/BaseModal';
import Stars from '../../../Rating/Stars';

// context
import { DashboardCandidateContext } from '../../../../context/dashboard/dashboard-candidate-context';

// loading animation
import LoadingText from '../../../Progress/LoadingText/LoadingText';

// styles
import css from './PersonalInfo.module.scss';
import ConfirmationBox from '../../../Confirmation/ConfirmationBox/ConfirmationBox';

const PersonalInfo = ({
  showEditModal,
  showDeleteModal,
  setShowEditModal,
  setShowDeleteModal,
}: any) => {
  // context
  const { candidate, updatePersonalInfo, deleteCandidate }: any = useContext(
    DashboardCandidateContext
  );

  // deconstruct candidate obj
  const {
    firstName,
    lastName,
    dateOfBirth,
    email,
    phone,
    location,
    rating,
  }: any = candidate;

  // placeholders until tags and rating is implemented
  const tags = [{ title: `Profil (${candidate?.progress}%)` }];

  const updatePersonalInfoHandler = async (data: any) => {
    await updatePersonalInfo(data);
    setShowEditModal(false);
  };

  const formattedDateOfBirth =
    dateOfBirth && toLocaleString(dateOfBirth).split(' ')[0];

  return (
    <>
      <BaseModal
        isOpen={!!showEditModal}
        onClose={() => setShowEditModal(false)}
        heading="Redigeringsläge"
      >
        <PersonalInfoForm
          candidate={candidate}
          updatePersonalInfo={updatePersonalInfoHandler}
        />
      </BaseModal>
      <ConfirmationBox
        type="danger"
        title="Oåterkallelig åtgärd"
        body="Du håller nu på att radera en kandidat. Är du säker på att du tryckt rätt?"
        isOpen={showDeleteModal}
        onConfirm={async () => {
          await deleteCandidate();
          setShowDeleteModal(false);
        }}
        onClose={() => setShowDeleteModal(false)}
      ></ConfirmationBox>
      <Card>
        <CardSection>
          <div className={css['personal-info']}>
            <LoadingText className="h2">
              <h2 className="no-vertical-margin">
                {firstName} {lastName}
              </h2>
              <Stars type="static" filled={rating} max={5} />
              {tags && (
                <div className={css['tags']}>
                  <div className="list--tags">
                    {tags.map((tag: any, idx: number) => (
                      <TagListItem key={idx} title={tag.title} />
                    ))}
                  </div>
                </div>
              )}
            </LoadingText>
          </div>

          <p className="no-vertical-margin">{formattedDateOfBirth}</p>
        </CardSection>
        <CardSection>
          <div className={css['contact-info']}>
            {location && (
              <div className={css['wrapper']}>
                <div className={css['icon-wrapper']}>
                  <i className="fa-solid fa-location-dot"></i>
                </div>

                <p className="no-vertical-margin">
                  <LoadingText className="h3" minWidthPx={200}>
                    {location?.formatted}
                  </LoadingText>
                </p>
              </div>
            )}

            {phone && (
              <div className={css['wrapper']}>
                <div className={css['icon-wrapper']}>
                  <i className="fa-solid fa-phone"></i>
                </div>
                <a href={`tel:${phone}`} className="no-vertical-margin">
                  <LoadingText className="h3" minWidthPx={200}>
                    {phone}
                  </LoadingText>
                </a>
              </div>
            )}

            {email && (
              <div className={css['wrapper']}>
                <div className={css['icon-wrapper']}>
                  <i className="fa-solid fa-at"></i>
                </div>
                <a href={`mailto:${email}`} className="no-vertical-margin">
                  <LoadingText className="h3" minWidthPx={200}>
                    {email}
                  </LoadingText>
                </a>
              </div>
            )}
          </div>
        </CardSection>
      </Card>
    </>
  );
};

export default PersonalInfo;
