import React from 'react';
import { Link } from 'react-router-dom';

import css from './Footer.module.scss';
import copy from './footerCopy.json';

import Button from '../../../website/components/atoms/Button/Button';
import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';

import { ReactComponent as Logo } from '../../../assets/icons/logo-black.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const containsCustomUri = (url: string) => {
    let regexp = /tel:|mailto:?/gi;
    return regexp.test(url);
  };
  return (
    <footer className={css.root} id="footer">
      <div className={css.footerInner}>
        <div className={css.ctaRow}>
          <Heading level="h2" looksLike="h4">
            {copy.ctaRow.heading}
          </Heading>
          <Button
            label={copy.ctaRow.button.text}
            link={copy.ctaRow.button.link}
            type="cta"
          />
        </div>
        <div className={css.navRow}>
          <figure>
            <Link to="/">
              <Logo />
            </Link>
            <figcaption>Kila People AB</figcaption>
          </figure>

          <nav>
            {copy.columns.map((column, index) => {
              return (
                <ul key={index}>
                  {column.links.map((linkItem, index) => {
                    return (
                      <li key={index}>
                        {linkItem.heading && (
                          <Heading level="h3">
                            <Link to={linkItem.link}>{linkItem.heading}</Link>
                          </Heading>
                        )}

                        {linkItem.text && containsCustomUri(linkItem.link) ? (
                          <a href={linkItem.link} target="_blank">
                            {linkItem.text}
                          </a>
                        ) : (
                          <Link to={linkItem.link}>{linkItem.text}</Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </nav>
        </div>
        <div className={css['copyrightRow']}>
          <Paragraph type="small">
            &copy; Copyright {currentYear}, Kila People AB
          </Paragraph>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
