import { db } from '../firebase';
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  getDocs,
  addDoc,
  deleteDoc,
  query as dbQuery,
  Timestamp,
} from 'firebase/firestore';

const isEven = (arr: any[]) => {
  return arr.length % 2 === 0;
};

const createFirestoreRef = (segments: string[]) => {
  const jointSegments = segments.join('/');
  return !isEven(segments)
    ? collection(db, jointSegments)
    : doc(db, jointSegments);
};

class DatabaseService {
  instanceRef: any;
  _query: any;

  // Specify 'candidates', 'applications'...  as collection name
  constructor(...segments: string[]) {
    this.instanceRef = createFirestoreRef(segments);
  }

  // returns subcollection from segments param together with every available class methods
  go = (...segments: string[]): any => {
    if (!segments.length) return;
    const prevSegments = this.instanceRef.path.split('/');
    const newSegments: any = [...prevSegments, ...segments];

    return new DatabaseService(...newSegments);
  };

  // append query conditions/params
  query = (...params: any) => {
    this._query = dbQuery(this.instanceRef, ...params);
    return {
      _query: this._query,
      getAll: this.getAll,
    };
  };

  // returns list of records as an array of javascript objects
  getAll = async () => {
    const snapshot = await getDocs(this._query || this.instanceRef);
    return snapshot.docs.map((doc) => {
      return {
        id: doc.id, // append document id to each document
        data: doc.data(),
      };
    });
  };

  // returns a single document in object format
  getOne = async (id: string) => {
    if (!id) return;

    const docRef = doc(this.instanceRef, id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) return snapshot.data();
  };

  // resolve a relation, returns the referenced document
  getReference = async (documentReference: any) => {
    const res = await getDoc(documentReference);
    const data: any = res.data();

    if (data && documentReference.id) {
      data.id = documentReference.id;
    }

    return data;
  };

  // save a new document in the database
  create = async (data: any) => {
    const created = await addDoc(this.instanceRef, {
      ...data,
      createdAt: Timestamp.now(),
    });

    return created;
  };

  // update an existing document with new data
  update = async (id: string, values: any) => {
    const docRef = doc(this.instanceRef, id);
    return await updateDoc(docRef, { ...values, updatedAt: Timestamp.now() });
  };

  set = async (id: string, ...rest: [any, any?]) => {
    const docRef = doc(this.instanceRef, id);
    await setDoc(docRef, ...rest);
    return docRef;
  };

  // delete an existing document from the collection
  remove = async (id: string) => {
    const docRef = doc(this.instanceRef, id);
    await deleteDoc(docRef);
  };

  exists = async (id: string) => {
    if (!id) return;

    const docRef = doc(this.instanceRef, id);
    const snapshot = await getDoc(docRef);

    return snapshot.exists();
  };
}

// Create services for each entity type
export const CandidateService = new DatabaseService('candidates');
export const ApplicationService = new DatabaseService('applications');
export const InterviewService = new DatabaseService('interviews');
export const JobService = new DatabaseService('jobs');
export const PoolService = new DatabaseService('pools');
