import BaseButton from '../../../../components/common/BaseButton/BaseButton';
import { useFieldArray } from 'react-hook-form';

import { v4 as uuid } from 'uuid';

// styles
import css from './NewJobModal.module.scss';
import BaseInput from '../../../../components/common/inputs/BaseInput/BaseInput';

const AnswerOptions = ({
  pageIndex,
  formGroupIndex,
  isValid,
  control,
  register,
}: any) => {
  const {
    fields: answerOptions,
    update,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `form.${pageIndex}.fields.${formGroupIndex}.fields`, // form => page => field group => answer options
  });

  const addRadioField = (label?: any, uid = null, required = false) => {
    const field = {
      component: 'radio',
      label: '',
      type: 'radio',
      _uid: uuid(),
    };
    append(field);
  };
  return (
    <>
      {answerOptions.map((answerOption, k) => (
        <div className={css['job-page']}>
          <div className="adaptable-flex-direction space-between">
            <BaseInput
              customProps={{ noBottomMargin: true }}
              type="text"
              label={`Svarsalternativ ${k + 1}`}
              inputid="answerOption"
              {...register(
                `form.${pageIndex}.fields.${formGroupIndex}.fields.${k}.label`,
                { required: true }
              )}
            />
            <BaseButton
              title="Ta bort"
              type="button"
              styleType="delete"
              onClick={() => remove(k)}
            />
          </div>
        </div>
      ))}
      <BaseButton
        title="Lägg till svarsalternativ"
        type="button"
        styleType="cta button--small button--add"
        onClick={addRadioField}
        disabled={!isValid}
      />
    </>
  );
};

export default AnswerOptions;
