import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from '../../firebase';

interface PropType {
  component: React.FC;
}

export const PrivateRoute: FC<PropType> = ({ component: Component }) => {
  const { isAuthenticated, isAdmin } = useAuthState();

  if (isAuthenticated && isAdmin) return <Component />;
  if (isAuthenticated && !isAdmin) return <Component />;
  return <Navigate to="/loginwithemail" />;
};
