import { FC } from 'react';
import NavigationTabs from '../../../NavigationTabs/NavigationTabs';

// styles
import css from './Navigation.module.scss';
import { Outlet } from 'react-router-dom';

type Props = {};

const TabContent: FC = ({ children }) => {
  return (
    <div className={css['tab-content']}>
      {children}
      <div></div>
    </div>
  );
};

const Navigation = (props: Props) => {
  return (
    <div className={css['tabs']}>
      <NavigationTabs
        tabs={[
          { to: 'candidates', title: 'Kandidater', notifications: null },
          {
            to: 'dispatches',
            title: 'Jobbutskick',
            notifications: null,
          },
        ]}
      />
      <TabContent>
        <Outlet />
      </TabContent>
      {/* <Routes>
              <Route path={'/'} element={<TabContent />}>
                <Route path="" element={<Navigate to="candidates" replace />} />

                <Route path="candidates" element={<CandidateList />} />
                <Route path="jobpostings/*" element={<MessageList />} />
              </Route>
            </Routes> */}
    </div>
  );
};

export default Navigation;
