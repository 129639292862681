import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { verifyEmailExistence } from '../../../utils/validation';
import { useAuthState } from '../../../firebase';

// components
import BaseInput from '../../../components/common/inputs/BaseInput/BaseInput';
import BaseButton from '../../../components/common/BaseButton/BaseButton';
import BaseCard from '../../../components/common/BaseCard/BaseCard';
import EmailField from '../../inputs/Email';

const PersonalInfoFormStep = ({ setFulfilledPrerequisites, jobId }: any) => {
  const {
    register,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { isValid, errors },
  } = useFormContext();

  const navigate = useNavigate();

  const onClickHandler = () => {
    verifyEmailExistence(email).then(({ userExists }: any) => {
      if (userExists) return;

      localStorage.setItem(jobId, JSON.stringify(getValues()));
      setFulfilledPrerequisites(true);

      navigate('../apply?step=0', { replace: true });
    });
  };

  const email = useWatch({
    name: 'email',
  });

  // if user is logged in, redirect them away from this component/page.
  const { user: authenticatedUser } = useAuthState();
  useEffect(() => {
    if (authenticatedUser) navigate(`/jobs/${jobId}/apply?step=0`);
  }, [authenticatedUser]);

  return (
    <BaseCard
      title="Personuppgifter"
      progressFil={'0'}
      alignContentEnd
      minHeight="530px"
    >
      <BaseInput
        {...register('applicantInfo.firstName', {
          required: true,
        })}
        label="Förnamn"
        placeholder="Tony"
      />
      <BaseInput
        {...register('applicantInfo.lastName', { required: true })}
        label="Efternamn"
        placeholder="Stark"
      />
      <EmailField
        name="applicantInfo.email"
        watch={watch}
        register={register}
        setError={setError}
        clearErrors={clearErrors}
        customRedirectRoute={`/jobs/${jobId}/apply?step=0`}
      />

      <BaseButton
        type="button"
        styleType="cta"
        disabled={!isValid || !!Object.keys(errors).length}
        onClick={onClickHandler}
        title="Nästa"
      />
    </BaseCard>
  );
};

export default PersonalInfoFormStep;
