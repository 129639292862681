import { useState, useMemo, useRef, useEffect, Fragment } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Column } from 'react-table';

import { SelectColumnFilter } from '../../../components/dashboard/TableFilter/ColumnFilter';
import Table from '../../../components/dashboard/Table/Table';

import css from './Table.module.scss';
import AddCandidateModal from '../../../components/dashboard/Modals/AddCandidateModal/AddCandidateModal';

type Props = {
  initRouteData: (collectionName: string) => Promise<any>;
  addNewCandidateToState: (candidateObj: object) => void;
  candidates: {}[];

  updateRouteDoc: (collName: string, docId: string, newDoc: object) => void;
  updateCandidate: (updatedFields: any, candidateId: string) => void;
};

const CandidatesTable = (props: Props) => {
  const componentMounted = useRef(true);
  const [data, setData] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAddCandidate, setShowAddCandidate] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState<any>(null);
  const columns = useMemo<Column[]>(
    () =>
      !!data?.length
        ? [
            {
              Header: 'id',
              accessor: 'id',
              disableFilters: true,
            },
            {
              Header: 'customRouteRedirect',
              id: 'customRouteRedirect',
              accessor: ({ id }: any) => {
                return `/dashboard/candidates/${id}/applications`;
              },
              disableFilters: true,
            },
            {
              Header: 'Förnamn',
              id: 'fName',
              accessor: 'data.firstName',
              disableFilters: true,
            },
            {
              Header: 'Efternamn',
              id: 'lName',
              accessor: 'data.lastName',
              disableFilters: true,
            },
            {
              Header: 'Adress',
              id: 'data.location',
              disableFilters: true,
              Cell: (cell: any) => {
                const { location } = cell.row.original.data;
                const formattedLocation = location?.formatted;
                const postalTown =
                  location?.geoData?.postal_town || location?.postal_town;
                return (
                  postalTown || formattedLocation || 'Ingen angiven adress'
                );
              },
            },
            {
              Header: 'Mejladress',
              accessor: 'data.email',
              disableFilters: true,
            },
            {
              Header: 'Telefon',
              accessor: 'data.phone',
              disableFilters: true,

              Cell: (cell: any) => {
                const { data } = cell.row.original;

                if (!data.phone) return 'null';
                return data.phone.replace(/\s/g, '');
              },
            },
          ].map((column: any): any => {
            return {
              Filter: SelectColumnFilter,
              filter: 'includes',
              ...column,
            };
          })
        : [],
    [data]
  );

  const hiddenColumns = useMemo(() => ['id', 'customRouteRedirect'], []);

  const onShowApplicantHandler = async (candidate: any) => {
    props.updateRouteDoc('candidates', candidate.id, candidate);

    setCurrentCandidate(candidate);
    setModalIsOpen(true);
  };

  const updateCandidateInFirestore = async (updatedFields: object) => {
    const candidateRef = doc(db, 'candidates', currentCandidate.id);

    await updateDoc(candidateRef, {
      ...updatedFields,
    });
  };

  const updateCandidate = async (updatedFields: object) => {
    try {
      await updateCandidateInFirestore(updatedFields);
      props.updateCandidate(updatedFields, currentCandidate.id);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const onEditCandidateHandler = async (updatedFields: any) => {
    await updateCandidate(updatedFields).catch((err) => console.error(err));
    setModalIsOpen(false);
  };

  const { candidates, initRouteData } = props;

  useEffect(() => {
    if (candidates) return setData(candidates);
    if (!componentMounted.current) return;

    (async () => {
      const candidatesSet = await initRouteData('candidates');
      if (!componentMounted.current) return;
      setData(candidatesSet);
    })();

    return () => {
      componentMounted.current = false;
    };
  }, [candidates, initRouteData]);

  return (
    <div className={css.dashboardTable}>
      {showAddCandidate && (
        <AddCandidateModal
          addNewCandidateToState={props.addNewCandidateToState}
          modalIsOpen={showAddCandidate}
          closeModal={() => setShowAddCandidate(false)}
        />
      )}

      {data && (
        <Fragment>
          <button
            className="button--cta button--add"
            onClick={() => setShowAddCandidate(true)}
          >
            Skapa kandidat
          </button>
          <Table
            data={data}
            definedColumns={columns}
            hiddenColumns={hiddenColumns}
            onClickRow={onShowApplicantHandler}
            rowRedirectTitle="Visa ansökningar"
            rowRedirectCollection="applications"
          />
        </Fragment>
      )}
    </div>
  );
};

export default CandidatesTable;
