import { FC } from 'react';
import css from './index.module.scss';

type Props = {
  label?: string;
};

const index: FC<Props> = ({ children, label }) => {
  const headingText = label ? label : 'Ändra status';
  return (
    <div className={css['container']}>
      <h2 className="no-vertical-margin">{headingText}</h2>
      <div className={css['change-status']}>{children}</div>
    </div>
  );
};

export default index;
