import { ReactNode } from 'react';

import css from './ListHeader.module.scss';

type Props = {
  children: ReactNode;
};

const ListHeader = ({ children }: Props) => {
  return <header className={css['tab-content-header']}>{children}</header>;
};

export default ListHeader;
