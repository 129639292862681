
import css from './NumberedSteps.module.scss';
import classNames from 'classnames';

import Button from '../../../website/components/atoms/Button/Button';
import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';

import HeadingWithLabel from '../../../website/components/molecules/HeadingWithLabel/HeadingWithLabel';

type Props = {
  copy: {
    headingLabel: string;
    heading: string;
    preamble?: string;
    steps: Array<{
      heading: string;
      text: string;
      button?: {
        text: string;
        link: string;
      };
    }>;
  };
};

const NumberedSteps = ({ copy }: Props) => {
  const { headingLabel, heading, preamble, steps } = copy;

  const sectionClasses = classNames(
    'website__section',
    css['root'],
  );

  return (
    <section className={sectionClasses}>
      <div className={css['section__inner']}>
        <HeadingWithLabel heading={heading} headingLabel={headingLabel} />
        {preamble && <Paragraph type="preamble">{preamble}</Paragraph>}

        <ol className={css['steps-list']}>
          {steps.map((step, index) => {
            const { heading, text, button } = step;

            return (
              <li key={index}>
                <Heading level="h3">{heading}</Heading>
                <Paragraph>{text}</Paragraph>
                {button && <Button label={button.text} link={button.link} type="cta" />}
              </li>
            )
          })}
        </ol>
      </div>
    </section>
  );
};

export default NumberedSteps;
