import BaseModal from '../../../../../../components/common/BaseModal/BaseModal';
import BaseInput from '../../../../../../components/common/inputs/BaseInput/BaseInput';
import { useState } from 'react';

import algoliasearch from 'algoliasearch/lite';

import CandidatePredictions from './CandidatePredictions';
import BaseButton from '../../../../../../components/common/BaseButton/BaseButton';
// import { Configure } from 'react-instantsearch-hooks-web';

const {
  InstantSearch,
  connectSearchBox,
  Configure,
} = require('react-instantsearch-dom');

type Props = {};

// init agolia client search
const searchClient = algoliasearch(
  '3SF4CV47LA',
  '81fc78135c74595cbc6bd6ebf0cb8cad'
);

const SearchBox = ({ currentRefinement, refine }: any) => (
  <BaseInput
    value={currentRefinement}
    onChange={(event: any) => refine(event.currentTarget.value)}
  />
);

const CustomSearchBox = connectSearchBox(SearchBox);

const AddCandidateModal = ({
  isOpen,
  onClose,
  poolCandidateIds,
  addSelectedCandidates,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const [addedCandidates, setAddedCandidates] = useState<any>([]);
  const addCandidateHandler = (obj: string) => {
    setAddedCandidates((prev: any) => {
      return [...prev, obj];
    });
  };
  const removeCandidateHandler = (id: string) => {
    setAddedCandidates((prev: any) => {
      return prev.filter((candidates: any) => candidates.objectID !== id);
    });
  };
  const isAddedCandidate = (id: string) => {
    return addedCandidates.find((candidate: any) => candidate.objectID === id);
  };

  const resetState = () => {
    setAddedCandidates([]);
    onClose();
  };

  const addSelectedCandidatesHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const candidateIds = addedCandidates.map(({ objectID }: any) => objectID);
      await addSelectedCandidates(candidateIds);
      resetState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal heading="Lägg till kandidat" isOpen={isOpen} onClose={onClose}>
      <ul>
        {addedCandidates.map((added: any) => (
          <li key={added.objectID}>
            {added.firstName} {added.lastName}
          </li>
        ))}
      </ul>
      <form onSubmit={addSelectedCandidatesHandler}>
        <InstantSearch searchClient={searchClient} indexName="candidates">
          <Configure
            facets={['objectID']}
            facetsExcludes={{
              objectID: poolCandidateIds,
            }}
          />
          <CustomSearchBox />

          <CandidatePredictions
            isAddedCandidate={isAddedCandidate}
            addCandidate={addCandidateHandler}
            removeCandidate={removeCandidateHandler}
          />
        </InstantSearch>

        <footer>
          <button
            className="button--text button--outlined"
            type="button"
            onClick={resetState}
          >
            Avbryt
          </button>
          <BaseButton
            title="Spara"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </footer>
      </form>
    </BaseModal>
  );
};

export default AddCandidateModal;
