import css from './TextWithIconsSection.module.scss';
import classNames from 'classnames';

import Button from '../../../website/components/atoms/Button/Button';
import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';

import IconWithText from '../../components/molecules/IconWithText/IconWithText';
import HeadingWithLabel from '../../../website/components/molecules/HeadingWithLabel/HeadingWithLabel';

type Props = {
  copy: {
    headingLabel: string;
    heading: string;
    preamble: string;
    blurbs: Array<{
      icon: {
        weight: string;
        icon: string;
      },
      heading: string;
      text: string
    }>;
    cta?: {
      heading: string;
      button: {
        text: string;
        link: string;
      };
    };
  };
};

const TextWithIconsSection = ({ copy }: Props) => {
  const { headingLabel, heading, preamble, blurbs, cta } = copy;

  const sectionClasses = classNames(
    'website__section',
    css['root'],
  );

  return (
    <section className={sectionClasses}>

      <HeadingWithLabel heading={heading} headingLabel={headingLabel} customClasses={css.centered} />

      <Paragraph type="preamble" customClasses={css.centered}>{preamble}</Paragraph>

      <div className={`${css['blurbs-wrapper']}`}>
        {blurbs.map(({ icon, heading, text }, index) => {
          return <IconWithText key={index} icon={icon} heading={heading} text={text} />;
        })}
      </div>

      { cta &&
        <div className={`${css.centered} ${css.cta}`}>
          <Heading level="h4" looksLike="h5">{cta.heading}</Heading>
          <Button label={cta.button.text} link={cta.button.link} type="cta" />
        </div>
      }
    </section>
  );
};

export default TextWithIconsSection;
