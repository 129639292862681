import { FC } from 'react';

import css from './DispatchList.module.scss';

const DispatchList: FC = ({ children }) => {
  return (
    <div className={css['messages__list']}>
      <ul className="list--lined">{children}</ul>
    </div>
  );
};

export default DispatchList;
