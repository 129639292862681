import { useState, useEffect, ReactNode } from 'react';
import css from './Header.module.scss';

import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import NavLinks from './NavLinks/NavLinks';
import classNames from 'classnames';

type HeaderProps = {
  isStatic?: boolean;
  hasDarkText?: boolean;
  showLogoOnly?: boolean;
  isDashboardHeader?: boolean;
  customNavLinks?: ReactNode;
};

const Header = ({
  isDashboardHeader,
  isStatic,
  hasDarkText,
  customNavLinks,
  ...props
}: HeaderProps) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState<null | boolean>(
    null
  );
  const isMobile = width < 1024;
  const isDesktop = !isMobile;
  // Switch between mobile and desktop menu when resizing the window.
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // when width matched mobile, reset menu state.
  useEffect(() => {
    if (!isDesktop) return;
    setMobileMenuIsOpen(null);
  }, [isDesktop]);

  const headerClasses = classNames(css['root'], {
    [css['is-static']]: isStatic || isDashboardHeader,
    [css['mobile-menu-is-open']]: mobileMenuIsOpen,
    [css['theme--dark']]: hasDarkText || isDashboardHeader,
    [css['header--dashboard']]: isDashboardHeader,
  });

  return (
    <header id="app-header" className={headerClasses}>
      <nav>
        <Link to="/" className={css.logo} aria-label="Logo">
          <Logo />
        </Link>

        {isMobile && (
          <HamburgerMenu
            mobileMenuIsOpen={mobileMenuIsOpen}
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            isDashboardHeader={isDashboardHeader}
          />
        )}
        {isDesktop && !props.showLogoOnly && (
          <NavLinks
            isDashboardHeader={isDashboardHeader}
            customNavLinks={customNavLinks}
          />
        )}
      </nav>
    </header>
  );
};

export default Header;
