import css from './DashboardContent.module.scss';

type Props = {
  heading: string;
  onOpenModal: () => void;
};

const DashboardContentHeader = (props: Props) => {
  return (
    <header className={css.dashboardHeader}>
      <h1 className="h2 no-vertical-margin">{props.heading}</h1>
    </header>
  );
};

export default DashboardContentHeader;
