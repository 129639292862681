import css from './index.module.scss';

import BaseSelect from '../common/BaseSelect/BaseSelect';

type Props = {
  setStatus: any;
  currentStatus?: string;
  options: string[];
};

const StatusSelect = ({ setStatus, currentStatus, options }: Props) => {
  return (
    <div className={css['input-wrapper']}>
      <BaseSelect
        onChange={setStatus}
        defaultSelected={currentStatus}
        options={options}
        noVerticalMargin
      />
    </div>
  );
};

export default StatusSelect;
