import { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../../../firebase';

import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import DashboardModal from '../../../dashboard/DashboardModal/DashboardModal';
import BaseInput from '../../../common/inputs/BaseInput/BaseInput';
import AddContact from './CompanyContact/AddContact/AddContact';
import ContactsList from './CompanyContact/Contacts/ContactsList/ContactsList';
import ColoredBackground from '../../../common/ColoredBackground/ColoredBackground';

import css from './AddCompanyModal.module.scss';

type Props = {
  closeModal: () => void;
  addNewCompanyToState: (companyObj: object) => void;
  modalIsOpen: boolean;
};

const AddCompanyModal = (props: Props) => {
  const { addNewCompanyToState, modalIsOpen, closeModal } = props;

  const methods = useForm<any>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      contact: {
        isEdit: false,
        data: {},
      },
      data: {
        name: '',
        contacts: [] as {}[],
      },
    },
  });
  const { register, reset, control, handleSubmit, getValues, setValue } =
    methods;
  const { isValid } = methods.formState;
  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: 'data.contacts',
  });

  const [showAddContact, setShowAddContact] = useState(false);
  const [editPosition, setEditPosition] = useState<any>(null);

  const initNewContact = () => {
    setShowAddContact(true);
  };

  const onDeleteContactHandler = (position: number) => {
    remove(position);

    setValue('contact', { isEdit: false, data: {} });
    setShowAddContact(false);
  };

  const onUpdateContactHandler = (position: number) => {
    const updatedContact = getValues('contact.data');

    update(editPosition, updatedContact);
    setValue('contact', { isEdit: false, data: {} });
    setShowAddContact(false);
  };

  const onEditContactHandler = (position: number) => {
    const contactToBeEdited = getValues(`data.contacts.${position}`);

    setEditPosition(position);
    setValue('contact', { isEdit: true, data: contactToBeEdited });
    setShowAddContact(true);
  };

  const onAddContactHandler = () => {
    const newContact = getValues('contact.data');

    append(newContact);
    setValue('contact', { isEdit: false, data: {} });
    setShowAddContact(false);
  };

  const onCloseAddContactHandler = () => {
    setValue('contact', { isEdit: false, data: {} });
    setShowAddContact(false);
  };

  const onSubmit = async (form: any) => {
    const { name, contacts } = form.data;
    const companyObj = {
      name,
      contacts,
      createdAt: Timestamp.now(),
    };

    try {
      const { id } = await addDoc(collection(db, 'companies'), companyObj);
      addNewCompanyToState({ id, data: companyObj });
      closeModal();
      reset();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DashboardModal
      noToolbox
      title="Företagsuppgifter"
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={css['add-company-form']}
        >
          <section className={css['modal__form-section']}>
            <BaseInput
              type="text"
              htmlFor="data.name"
              label="Företag"
              placeholder="Max Lagerbutik AB"
              {...register('data.name', { required: true })}
            />
          </section>

          <section className={css['modal__form-section']}>
            <ColoredBackground scssColor="#F3F5F6">
              <h4>Kontaktpersoner</h4>
              {showAddContact && (
                <AddContact
                  position={editPosition}
                  onUpdateContact={onUpdateContactHandler}
                  onAddContact={onAddContactHandler}
                  onCloseAddContact={onCloseAddContactHandler}
                />
              )}
              {!!fields?.length && (
                <ContactsList
                  onEditContact={onEditContactHandler}
                  onDeleteContact={onDeleteContactHandler}
                  contacts={fields}
                />
              )}

              <button
                type="button"
                className="button--text"
                id={css['init-btn']}
                onClick={initNewContact}
              >
                + Lägg till kontaktperson
              </button>
            </ColoredBackground>
          </section>
          <button
            type="submit"
            disabled={!isValid || showAddContact}
            className="button--cta"
          >
            Skapa företag
          </button>
        </form>
      </FormProvider>
    </DashboardModal>
  );
};

export default AddCompanyModal;
