import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../../../../firebase';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import CandidateList from '../../../../../../components/Pools/lists/CandidateList/CandidateList';
import { PoolService } from '../../../../../../services/database-service';
import useEffectOnce from '../../../../../../hooks/useEffectOnce';
import RecipientList from '../../../../../../components/Pools/lists/RecipientList/RecipientList';
import { PoolContext } from '../../../../../../context/dashboard/pool-context';

type Props = {};

const Dispatch = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [dispatch, setDispatch]: any = useState({});
  const [recipients, setRecipients] = useState([]);

  const { disqualifyRecipient, confirmRecipient, unconfirmRecipient } =
    useContext(PoolContext);

  const { poolId, dispatchId } = useParams();

  const initPoolListener = (docRef: any) => {
    return onSnapshot(docRef, { includeMetadataChanges: true }, (doc: any) => {
      if (!doc.exists()) return;
      const docData = doc.data();
      setDispatch(docData);
    });
  };

  const initRecipientsListener = (docRef: any) => {
    return onSnapshot(
      query(docRef, orderBy('acceptedAt', 'desc')),
      { includeMetadataChanges: true },
      (snapshot: any) => {
        const docs = snapshot.docs.map((doc: any) => {
          return {
            id: doc.id, // append document id to each document
            data: doc.data(),
          };
        });
        setRecipients(docs);
      }
    );
  };

  useEffect(() => {
    if (!poolId || !dispatchId) return;
    const poolRef = doc(db, 'pools', poolId, 'messages', dispatchId);
    const unsubPool = initPoolListener(poolRef);

    const recipientsRef = collection(poolRef, 'recipients');
    const unsubRecipients = initRecipientsListener(recipientsRef);
    return () => {
      unsubPool();
      unsubRecipients();
    };
  }, [poolId, dispatchId]);

  return (
    dispatch && (
      <div>
        <div></div>
        <h2>{`Tackat ja: ${dispatch.participantCount}/${dispatch.maxParticipants}`}</h2>
        <RecipientList
          recipients={recipients}
          confirmRecipient={(candidateId: string) => {
            confirmRecipient(dispatchId as string, candidateId);
          }}
          unconfirmRecipient={(candidateId: string) => {
            unconfirmRecipient(dispatchId as string, candidateId);
          }}
          disqualifyRecipient={(candidateId: any) => {
            disqualifyRecipient(dispatchId as string, candidateId);
          }}
        />
      </div>
    )
  );
};

export default Dispatch;
