import { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import useWidth from '../../hooks/UI/useWindowWidth';

import Header from '../../components/Header/Header';
import Copyright from '../../components/Copyright/Copyright';

import css from './Login.module.scss';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [message, setMessage] = useState(false);
  const [width] = useWidth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!!localStorage.getItem('newPassword')) {
      setMessage(true);
      localStorage.clear();
    }
  }, []);

  const onSubmit = (data: any) => {
    signInWithEmailAndPasswordFunc(data.userName, data.password);
  };

  const signInWithEmailAndPasswordFunc = async (
    userName: string,
    password: string
  ) => {
    try {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, userName, password)
        .then((userCredential) => {
          const user: any = userCredential.user;
          if (user?.reloadUserInfo.customAttributes) {
            navigate('/dashboard');
          } else {
            navigate('/candidate');
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          switch (errorCode) {
            case 'auth/Invalid-email':
            case 'auth/user-disabled':
            case 'auth/user-not-found':
              alert('Fel användarnamn');
              console.log(errorMessage);
              break;
            case 'auth/wrong-password':
              alert('Fel lösenord');
              console.log(errorMessage);
              break;
            default:
          }
        });
    } catch (err: any) {
      switch (err.code) {
        case 'auth/Invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          alert('Fel användarnamn');
          break;
        case 'auth/wrong-password':
          alert('Fel lösenord');
          break;
        default:
      }
    }
  };

  return (
    <>
      {width < 481 && message && (
        <p className={css.messageBar}>
          <strong>Mejl skickat!</strong>
        </p>
      )}
      <div className={css.root}>
        <Header isStatic hasDarkText />
        <div className={css.formBox}>
          {width >= 481 && message && (
            <p className={css.messageBar}>
              <strong>Mejl skickat!</strong>
            </p>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className={css.loginHeader}>Logga in</h1>
            <div>
              <label>Email</label>
              <input
                type="text"
                {...register('userName', { required: true })}
              />
              {errors.userName && (
                <span className={css.error}>Skriv ditt användarnamn</span>
              )}
            </div>
            <div>
              <label>Lösenord</label>
              <input
                type="password"
                {...register('password', { required: true })}
              />
              {errors.password && (
                <span className={css.error}>Skriv ditt lösenord</span>
              )}
            </div>
            <div className={css.buttons}>
              <input type="submit" value="LOGGA IN" className="button--cta" />
              <button
                className="button--full-width button--default"
                onClick={() => navigate('/apply-name')}
              >
                REGISTRERA DIG
              </button>
              <a className={css.forgotPassword} href="/recover-password">
                Glömt lösenord?
              </a>
            </div>
          </form>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default Login;
