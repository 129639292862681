import { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FUNCTIONS_URL } from '../../../utils/urls';
import { getAuth } from '@firebase/auth';

import BaseCard from '../../../components/common/BaseCard/BaseCard';
import ExpandIcon from '../../../assets/icons/expand.svg';
import css from './SpecificJob.module.scss';

type Props = {
  companyName: string;
  title: string;
  description: string;
  formOfEmployment: string;
  route: any;
  postalCode: any;
  postalTown: any;
  streetNumber: any;
};

const SpecificJobInfo = (props: Props) => {
  let { id } = useParams();
  useEffect(() => {
    const userUid = getAuth().currentUser?.uid;
    if (!!userUid) {
      const fetchData = async () => {
        const data = await fetch(`${FUNCTIONS_URL}/candidate/has-applied`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ jobsId: id, candidateId: userUid }),
        });
        if (data.status === 200) {
          const json = await data.json();
          return json;
        }
        return data.status;
      };
      fetchData()
        .then((data) => {
          if (data === 400) {
            setHasApplied(true);
            alert(
              'Något gick fel, vänligen kontakta Kila support genom chatikonen till höger eller mejla oss på info@kila.se'
            );
          } else {
            setHasApplied(data);
          }
        })
        .catch((err) => console.warn(err));
    }
  }, []);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const descTextRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [expandDesc, setExpandDesc] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const [initialHeight, setInitialHeight] = useState('');

  const onToggleExpand = () => {
    if (descriptionRef.current) {
      const descRef = descriptionRef.current;

      if (!initialHeight.length) {
        const initialContainerHeight = window.getComputedStyle(descRef).height;
        setInitialHeight(initialContainerHeight);
      }

      const descHeight: any = descRef.firstElementChild?.clientHeight;
      // Add 60px extra space to make sure the toggle button isn't covering the text.
      let newContainerHeight = !expandDesc
        ? `${descHeight + 60}px`
        : initialHeight;
      descRef.style.height = newContainerHeight;
      setExpandDesc((prevValue) => !prevValue);
    }
  };

  const initApplyProcess = () => {
    // check if visitor is logged in, else store applicant record

    navigate(`apply?step=0`);
  };

  return (
    <BaseCard minHeight="530px">
      {/* <div className={css.companyImage}>
        <img src={JobCompanyLogo} alt="företagets logotyp" />
      </div> */}

      <h3 id={css.title}>{props.title}</h3>
      {!!props.postalTown && (
        <div className={css.companyLocation}>
          <h3>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
            </svg>
            <div>
              <span> {props.postalTown}</span>
            </div>
          </h3>
        </div>
      )}
      <hr className={css.hr} />

      <div
        className={`${css.jobDescriptionContainer} ${
          expandDesc ? css.expanded : css.closed
        }`}
      >
        <div className={css.jobDescriptionWrapper} ref={descriptionRef}>
          <div className={css.textWrapper} ref={descTextRef}>
            {props.description.split('<linebreak>').map((textSection) => {
              return <p key={Math.random()}>{textSection}</p>;
            })}
          </div>
          <div className={css.linearBlockFade}></div>
        </div>
        <button
          type="button"
          onClick={onToggleExpand}
          className={css.btnReadMore}
        >
          <img src={ExpandIcon} alt="expand description icon" />
        </button>
      </div>
      <button
        className={`button--cta ${css.btnApply} ${
          expandDesc ? css.socialDistance : css.initialBtnState
        }`}
        disabled={hasApplied}
        onClick={initApplyProcess}
      >
        {!hasApplied ? <>Ansök till jobbet</> : <>Du har redan ansökt</>}
      </button>
    </BaseCard>
  );
};

export default SpecificJobInfo;
