// components
import SubmitFooterComponent from '../../../../components/interview/InterviewSection/Form/SubmitFooter/SubmitFooter';

type Props = {
  isValid: boolean;
};

const SubmitFooter = ({ isValid }: Props) => {
  return <SubmitFooterComponent isValid={isValid} />;
};

export default SubmitFooter;
