import { useState, useEffect, useRef, useCallback } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../../../firebase';

import { useForm, FormProvider } from 'react-hook-form';
import DashboardModal from '../../DashboardModal/DashboardModal';
import BaseInput from '../../../common/inputs/BaseInput/BaseInput';

import css from './AddCandidateModal.module.scss';

const apiKey = process.env.REACT_APP_GMAP_API_KEY;
const baseApiURL = 'https://maps.googleapis.com/maps/api/js';
const restOfParams = '&libraries=places&v=weekly&language=sv';
const gMapSource = `${baseApiURL}?key=${apiKey}${restOfParams}`;

type Props = {
  closeModal: () => void;
  addNewCandidateToState: (candidateObj: object) => void;
  modalIsOpen: boolean;
};

function loadAsyncScript(src: string) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src: src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

function extractTargetByTypes(arr: any, wantedTypes: any) {
  const tempObj = Object.create(null);
  for (let obj of arr as any) {
    const { long_name, types } = obj;
    let hasCommonTypes = types.some((type: any) => wantedTypes.includes(type));
    if (!hasCommonTypes) continue;
    tempObj[types[0]] = long_name;
  }
  return tempObj;
}

const AddCandidateModal = (props: Props) => {
  const { addNewCandidateToState, modalIsOpen, closeModal } = props;

  const methods = useForm<any>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      contact: {
        isEdit: false,
        data: {},
      },
      data: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
    },
  });
  const { reset, register, handleSubmit, clearErrors, setValue } = methods;
  const { isValid } = methods.formState;
  const [showAddCandidate, setShowAddCandidate] = useState(false);
  const [hasSelected, setHasSelected] = useState<any>(
    !!localStorage.getItem('geoData') || null
  );
  const [geoData, setGeoData] = useState<any>();

  const onChangeAddress = useCallback((autocomplete: any) => {
    const location = autocomplete.getPlace();
    if (location.name) return;
    setHasSelected(true);
    clearErrors('location');
    setValue('location', locationInputEl.current.value, {
      shouldValidate: true,
    });

    const { address_components, geometry } = location;
    const { lat, lng } = geometry.location;

    const { ...extracted } = extractTargetByTypes(address_components, [
      'route',
      'street_number',
      'postal_code',
      'postal_town',
    ]);
    extracted['coordinates'] = { lat: lat(), long: lng() };
    setGeoData(extracted);
  }, []);

  const locationInputEl = useRef<any>(null);

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }

    return loadAsyncScript(gMapSource);
  };

  const { ref, ...rest } = register('location', {
    required: true,
    validate: () => {
      return hasSelected === true && hasSelected !== null;
    },
    onChange: () => {
      setHasSelected(false);
    },
    onBlur: (e) => {
      let sameValue = e.value === e.defaultValue;
      setHasSelected(sameValue);
    },
  });

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
    return () => {
      document.querySelector('.pac-container')?.remove();
    };
  }, []);

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteLsr, setAutocompleteLsr] = useState<any>(null);

  const initAutocomplete = async () => {
    const inputEl = locationInputEl.current;
    const options = {
      types: ['address'],
      componentRestrictions: { country: 'se' },
      fields: ['address_component', 'geometry'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputEl,
      options
    );

    setAutocomplete(autocomplete);
  };

  useEffect(() => {
    if (autocomplete && !autocompleteLsr) {
      const lsr = autocomplete.addListener('place_changed', () => {
        return onChangeAddress(autocomplete);
      });
      setAutocompleteLsr(lsr);
    }

    return () => {
      if (autocomplete && autocompleteLsr) {
        google.maps.event.removeListener(autocompleteLsr);
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [autocomplete, autocompleteLsr, onChangeAddress]);

  const onSubmit = async (form: any) => {
    const { firstName, lastName, email, phone } = form.data;
    const candidateObj = {
      firstName,
      lastName,
      phone,
      email,
      location: {
        formatted: form.location,
        geoData,
      },
      createdAt: Timestamp.now(),
    };

    try {
      const { id } = await addDoc(collection(db, 'candidates'), candidateObj);
      addNewCandidateToState({ id, data: candidateObj });
      closeModal();
      reset();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DashboardModal
      noToolbox
      title="Kandidatuppgifter"
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={css['add-candidate-form']}
        >
          <section className={css['modal__form-section']}>
            <BaseInput
              customProps={{ noBottomMargin: true }}
              type="text"
              htmlFor="data.firstName"
              label="Förnamn"
              placeholder="Semawi"
              {...register('data.firstName', { required: true })}
            />
            <BaseInput
              customProps={{ noBottomMargin: true }}
              type="text"
              htmlFor="data.lastName"
              label="Efternamn"
              placeholder="Olson"
              {...register('data.lastName', { required: true })}
            />
            <BaseInput
              customProps={{ noBottomMargin: true }}
              type="email"
              htmlFor="data.email"
              label="Mejladress"
              placeholder="semawi.olson@gmail.com"
              {...register('data.email', {
                required: 'Ogiltigt mejladress.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: '',
                },
              })}
            />
            <BaseInput
              customProps={{ noBottomMargin: true }}
              type="number"
              htmlFor="data.phone"
              label="Telefon"
              placeholder="Telefonnummer"
              {...register('data.phone', { required: true })}
            />
            <BaseInput
              {...rest}
              label="Vart bor du?"
              type="text"
              onFocus={() => {
                setHasSelected(false);
              }}
              placeholder="Sveavägen, Stockholm, Sverige"
              name="location"
              ref={(e) => {
                ref(e);
                locationInputEl.current = e;
              }}
            />
          </section>
          <button
            type="submit"
            disabled={!isValid || showAddCandidate}
            className="button--cta"
          >
            Skapa kandidat
          </button>
        </form>
      </FormProvider>
    </DashboardModal>
  );
};

export default AddCandidateModal;
