import GoRoute from '../../GoRoute/GoRoute';

// styles
import css from './Header.module.scss';

const Header = ({ candidateId, appId, confirmBeforeNavigate }: any) => {
  const appPath = `/dashboard/candidates/${candidateId}/applications?appId=${appId}`;
  return (
    <header className={css['header']}>
      <div className="container">
        <nav>
          <GoRoute
            title="tillbaka"
            path={appPath}
            {...(confirmBeforeNavigate && {
              confirmText: 'Är du säker på att du vill lämna interjun?',
            })}
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;
