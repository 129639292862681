import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './components/AuthenticatedRoute/PrivateRoute';
import ScrollToTop from './helpers/ScrollToTop';

// Website
import NoMatch from './containers/NoMatch/NoMatch';
// import AllComponents from './website/pages/AllComponents/AllComponents';
import Landingpage from './website/pages/LandingPage/LandingPage';
import Companies from './website/pages/Companies/Companies';
import PrivacyPolicyCompanies from './website/pages/Companies/PrivacyPolicyCompanies/PrivacyPolicyCompanies';
import Candidates from './website/pages/Candidates/Candidates';
import PrivacyPolicyCandidates from './website/pages/Candidates/PrivacyPolicyCandidates/PrivacyPolicyCandidates';
import AboutUs from './website/pages/AboutUs/AboutUs';
import Internship from './website/pages/Internship/Internship';
import Education from './website/pages/Education/Education';

// Jobs
import SpecificJobRoutes from './containers/SpecificJobPages/SpecificJobRoutes/SpecificJobRoutes';

// Apply flow
import ApplyName from './containers/ApplySteps/ApplyName';
import ApplyInfo from './containers/ApplySteps/ApplyInfo';
import ApplyEmail from './containers/ApplySteps/ApplyEmail';
import ApplyDone from './containers/ApplySteps/ApplyDone';
import ApplyVerified from './containers/ApplySteps/ApplyVerified';
// import BasicElements from './containers/BasicElements/BasicElements';

// Login
import Login from './containers/Login/Login';
import LoginWithEmail from './containers/Login/LoginWithEmail';
import RecoverPassword from './containers/RecoverPassword/RecoverPassword';

// Dashboard
import Dashboard from './containers/Dashboard/Dashboard/Dashboard';
import Candidate from './containers/Candidate/Candidate';

// Context providers
import { AuthContextProvider } from './firebase';
import { GlobalContextProvider } from './context/global/GlobalContextProvider';

export default function App() {
  return (
    <GlobalContextProvider>
      <AuthContextProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            {/* Test pages */}
            {/* TODO: Comment out test pages */}
            {/* <Route element={<AllComponents />} path="/all-components" />
            <Route element={<BasicElements />} path="/basic-elements" />*/}

            {/* Website */}
            <Route element={<Landingpage />} path="/" />

            <Route element={<Companies />} path="/foretag" />
            <Route
              element={<PrivacyPolicyCompanies />}
              path="/foretag/integritetspolicy"
            />
            <Route element={<Internship />} path="/foretag/praktik" />
            <Route element={<Education />} path="/foretag/utbildning" />

            <Route element={<Candidates />} path="/jobbsokande" />
            <Route
              element={<PrivacyPolicyCandidates />}
              path="/jobbsokande/integritetspolicy"
            />

            <Route element={<AboutUs />} path="/om-oss" />

            {/* Website - Login */}
            <Route element={<Login />} path="/login" />
            <Route element={<LoginWithEmail />} path="/loginwithemail" />
            <Route element={<RecoverPassword />} path="/recover-password" />

            {/* Website - Jobs */}
            <Route element={<SpecificJobRoutes />} path="jobs/:id/*" />

            {/* Website - Apply flow */}
            <Route element={<ApplyName />} path="/apply-name" />
            <Route element={<ApplyInfo />} path="/apply-info" />
            <Route element={<ApplyEmail />} path="/apply-email" />
            <Route element={<ApplyDone />} path="/apply-done" />
            <Route element={<ApplyVerified />} path="/apply-verified" />
            <Route element={<ApplyVerified />} path="/test" />

            {/* Dashboard */}
            <Route
              path="/dashboard/*"
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route
              path="/candidate"
              element={<PrivateRoute component={Candidate} />}
            />

            <Route element={<NoMatch />} path="*" />
          </Routes>
        </Router>
      </AuthContextProvider>
    </GlobalContextProvider>
  );
}
