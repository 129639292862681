import { memo, useMemo } from 'react';

import css from './PoolList.module.scss';

import ListItem from '../../common/ListItem/ListItem';
import { firstToUpper } from '../../../../utils/convert/typography';

type Props = { pools: any };

const PoolList = ({ pools }: Props) => {
  const capitalizeTitle = (str: string) => firstToUpper(str);
  const poolItems = useMemo(() => {
    return pools.map(({ id, data }: any) => (
      <ListItem
        key={id}
        linkTo={id}
        text={`${capitalizeTitle(data.title)}, ${capitalizeTitle(
          data.companyName
        )}`}
      />
    ));
  }, [pools]);

  return <ul className="block-list">{poolItems}</ul>;
};

export default memo(PoolList);
