import ContactsListItem from '../ContactsListItem/ContactsListItem';

import css from './ContactsList.module.scss';

type Props = {
  onDeleteContact: (position: number) => void;
  onEditContact: (position: number) => void;
  contacts: { id: any }[];
};

const CompanyContactList = (props: Props) => {
  const { contacts, onDeleteContact, onEditContact } = props;

  return (
    <div className={css['list-container']}>
      <div className={css['contacts-list']}>
        {contacts.map((field: any, idx: number) => {
          return (
            <ContactsListItem
              key={idx}
              contactData={field}
              onDeleteContact={onDeleteContact}
              onEditContact={onEditContact}
              position={idx}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CompanyContactList;
