import { useMemo, Fragment } from 'react';

import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';

import css from './Forms.module.scss';

const ForkliftLicensesForm = ({
  forkliftLicenses,
  onUpdateCandidateLicenses,
  buttonText,
}: any) => {
  const availableLicenses = useMemo(
    () => [
      {
        title: 'A-körkort',
        licenses: [
          {
            category: 'A1',
            title: 'Låglyftande plocktruck',
            previouslySet: false,
          },
          {
            category: 'A2',
            title: 'Ledstaplare med eller utan åkplatta',
            previouslySet: false,
          },
          {
            category: 'A3',
            title: 'Låglyftande åktruck stående eller sittande',
            previouslySet: false,
          },
          {
            category: 'A4',
            title: 'Låglyftare med eller utan åkplatta',
            previouslySet: false,
          },
        ],
      },
      {
        title: 'B-körkort',
        licenses: [
          { category: 'B1', title: 'Motviktstruck', previouslySet: false },
          {
            category: 'B2',
            title: 'Höglyftande åktruck',
            previouslySet: false,
          },
          { category: 'B3', title: 'Skjutstativtruck', previouslySet: false },
          {
            category: 'B4',
            title: 'Höglyftande plocktruck',
            previouslySet: false,
          },
          { category: 'B5', title: 'Smalgångstruck', previouslySet: false },
          { category: 'B6', title: 'Fyrvägstruck', previouslySet: false },
        ],
      },
      {
        title: 'C-körkort',
        licenses: [
          { category: 'C2', title: 'Hjullastare', previouslySet: false },
          { category: 'C3A', title: 'Containertruck', previouslySet: false },
          { category: 'C3B', title: 'Timmertruck', previouslySet: false },
          { category: 'C4', title: 'Sidlastare', previouslySet: false },
          { category: 'C5', title: 'Grensletruck', previouslySet: false },
          { category: 'C7', title: 'Teleskoplastare', previouslySet: false },
        ],
      },
      {
        title: 'D-körkort',
        licenses: [
          { category: 'D1', title: 'Drag- & flaktruck', previouslySet: false },
          { category: 'D2', title: 'Terminaldragtruck', previouslySet: false },
        ],
      },
    ],
    []
  );

  const onUpdateCandidateLicensesHandler = (data: any) => {
    onUpdateCandidateLicenses(data, 'forkliftLicenses');
  };

  const isPreviouslySet = (option: any) => {
    return (
      forkliftLicenses &&
      forkliftLicenses.some((license: any) => {
        return (
          license.data.title.toLowerCase() === option.category.toLowerCase()
        );
      })
    );
  };

  // jsx

  const renderLicenseGroup = (licenses: any[], groupIdx: number) => {
    return licenses.map((item: any, idx: number) => {
      const licenseTitle = `${item.category}. 
         ${item.title}`;
      return (
        <Fragment key={idx}>
          <GroupInstance>
            <input
              type="checkbox"
              id={`forklift-license-${groupIdx}-${idx}`}
              value={item.category}
              name={item.category}
              defaultChecked={isPreviouslySet(item)}
            />
          </GroupInstance>
          <label htmlFor={`forklift-license-${groupIdx}-${idx}`}>
            {licenseTitle}
          </label>
        </Fragment>
      );
    });
  };

  const licenses = availableLicenses.map(
    ({ title, licenses }: any, idx: number) => {
      return (
        <fieldset key={idx}>
          <legend>{title}</legend>
          <div className={css['license-group']}>
            {renderLicenseGroup(licenses, idx)}
          </div>
        </fieldset>
      );
    }
  );

  return (
    <FormGroup
      buttonText={buttonText}
      onSaveData={(data: any) => {
        onUpdateCandidateLicensesHandler(data);
      }}
      FormIsValid={true} // is true because currently no validation is in place for drivers lice..
    >
      <div className={css['licenses']}>{licenses}</div>
    </FormGroup>
  );
};

export default ForkliftLicensesForm;
