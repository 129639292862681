import { useFieldArray } from 'react-hook-form';
import BaseButton from '../../../../components/common/BaseButton/BaseButton';
import BaseInput from '../../../../components/common/inputs/BaseInput/BaseInput';
import { v4 as uuid } from 'uuid';

// styles
import css from './NewJobModal.module.scss';
import FieldGroups from './FieldGroups';

type Props = {};

const Pages = ({
  control,
  register,
  setValue,
  getValues,
  errors,
  isValid,
}: any) => {
  const {
    fields: pages,
    append,
    update,
    remove,
    swap,
    move,
    insert,
  } = useFieldArray({
    control,
    name: 'form', // unique name for your Field Array
  });

  const addPage = () => {
    const page = {
      component: 'page',
      label: '',
      _uid: uuid(),
      fields: [],
    };
    append(page);

    return page;
  };

  return (
    <div>
      {pages.map((page, index) => (
        <div className={css['job-page']}>
          <div className="adaptable-flex-direction space-between">
            <h2 className="h4">Sida {index + 1}</h2>
            <BaseButton
              title="Ta bort"
              type="button"
              styleType="delete"
              onClick={() => remove(index)}
            />
          </div>

          <BaseInput
            key={page.id} // important to include key with field's id
            type="text"
            label={`Titel`}
            inputid="label"
            {...register(`form.${index}.label`, { required: true })}
          />
          <FieldGroups
            nestIndex={index}
            isValid={isValid}
            {...{ control, register }}
          />
        </div>
      ))}
      <BaseButton
        title="Ny sida"
        type="button"
        styleType="cta button--add"
        onClick={addPage}
        disabled={!isValid}
      />
    </div>
  );
};

export default Pages;
