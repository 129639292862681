import React from 'react';

import css from './ModalFooter.module.scss';

type Props = {
  children: React.ReactNode;
};

const ModalFooter: React.FC<Props> = (props) => {
  return <footer className={css.modalFooter}>{props.children}</footer>;
};

export default ModalFooter;
