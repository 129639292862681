import { useContext } from 'react';
import { CandidateProfileContext } from '../../../context/candidate-profile-context';
import { toLocaleString } from '../../../utils/dates';
import { firstToUpper } from '../../../utils/convert/typography';
import { sortArrayByDate } from '../../../utils/sort/array';
import IconButton from '../../common/IconButton/IconButton';
import LoadingText from '../../Progress/LoadingText/LoadingText';

import css from './List.module.scss';

const EducationList = () => {
  const ctx = useContext(CandidateProfileContext);

  const { mapToIterableArray, setEditState, setAddState, isLoading } = ctx;

  const iterableEducations = !isLoading ? mapToIterableArray('educations') : [];

  const sortedEducations = sortArrayByDate({
    arr: iterableEducations,
    requireEndDate: true,
  });

  const listEducations = sortedEducations?.map((item: any, key: any) => {
    const {
      id,
      data: { degree, school, startDate, endDate },
    } = item;
    return (
      <li key={key}>
        <div className={css['list-item']}>
          <span>
            <LoadingText maxWidth={100}>{firstToUpper(degree)}, </LoadingText>
          </span>
          <span>
            <LoadingText maxWidth={50}>{firstToUpper(school)}</LoadingText>
          </span>
          <time className={css['from-to']}>
            <LoadingText>
              {firstToUpper(
                toLocaleString(startDate, 'sv-SE', {
                  year: 'numeric',
                  month: 'long',
                })
              )}
            </LoadingText>
            {' - '}
            <LoadingText>
              {!!endDate ? (
                firstToUpper(
                  toLocaleString(endDate, 'sv-SE', {
                    month: 'long',
                    year: 'numeric',
                  })
                )
              ) : (
                <span>Nuvarande</span>
              )}
            </LoadingText>
          </time>
        </div>
        <IconButton
          faIcon="pencil"
          text="Redigera"
          onClick={() => {
            setEditState({
              typeOfEdit: 'education',
              itemLocation: 'educations',
              itemId: id,
              item,
            });
          }}
        />
      </li>
    );
  });

  const ctaButton = (
    <button
      onClick={() => {
        setAddState({ contentRef: 'education', type: 'add' });
      }}
      className="button--cta button--add"
    >
      Lägg till utbildning
    </button>
  );

  return (
    <>
      {!!iterableEducations && iterableEducations.length ? (
        <ul className="list--lined">{listEducations}</ul>
      ) : !isLoading ? (
        ctaButton
      ) : null}
    </>
  );
};

export default EducationList;
