import React from 'react';
import css from './HeadingRow.module.scss';

import NumberBadgeHeading from '../NumberBadgeHeading/NumberBadgeHeading';

type Props = {
  heading: string;
  headingLevel: 1 | 2 | 3 | 4 | 5;
  badgeNumber: number;
  buttonText?: string;
  onButtonClick?: () => void;
  children?: React.ReactNode;
};

const HeadingRow = ({heading, headingLevel, badgeNumber, buttonText, onButtonClick, children}: Props) => {
  const CustomTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;
  return (
    <header className={css['heading-row']}>
      <NumberBadgeHeading heading={heading} headingLevel={headingLevel} number={badgeNumber} />
      { buttonText && onButtonClick && (<button onClick={onButtonClick} className="button--cta button--small">{buttonText}</button>)}
      {children}
    </header>
  );
};

export default HeadingRow;
