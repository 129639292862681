import css from './HeroSection.module.scss';

import Button from '../../../website/components/atoms/Button/Button';
import Heading from '../../../website/components/atoms/Heading/Heading';

type Props = {
  copy: {
    headingRow1: string;
    headingRow2?: string;
    text: string;
    buttons: Array<
      {
        text: string,
        link: string,
      }
    >,
  }
};

const HeroSection = ({ copy }: Props) => {
  const { headingRow1, headingRow2, text, buttons } = copy;

  return (
    <section className={css.root}>
      <div>
        <Heading level="h1">
          {headingRow1}
          {headingRow2 && <span>{headingRow2}</span>}
        </Heading>
        {buttons.map((button, index) => {
          return <Button key={index} label={button.text} link={button.link} type="default" />
        })}
      </div>
    </section>
  );
};
export default HeroSection;
