// Assets
import copy from './landingPageCopy.json';

// Images
import ImageTextAndImage1 from '../../../website/assets/images/together.webp';
import ImageTextAndImage2 from '../../../website/assets/images/internship.webp';

// Shared header with dashboard
import Header from '../../../components/Header/Header';

// Sections
import HeroSection from '../../../website/sections/HeroSection/HeroSection';
import TextWithIconsSection from '../../../website/sections/TextWithIconsSection/TextWithIconsSection';
import ImageAndTextSection from '../../../website/sections/ImageAndTextSection/ImageAndTextSection';
import Quote from '../../../website/sections/Quote/Quote';
import Testimonials from '../../../website/sections/Testimonials/Testimonials';
import Cta from '../../../website/sections/Cta/Cta';
import Footer from '../../../website/sections/Footer/Footer';

const LandingPage = () => {
  return (
    <>
      <Header/>
      <HeroSection copy={copy.hero} />
      <TextWithIconsSection copy={copy.whyKilaIsDifferent} />
      {/*<Quote copy={copy.quote} />*/}
      <ImageAndTextSection copy={copy.education} image={ImageTextAndImage1} imagePosition='left'/>
      {/*<Testimonials copy={copy.testimonials}/>*/}
      <Cta copy={copy.cta} />
      <ImageAndTextSection copy={copy.internship} image={ImageTextAndImage2} imagePosition='right' />
      <Footer />
    </>
  );
};

export default LandingPage;
