import { useState, useEffect } from "react";

/**
 * Useful for conditional rendering and/or advanced UI/UX handling
 */

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return [width];
};

export default useWindowWidth;
