import React, { useState, Fragment, useMemo } from 'react';

import Modal from 'react-modal';
import css from '../../UserInfoModal/UserInfoModal.module.scss';
import BaseSelect from '../../common/BaseSelect/BaseSelect';
import ModalComments from './ModalComments/ModalComments';
import ModalHeader from './ModalHeader/ModalHeader';
import ModalFooter from './ModalFooter/ModalFooter';
import Toolbox from '../../Toolbox/Toolbox';
// import { ReactComponent as PencileIcon } from '../../../assets/icons/pencile.svg';
// import { ReactComponent as CloseIcon } from '../../../assets/icons/close-round-line.svg';

type Props = {
  children: React.ReactNode;
  modalIsOpen: boolean;
  data?: object;
  title?: string;
  isLoading?: boolean;
  dataStatus?: string;
  noToolbox?: boolean;
  comments?: {}[];
  closeModal: () => void;
  onDeleteItem?: () => void;
  onStatusChange?: (value: string) => void;
  onAddComment?: (comment: object) => void;
};

const DashboardModal: React.FC<Props> = (props) => {
  const [showToolbox, setShowToolbox] = useState(false);
  const toggleToolbox = () => {
    setShowToolbox((visiblility) => !visiblility);
  };

  const statusSelectOptions = useMemo(() => {
    return [
      'ej hanterad',
      'kontaktad',
      'länk skickad',
      'bokad intervju',
      'intervju klar',
      'presenterad',
      'inte relevant',
      'fått jobb',
    ];
  }, []);

  return (
    <Modal
      className={css.modalUser}
      style={{
        overlay: { background: 'rgba(0, 0, 0, 0.5)', zIndex: 500 },
        content: { zIndex: 999, display: 'flex', maxHeight: '700px' },
      }}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      contentLabel="User Modal"
      ariaHideApp={false}
      parentSelector={(): any => document.querySelector('#dashboard-portal')}
    >
      {props.isLoading && (
        <div className={css.loadingWrapper}>
          <div className={css['dot-fire']}></div>
        </div>
      )}
      {!props.isLoading && (
        <Fragment>
          <div className={css['modal']}>
            <div id="user-data-content" className={css.modalContentWrapper}>
              <ModalHeader title={props.title}>
                {!props.noToolbox && (
                  <button
                    className={`${css.settingsBtn} button--icon button--icon--menu`}
                    onClick={toggleToolbox}
                  >
                    <span>Redigera</span>
                  </button>
                )}

                {showToolbox && <Toolbox onDeleteItem={props.onDeleteItem} />}
              </ModalHeader>
            </div>
            <div className={css['modal-content']}>{props.children}</div>
            <ModalFooter>
              {props.dataStatus && (
                <BaseSelect
                  defaultValue={props.dataStatus}
                  onChange={props.onStatusChange}
                  label="Status"
                  options={statusSelectOptions}
                  flexCol
                />
              )}
              {props.comments && props.onAddComment && (
                <ModalComments
                  comments={props.comments}
                  onAddComment={props.onAddComment}
                />
              )}
            </ModalFooter>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default DashboardModal;
