import CandidatePrediction from '../../../../../../components/PredictionList/CandidatePrediction/CandidatePrediction';

const { Hits } = require('react-instantsearch-dom');

const CandidatePredictions = ({
  isAddedCandidate,
  addCandidate,
  removeCandidate,
}: any) => {
  return (
    <Hits
      hitComponent={({ hit }: any) => (
        <CandidatePrediction
          hit={hit}
          isAdded={isAddedCandidate(hit.objectID)}
          onAddCandidate={addCandidate}
          onRemoveCandidate={removeCandidate}
        />
      )}
    />
  );
};

export default CandidatePredictions;
