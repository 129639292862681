import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import BaseInput from '../common/inputs/BaseInput/BaseInput';

import css from './DateOfBirth.module.scss';

type Props = {
  onSetDate: (date: any) => void;
  setIsValid: (bool: boolean) => void;
  birthday: any;
};

const defaultState = {
  year: '',
  month: '',
  day: '',
};

const DateOfBirth = ({ onSetDate, setIsValid, birthday }: Props) => {
  // unwrap birhday prop.
  const birthDate = new Date(birthday);
  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth() + 1;
  const birthDay = birthDate.getDate();

  // init input state and set default state values.
  const [birthdayState, setBirthdayState] = useState<any>({
    year: birthYear || defaultState['year'],
    month: birthMonth || defaultState['month'],
    day: birthDay || defaultState['day'],
  });

  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const validation = useMemo(() => {
    return {
      year: { min: currentYear - 100, max: currentYear },
      month: { min: 1, max: 12 },
      day: { min: 1, max: 31 },
    } as any;
  }, []);

  // refs
  const dayRef = useRef<any>(null);
  const monthRef = useRef<any>(null);
  const yearRef = useRef<any>(null);

  const inputRefs = [yearRef, monthRef, dayRef];

  // error state
  const [errors, setErrors] = useState<any>({});

  const setErrorsHandler = (key: string) => {
    setErrors((prevErrors: any) => {
      return { ...prevErrors, [key]: true };
    });
  };

  const clearErrors = (key: string) => {
    setErrors((prevErrors: any) => {
      const errorsClone: any = { ...prevErrors };
      if (key) delete errorsClone[key];
      return key ? errorsClone : defaultState;
    });
  };

  const errorsExists = useCallback(() => {
    return !!Object.keys(errors).length;
  }, [errors]);

  const isValidValue = (key: any, value: any) => {
    const numValue = Number(value);
    const inputValidation = validation[key];

    return numValue >= inputValidation.min && numValue <= inputValidation.max;
  };

  const setDateOfBirthHandler = ({ e, key }: any) => {
    const el = e.target;
    const {
      target: { value },
    } = e;

    const maxLength = el.getAttribute('maxlength');
    const dataIndex = el.getAttribute('data-index');

    const nextSibling = inputRefs[Number(dataIndex) + 1]?.current;
    const previousSibling = inputRefs[Number(dataIndex) - 1]?.current;

    const hitMaxLength = value.length >= maxLength;
    const isLastInput = Number(dataIndex) >= inputRefs.length;
    const wasLastErasable = value.length < 1;

    if (hitMaxLength && !isLastInput) {
      if (!isValidValue(key, value)) {
        setErrorsHandler(key);
        e.preventDefault();
      } else {
        clearErrors(key);
      }
      if (nextSibling) nextSibling.focus();
    } else if (wasLastErasable && previousSibling) {
      previousSibling.focus();
    }

    setBirthdayState((prevBirthdayState: any) => {
      return { ...prevBirthdayState, [key]: value };
    });
  };

  const isValidKeyCodes = (key: any) => {
    return /^[0-9]|[backspace]$/i.test(key);
  };

  const handleKeyDown = ({ e, key }: any) => {
    const { key: eventKey } = e;

    if (!isValidKeyCodes(eventKey)) return e.preventDefault();

    const currStateValue = birthdayState[key];

    const maxLength = e.target.getAttribute('maxlength');
    const dataIndex = e.target.getAttribute('data-index');

    const previousSibling = inputRefs[Number(dataIndex) - 1]?.current;
    const nextSibling = inputRefs[Number(dataIndex) + 1]?.current;

    const hitMaxLength = currStateValue.length >= maxLength;

    if (previousSibling) {
      const { name } = previousSibling;
      const prevSiblingValue = birthdayState[name];

      if (!isValidValue(name, prevSiblingValue)) {
        setErrorsHandler(previousSibling.name);
        e.preventDefault();
      } else {
        clearErrors(key);
      }
    }

    const isBackspace = eventKey.toLowerCase() === 'backspace';
    if (hitMaxLength && !isBackspace) {
      if (!nextSibling) return e.preventDefault();

      if (!errorsExists()) nextSibling.focus();
    } else if (
      isBackspace &&
      birthdayState[key].length < 1 &&
      previousSibling
    ) {
      previousSibling.focus();
    }
  };

  const onFocusHandler = ({ e }: any) => {
    const el = e.target;

    const dataIndex = el.getAttribute('data-index');

    const previousSibling = inputRefs[Number(dataIndex) - 1]?.current;
    const previousSiblingValue = previousSibling.value;

    if (previousSiblingValue.length < 2 && Number(previousSiblingValue) !== 0) {
      setBirthdayState((prevBirthdayState: any) => {
        return {
          ...prevBirthdayState,
          [previousSibling.name]: `0${previousSiblingValue}`,
        };
      });
    }
  };

  const onBlurHandler = ({ e, key }: any) => {
    const { value } = e.target;

    if (value.length < 2 && Number(value) < 10 && Number(value) !== 0) {
      setBirthdayState((prevBirthdayState: any) => {
        return {
          ...prevBirthdayState,
          [key]: `0${value}`,
        };
      });
    }
  };

  const errorListJsx = (
    <ol>
      {errors['year'] && (
        <li>
          <small>
            År får endast vara mellan {currentYear - 100} - {currentYear}
          </small>
        </li>
      )}
      {errors['month'] && (
        <li>
          <small>Månad får endast vara mellan 1 - 12</small>
        </li>
      )}
      {errors['day'] && (
        <li>
          <small>Dag får endast vara mellan 1 - 31</small>
        </li>
      )}
    </ol>
  );

  useEffect(() => {
    onSetDate(birthdayState);
  }, [birthdayState]);

  useEffect(() => {
    const emptyValues = [...Object.values(birthdayState)].some((v: any) => {
      return v?.length < 1;
    });

    setIsValid(!emptyValues && !errorsExists());
  }, [birthdayState, errorsExists, setIsValid]);

  const { year, month, day } = birthdayState;

  return (
    <div className={css['date-of-birth']}>
      <fieldset className={`no-vertical-margin ${css['fieldset']}`}>
        <legend>Födelsedag</legend>
        <div className={css['input-control']}>
          <BaseInput
            className={!!errors['year'] ? 'input--error' : ''}
            placeholder="ÅÅÅÅ"
            type="number"
            pattern="[0-9]*"
            maxLength={4}
            min={String(currentYear - 120)}
            max={String(currentYear)}
            ref={yearRef}
            value={year}
            data-index="0"
            name="year"
            onChange={(e: any) =>
              setDateOfBirthHandler({
                e,
                key: 'year',
              })
            }
            onKeyDown={(e: any) => handleKeyDown({ e, key: 'year' })}
          />
        </div>
        <div className={css['input-control']}>
          <BaseInput
            className={!!errors['month'] ? 'input--error' : ''}
            placeholder="MM"
            type="number"
            pattern="[0-9]*"
            maxLength={2}
            min="1"
            max="12"
            ref={monthRef}
            value={month}
            data-index="1"
            name="month"
            onChange={(e: any) =>
              setDateOfBirthHandler({
                e,
                key: 'month',
              })
            }
            onKeyDown={(e: any) => handleKeyDown({ e, key: 'month' })}
            onBlur={(e: any) => onBlurHandler({ e, key: 'month' })}
          />
        </div>

        <div className={css['input-control']}>
          <BaseInput
            className={!!errors['day'] ? 'input--error' : ''}
            placeholder="DD"
            type="number"
            pattern="[0-9]*"
            maxLength={2}
            min="1"
            max="31"
            ref={dayRef}
            value={day}
            data-index="2"
            name="day"
            onChange={(e: any) =>
              setDateOfBirthHandler({
                e,
                key: 'day',
                validation: { min: 1, max: 31 },
              })
            }
            onKeyDown={(e: any) => handleKeyDown({ e, key: 'day' })}
            onFocus={(e: any) => onFocusHandler({ e, key: 'day' })}
            onBlur={(e: any) => onBlurHandler({ e, key: 'day' })}
          />
        </div>
      </fieldset>
      {!!Object.keys(errors).length && errorListJsx}
      <p className="no-vertical-margin">
        <small>Exempel: 1968/08/20</small>
      </p>
    </div>
  );
};

export default DateOfBirth;
