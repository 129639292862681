import { useState } from 'react';

import DashboardModal from '../../DashboardModal/DashboardModal';
import ModalItem from '../../DashboardModal/ModalItem';
import ContactsList from '../AddCompanyModal/CompanyContact/Contacts/ContactsList/ContactsList';
import ColoredBackground from '../../../common/ColoredBackground/ColoredBackground';

import css from './CompanyModal.module.scss';

type Props = {
  modalIsOpen: boolean;
  company: any;
  closeModal: () => void;
};

const CompanyModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<any>(false);
  const { data } = props.company;

  return (
    <DashboardModal
      isLoading={isLoading}
      title="Företagsinformation"
      modalIsOpen={props.modalIsOpen}
      closeModal={props.closeModal}
    >
      <ModalItem title="Företag" value={data.name} />

      <section className={css['modal-item__section']}>
        <ColoredBackground scssColor="#F3F5F6">
          <h4>Kontaktpersoner</h4>
          {!!data.contacts ? (
            <ContactsList
              onEditContact={() => {}}
              onDeleteContact={() => {}}
              contacts={data.contacts}
            />
          ) : (
            <p>Inga kontaktpersoner finns för {data.name}.</p>
          )}
        </ColoredBackground>
      </section>
    </DashboardModal>
  );
};

export default CompanyModal;
