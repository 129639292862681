// styles
import css from './Tooltip.module.scss';

type Props = {
  tip: string;
};

const Item = ({ tip }: Props) => {
  return <li className={`no-vertical-margin ${css['tooltip-li']}`}>{tip}</li>;
};

export default Item;
