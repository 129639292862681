import ConfirmationBox from '../../../Confirmation/ConfirmationBox/ConfirmationBox';

const ConfirmPoolRemoval = ({ onConfirm, isOpen, onClose }: any) => {
  return (
    <ConfirmationBox
      type="danger"
      title="OBS! Oåterkallelig åtgärd."
      body="Genom att radera en pool så försvinner den från databasen, och om du vid ett senare tillfälle behöver den igen måste återskapningen ske manuellt. Vill du fortsätta?"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default ConfirmPoolRemoval;
