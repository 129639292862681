import { FC, useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import css from './LoadingText.module.scss';

type Props = {
  className?: string;
  style?: any;
  maxWidth?: number;
  minWidthPx?: number;
};

const LoadingText: FC<Props> = ({
  children,
  className,
  style,
  maxWidth,
  minWidthPx,
}: any) => {
  const [receivedChildren, setReceivedChildren] = useState<boolean>(false);

  useEffect(() => {
    let receivedTimeout: any;
    if (children && !receivedTimeout) {
      receivedTimeout = setTimeout(() => {
        setReceivedChildren(true);
      }, 500);
    }

    return () => {
      receivedTimeout && clearTimeout(receivedTimeout);
    };
  }, [children]);

  return children && receivedChildren ? (
    children
  ) : (
    <div
      style={{
        ...style,
        maxWidth: `${maxWidth}%`,
        ...(minWidthPx && { minWidth: minWidthPx }),
      }}
      className={`no-vertical-margin ${className} ${css['extend']}`}
    >
      <Skeleton />
    </div>
  );
};

export default LoadingText;
