import { useMemo, Fragment, useState, useEffect } from 'react';
import useQuery from '../../../hooks/useQuery';
import { Link, useNavigate } from 'react-router-dom';
import { useTable, useGlobalFilter, useFilters, useSortBy } from 'react-table';
import { DefaultColumnFilter, GlobalFilter } from '../TableFilter/ColumnFilter';

import css from './Table.module.scss';

const Table = ({
  data,
  definedColumns,
  hiddenColumns,

  rowRedirectTitle,
  rowRedirectCollection,
}: any) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [data]
  );

  const filterTypes = useMemo(
    () => ({
      select: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [data]
  );

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => {
      return [
        ...columns,
        {
          id: 'linkToDocument',
          disableFilters: true,
          Cell: ({ row }: any) => {
            let { rowRedirectId } = row.values;
            const customRouteRedirect = row.values?.customRouteRedirect;
            const redirectLink =
              customRouteRedirect ||
              `/dashboard/${rowRedirectCollection}/?filterSearch=${rowRedirectId}`;
            return (
              <a
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                href={redirectLink}
              >
                {rowRedirectTitle}
              </a>
            );
          },
        },
      ];
    });
  };

  let queryParams = useQuery();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns: definedColumns,
      data,
      defaultColumn,
      initialState: {
        globalFilter: queryParams.get('filterSearch'),
        hiddenColumns: [...hiddenColumns, 'rowRedirectId'],
      },
      filterTypes,
    } as any,
    useFilters,
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const navigate = useNavigate();

  return (
    <table className="table--clickable" {...getTableProps()}>
      <thead>
        <tr style={{ backgroundColor: 'white' }}>
          <th colSpan={6} style={{ height: 'max-content', padding: '0 0' }}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </th>
        </tr>
        {headerGroups.map((headerGroup: any, i: number) => (
          <Fragment key={i}>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  {column.render('Header')}
                  <div>
                    {column.canFilter && !column.disableFilters
                      ? column.render('Filter')
                      : null}
                  </div>
                </th>
              ))}
            </tr>
          </Fragment>
        ))}
      </thead>
      <tbody className={css.tableRowUser} {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr
              title="Visa mer information"
              className={css.tableRow}
              {...row.getRowProps()}
              onClick={() => {
                let { rowRedirectId } = row.values;
                const customRouteRedirect = row.values?.customRouteRedirect;
                const redirectLink =
                  customRouteRedirect ||
                  `/dashboard/${rowRedirectCollection}/?filterSearch=${rowRedirectId}`;
                window.open(redirectLink, '_blank', 'noopener,noreferrer');
              }}
            >
              {row.cells.map((cell: any) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
