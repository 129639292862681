import { useState } from 'react';
import JobListing from '../../../../models/JobListing';
import BaseModal from '../../../../components/common/BaseModal/BaseModal';
import BaseInput from '../../../../components/common/inputs/BaseInput/BaseInput';
import TextareaAutosize from 'react-textarea-autosize';
import BaseSelect from '../../../../components/common/BaseSelect/BaseSelect';
import InputAutocomplete from '../../../../components/common/inputs/InputAutocomplete/InputAutocomplete';
import { v4 as uuid } from 'uuid';
import { useForm, useFieldArray } from 'react-hook-form';
import BaseButton from '../../../../components/common/BaseButton/BaseButton';
import Pages from './Pages';
import FieldGroup from './FieldGroups';

// styles
import css from './NewJobModal.module.scss';
import { JobService } from '../../../../services/database-service';

type Props = {};

const NewJobModal = ({ isOpen, onClose }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
  });

  const onSelectEmploymentForm = (v: any) => {
    setValue('formOfEmployment', v);
  };

  const onSelectLocation = (oLocation: any) => {
    setValue('location', oLocation);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await JobService.create(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      reset();
      onClose();
    }
  };

  // const addTextField = (
  //   pageIndex: any,
  //   fieldGroupIndex: any,
  //   label: any,
  //   uid = null,
  //   required = false,
  //   defaultValue = ''
  // ) => {
  //   const field = {
  //     component: 'text',
  //     label,
  //     required,
  //     defaultValue,
  //     _uid: uid || uuid(),
  //   };
  //   return addField(pageIndex, fieldGroupIndex, field);
  // };
  const values = watch();

  return (
    <BaseModal heading="Nytt jobb" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BaseInput
          customProps={{ noBottomMargin: true }}
          type="text"
          label="Titel"
          inputid="title"
          {...register('title', { required: true })}
        />
        <BaseInput
          customProps={{ noBottomMargin: true }}
          type="text"
          label="Företag"
          inputid="companyName"
          {...register('companyName', { required: true })}
        />

        <BaseSelect
          label="Typ av jobb"
          options={['heltid', 'deltid']}
          defaultOption={{ option: '---', value: null }}
          onChange={onSelectEmploymentForm}
        />
        <InputAutocomplete
          label="Plats"
          onSelectLocation={onSelectLocation}
          componentRestrictions={{ country: 'se' }}
        />
        <label htmlFor="desc">Beskrivning</label>
        <TextareaAutosize
          id="desc"
          minRows={5}
          {...register('description', { required: true })}
        />
        <Pages
          {...{ control, register, getValues, setValue, errors, isValid }}
        />
        <BaseButton
          title="Skapa jobb"
          type="submit"
          styleType="cta"
          isLoading={isLoading}
        />
      </form>
    </BaseModal>
  );
};

export default NewJobModal;
