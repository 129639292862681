import css from './Candidate.module.scss';
import CandidatePageHeader from '../../components/Header/Header';
import EducationList from '../../components/Candidate/List/EducationList';
import ExperienceList from '../../components/Candidate/List/ExperienceList';
import PersonalInfoList from '../../components/Candidate/List/PersonalInfoList';
import DriversLicensesList from '../../components/Candidate/List/DriversLicensesList';
import ForkliftLicensesList from '../../components/Candidate/List/ForkliftLicensesList';
import LanguagesList from '../../components/Candidate/List/LanguagesList';

import { CandidateProfileContextProvider } from '../../context/candidate-profile-context';

import CandidateModal from './CandidateModal';
import CandidateCard from '../../components/Candidate/CandidateCard/CandidateCard';

const Candidate = () => {
  const candidateProfileCard = (
    <CandidateCard
      isProfileCard
      buttons={[
        {
          faIcon: 'pencil',
          iconButtonText: 'Lägg till information',
          modalConfig: {
            contentRef: 'personalInfo',
            type: 'edit',
          },
        },
      ]}
    >
      <PersonalInfoList />
    </CandidateCard>
  );

  const candidateExperienceCard = (
    <CandidateCard
      heading="Erfarenhet"
      buttons={[
        {
          faIcon: 'plus',
          iconButtonText: 'Lägg till erfarenhet',
          modalConfig: {
            contentRef: 'experience',
            type: 'add',
          },
        },
      ]}
    >
      <ExperienceList />
    </CandidateCard>
  );

  const candidateEducationCard = (
    <CandidateCard
      heading="Utbildning"
      buttons={[
        {
          faIcon: 'plus',
          iconButtonText: 'Lägg till utbildning',
          modalConfig: {
            contentRef: 'education',
            type: 'add',
          },
        },
      ]}
    >
      <EducationList />
    </CandidateCard>
  );

  const candidateDriversLicensesCard = (
    <CandidateCard
      heading="Körkort"
      buttons={[
        {
          faIcon: 'pen',
          iconButtonText: 'Redigera körkort',
          modalConfig: {
            contentRef: 'driversLicenses',
            type: 'edit',
          },
        },
      ]}
    >
      <DriversLicensesList />
    </CandidateCard>
  );

  const forkliftLicensesCard = (
    <CandidateCard
      heading="Truckkort"
      buttons={[
        {
          faIcon: 'pen',
          iconButtonText: 'Redigera truckkort',
          modalConfig: {
            contentRef: 'forkliftLicenses',
            type: 'edit',
          },
        },
      ]}
    >
      <ForkliftLicensesList />
    </CandidateCard>
  );

  const candidateLanguagesCard = (
    <CandidateCard
      heading="Språk"
      buttons={[
        {
          faIcon: 'pen',
          iconButtonText: 'Redigera språk',
          modalConfig: {
            contentRef: 'languages',
            type: 'edit',
          },
        },
        {
          faIcon: 'plus',
          iconButtonText: 'Lägg till språk',
          modalConfig: {
            contentRef: 'language',
            type: 'add',
          },
        },
      ]}
    >
      <LanguagesList />
    </CandidateCard>
  );

  return (
    <>
      <CandidatePageHeader isDashboardHeader />
      <CandidateProfileContextProvider>
        <CandidateModal />

        <div className={`${css['main-wrapper']}`}>
          <main className={css.candidate}>
            <section>{candidateProfileCard}</section>
            <section>{candidateExperienceCard}</section>
            <section>{candidateEducationCard}</section>
            <section>{candidateDriversLicensesCard}</section>
            <section>{forkliftLicensesCard}</section>
            <section>{candidateLanguagesCard}</section>
          </main>
        </div>
      </CandidateProfileContextProvider>
    </>
  );
};

export default Candidate;
