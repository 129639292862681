import { useState, useRef, Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { toLocaleString } from '../../../../utils/dates';

import BaseInput from '../../../common/inputs/BaseInput/BaseInput';

import css from './ModalComments.module.scss';

type Props = {
  comments: {}[];
  onAddComment: (comment: object) => void;
};

type FormValues = {
  title: string;
  description: string;
};

const ModalOverlay = () => {
  return createPortal(
    <div className={css.modalCommentsOverlay}></div>,
    document.getElementById('user-data-content') as any
  );
};

const ModalComments = (props: Props) => {
  const [showComments, setShowComments] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { title: '', description: '' },
  });

  const domIsReady = useRef(false);

  const toggleShowComments = () => {
    setShowComments((prev) => !prev);
  };

  const onSubmitHandler = (data: object) => {
    props.onAddComment(data);
    reset();
  };

  const toggleCommentsBtnText = `${showComments ? 'Dölj' : 'Visa'} kommentarer`;

  const adminComments =
    props.comments &&
    props.comments.map((comment: any, idx: number) => {
      return (
        <li key={idx}>
          <h1 className={css.commentTitle}>{comment.title}</h1>
          <p>{comment.description}</p>
          <span className={css.commentCreatedAt}>
            {toLocaleString(comment.date)}
          </span>
        </li>
      );
    });

  useEffect(() => {
    domIsReady.current = true;
  }, []);

  return (
    <Fragment>
      {showComments && domIsReady.current && <ModalOverlay />}

      <div className={css.container}>
        <button className="button--default" onClick={toggleShowComments}>
          {toggleCommentsBtnText}
        </button>
        {showComments && (
          <div className={css.modalComments}>
            <ul>
              {adminComments}
              <li>
                <form
                  className={css.modalCommentsForm}
                  onSubmit={handleSubmit(onSubmitHandler)}
                >
                  <BaseInput
                    {...register('title', { required: true })}
                    placeholder="Ange en titel.."
                    type="text"
                    label="titel"
                  />
                  <div className={css.formControl}>
                    <label htmlFor="description">Beskrivning</label>
                    <textarea
                      {...register('description', { required: true })}
                      className={css.modalCommentsTextarea}
                      placeholder="Lägg till en beskrivning.."
                      name="description"
                    ></textarea>
                  </div>

                  <button
                    className="button--cta button--small"
                    disabled={!isValid}
                  >
                    Lägg till kommentar
                  </button>
                </form>
              </li>
            </ul>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ModalComments;
