import { forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

// styles
import css from './Star.module.scss';

type Props = {};

const Star = forwardRef(
  ({ type, value, className, color, iconAttributes }: any, ref: any) => {
    const uuid = uuidv4();

    return (
      <div className={css['rating-container']}>
        <input
          disabled={type === 'static'}
          className="rating"
          type="radio"
          id={uuid}
          value={value}
          {...(ref && { ref: ref })}
        />
        <label
          htmlFor={uuid}
          data-placement={value}
          className={className}
          style={color}
          {...iconAttributes}
        />
      </div>
    );
  }
);

export default Star;
