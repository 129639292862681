import { useForm } from 'react-hook-form';
import { auth } from '../../firebase';

import Header from '../../components/Header/Header';
import Copyright from '../../components/Copyright/Copyright';

import css from './RecoverPassword.module.scss';
import { useNavigate } from 'react-router';

const RecoverPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    resetPassword(data.email);
  };

  const resetPassword = async (email: string) => {
    try {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          localStorage.setItem('newPassword', 'true');
          navigate('/login');
        })
        .catch((error) => {
          alert(
            'Denna användare finns inte i vårt system, vänligen kontakta oss för vidare hjälp'
          );
        });
    } catch {
      alert('Kontakta vår support');
    }
  };

  return (
    <div className={css.root}>
      <Header hasDarkText isStatic />
      <div className={css.formBox}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className={css.loginHeader}>Glömt lösenord?</h1>
          <p>
            Fyll i din mejladress nedan så skickar vi ett mejl till dig med
            vidare instruktioner om hur du skapar ett nytt lösenord.
          </p>
          <div>
            <label>E-postadress</label>
            <input type="text" {...register('email', { required: true })} />
            {errors.email && (
              <span className={css.error}>Skriv din Mailadress</span>
            )}
          </div>
          <div className={css.buttons}>
            <input type="submit" value="SKICKA" />
            <a className={css.forgotPassword} href="/login">
              Tillbaka
            </a>
          </div>
        </form>
      </div>
      <Copyright />
    </div>
  );
};

export default RecoverPassword;
