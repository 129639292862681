import { useMemo, useContext } from 'react';
import { CandidateProfileContext } from '../../context/candidate-profile-context';

import BaseModal from '../../components/common/BaseModal/BaseModal';

const CandidateModal = () => {
  const { modal, clearModal } = useContext(CandidateProfileContext);

  const showModal = useMemo(() => !!modal?.jsx, [modal]);

  return (
    <BaseModal
      isCandidateModal
      isOpen={showModal}
      onClose={clearModal}
      contentLabel="Example Modal"
      heading={modal?.title}
    >
      {modal?.jsx}
    </BaseModal>
  );
};

export default CandidateModal;
