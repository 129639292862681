import { FC } from 'react';
import css from './DashboardModal.module.scss';

type Props = {
  children?: React.ReactNode;
  value?: string;
  title?: string;
};

const ModalItem: FC<Props> = ({ title, value, children }) => {
  return (
    <li className={css.listItem}>
      <p className={css.listItemTitle}>{title}</p>

      <p>{value}</p>
      {children}
    </li>
  );
};

export default ModalItem;
