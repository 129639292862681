import { FUNCTIONS_URL } from '../utils/urls';

export const verifyEmailExistence = async (
  email: string | null,
  signal?: any
) => {
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${FUNCTIONS_URL}/candidate/verify-existence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        signal,
      });
      const resData = await res.json();
      resolve(resData);
    } catch (err) {
      reject(err);
    }
  });
};
