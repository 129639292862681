import { FC } from 'react';
import classNames from 'classnames';

// styles
import css from './Card.module.scss';

type CardSectionProps = {
  gap?: boolean;
};

type CardProps = {
  direction?: string;
  gap?: boolean;
};

export const CardSection: FC<CardSectionProps> = ({ children, gap }) => {
  const classes = classNames({
    [css['content__section']]: true,
    [css['flex--column']]: true,
    [css['gap']]: gap,
  });
  return <section className={classes}>{children}</section>;
};

const Card: FC<CardProps> = ({ children, direction }) => {
  return (
    <article className={`${css['card']}`}>
      <div className={`${css['content']} ${css[`flex--${direction}`]}`}>
        {children}
      </div>
    </article>
  );
};

export default Card;
