/**
 *
 * @param arr array of item to be sorted
 * @param cb callback function
 * @returns sorted array
 *
 * @example multipleSort([1,2,3], function(o){ return o.date });
 */

// import { arrayRemove } from "firebase/firestore";

export function multipleSort(arr: any, cb: any) {
  for (var i = arr.length; i; ) {
    var o = arr[--i];
    arr[i] = [].concat(cb.call(o, o, i), o);
  }
  arr.sort((a: any, b: any) => {
    for (var i = 0, len = a.length; i < len; ++i) {
      if (a[i] != b[i]) return a[i] < b[i] ? -1 : 1;
    }
    return 0;
  });
  for (var i = arr.length; i; ) {
    arr[--i] = arr[i][arr[i].length - 1];
  }
  return arr;
}

export const sortArrayByDate = ({ arr, requireEndDate }: any) => {
  if (!arr?.length) return;
  let sortedDateArray = multipleSort(arr, (obj: any) => {
    return [new Date(obj.data.startDate), obj.data.endDate !== null];
  }).reverse();

  if (requireEndDate) {
    sortedDateArray = multipleSort(sortedDateArray, (obj: any) => {
      return obj.data.endDate !== null;
    });
  }

  return sortedDateArray;
};

export const alphabeticalSort = (arr: any, key: string) => {
  if (!arr?.length) return;
  return arr.sort((a: any, b: any) => {
    return key ? a[key].localeCompare(b[key]) : a.localeCompare(b)
  })
}
