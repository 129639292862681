import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { InterviewContext } from '../../../../context/dashboard/interview-context';

// components
import QuestionsComponent from '../../../../components/interview/InterviewSection/QuestionsList/QuestionsList';
import Question from './Question';
import {
  ColumnList,
  ColumnListItem,
} from '../../../../components/ColumnList/ColumnList';
import { disassembleFirestoreMap } from '../../../../utils/convert/firestore';
import OptionalOther from '../OptionalOther';

type Props = {
  mapRef: string; // path to nested object where values should be stored.
  questions: {
    [key: string]: string | number | undefined;
    id: string;
    question: string;
    rating?: number;
  }[];
};

const QuestionsList = ({ mapRef, questions }: Props) => {
  const { isExistingInterview }: any = useContext(InterviewContext);

  const { getValues } = useFormContext();

  const otherFieldValue = getValues(`criterias.${mapRef}.other`);

  const getSectionQuestions = (mapRef: string) => {
    const values = getValues(`criterias.${mapRef}`).questions;
    const valuesArr = disassembleFirestoreMap(values);
    return valuesArr;
  };

  // jsx
  const renderQuestionFields = () => {
    return questions.map(({ question, id }) => {
      return <Question key={id} mapRef={mapRef} question={question} id={id} />;
    });
  };

  const renderAnswersList = () => {
    const sectionQuestions = getSectionQuestions(mapRef);

    return sectionQuestions.map(({ data: { question, answer }, id }: any) => {
      const defaultText = '-- ingen anteckning --';
      return (
        <ColumnListItem
          key={id}
          title={question}
          text={answer || defaultText}
        />
      );
    });
  };

  return !isExistingInterview ? (
    <>
      <QuestionsComponent>{renderQuestionFields()}</QuestionsComponent>
      <OptionalOther mapRef={mapRef} />
    </>
  ) : (
    <>
      <ColumnList>{renderAnswersList()}</ColumnList>
      {!!otherFieldValue && (
        <ColumnListItem title="Övrigt" text={otherFieldValue} />
      )}
    </>
  );
};

export default QuestionsList;
