import { Link } from 'react-router-dom';
import { getAge, toLocaleString } from '../../../utils/dates';

// components
import ContactDetail from '../../Candidate/ContactDetail/ContactDetail';
import DistanceBadge from '../../../containers/DistanceBadge';

// styles
import css from './ApplicantOverview.module.scss';
import { forwardRef, useImperativeHandle, useRef } from 'react';

type Props = {
  candidateId: string;
  fullName: string;
  dateOfBirth: any;
  email: string;
  phone: number | string;
  formattedLocation: string | undefined;
  jobDistance: string | number;
  showDistance: boolean;
};

const ApplicantOverview = forwardRef(
  (
    {
      candidateId,
      fullName,
      dateOfBirth,
      email,
      phone,
      formattedLocation,
      jobDistance,
      showDistance,
    }: Props,
    ref: any
  ) => {
    const renderDateOfBirth = () => {
      let output = 'Inget födelsedatum tillagt';
      if (dateOfBirth) {
        const date = toLocaleString(dateOfBirth).split(' ')[0];
        const age = getAge(dateOfBirth);
        output = `${date} (${age} år)`;
      }
      return (
        <h2 className={`h5 no-vertical-margin ${css['date-of-birth']}`}>
          {output}
        </h2>
      );
    };
    return (
      <section className="container">
        <div className="border-bottom">
          <div className={`${css['applicant-overview']}`}>
            <div className={css['name-container']}>
              <h1 ref={ref} className={`h3 no-vertical-margin ${css['name']}`}>
                {fullName}
              </h1>
              {renderDateOfBirth()}
            </div>
            <div className={css['link-wrapper']}>
              <button className="button--text--solid flex-row normal-gap no-vertical-margin">
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                <Link
                  to={`/dashboard/candidates/${candidateId}`}
                  target="_blank"
                >
                  Öppna kandidat i extern vy
                </Link>
              </button>
            </div>
            <div className={css['contact-details']}>
              {email && (
                <ContactDetail
                  title={email}
                  icon="at"
                  href={`mailto:${email}`}
                />
              )}
              {phone && (
                <ContactDetail
                  title={phone}
                  icon="phone"
                  href={`tel:${phone}`}
                />
              )}

              <div className={css['location-details']}>
                <ContactDetail
                  title={formattedLocation || 'Ingen address finns'}
                  icon="location"
                />
                {jobDistance && showDistance && (
                  <DistanceBadge distance={Number(jobDistance)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default ApplicantOverview;
