
import css from "./IconWithText.module.scss";
import Heading from '../../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../../website/components/atoms/Paragraph/Paragraph';

type Props = {
  icon: {
    weight: string;
    icon: string;
  };
  heading: string;
  text: string;
};

const IconWithText = ({ icon, heading, text }: Props) => {
  return (
    <div className={css.root}>
      <i className={`fa-${icon.weight} fa-${icon.icon} ${css.icon}`}></i>
      <div>
        <Heading level="h3">{heading}</Heading>
        <Paragraph>{text}</Paragraph>
      </div>
    </div>
  );
};

export default IconWithText;
