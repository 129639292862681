import BaseInput from '../common/inputs/BaseInput/BaseInput';
import css from './index.module.scss';

type Props = {
  comment: string;
  setComment: (e: any) => void;
};

const Comment = ({ comment, setComment }: Props) => {
  return (
    <div className={css['input-wrapper']}>
      <BaseInput
        placeholder="Kommentar.."
        value={comment}
        onChange={setComment}
        className="no-vertical-margin"
      />
    </div>
  );
};

export default Comment;
