
import css from './Paragraph.module.scss';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  type?: 'preamble' | 'small' | 'regular' ;
  customClasses?: string;
  noHorizontalMargin?: boolean;
};

const Paragraph = ({
  children,
  type = 'regular',
  customClasses = '',
  noHorizontalMargin
}: Props) => {

  const paragraphClasses = classNames(
    css['root'],
    customClasses,
    css[type],
    {[css['noHorizontalMargin']]: noHorizontalMargin},
  );

  return (
    <p className={paragraphClasses} >{children}</p>
  );
};

export default Paragraph;
