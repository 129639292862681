import { FC, ReactNode } from 'react';

// accordion package
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// styles
import 'react-accessible-accordion/dist/fancy-example.css';
import css from './BaseAccordion.module.scss';

type AccordionItemProps = {
  uuid?: string;
  preview: ReactNode;
  expanded: ReactNode;
};

export const BaseAccordionItem = ({
  uuid,
  preview,
  expanded,
}: AccordionItemProps) => {
  return (
    <AccordionItem className={css['accordion__item']} uuid={uuid}>
      <AccordionItemHeading>
        <AccordionItemButton className={css['application-preview']}>
          {preview}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel
        className={`accordion__panel ${css['application-details']}`}
      >
        {expanded}
      </AccordionItemPanel>
    </AccordionItem>
  );
};

const BaseAccordion = ({ children, preExpanded }: any) => {
  return (
    <Accordion className={css['accordion']} preExpanded={preExpanded}>
      {children}
    </Accordion>
  );
};

export default BaseAccordion;
