import { useFormContext } from 'react-hook-form';
import { firstToUpper } from '../../../../utils/convert/typography';
import classNames from 'classnames';
import ReactTextareaAutosize from 'react-textarea-autosize';
import useEffectOnce from '../../../../hooks/useEffectOnce';

type Props = {};

const Question = ({ mapRef, question, id }: any) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods

  const getRegisterRef = (mapRef: any, id: any) => {
    return mapRef ? `criterias.${mapRef}.questions.${id}.answer` : id;
  };

  const isFieldErrors = (answerId: string) => {
    const formGroup = errors[mapRef] as any;
    const groupAnswers = formGroup?.answers;
    return groupAnswers && groupAnswers[answerId];
  };

  const getTextareaClasses = (id: any) => {
    return classNames({
      error__input: isFieldErrors(id),
    });
  };

  // register question in answer obj on load
  useEffectOnce(() => {
    setValue(`criterias.${mapRef}.questions.${id}.question`, question);
  });

  // jsx
  const errorMsg = (
    <span className="error__summary">
      Ange minst ett tecken för att fortsätta.
    </span>
  );
  return (
    <div>
      <label htmlFor={id}>{firstToUpper(question)}</label>
      <ReactTextareaAutosize
        id={id}
        className={getTextareaClasses(id)}
        {...register(getRegisterRef(mapRef, id), {
          required: false,
        })}
      />
      {isFieldErrors(id) && errorMsg}
    </div>
  );
};

export default Question;
