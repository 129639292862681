import Submenu from '../../common/Submenu/Submenu';

import css from './RecipientList.module.scss';
import classNames from 'classnames';

type Props = {
  recipients: any;
  confirmRecipient: any;
  unconfirmRecipient: any;
  disqualifyRecipient: any;
};

const RecipientList = ({
  recipients,
  confirmRecipient,
  unconfirmRecipient,
  disqualifyRecipient,
}: Props) => {
  const getColorMarkClasses = (response: 'PENDING' | 'JA' | 'NEJ') => {
    return classNames(css['color-mark'], {
      [css['color-mark--yellow']]: response === 'PENDING',
      [css['color-mark--red']]: response === 'NEJ',
      [css['color-mark--green']]: response === 'JA',
    });
  };
  const getLiClasses = (isDisqualified: boolean) => {
    return classNames(css['candidate'], {
      [css['candidate--disqualified']]: isDisqualified,
    });
  };
  return (
    <>
      <ul className={`block-list ${css['block-list']}`}>
        {recipients.map(({ id, data }: any) => {
          return (
            <li key={id} className={getLiClasses(data?.isDisqualified)}>
              <div className={css['input-wrapper']}>
                <label htmlFor={id}>
                  <div className={css['candidate__details-container']}>
                    <span className={css['candidate__name']}>
                      {data.firstName} {data.lastName}
                    </span>
                    <span className={getColorMarkClasses(data.response)}>
                      {data.isConfirmed && <i className="fa fa-check"></i>}
                    </span>
                  </div>
                  <span className={css['candidate__contact-details']}>
                    <span>{data.phone}</span>

                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </span>
                </label>
              </div>

              <Submenu>
                <li>
                  <a href={`/dashboard/candidates/${id}`} target="_blank">
                    Visa profil (ny flik)
                  </a>
                </li>

                {data.response === 'JA' && (
                  <li>
                    {data.isConfirmed ? (
                      <button
                        onClick={() => {
                          unconfirmRecipient(id);
                        }}
                      >
                        Markera som ohanterad
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          confirmRecipient(id);
                        }}
                      >
                        Markera som hanterad
                      </button>
                    )}
                  </li>
                )}

                {!data?.isDisqualified && (
                  <li>
                    <button
                      onClick={() => {
                        disqualifyRecipient(id);
                      }}
                    >
                      Diskvalificera
                    </button>
                  </li>
                )}
              </Submenu>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RecipientList;
