import { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

// styles
import css from './ConfirmationButton.module.scss';

type Props = {
  title: string;
  type?: 'danger' | 'warning' | 'success' | 'default';
  countdown?: {
    title?: string;
    seconds: number;
  };
  onConfirm: () => void;
};

const ConfirmationButton = ({ title, type, countdown, onConfirm }: Props) => {
  const [counter, setCounter] = useState<any>(3);
  const [isCountingDown, setIsCountingDown] = useState<any>(false);
  const intervalRef = useRef<any>(null);

  const onHoldConfirm = () => {
    if (intervalRef.current) return;
    setIsCountingDown(true);
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter: any) => prevCounter - 1);
    }, 1000);
  };

  const resetCounter = () => {
    if (!intervalRef.current || !isCountingDown) return;
    setIsCountingDown(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setCounter(3);
  };

  useEffect(() => {
    if (counter && counter < 1) {
      onConfirm();
      resetCounter();
    }

    return () => {
      if (intervalRef.current || isCountingDown) return;
      resetCounter();
    };
  }, [counter]);

  const countdownTitle =
    countdown?.title && `${countdown?.title} ${counter}...`;

  const buttonClasses = classNames({
    [css['confirm-button']]: true,
    [css[type || 'default']]: true,
  });

  return (
    <button
      className={buttonClasses}
      onClick={!countdown ? onConfirm : undefined}
      onMouseDown={!!countdown ? onHoldConfirm : undefined}
      onMouseUp={!!countdown ? resetCounter : undefined}
    >
      {!isCountingDown ? title : countdownTitle}
    </button>
  );
};

export default ConfirmationButton;
