import css from './LoadingDots.module.scss';
const LoadingDots = () => {
  return (
    <div className={css['spinner']}>
      <div className={css['spinner-item']}></div>
      <div className={css['spinner-item']}></div>
    </div>
  );
};

export default LoadingDots;
