import {
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
  ReactNode,
} from 'react';

import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useAuthState } from '../../../firebase';
import { onLogoutHandler } from '../../../utils/logout';
import css from '../Header.module.scss';

import Button from '../../../website/components/atoms/Button/Button';

type NavLinksProps = {
  isDashboardHeader?: boolean;
  customNavLinks?: ReactNode;
};

const NavLinks = ({ ...props }: NavLinksProps) => {
  const { isAuthenticated, isAdmin } = useAuthState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isDesktop = windowWidth >= 1024;
  const isMobile = !isDesktop;
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(isMobile); // Always show the account menu items in mobile menu
  const [subnavIsOpen, setSubnavIsOpen] = useState(false);
  const navigate = useNavigate();
  const accountMenuRef = useRef<any>();

  const logout = () => {
    onLogoutHandler();
    navigate('/loginwithemail');
  };

  const toggleAccountMenu = (e: any) => {
    e.stopPropagation();
    setAccountMenuIsOpen((prevState) => !prevState);
  };

  const closeAccountMenu = useCallback(() => {
    setAccountMenuIsOpen(false);
  }, []);

  const clickOccurredOutside = (el: any) => {
    const potentialParent = accountMenuRef?.current as any;
    return !potentialParent.contains(el);
  };

  const toggleMobileSubnav = (e: any) => {
    // TODO: Make it work for multiple menu items
    e.preventDefault();
    e.stopPropagation();
    setSubnavIsOpen(!subnavIsOpen);
  };

  const detectClickOutsideMenu = useCallback(
    (e: any) => {
      if (accountMenuIsOpen && clickOccurredOutside(e.target)) {
        closeAccountMenu();
      }
    },
    [accountMenuIsOpen, closeAccountMenu]
  );

  useEffect(() => {
    if (!accountMenuRef.current || isMobile) return;

    document.addEventListener('click', detectClickOutsideMenu);

    return () => {
      document.removeEventListener('click', detectClickOutsideMenu);
    };
  }, [detectClickOutsideMenu, isMobile]);

  return (
    <ul className={`${css['nav-links']}`}>
      {props.customNavLinks && <>{props.customNavLinks}</>}
      {!props.isDashboardHeader && (
        <>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? css['is-active-page'] : undefined
              }
              to="/om-oss"
            >
              <span>Om oss</span>
            </NavLink>
          </li>

          <li className={`${css['has-subnav']} js-has-subnav`}>
            <NavLink
              className={({ isActive }) =>
                isActive ? css['is-active-page'] : undefined
              }
              to="/foretag"
            >
              <span>Företag</span>
            </NavLink>
            {isMobile && (
              <button
                className={css['subnav-caret-mobile']}
                onClick={toggleMobileSubnav}
              >
                {subnavIsOpen && <i className="fa-solid fa-caret-up"></i>}
                {!subnavIsOpen && <i className="fa-solid fa-caret-down"></i>}
                <span>Öppna undermeny</span>
              </button>
            )}
            {((isMobile && subnavIsOpen) || isDesktop) && (
              <div className={`${css['subnav']} subnav`}>
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? css['is-active-page'] : undefined
                      }
                      to="/foretag/utbildning"
                    >
                      <span>Utbildning</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? css['is-active-page'] : undefined
                      }
                      to="/foretag/praktik"
                    >
                      <span>Praktik</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? css['is-active-page'] : undefined
              }
              to="/jobbsokande"
            >
              <span>Jobbsökande</span>
            </NavLink>
          </li>
        </>
      )}
      {isAuthenticated && (
        <li className={`${css['account-wrapper']}`}>
          {isDesktop && (
            <button
              className={`${css['account__button']}`}
              onClick={toggleAccountMenu}
              aria-haspopup="true"
              aria-expanded={accountMenuIsOpen}
            >
              <i
                className={`fa fa-user ${css['account__button__user-icon']}`}
              ></i>
              <span>Konto {isAdmin ? '(admin)' : ''}</span>
              <i
                className={`fa fa-chevron-${
                  accountMenuIsOpen ? 'up' : 'down'
                } ${css['account__button__arrow-icon']}`}
              ></i>
            </button>
          )}

          {accountMenuIsOpen && (
            <ul
              className={`${css['account__menu']}`}
              ref={accountMenuRef}
              role="menu"
              aria-label="Konto"
            >
              {!props.isDashboardHeader && (
                <li>
                  <NavLink to={isAdmin ? '/dashboard' : '/candidate'}>
                    {isAdmin ? 'Dashboard' : 'Min profil'}
                  </NavLink>
                </li>
              )}
              <li>
                <button
                  className={`button--link ${css['logout-button']}`}
                  onClick={logout}
                >
                  <span>Logga ut</span>
                </button>
              </li>
            </ul>
          )}
        </li>
      )}

      {!isAuthenticated && (
        <>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? css['is-active-page'] : undefined
              }
              to="/loginwithemail"
            >
              <span>Logga in</span>
            </NavLink>
          </li>
          <li>
            <Button
              label="Registrera dig"
              link="/apply-info"
              type="cta"
              customClasses={`${css['register-button']}`}
            />
          </li>
        </>
      )}
    </ul>
  );
};

export default memo(NavLinks);
