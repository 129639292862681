import { v4 as uuidv4 } from 'uuid';
export const reassembleNestedMap = (
  obj: any,
  constructor: (key: any, value: any) => any
) => {
  const dictionary = Object.create(null);
  for (let key in obj) {
    const value = obj[key];
    if (!value) continue;

    dictionary[uuidv4()] = constructor(key, value);
  }

  return dictionary;
};
