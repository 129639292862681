import { auth } from '../firebase';
import { Timestamp } from 'firebase/firestore';

export default class Event {
  createdAt = Timestamp.now();
  title;
  body;
  constructor(title: string, body: string) {
    this.title = title;
    this.body = body;
  }
}

export class Comment extends Event {
  author;
  type = 'comment';

  constructor(title: string, body: string) {
    super(title, body);
    this.author = this.getAuthor();
  }

  getAuthor() {
    const currentUser: any = auth.currentUser;
    return (
      auth.currentUser && {
        displayName: currentUser.displayName || 'Admin',
        uid: currentUser.uid,
      }
    );
  }
}

export class StatusUpdate extends Event {
  author;
  type = 'status_update';
  newStatus: string;
  previousStatus: string;

  constructor({ title, body, newStatus, previousStatus }: any) {
    super(title, body);
    this.author = this.getAuthor();
    this.newStatus = newStatus;
    this.previousStatus = previousStatus || null;
  }

  getAuthor() {
    const currentUser: any = auth.currentUser;
    return (
      auth.currentUser && {
        displayName: currentUser.displayName || 'Admin',
        uid: currentUser.uid,
      }
    );
  }
}
