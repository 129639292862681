import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

// components
import RatingsList from '../../../components/interview/InterviewSection/RatingsList';
import RatingsListItem from '../../../components/interview/InterviewSection/RatingsListItem';

// containers
import SectionRating from './SectionRating';
import Rating from '../../../components/Rating/Rating';

const TotalRating = ({ templates }: any) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const values = watch();

  const N_STARS = useMemo(() => 40, []);
  const totalRating = useMemo(
    () =>
      values.criterias
        ? Object.values(values.criterias).reduce((acc: any, item: any) => {
            const rating = parseInt(item?.rating) || 0;
            return acc + rating;
          }, 0)
        : 0,
    [values]
  ) as number;

  const formattedRating = useMemo(
    () => Math.round((totalRating / N_STARS) * 5),
    [totalRating, N_STARS]
  );

  const isError = !!Object.keys(errors).length;

  useEffect(() => {
    setValue('totalRating', formattedRating);
  }, [formattedRating, setValue]);

  return (
    <RatingsList>
      <RatingsListItem
        title="Eget driv"
        C={
          <SectionRating
            mapRef="grit"
            ratingHelp={templates.grit.ratingHelp}
            tooltipTitle="eget driv"
            tooltipPosition="right"
            before
          />
        }
      />
      <RatingsListItem
        title="Engagemang"
        C={
          <SectionRating
            mapRef="commitment"
            ratingHelp={templates.commitment.ratingHelp}
            tooltipTitle="engagemang"
            tooltipPosition="right"
            before
          />
        }
      />
      <RatingsListItem
        title="Erfarenhet"
        C={
          <SectionRating
            mapRef="experience"
            ratingHelp={templates.experience.ratingHelp}
            tooltipTitle="erfarenhet"
            tooltipPosition="right"
            before
          />
        }
      />
      <RatingsListItem
        title="Samarbetsförmåga"
        C={
          <SectionRating
            mapRef="collaboration_skills"
            ratingHelp={templates.collaboration_skills.ratingHelp}
            tooltipTitle="samarbetsförmåga"
            tooltipPosition="right"
            before
          />
        }
      />
      <RatingsListItem
        title="Konflikthantering"
        C={
          <SectionRating
            mapRef="conflict_management"
            ratingHelp={templates.conflict_management.ratingHelp}
            tooltipTitle="konflikthantering"
            tooltipPosition="right"
            before
          />
        }
      />
      <RatingsListItem
        title="Svenska"
        C={
          <SectionRating
            mapRef="swedish"
            ratingHelp={templates.swedish.ratingHelp}
            tooltipTitle="svenska"
            tooltipPosition="right"
            before
          />
        }
      />

      <RatingsListItem
        title="Utbildning"
        C={
          <SectionRating
            mapRef="education"
            ratingHelp={templates.education.ratingHelp}
            tooltipTitle="utbildning"
            tooltipPosition="right"
            before
          />
        }
      />

      <RatingsListItem
        title="Helhetsintryck"
        C={
          <SectionRating
            mapRef="overall_impression"
            ratingHelp={templates.overall_impression.ratingHelp}
            tooltipTitle="helhetsintryck"
            tooltipPosition="right"
            before
          />
        }
      />
      <RatingsListItem
        title="Betyg"
        infoText="Fyll i rating för alla sektioner"
        isError={isError}
        dark
        C={
          <>
            <Rating type="static" value={formattedRating} />
          </>
        }
      />
    </RatingsList>
  );
};

export default TotalRating;
