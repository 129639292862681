import React from 'react';
import css from './NumberBadgeHeading.module.scss';

import NumberBadge from '../NumberBadge/NumberBadge';

type Props = {
  heading: string;
  headingLevel: 1 | 2 | 3 | 4 | 5;
  number: number;
};

const NumberBadgeHeading = ({heading, headingLevel, number}: Props) => {
  const CustomTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;

  return (
    <div className={css['number-badge-heading']}>
      <CustomTag>{heading}</CustomTag>
      <NumberBadge number={number}/>
    </div>
  );
};

export default NumberBadgeHeading;
