import { useNavigate } from 'react-router';

import useWindowWidth from '../../hooks/UI/useWindowWidth';

import Header from '../../components/Header/Header';
import Copyright from '../../components/Copyright/Copyright';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import css from './Apply.module.scss';

const ApplyDone = () => {
  const [width] = useWindowWidth();
  const navigate = useNavigate();

  return (
    <>
      {width < 481 && <ProgressBar filler="100" />}
      <div className={css.root}>
        <Header showLogoOnly hasDarkText isStatic />
        <div className={css.formBox}>
          {width > 481 && <ProgressBar filler="100" />}
          <section className={css.section}>
            <h3>Kul, nu är du en av oss!</h3>
            <p>
              Börja din resa med att fylla på din profil för att öka
              sannolikheten till att just du ska få ett jobb.
            </p>
            <button
              className="button--full-width button--cta"
              onClick={() => navigate('/candidate')}
            >
              Gå till profil
            </button>
            <p>
              Om du vill komma i kontakt med oss så kan du klicka på chatikonen
              till höger eller mejla oss på info@kila.se
            </p>
          </section>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default ApplyDone;
