import { useState, createContext, FC } from 'react';
import { useParams } from 'react-router-dom';
import useEffectOnce from '../../hooks/useEffectOnce';
import { reassembleNestedMap } from '../../utils/convert/object';
import { v4 as uuidv4 } from 'uuid';
// temporary firebase imports
import { deleteField } from 'firebase/firestore';

// candidate service
import { CandidateService } from '../../services/database-service';
import { httpsCallable } from 'firebase/functions';
import { httpsCallableWrapper } from '../../firebase';

// initial context props
type initialCtxProps = {
  candidate: {} | null;
  candidateEvents: {}[] | null;
  candidateApplications: {}[] | null;
  isLoading: boolean;
  setCandidateEvents: (events: any) => void;
  setCandidateApplications: (applications: any) => void;
  updatePersonalInfo: (data: any) => void;
  deleteCandidate: () => void;
  onUpdateNestedMapHandler: (obj: any) => void;
  updateLanguages: (langs: any) => void;
  updateDriversLicenses: (licenses: any) => void;
  updateForkliftLicenses: (licenses: any) => void;
  deleteNestedMap: (key: string, id: string) => void;
  setNestedMap: (key: string, data: any) => void;
};

// initial context object
const initialCtx: initialCtxProps = {
  candidate: {},
  candidateEvents: [],
  candidateApplications: [],
  isLoading: false,
  setCandidateEvents: (events: any) => {},
  setCandidateApplications: (applications: any) => {},
  deleteCandidate: () => {},
  updatePersonalInfo: (data: any) => {},
  onUpdateNestedMapHandler: (obj: any) => {},
  updateLanguages: (langs: any) => {},
  updateDriversLicenses: (licenses: any) => {},
  updateForkliftLicenses: (licenses: any) => {},
  deleteNestedMap: (key: string, id: string) => {},
  setNestedMap: (key: string, data: any) => {},
};

// ititialize candidate context
export const DashboardCandidateContext = createContext(initialCtx);

// context provider component
export const DashboardCandidateContextProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<any>({});
  const [candidate, setCandidate] = useState<any>({});
  const [candidateEvents, setCandidateEvents] = useState<{}[] | null>(null);
  const [candidateApplications, setCandidateApplications] = useState<
    {}[] | null
  >([]);

  // get candidateId from url/params
  const { candidateId }: any = useParams();

  const updatePersonalInfoState = (newValues: any) => {
    setCandidate((prevCandidate: any) => {
      return { ...prevCandidate, ...newValues };
    });
  };

  // update top level information. E.g firstName, lastName..
  const updatePersonalInfo = async (data: any): Promise<void> => {
    const { newValues } = data;
    setIsLoading(true);
    try {
      await CandidateService.update(candidateId as string, newValues);
      updatePersonalInfoState(newValues);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateNestedMapState = (key: string, id: string, data: any) => {
    setCandidate((prevCandidate: any) => {
      const prevCandidateClone = { ...prevCandidate };
      prevCandidateClone[key][id] = data;

      return prevCandidateClone;
    });
  };

  // update nested maps. E.g an education inside the educations map.
  const updateNestedMapHandler = async ({ key, id, data }: any) => {
    setIsLoading(true);
    try {
      const nestedTarget = `${key}.${id}`;

      await CandidateService.update(candidateId as string, {
        [nestedTarget]: data,
      });
      updateNestedMapState(key, id, data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNestedMapState = (key: string, id: string) => {
    setCandidate((prev: any) => {
      const prevClone = { ...prev };
      delete prevClone[key][id];
      return prevClone;
    });
  };

  // delete a nested key. E.g an education inside the educations map.
  const deleteNestedMapHandler = async (key: any, id: any) => {
    setIsLoading(true);
    try {
      await CandidateService.update(candidateId, {
        [`${key}.${id}`]: deleteField(),
      });
      deleteNestedMapState(key, id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCandidate = (key: string, data: any) => {
    setCandidate((prevCandidate: any) => {
      const prevCandidateClone = { ...prevCandidate };
      prevCandidateClone[key] = data;

      return prevCandidateClone;
    });
  };

  const deleteCandidate = async () => {
    const callableFn = httpsCallableWrapper('candidates-deleteCandidate');
    try {
      await callableFn({
        candidateId: candidateId,
      });
      setCandidate(null);
    } catch (err) {
      console.error(err);
    }
  };

  // update top level maps. E.g the whole educations map.
  const updateCandidateHandler = async (key: string, data: any) => {
    setIsLoading(true);
    CandidateService.update(candidateId, {
      [key]: data,
    })
      .then(() => updateCandidate(key, data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  const updateLanguages = async (langs: any) => {
    const reassembledLanguages = reassembleNestedMap(langs, (key, value) => {
      return { title: key, level: value };
    });

    await updateCandidateHandler('languages', reassembledLanguages);
  };

  const updateDriversLicenses = async (licenses: any) => {
    const reassembledLicenses = reassembleNestedMap(licenses, (key) => {
      return { title: key };
    });
    await updateCandidateHandler('driversLicenses', reassembledLicenses);
  };

  const updateForkliftLicenses = async (licenses: any) => {
    const reassembledLicenses = reassembleNestedMap(licenses, (key) => {
      return { title: key };
    });
    await updateCandidateHandler('forkliftLicenses', reassembledLicenses);
  };

  const setNestedMapState = (key: string, newData: any) => {
    setCandidate((prev: any) => {
      const prevClone = { ...prev };
      prevClone[key] = { ...prevClone[key], ...newData };

      return prevClone;
    });
  };

  const setNestedMapHandler = async (key: string, data: any) => {
    const newData = { [uuidv4()]: data };
    setIsLoading(true);
    try {
      CandidateService.set(
        candidateId,
        {
          [key]: newData,
        },
        { merge: true }
      );
      setNestedMapState(key, newData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCandidate = async (id: any) => {
    setIsLoading(true);
    try {
      const candidate: any = await CandidateService.getOne(id);
      const secrecy = await CandidateService.go(id, 'secrecy').getOne('data');
      candidate.rating = secrecy?.rating;
      return candidate;
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    getCandidate(candidateId).then(setCandidate);
  });

  return (
    <DashboardCandidateContext.Provider
      value={{
        candidate,
        candidateEvents,
        candidateApplications,
        setCandidateEvents,
        setCandidateApplications,
        updatePersonalInfo,
        deleteCandidate,
        onUpdateNestedMapHandler: updateNestedMapHandler,
        updateLanguages,
        updateDriversLicenses,
        updateForkliftLicenses,
        setNestedMap: setNestedMapHandler,
        deleteNestedMap: deleteNestedMapHandler,
        isLoading: isLoading,
      }}
    >
      {children}
    </DashboardCandidateContext.Provider>
  );
};

export default DashboardCandidateContextProvider;
