import { useCallback, useEffect, useState } from 'react';
import MonthYearPicker from '../common/MonthYearPicker/MonthYearPicker';
import css from './StartEndDate.module.scss';

type Props = {
  setDate: (date: any) => void;
  defaultDate?: {
    startDate: string;
    endDate: string | null;
  };
  checkboxLabel?: string;
  setIsValid: (bool: boolean) => void;
};

// TODO: refactor component and make it strictly presentational.

const StartEndDate = ({
  setDate,
  defaultDate,
  checkboxLabel,
  setIsValid,
}: Props) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [isCurrentRole, setIsCurrentRole] = useState<any>(false);

  const getMonthAndYear = (date: any) => {
    const dateObj = new Date(date);

    const monthIndex = dateObj.getMonth();
    const fullYear = dateObj.getFullYear();
    return { monthIndex, fullYear };
  };

  const getEndYearRange = useCallback(() => {
    const currentYear = new Date().getFullYear();
    return currentYear - startDate.year;
  }, [startDate]);

  const isSameYear = useCallback(() => {
    const startYear = startDate.year;
    const endYear = endDate.year;

    return startYear === endYear;
  }, [startDate, endDate]);

  const isInvalidStartDate = (month: any, year: any) => {
    const monthIsInvalid = !month && month !== 0;
    return monthIsInvalid || !year;
  };

  // sets default date if any is provided.
  useEffect(() => {
    if (startDate || endDate) return;

    const startDateObj = getMonthAndYear(defaultDate?.startDate);
    const endDateObj = getMonthAndYear(defaultDate?.endDate);

    if (isInvalidStartDate(startDateObj?.monthIndex, startDateObj?.fullYear)) {
      !!setIsValid && setIsValid(false);
    }

    setStartDate({
      month: startDateObj.monthIndex,
      year: startDateObj.fullYear,
    });
    setEndDate({
      month: defaultDate?.endDate ? endDateObj.monthIndex : null,
      year: endDateObj.fullYear,
    });
  }, []);

  // update start and end date in context when values change.
  useEffect(() => {
    const { month: startMonth, year: startYear } = startDate || {};
    const { month: endMonth, year: endYear } = endDate || {};

    if (isInvalidStartDate(startMonth, startYear)) {
      return !!setIsValid && setIsValid(false);
    }

    const isValidEndYear = !!endYear;
    const isValidEndMonth = !!endMonth || endMonth === 0;

    if (!isCurrentRole && (!isValidEndYear || !isValidEndMonth)) {
      !!setIsValid && setIsValid(false);
      return;
    }

    setDate({
      startDate: new Date(startYear, startMonth).toLocaleDateString('sv-SE'),
      endDate: !isCurrentRole
        ? new Date(endYear, endMonth).toLocaleDateString('sv-SE')
        : null,
    });
    !!setIsValid && setIsValid(true);
  }, [startDate, endDate, isCurrentRole, setDate]);

  // prevents user from selecting illogical dates. E.g an end month which comes before the start month.
  useEffect(() => {
    if (!startDate || !endDate || !isSameYear()) return;

    if (startDate.month > endDate.month) {
      setEndDate((prev: any) => {
        return { ...prev, month: startDate.month };
      });
    } else if (endDate.month < startDate.month) {
      setStartDate((prev: any) => {
        return { ...prev, month: endDate.month };
      });
    }
  }, [startDate, endDate, isSameYear]);

  const endYearRange = startDate ? getEndYearRange() : 100;

  return (
    <div className={css['container']}>
      {!!checkboxLabel && (
        <>
          <input
            className={css['current-role-input']}
            type="checkbox"
            id="current-role"
            checked={isCurrentRole}
            value={isCurrentRole}
            onChange={() => setIsCurrentRole((prev: any) => !prev)}
          />
          <label htmlFor="current-role">{checkboxLabel}</label>
        </>
      )}

      <div className={css['pickers']}>
        {startDate && (
          <MonthYearPicker
            label="Startdatum"
            yearRange={100}
            month={startDate.month}
            setMonth={(month: any) => {
              setStartDate((prev: any) => {
                return { ...prev, month: month };
              });
            }}
            year={startDate.year}
            setYear={(year: any) => {
              setStartDate((prev: any) => {
                return { ...prev, year: year };
              });
            }}
          />
        )}
        {endDate && (
          <MonthYearPicker
            label="Slutdatum"
            yearRange={endYearRange}
            disablePicker={isCurrentRole}
            beginMonthAt={isSameYear() ? startDate?.month : 0}
            month={endDate.month}
            setMonth={(month: any) => {
              setEndDate((prev: any) => {
                return { ...prev, month: month };
              });
            }}
            year={endDate.year}
            setYear={(year: any) => {
              setEndDate((prev: any) => {
                return { ...prev, year: year };
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default StartEndDate;
