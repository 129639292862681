import React from 'react';
import Modal from 'react-modal';

import { useForm } from 'react-hook-form';
import { firestore } from '../../firebase';

import css from './CreateCandidateModal.module.scss';

interface CreateCandidateModalProps {
  modalIsOpen: boolean;
  setIsOpen: any;
}

const CreateCandidateModal: React.FC<CreateCandidateModalProps> = (
  props: CreateCandidateModalProps
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  // const ref = useRef(null);
  // const [selectedFile, setSelectedFile] = useState<string | Blob>();

  const onSubmit = (data: any) => {
    firestore.collection('candidates').add({
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      ssn: data.ssn,
      email: data.email,
      phone: data.phone,
      comment: data.comment,
      status: 'Pågående',
    });
    closeModal();
  };

  // const handleResumeChange = async function (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) {
  //   const uploadedResume = e?.target.files;
  //   if (!uploadedResume) return;
  //   savePicture(uploadedResume[0]);
  //   console.log("KLAR?");
  // };

  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Modal
      className={css.modalCreateCandidate}
      style={{ overlay: { background: 'rgba(0, 0, 0, 0.5)' } }}
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Create Candidate Modal"
      ariaHideApp={false}
    >
      <div className={css.wrapper}>
        <div className={css.closeBtnContainer}>
          <button onClick={() => closeModal()}>STÄNG</button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={css.inputContainer}>
          <div className={css.inputContainerRow}>
            <div>
              <label>Förnamn</label>
              <input
                type="text"
                {...register('firstName', { required: true })}
              />
              {errors.firstName && (
                <span className={css.error}>Skriv förnamn</span>
              )}
            </div>
            <div>
              <label>Efternamn</label>
              <input
                type="text"
                {...register('lastName', { required: true })}
              />
              {errors.lastName && (
                <span className={css.error}>Skriv efternamn</span>
              )}
            </div>
          </div>
          <div className={css.inputContainerRow}>
            <div>
              <label>Adress</label>
              <input type="text" {...register('address', { required: true })} />
              {errors.adress && <span className={css.error}>Skriv adress</span>}
            </div>
            <div>
              <label>Personnummer</label>
              <input type="text" {...register('ssn', { required: true })} />
              {errors.ssn && (
                <span className={css.error}>Skriv personnummer</span>
              )}
            </div>
          </div>
          <div className={css.inputContainerRow}>
            <div>
              <label>E-postadress</label>
              <input type="email" {...register('email', { required: true })} />
              {errors.email && (
                <span className={css.error}>Skriv e-postadress</span>
              )}
            </div>
            <div>
              <label>Mobilnummer</label>
              <input type="text" {...register('phone', { required: true })} />
              {errors.phone && (
                <span className={css.error}>Skriv mobilnummer</span>
              )}
            </div>
          </div>
          <div>
            <label>Kommentar</label>
            <textarea
              placeholder="Skriv kommentarer om kandidat"
              cols={50}
              rows={6}
              wrap="hard"
              {...register('comment')}
            />
          </div>
          <div>
            {/* <input
              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
              id="resume"
              name="resume"
              type="file"
              multiple={false}
              onChange={handleResumeChange}
            /> */}
          </div>
          <button className={css.createCandidateBtn}>SKAPA KANDIDAT</button>
        </form>
      </div>
    </Modal>
  );
};

export default CreateCandidateModal;
