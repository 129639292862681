import React from 'react';
import { NavLink } from 'react-router-dom';

import css from './ListItem.module.scss';

type Props = {
  linkTo: string;
  text: string
};

const ListItem = ({linkTo, text}: Props) => {

  return (
    <li>
      <NavLink
        to={linkTo}
        className={({ isActive }) => (isActive ? css['item-active'] : '')}
      >
        {text}
      </NavLink>
    </li>
  );
};

export default ListItem;
