import BaseButton from '../../../../common/BaseButton/BaseButton';

// styles
import css from './SubmitFooter.module.scss';

const SubmitFooter = ({ isValid }: { isValid: boolean }) => {
  return (
    <div className="container">
      <footer className={css['submit-footer']}>
        <div className={css['button-wrapper']}>
          <BaseButton
            type="submit"
            title="spara och avsluta intervju"
            disabled={!isValid}
          />
        </div>
      </footer>
    </div>
  );
};

export default SubmitFooter;
