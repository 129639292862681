import LoadingText from '../../Progress/LoadingText/LoadingText';
// styles
import css from './ContactDetail.module.scss';

type Props = {
  title: string | number;
  icon: string; // must be an font-awesome icon.
  href?: string;
};

const ContactDetail = ({ title, icon, href }: Props) => {
  const titleJsx = href ? (
    <a href={href} className="no-vertical-margin">
      <LoadingText className="h3" minWidthPx={200}>
        {title}
      </LoadingText>
    </a>
  ) : (
    <p className="no-vertical-margin">
      <LoadingText className="h3" minWidthPx={200}>
        {title}
      </LoadingText>
    </p>
  );
  return (
    <div className={css['wrapper']}>
      <div className={css['icon-wrapper']}>
        <i className={`fa-solid fa-${icon}`}></i>
      </div>
      {titleJsx}
    </div>
  );
};

export default ContactDetail;
