import {
  createContext,
  FC,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { v4 as uuidv4 } from 'uuid';
import { useAuthState, db } from '../firebase';
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteField,
} from 'firebase/firestore';

// spinner
import LoadingSpinner from '../components/Progress/LoadingSpinner/LoadingSpinner';

// forms
import PersonalInfoForm from '../components/Candidate/Forms/PersonalInfoForm';
import ExperienceForm from '../components/Candidate/Forms/ExperienceForm';
import EducationForm from '../components/Candidate/Forms/EducationForm';
import LanguageForm from '../components/Candidate/Forms/LanguageForm';
import DriversLicensesForm from '../components/Candidate/Forms/DriversLicensesForm';
import ForkliftLicensesForm from '../components/Candidate/Forms/ForkliftLicensesForm';

import { disassembleFirestoreMap } from '../utils/convert/firestore';
import useEffectOnce from '../hooks/useEffectOnce';

const initialState: any = {
  modal: {
    content: {},
    type: '',
  },
  setModal: () => {},
  clearModal: () => {},
  onTriggerEdit: () => {},
};

type Props = {
  children: React.ReactNode;
};

export const CandidateProfileContext = createContext(initialState);

export const CandidateProfileContextProvider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [candidate, setCandidate] = useState<any>(null);
  const { user } = useAuthState();
  const [modal, setModal] = useState<object | null>(null);
  const [editState, setEditState] = useState<any>(null);
  const [addState, setAddState] = useState<any>(null);

  const setAddStateHandler = (newState: any) => {
    setAddState((prev: any) => {
      return { ...prev, ...newState };
    });
  };

  const mapToIterableArray = (key: any) => {
    const target = candidate[key];
    if (!target) return;

    return disassembleFirestoreMap(target);
  };

  const clearModal = () => {
    setModal(null);
  };

  const setEditStateHandler = ({
    typeOfEdit,
    itemLocation,
    itemId,
    item,
  }: any) => {
    setEditState({ typeOfEdit, itemLocation, itemId, item });
  };

  const getCandidate = async () => {
    try {
      const candidateRef = doc(db, 'candidates', user.uid);
      const candidateSnap = await getDoc(candidateRef);

      if (!candidateSnap.exists()) throw new Error('No candidate found.');

      return candidateSnap.data();
    } catch (err) {
      console.error(err);
    }
  };

  const getDefaultCandidate = async () => {
    const res = await fetch(
      `http://127.0.0.1:5001/kila-4457f/europe-west1/admin-app/get-candidate/${user?.uid}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const resData = await res.json();
    return resData;
  };

  const addNestedCandidateValue = useCallback(
    (candidateRef: any, itemLocation: any, values: any) => {
      const newUuid = uuidv4();
      setDoc(
        candidateRef,
        { [itemLocation]: { [newUuid]: values } },
        { merge: true }
      )
        .then(() => {
          setCandidate((prev: any) => {
            const prevClone = { ...prev };
            prevClone[itemLocation] = {
              ...prev[itemLocation],
              [newUuid]: values,
            };
            return { ...prevClone };
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          clearModal();
        });
    },
    [user.uid]
  );
  const updateNestedCandidateValue = (
    candidateRef: any,
    itemLocation: any,
    itemId: any,
    newValues: any
  ) => {
    const location = `${itemLocation}.${itemId}`;
    updateDoc(candidateRef, {
      [location]: newValues,
    })
      .then(() => {
        setCandidate((prev: any) => {
          const prevClone = { ...prev };
          prevClone[itemLocation] = {
            ...prevClone[itemLocation],
            [itemId]: newValues,
          };

          return prevClone;
        });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
        clearModal();
      });
  };

  const onSaveNestedObject = useCallback(
    ({ map, id, data }: any) => {
      clearModal();
      setIsLoading(true);

      const candidateRef = doc(db, 'candidates', user.uid);
      if (!id) {
        addNestedCandidateValue(candidateRef, map, data);
        return;
      } else {
        updateNestedCandidateValue(candidateRef, map, id, data);
      }
    },
    [user.uid]
  );

  const onRemoveNestedObject = useCallback(
    async ({ itemId, itemLocation }) => {
      setIsLoading(true);
      const candidateRef = doc(db, 'candidates', user.uid);

      try {
        await updateDoc(candidateRef, {
          [`${itemLocation}.${itemId}`]: deleteField(),
        });

        setCandidate((prev: any) => {
          const prevClone = { ...prev };
          delete prevClone[itemLocation][itemId];
          return { ...prevClone };
        });
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
      clearModal();
    },
    [user.uid]
  );

  const updateCandidateValues = (candidate: any, updatedValues: any) => {
    const updatedCandidate = { ...candidate };
    for (const key in updatedValues) {
      const value = updatedValues[key];
      updatedCandidate[key] = value;
    }
    return updatedCandidate;
  };

  const updatePersonalInfo = useCallback(
    async ({ newValues }: any) => {
      setIsLoading(true);

      const candidateRef = doc(db, 'candidates', user.uid);
      updateDoc(candidateRef, newValues)
        .then(() => {
          setCandidate((prev: any) => {
            const candidateClone = { ...prev };
            const updatedCandidate = updateCandidateValues(
              candidateClone,
              newValues
            );

            return { ...updatedCandidate };
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          clearModal();
        });
    },
    [user.uid]
  );

  const reassembleFirestoreLanguages = (languages: any) => {
    const dictionary = Object.create(null);
    for (let key in languages) {
      const value = languages[key];
      if (!value) continue;

      dictionary[uuidv4()] = {
        title: key,
        level: value,
      };
    }

    return dictionary;
  };

  const updateCandidateLanguages = useCallback(
    (languages: any) => {
      setIsLoading(true);

      const reassembledLanguages = reassembleFirestoreLanguages(languages);

      const candidateRef = doc(db, 'candidates', user.uid);
      updateDoc(candidateRef, {
        languages: reassembledLanguages,
      })
        .then(() => {
          setCandidate((prev: any) => {
            const candidateClone = { ...prev };
            const updatedCandidate = updateCandidateValues(candidateClone, {
              languages: reassembledLanguages,
            });

            return { ...updatedCandidate };
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          clearModal();
        });
    },
    [user.uid]
  );

  const reassembleFirestoreLicenses = (licenses: any) => {
    const dictionary = Object.create(null);
    for (let key in licenses) {
      const value = licenses[key];
      if (!value) continue;

      dictionary[uuidv4()] = {
        title: key,
      };
    }

    return dictionary;
  };

  const updateCandidateLicenses = useCallback(
    (updatedLicenses: any, licensesName: string) => {
      setIsLoading(true);

      const reassembledLicenses = reassembleFirestoreLicenses(updatedLicenses);
      const candidateRef = doc(db, 'candidates', user.uid);
      updateDoc(candidateRef, {
        [licensesName]: reassembledLicenses,
      })
        .then(() => {
          setCandidate((prev: any) => {
            const candidateClone = { ...prev };
            const updatedCandidate = updateCandidateValues(candidateClone, {
              [licensesName]: reassembledLicenses,
            });

            return { ...updatedCandidate };
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          clearModal();
        });
    },
    [user.uid]
  );

  const setPotentiallyChangedFields = useCallback((fields: any) => {
    setEditState((state: any) => {
      return { ...state, potentiallyChangedFields: fields };
    });
  }, []);

  const hasUnsavedChanges = () => {
    const fields = editState?.potentiallyChangedFields;
    if (!fields) return;

    for (let key in fields) {
      const hasChanged = fields[key];
      if (!!hasChanged) return true;
    }
  };

  // TRIGGER ON EDIT BUTTON
  useEffect(() => {
    if (!editState) return;
    const { typeOfEdit, itemLocation, itemId, item } = editState;

    let modalContentJsx;

    switch (typeOfEdit) {
      case 'personalInfo':
        modalContentJsx = {
          title: 'Redigera huvudinformation',
          jsx: (
            <PersonalInfoForm
              candidate={candidate}
              updatePersonalInfo={updatePersonalInfo}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
            />
          ),
        };
        break;
      case 'experience':
        setModal({
          title: 'Redigera erfarenhet',
          jsx: (
            <ExperienceForm
              buttonText="spara"
              onSaveNestedObject={(data: any) => {
                onSaveNestedObject({ map: 'experiences', data, id: itemId });
              }}
              experience={item}
              experienceLocation={itemLocation}
              experienceId={itemId}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
              onRemoveExperience={(id: any) => {
                onRemoveNestedObject({
                  itemId: id,
                  itemLocation: 'experiences',
                });
              }}
            />
          ),
        });
        break;
      case 'education':
        modalContentJsx = {
          title: 'Redigera utbildning',
          jsx: (
            <EducationForm
              buttonText="spara"
              onSaveNestedObject={(data: any) => {
                onSaveNestedObject({ map: 'educations', data, id: itemId });
              }}
              educationLocation={itemLocation}
              educationId={itemId}
              education={item}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
              onRemoveEducation={(id: any) => {
                onRemoveNestedObject({
                  itemId: id,
                  itemLocation: 'educations',
                });
              }}
              isEdit
            />
          ),
        };
        break;
      case 'driversLicenses':
        modalContentJsx = {
          title: 'Redigera körkort',
          jsx: (
            <DriversLicensesForm
              buttonText="spara"
              driversLicenses={disassembleFirestoreMap(
                candidate.driversLicenses
              )}
              onUpdateCandidateLicenses={updateCandidateLicenses}
            />
          ),
        };
        break;
      case 'forkliftLicenses':
        modalContentJsx = {
          title: 'Redigera truckkort',
          jsx: (
            <ForkliftLicensesForm
              buttonText="spara"
              forkliftLicenses={disassembleFirestoreMap(
                candidate.forkliftLicenses
              )}
              onUpdateCandidateLicenses={updateCandidateLicenses}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
            />
          ),
        };
        break;
      case 'languages':
        const iterableLanguages = disassembleFirestoreMap(candidate.languages);
        modalContentJsx = {
          title: 'Redigera språk',
          jsx: (
            <LanguageForm
              buttonText="spara"
              actionType="edit"
              languages={iterableLanguages}
              updateCandidateLanguages={updateCandidateLanguages}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
              onRemoveLanguage={(id: any) => {
                onRemoveNestedObject({ itemId: id, itemLocation: 'languages' });
              }}
              isEdit
            />
          ),
        };
        break;
      default:
        setModal({
          title: 'No corresponding jsx found..',
          jsx: (
            <p>
              ajaj, nu hände det något som inte skulle hända.. Kontakta support.
            </p>
          ),
        });
        break;
    }
    modalContentJsx && setModal(modalContentJsx);
  }, [
    onRemoveNestedObject,
    editState,
    addState,
    updateCandidateLanguages,
    onSaveNestedObject,
    updateCandidateLicenses,
    updatePersonalInfo,
    setPotentiallyChangedFields,
  ]);

  // TRIGGER ON ADD BUTTON
  useEffect(() => {
    if (!addState) return;

    const { contentRef } = addState;
    let modalContentJsx;

    switch (contentRef) {
      case 'personalInfo':
        modalContentJsx = {
          title: 'Redigera huvudinformation',
          jsx: (
            <PersonalInfoForm
              candidate={candidate}
              updatePersonalInfo={updatePersonalInfo}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
            />
          ),
        };
        break;
      case 'experience':
        setModal({
          title: 'Lägg till erfarenhet',
          jsx: (
            <ExperienceForm
              buttonText="Spara"
              onSaveNestedObject={(data: any) => {
                onSaveNestedObject({ map: 'experiences', data, id: null });
              }}
              experienceLocation="experiences"
              setPotentiallyChangedFields={() => {}}
            />
          ),
        });
        break;
      case 'education':
        modalContentJsx = {
          title: 'Lägg till utbildning',
          jsx: (
            <EducationForm
              buttonText="Spara"
              onSaveNestedObject={(data: any) => {
                onSaveNestedObject({ map: 'educations', data, id: null });
              }}
              educationLocation="educations"
              setPotentiallyChangedFields={() => {}}
            />
          ),
        };
        break;
      case 'driversLicense':
        modalContentJsx = {
          title: 'Spara',
          jsx: (
            <DriversLicensesForm
              buttonText="Spara"
              driversLicenses={disassembleFirestoreMap(
                candidate.driversLicenses
              )}
              onUpdateCandidateLicenses={updateCandidateLicenses}
            />
          ),
        };
        break;
      case 'forkliftLicense':
        modalContentJsx = {
          title: 'Lägg till truckkort',
          jsx: (
            <ForkliftLicensesForm
              buttonText="Spara"
              forkliftLicenses={disassembleFirestoreMap(
                candidate.forkliftLicenses
              )}
              onUpdateCandidateLicenses={updateCandidateLicenses}
              setPotentiallyChangedFields={() => {}}
            />
          ),
        };
        break;
      case 'language':
        const iterableLanguages = disassembleFirestoreMap(candidate.languages);
        modalContentJsx = {
          title: 'Spara',
          jsx: (
            <LanguageForm
              actionType="add"
              languages={iterableLanguages}
              languageLocation="languages"
              onAddLanguage={(data: any) => {
                onSaveNestedObject({ map: 'languages', data, id: null });
              }}
              setPotentiallyChangedFields={setPotentiallyChangedFields}
            />
          ),
        };
        break;
      default:
        setModal({
          title: 'No corresponding jsx found..',
          jsx: (
            <p>
              ajaj, nu hände det något som inte skulle hända.. Kontakta support.
            </p>
          ),
        });
        break;
    }
    modalContentJsx && setModal(modalContentJsx);
  }, [
    addState,
    updateCandidateLanguages,
    onSaveNestedObject,
    updateCandidateLicenses,
    updatePersonalInfo,
    setPotentiallyChangedFields,
  ]);

  useEffectOnce(() => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    (async () => {
      let fetchedCandidate = await getCandidate();
      if (fetchedCandidate) {
        setCandidate(fetchedCandidate);
        setIsLoading(false);
        return;
      }
      const defaultCandidate = await getDefaultCandidate();
      const candidateRef = doc(db, 'candidates', user.uid);
      await setDoc(
        candidateRef,
        {
          ...defaultCandidate,
          experiences: {
            [uuidv4()]: {
              title: 'Lagerarbetare',
              typeOfEmployment: 'Heltid',
              companyName: 'Apotea',
              location: {
                formatted: 'Uppsala',
                geoData: {},
              },
              startDate: today.toISOString(),
              endDate: today.toISOString(),
            },
            [uuidv4()]: {
              title: 'Lagerarbetare',
              typeOfEmployment: 'Heltid',
              companyName: 'Åhléns',
              location: {
                formatted: 'Rotebro',
                geoData: {},
              },
              startDate: today.toISOString(),
              endDate: today.toISOString(),
            },
          },

          educations: {
            [uuidv4()]: {
              school: 'Luleå Tekniska universitet',
              degree: 'kandidat',
              location: {
                formatted: 'Uppsala',
                geoData: {},
              },
              startDate: today.toISOString(),
              endDate: today.toISOString(),
            },
            [uuidv4()]: {
              school: 'Rosenvallsskolan',
              degree: 'lågstadie',
              location: {
                formatted: 'Uppsala',
                geoData: {},
              },
              startDate: today.toISOString(),
              endDate: null,
            },
          },
          driversLicenses: {
            [uuidv4()]: {
              title: 'Truckkort',
              verifiedWithPicture: false,
            },
          },
          languages: {
            [uuidv4()]: {
              title: 'Svenska',
              level: 'Modersmål',
            },
            [uuidv4()]: {
              title: 'Engelska',
              level: 'Nybörjare',
            },
            [uuidv4()]: {
              title: 'Franska',
              level: 'Avancerad',
            },
            [uuidv4()]: {
              title: 'Italienska',
              level: 'Nybörjare',
            },
          },
        },
        { merge: true }
      );
      fetchedCandidate = await getCandidate();
      setCandidate(fetchedCandidate);
      setIsLoading(false);
    })();
  });

  return (
    <CandidateProfileContext.Provider
      value={{
        candidate: candidate,
        setCandidate,
        mapToIterableArray: mapToIterableArray,
        modal: modal,
        clearModal: clearModal,
        setEditState: setEditStateHandler,
        editState: editState,
        setPotentiallyChangedFields: setPotentiallyChangedFields,
        hasUnsavedChanges: hasUnsavedChanges,
        setAddState: setAddStateHandler,
        isLoading: isLoading,
      }}
    >
      {children}
      {/* {isLoading && <LoadingSpinner />}  */}
    </CandidateProfileContext.Provider>
  );
};

export default CandidateProfileContext;
