import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import useWindowWidth from '../../hooks/UI/useWindowWidth';

import { useAuthState } from '../../firebase';

import Copyright from '../../components/Copyright/Copyright';
import Header from '../../components/Header/Header';
import InfoPic from '../../assets/images/apply-info.webp';
// import ShareButton from '../../components/common/buttons/ShareButton/ShareButton';
import css from './Apply.module.scss';

const ApplyInfo = () => {
  const [width] = useWindowWidth();
  const navigate = useNavigate();

  const { user } = useAuthState();

  const sectionTitle = !!user ? (
    <h3>Jippi! Du är redan en KilaKompis!</h3>
  ) : (
    <h3>Sök jobb</h3>
  );

  const privateParagraph = (
    <>
      <p>
        Detta registreringsformulär är endast till för blivande KilaKompisar,
        och du är som tur redan en KilaKompis!
      </p>
    </>
  );
  const publicParagraph = (
    <p>
      I denna registrering fyller du i namn och kontaktuppgifter. Vi hör sedan
      av oss till dig för att öka dina möjligheter till att få ett jobb.
    </p>
  );

  const beginApplyStepsCta = (
    <button
      className="button--cta button--full-width"
      onClick={() => navigate('/apply-name')}
    >
      Sätt igång
    </button>
  );

  // const shareLinkCtas = (
  //   <>
  //     <ShareButton platform="facebook" url="https://kila.se/apply-info" />
  //     <ShareButton
  //       platform="linkedin"
  //       url="https://kila.se/apply-info"
  //       title="Titta vad Kila.se har att erbjuda!!"
  //     />
  //     <ShareButton
  //       platform="email"
  //       email="users email..."
  //       title="Titta vad Kila.se har att erbjuda!!"
  //       body="Checka in jobbflödet hos https://kila.se/apply-info"
  //     />
  //   </>
  // );

  useEffect(() => {
    const hasDepricatedLocalStorageItems = localStorage.getItem('geoData');
    if (hasDepricatedLocalStorageItems) {
      localStorage.removeItem('location');
      localStorage.removeItem('geoData');
    }
  }, []);

  return (
    <div className={css.root}>
      <section className={css.hero}>
        <Header isStatic={width >= 1024} hasDarkText />
        <img src={InfoPic} alt="Information" className={css.image} />
      </section>
      <div className={css.formBox}>
        <section className={css.section}>
          {width >= 481 && (
            <img src={InfoPic} alt="Information" className={css.image} />
          )}
          {sectionTitle}
          {!!user ? (
            <>{privateParagraph}</>
          ) : (
            <>
              {publicParagraph} {beginApplyStepsCta}
            </>
          )}
        </section>
      </div>

      <Copyright />
    </div>
  );
};

export default ApplyInfo;
