import { Navigate, Route, Routes } from 'react-router-dom';
import PoolContextProvider from '../../../context/dashboard/pool-context';

import Pools from './Pools';
import SelectedPool from '../../../components/Pools/SelectedPool/SelectedPool';
import Candidates from './Pool/Candidates';
import Dispatches from './Pool/Dispatches';
import Pool from './Pool';
import SelectedMessage from '../../../components/Pools/SelectedMessage/SelectedMessage';
import Dispatch from './Pool/Dispatches/Dispatch';

const PoolRoutes = () => {
  return (
    <Routes>
      <Route
        path="pools"
        element={
          <PoolContextProvider>
            <Pools />
          </PoolContextProvider>
        }
      >
        <Route path=":poolId" element={<Pool />}>
          <Route path="" element={<Navigate to="candidates" replace />} />
          <Route path="candidates" element={<Candidates />} />
          <Route path="dispatches" element={<Dispatches />}>
            <Route path=":dispatchId" element={<Dispatch />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default PoolRoutes;
