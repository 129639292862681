import React, { useState } from 'react';

import DashboardModal from '../../DashboardModal/DashboardModal';
import ModalItem from '../../DashboardModal/ModalItem';

type Props = {
  modalIsOpen: boolean;
  application: any;
  closeModal: () => void;
  onUpdateStatus?: (value: string) => void;
};

const ApplicationModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<any>(false);
  const { data } = props.application;

  return (
    <DashboardModal
      isLoading={isLoading}
      title="Svar"
      modalIsOpen={props.modalIsOpen}
      closeModal={props.closeModal}
      onStatusChange={props.onUpdateStatus}
      dataStatus={data.status}
    >
      {data.candidateAnswers &&
        data.candidateAnswers.map(({ question, answer }: any, idx: number) => {
          return <ModalItem key={idx} title={question} value={answer} />;
        })}
    </DashboardModal>
  );
};

export default ApplicationModal;
