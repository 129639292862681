import React, { useState, useEffect, useCallback } from 'react';

import { useAsyncDebounce } from 'react-table';

import BaseInput from '../../common/inputs/BaseInput/BaseInput';

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: any) => {
  const count = preFilteredRows.length;

  return (
    <BaseInput
      defaultValue={filterValue || ''}
      placeholder={`Search ${count} records...`}
      onChange={(e: any) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
};

export const SelectColumnFilter = (props: any) => {
  const { id, alias, staticFilterOptions, preFilteredRows, setFilter } =
    props.column;

  const [filterOptions, setFilterOptions] = useState<{
    options: {}[];
    aliases?: {}[];
  } | null>(null);

  const initDefaultFilter = useCallback(() => {
    const aliases: any = !!alias && new Set();
    const options: any = new Set();

    preFilteredRows.forEach((row: any, i: number) => {
      if (!!aliases) aliases.add(row.original.data[alias]);
      options.add(row.values[id]);
    });

    return { options: [...options.values()], aliases: aliases && [...aliases] };
  }, [id, alias, preFilteredRows]);

  useEffect(() => {
    setFilterOptions(() => {
      return staticFilterOptions
        ? { options: staticFilterOptions }
        : initDefaultFilter();
    });
  }, [staticFilterOptions, initDefaultFilter]);

  return (
    filterOptions && (
      <select
        style={{ marginTop: '.25rem' }}
        onChange={(e: any) => setFilter(e.target.value || undefined)}
      >
        <option value="">Alla</option>
        {filterOptions.options.map((option: any, i) => {
          const optionAlias =
            !!filterOptions.aliases && filterOptions.aliases[i];
          return (
            <option key={i} value={option}>
              {optionAlias || option}
            </option>
          );
        })}
      </select>
    )
  );
};

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <BaseInput
      label="Ange ett nyckelord"
      customProps={{ noBottomMargin: true }}
      defaultValue={value || ''}
      onChange={(e: any) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Sök bland ${count} dokument...`}
    />
  );
};
