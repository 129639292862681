import { FC } from 'react';

// styles
import css from './SectionRating.module.scss';

const SectionRating: FC = ({ children }) => {
  return <div className={css['section-rating']}>{children}</div>;
};

export default SectionRating;
