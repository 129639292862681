import { useMemo, Fragment } from 'react';

import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';

import css from './Forms.module.scss';

type Props = {
  isEdit?: boolean;
  driversLicenses: any;
  onUpdateCandidateLicenses: (data: any, key: string) => void;
  buttonText: string;
};

const DriversLicensesForm = ({
  isEdit,
  driversLicenses,
  onUpdateCandidateLicenses,
  buttonText,
}: Props) => {
  const availableLicenses = useMemo(
    () => [
      { title: 'B', previouslySet: false },
      { title: 'C', previouslySet: false },
      { title: 'CE', previouslySet: false },
      { title: 'D', previouslySet: false },
      { title: 'DE', previouslySet: false },
      { title: 'Annat', previouslySet: false },
      { title: 'Jag har inget körkort', previouslySet: false },
    ],
    []
  );

  const onUpdateCandidateLicensesHandler = (data: any) => {
    onUpdateCandidateLicenses(data, 'driversLicenses');
  };

  const isPreviouslySet = (option: any) => {
    return (
      driversLicenses &&
      driversLicenses.some((license: any) => {
        return license.data.title.toLowerCase() === option.title.toLowerCase();
      })
    );
  };

  return (
    <FormGroup
      removable={isEdit}
      buttonText={buttonText}
      onSaveData={(data: any) => {
        onUpdateCandidateLicensesHandler(data);
      }}
      FormIsValid={true} // is true because currently no validation is in place for drivers lice..
    >
      <fieldset>
        <legend>Körkort</legend>
        <div className={css['licenses']}>
          {availableLicenses.map((item: any, idx: number) => {
            return (
              <Fragment key={idx}>
                <GroupInstance>
                  <input
                    type="checkbox"
                    id={`drivers-license-${idx}`}
                    value={item.title}
                    name={item.title}
                    defaultChecked={isPreviouslySet(item)}
                  />
                </GroupInstance>
                <label htmlFor={`drivers-license-${idx}`}>{item.title}</label>
              </Fragment>
            );
          })}
        </div>
      </fieldset>
    </FormGroup>
  );
};

export default DriversLicensesForm;
