import {
  useState,
  useMemo,
  useCallback,
  useRef,
  Fragment,
  useEffect,
} from 'react';
import { Column } from 'react-table';
import { toLocaleString } from '../../../utils/dates';
import { getDocOfCollection } from '../../../firebase';

import Table from '../../../components/dashboard/Table/Table';
import { SelectColumnFilter } from '../../../components/dashboard/TableFilter/ColumnFilter';

import css from './Table.module.scss';
import useEffectOnce from '../../../hooks/useEffectOnce';
import { ApplicationService } from '../../../services/database-service';

type Props = {
  initRouteData: (collectionName: string) => Promise<any>;
  setRouteData: (property: string, colFound: null, append: {}[]) => void;
  updateRouteDoc: (collName: string, docId: string, newDoc: object) => void;
  applications: {}[];
};

const ApplicationsTable = (props: Props) => {
  const componentMounted = useRef(true);
  const [data, setData] = useState<any>([]);

  const columns = useMemo<Column[]>(
    () =>
      !!data.length
        ? [
            {
              Header: 'id',
              accessor: 'id',
              disableFilters: true,
            },
            {
              Header: 'customRouteRedirect',
              id: 'customRouteRedirect',
              accessor: ({ id, data }: any) => {
                return `/dashboard/candidates/${data.candidateId}/applications?appId=${id}`;
              },
              disableFilters: true,
            },
            {
              Header: 'Förnamn',
              id: 'fName',
              accessor: 'data.firstName',
              disableFilters: true,
            },
            {
              Header: 'Efternamn',
              id: 'lName',
              accessor: 'data.lastName',
              disableFilters: true,
            },

            {
              Header: 'Datum',
              accessor: ({ data }: any) => {
                const normalizedDate = toLocaleString(data.date, 'sv-SE', {
                  year: 'numeric',
                  month: 'long',
                });

                return normalizedDate;
              },
              Cell: (cell: any) => {
                const dateObj = new Date(cell.row.original.data.date);
                const timeString = dateObj.toLocaleTimeString(['sv-SE'], {
                  hour: '2-digit',
                  minute: '2-digit',
                });

                const normalizedDate = toLocaleString(
                  cell.row.original.data.date,
                  'sv-SE',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                );

                return (
                  <div className={css['date-column']}>
                    <span>{normalizedDate}</span>
                    <span>{timeString}</span>
                  </div>
                );
              },
              id: 'date',
            },
            {
              Header: 'Jobb',
              accessor: 'data.jobId',
              id: 'jobId',
              Cell: (cell: any) => {
                return cell.row.original.data.title;
              },
              alias: 'title',
              Filter: SelectColumnFilter,
              filter: 'includes',
            },
            {
              Header: 'Status',
              accessor: ({ data }: any) => {
                return data.status?.name || data.status;
              },
              id: 'status',
              alias: 'status',

              staticFilterOptions: [
                'Ej hanterad',
                'Kontaktad',
                'Länk skickad',
                'Bokad intervju',
                'Intervju klar',
                'Presenterad',
                'Inte relevant',
                'Fått jobb',
              ],
              Filter: SelectColumnFilter,
              filter: 'change',
            },
          ].map((column: any): any => {
            return {
              Filter: SelectColumnFilter,
              filter: 'includes',
              ...column,
            };
          })
        : [],
    [data]
  );

  const hiddenColumns = useMemo(
    () => ['id', 'data.candidateId', 'customRouteRedirect'],
    []
  );

  const onShowApplicationHandler = (app: any) => {
    props.updateRouteDoc('candidates', app.id, app);
  };

  const extractFieldsOfCollection = async (
    collName: string,
    docId: string,
    fields: any = null
  ) => {
    const doc = await getDocOfCollection(collName, docId);

    if (!doc) return;
    const dict = Object.create(null);
    for (let [key, value] of Object.entries(doc)) {
      if (fields && fields.hasOwnProperty(key)) {
        dict[key] = value;
      }
    }

    return dict;
  };

  type fnArgs = {
    collection: string;
    propertyId: string;
    fieldsToConcat: object;
    originalFields: {}[];
  };

  /**
   * A "hacky" way to grab two separate firebase documents and
   * combine them into one. E.g if we want to display an application along side the applicant's name.
   *
   * @constructor
   * @param {string} collName - The name of the collection to fetch and merge..
   * @param {string} propertyId - The ID of the document in collection the collection provided above.
   * @param {string} fieldsToConcat - The fields from the to be fetched document that we want to merge with our original array.
   * @param {string} originalFields - The array of which we want to merge our to be fetched document with.
   */

  const concatFieldsFromCollection = useCallback(
    async ({
      collection,
      propertyId,
      fieldsToConcat,
      originalFields,
    }: fnArgs) => {
      return Promise.all(
        originalFields.map(async (app: any) => {
          const docId = app.data[propertyId];
          const extracted =
            (await extractFieldsOfCollection(
              collection,
              docId,
              fieldsToConcat
            )) || fieldsToConcat;

          return {
            ...app,
            data: {
              ...app.data,
              ...extracted,
            },
          };
        })
      );
    },
    []
  );

  const { applications, initRouteData, setRouteData } = props;
  const combineWithJobAndCandidate: any = useCallback(
    async (fetchedApplications: any) => {
      let accumulation: any;
      try {
        accumulation = await concatFieldsFromCollection({
          collection: 'candidates',
          propertyId: 'candidateId',
          fieldsToConcat: { firstName: null, lastName: null },
          originalFields: fetchedApplications,
        });
        accumulation = await concatFieldsFromCollection({
          collection: 'jobs',
          propertyId: 'jobId',
          fieldsToConcat: { title: null },
          originalFields: accumulation,
        });
        return accumulation;
      } catch (err) {
        console.error(err);
      }
    },
    [concatFieldsFromCollection]
  );

  const getCombinedApps = (apps: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const combinedApplications: {}[] = await combineWithJobAndCandidate(
          apps
        );
        resolve(combinedApplications);
      } catch (err) {
        reject(err);
      }
    });
  };

  useEffect(() => {
    if (applications) return setData(applications);
    if (!componentMounted.current) return;

    (async () => {
      const apps = await ApplicationService.getAll();
      const combined = (await getCombinedApps(apps)) as {}[];
      setRouteData('applications', null, combined);
      if (!componentMounted.current) return;
      setData(combined);
    })();

    return () => {
      componentMounted.current = false;
    };
  }, [applications, initRouteData]);

  return (
    <div className={css.dashboardTable}>
      {data && (
        <Fragment>
          <button className="button--cta button--add" onClick={() => {}}>
            Skapa ansökan
          </button>
          <Table
            data={data}
            definedColumns={columns}
            hiddenColumns={hiddenColumns}
            rowRedirectTitle="Visa ansökan"
            rowRedirectCollection="candidates"
          />
        </Fragment>
      )}
    </div>
  );
};

export default ApplicationsTable;
