import { forwardRef } from 'react';

import css from './InputAcceptTerms.module.scss';

const InputAcceptTerms = forwardRef<HTMLInputElement, any>((props, ref) => {
  let privacyPolicyUrl;

  if (props.privacyPolicyType === 'company') {
    privacyPolicyUrl = '/foretag/integritetspolicy';
  } else {
    privacyPolicyUrl = '/jobbsokande/integritetspolicy';
  }
  return (
    <div className={css.checkbox}>
      <input id="acceptTerms" ref={ref} {...props} type="checkbox" required />
      <label htmlFor="acceptTerms">
        <span>
          Jag godkänner hanteringen av mina personuppgifter.{' '}
          <a href={privacyPolicyUrl} target="_blank">
            Integritetspolicy
          </a>
        </span>
      </label>
    </div>
  );
});

export default InputAcceptTerms;
