import { useState } from 'react';
import { Routes, Route, NavLink, useLocation } from 'react-router-dom';

import { getFirestoreCollection } from '../../../firebase';

// dashboard candidate context
import DashboardCandidateContextProvider from '../../../context/dashboard/dashboard-candidate-context';

import CandidatesTable from '../DashboardTables/CandidatesTable';
import ApplicationsTable from '../DashboardTables/ApplicationsTable';
import JobsTable from '../DashboardTables/JobsTable';
import CompaniesTable from '../DashboardTables/CompaniesTable';
import Header from '../../../components/Header/Header';
import DashboardContent from '../DashboardContent/DashboardContent';
import Candidate from '../Candidate/Candidate';
import PoolList from '../Pools/Pools';

// interview page
import Interview from '../Interview';

// styles
import css from './Dashboard.module.scss';
import InterviewContextProvider from '../../../context/dashboard/interview-context';
import {
  ApplicationService,
  CandidateService,
} from '../../../services/database-service';
import PoolContextProvider from '../../../context/dashboard/pool-context';
import PoolRoutes from '../Pools/Routes';

const Dashboard = () => {
  const [routeData, setRouteData] = useState<any>(Object.create(null));

  const location = useLocation();

  const setRouteDataHandler = (
    property: string,
    colFound: {}[] | null,
    replacement?: {}[]
  ) => {
    if (replacement) {
      setRouteData((prevData: object) => {
        const newRouteData: any = { ...prevData };
        newRouteData[property] = replacement;
        return newRouteData;
      });
    } else {
      setRouteData((prevData: object) => {
        const newRouteData: any = { ...prevData };
        newRouteData[property] = colFound;
        return newRouteData;
      });
    }
  };

  const addNewCompanyToState = (companyObj: object) => {
    const routeDataClone = { ...routeData };
    if (!routeDataClone?.companies) {
      routeDataClone.companies = [];
    }
    routeDataClone.companies.push(companyObj);
    setRouteData(routeDataClone);
  };

  const addNewCandidateToState = (companyObj: object) => {
    const routeDataClone = { ...routeData };
    if (!routeDataClone?.candidates) {
      routeDataClone.candidates = [];
    }
    routeDataClone.candidates.push(companyObj);
    setRouteData(routeDataClone);
  };

  const addNewSpontaneousToState = (companyObj: object) => {
    const routeDataClone = { ...routeData };
    if (!routeDataClone?.spontaneousCandidates) {
      routeDataClone.spontaneousCandidates = [];
    }
    routeDataClone.spontaneousCandidates.push(companyObj);
    setRouteData(routeDataClone);
  };

  const initRouteData = async (collectionName: string) => {
    const collectionFound = await getFirestoreCollection(collectionName);

    if (!collectionFound) {
      console.error('no collection with such name exists.');
      return;
    }

    setRouteDataHandler(collectionName, collectionFound);
    return collectionFound;
  };

  // Adhoc for spontaneous candidates
  const loadSpontaneousCandidates = async (collectionName: string) => {
    const candidates = await CandidateService.getAll();
    const apps = await ApplicationService.getAll();

    const spontaneous = candidates.filter((candidate: any) => {
      return !apps.find((app: any) => app.data.candidateId === candidate.id);
    });

    setRouteDataHandler(collectionName, spontaneous);
    return spontaneous;
  };

  const updateRouteDoc = (
    collectionName: string,
    docId: string,
    newDoc: {}
  ) => {
    const parentCollection = routeData[collectionName];
    const idxToBeUpdated = parentCollection.findIndex(
      (doc: any) => doc.id === docId
    );

    parentCollection.splice(idxToBeUpdated, 1, newDoc);
    setRouteData((prevData: object) => {
      const mergedData: any = { ...prevData };
      mergedData[collectionName] = parentCollection;
      return mergedData;
    });
  };

  const updateCandidateFields = (updatedFields: any, candidateId: string) => {
    setRouteData((prevRouteData: any) => {
      const candidatesCopy = [...prevRouteData['candidates']];
      const candidateIndex = candidatesCopy.findIndex((candidate: any) => {
        return candidate.id === candidateId;
      });
      const candidateObj = candidatesCopy[candidateIndex];

      for (let [key, value] of Object.entries(updatedFields)) {
        candidateObj.data[key] = value;
      }
      return { ...prevRouteData, candidates: candidatesCopy };
    });
  };

  const updateSpontaneousFields = (updatedFields: any, candidateId: string) => {
    setRouteData((prevRouteData: any) => {
      const candidatesCopy = [...prevRouteData['spontaneousCandidates']];
      const candidateIndex = candidatesCopy.findIndex((candidate: any) => {
        return candidate.id === candidateId;
      });
      const candidateObj = candidatesCopy[candidateIndex];

      for (let [key, value] of Object.entries(updatedFields)) {
        candidateObj.data[key] = value;
      }
      return { ...prevRouteData, candidates: candidatesCopy };
    });
  };

  return (
    <>
      <Header
        isDashboardHeader
        customNavLinks={
          <>
            <NavLink to="pools">Pooler</NavLink>
            <NavLink to="applications">Ansökningar</NavLink>
            <NavLink to="candidates">Kandidater</NavLink>
            <NavLink to="spontaneous">Spontana</NavLink>
            <NavLink to="jobs">Jobb</NavLink>
            <NavLink to="companies">Företag</NavLink>
          </>
        }
      />

      <div className={css.dashboard}>
        <div id="dashboard-portal" className={css['dashboard-portal']}></div>

        <PoolRoutes />
        <Routes>
          <Route
            path="/candidates/*"
            element={
              <Routes>
                <Route
                  path="/"
                  element={
                    <DashboardContent
                      heading="Kandidater"
                      children={
                        <CandidatesTable
                          initRouteData={initRouteData}
                          candidates={routeData['candidates']}
                          updateRouteDoc={updateRouteDoc}
                          addNewCandidateToState={addNewCandidateToState}
                          updateCandidate={updateCandidateFields}
                        />
                      }
                    />
                  }
                />

                <Route
                  path=":candidateId/*"
                  element={
                    <DashboardCandidateContextProvider>
                      <Candidate />
                    </DashboardCandidateContextProvider>
                  }
                />
              </Routes>
            }
          />

          <Route
            path="spontaneous"
            element={
              <DashboardContent
                heading="Kandidater - spontan"
                children={
                  <CandidatesTable
                    initRouteData={() =>
                      loadSpontaneousCandidates('spontaneousCandidates')
                    }
                    candidates={routeData['spontaneousCandidates']}
                    updateRouteDoc={updateRouteDoc}
                    addNewCandidateToState={addNewSpontaneousToState}
                    updateCandidate={updateSpontaneousFields}
                  />
                }
              />
            }
          />

          <Route
            path="/applications"
            element={
              <DashboardContent
                heading="Jobbansökningar"
                children={
                  <ApplicationsTable
                    initRouteData={initRouteData}
                    applications={routeData['applications']}
                    updateRouteDoc={updateRouteDoc}
                    setRouteData={setRouteDataHandler}
                  />
                }
              />
            }
          />

          <Route path="interviews">
            <Route
              path=":appId"
              element={
                <InterviewContextProvider>
                  <Interview />
                </InterviewContextProvider>
              }
            />
          </Route>

          <Route
            path="jobs/*"
            element={
              <Routes>
                <Route
                  path="/"
                  element={
                    <DashboardContent
                      heading="Jobb"
                      children={
                        <JobsTable
                          initRouteData={initRouteData}
                          jobs={routeData['jobs']}
                        />
                      }
                    />
                  }
                />
              </Routes>
            }
          />

          <Route
            path={'companies'}
            element={
              <DashboardContent
                heading="Företag"
                children={
                  <CompaniesTable
                    initRouteData={initRouteData}
                    addNewCompanyToState={addNewCompanyToState}
                    companies={routeData['companies']}
                  />
                }
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default Dashboard;
