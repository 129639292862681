import { useContext } from 'react';
import { CardSection } from '../../../../components/Card/Card';

// Context
import { DashboardCandidateContext } from '../../../../context/dashboard/dashboard-candidate-context';

// Lists
import ExperienceList from '../../../../components/dashboard/candidate/CurriculumVitae/ExperienceList';
import EducationList from '../../../../components/dashboard/candidate/CurriculumVitae/EducationList';
import LanguageList from '../../../../components/dashboard/candidate/CurriculumVitae/LanguageList';
import ForkliftLicenseList from '../../../../components/dashboard/candidate/CurriculumVitae/ForkliftLicenseList';
import DriversLicenseList from '../../../../components/dashboard/candidate/CurriculumVitae/DriversLicenseList';

// helper func
import { disassembleFirestoreMap } from '../../../../utils/convert/firestore';
import { sortArrayByDate } from '../../../../utils/sort/array';

const CurriculumVitae = () => {
  const {
    candidate,
    onUpdateNestedMapHandler,
    updateLanguages,
    updateDriversLicenses,
    updateForkliftLicenses,
    setNestedMap,
    deleteNestedMap,
    isLoading,
  } = useContext(DashboardCandidateContext);
  const {
    educations,
    experiences,
    languages,
    driversLicenses,
    forkliftLicenses,
  }: any = candidate;

  const sortedEducations = sortArrayByDate({
    arr: disassembleFirestoreMap(educations),
  });
  const sortedExperiences = sortArrayByDate({
    arr: disassembleFirestoreMap(experiences),
  });

  return (
    <>
      <CardSection gap>
        <EducationList
          isLoading={isLoading}
          educations={sortedEducations}
          onUpdateEducation={({ id, data }: any) => {
            onUpdateNestedMapHandler({
              key: 'educations',
              id,
              data,
            });
          }}
          onAddEducation={(data: any) => {
            setNestedMap('educations', data);
          }}
          onDeleteEducation={(educationId: string) => {
            deleteNestedMap('educations', educationId);
          }}
        />

        <ExperienceList
          isLoading={isLoading}
          experiences={sortedExperiences}
          onUpdateExperience={({ id, data }: any) => {
            onUpdateNestedMapHandler({
              key: 'experiences',
              id,
              data,
            });
          }}
          onAddExperience={(data: any) => {
            setNestedMap('experiences', data);
          }}
          onDeleteExperience={(experienceId: string) => {
            deleteNestedMap('experiences', experienceId);
          }}
        />
      </CardSection>
      <CardSection gap>
        <LanguageList
          isLoading={isLoading}
          languages={disassembleFirestoreMap(languages)}
          onUpdateLanguage={updateLanguages}
          onAddLanguage={(data: any) => {
            setNestedMap('languages', data);
          }}
          onDeleteLanguage={(languageId: string) => {
            deleteNestedMap('languages', languageId);
          }}
        />

        <DriversLicenseList
          isLoading={isLoading}
          driversLicenses={disassembleFirestoreMap(driversLicenses)}
          onUpdateLicenses={updateDriversLicenses}
        />

        <ForkliftLicenseList
          isLoading={isLoading}
          forkliftLicenses={disassembleFirestoreMap(forkliftLicenses)}
          onUpdateLicenses={updateForkliftLicenses}
        />
      </CardSection>
    </>
  );
};

export default CurriculumVitae;
