import css from './Copyright.module.scss';

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={css['copyright']}>
      <p className={css['copyright__text']}>&copy; Copyright {currentYear} Kila</p>
    </div>
  );
};

export default Copyright;
