import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const Checkbox = ({ label, name, register }: any) => {
  // const [isChecked, setIsChecked] = useState(false);
  const uuid = uuidv4();
  return (
    <div>
      <input type="checkbox" id={uuid} {...register(name)} />
      <label htmlFor={uuid}>{label}</label>
    </div>
  );
};

export default Checkbox;
