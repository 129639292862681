import { useContext } from 'react';
import { InterviewContext } from '../../../context/dashboard/interview-context';
import { disassembleFirestoreMap } from '../../../utils/convert/firestore';

// components
import InterviewHeader from '../../../components/interview/Header/Header';
import InterviewSection from '../../../components/interview/InterviewSection/InterviewSection';
import Other from './Other';
import Form from './Form';
import QuestionsList from './QuestionsList';

// containers
import SectionRating from './SectionRating';
import TotalRating from './TotalRating';
import ApplicationStatusForm from '../../ApplicationStatusForm';

import {
  ColumnList,
  ColumnListItem,
} from '../../../components/ColumnList/ColumnList';
import SectionTitle from '../../../components/interview/InterviewSection/SectionTitle/SectionTitle';
import OverallImpression from './OverallImpression';
import TagListItem from '../../../components/Candidate/TagList/TagListItem';
import AppOverview from './AppOverview';
import StartInfo from '../../../components/interview/InterviewSection/StartInfo/StartInfo';
import CandidateAnswers from '../../../components/interview/InterviewSection/CandidateAnswers/CandidateAnswers';

const Index = () => {
  const templates = {
    commitment: {
      questions: [
        {
          id: 'rnDm1UidV4',
          question: 'Varför är ett arbete viktigt för dig?',
        },
        {
          id: 'anThrRndmv4',
          question: 'Hur skulle du säkerställa att du gör ett bra jobb?',
        },
        {
          id: 'skCgH33sv3',
          question:
            'Vad förväntar du dig av en arbetsgivare? (feedback, stöd, utbildning?)',
        },
      ],
      ratingHelp: [
        'Negativ individ som inte har tagit kontroll över sitt liv utan lägger över ansvaret på andra att hen ska lyckas.',
        'En neutral individ som inte känns speciellt driven och kapabel att få saker att hända. ',
        'Kan inte riktigt uttrycka i ord initiativ som visar att hen är en driven person. Men har en positiv attityd ich man märker en vilja att ta sig framåt i livet.',
        'Kan visa på enstaka initiativ som har gjort att hen tar sig framåt i livet. Positiv attityd, och har genomfört enstaka "vid sidan av" aktiviteter ((ex. SFI, engagemang i föreningar, ideelt arbete)',
        'Visar tydligt på egna initiativ som har gjort att hen tar sig framåt i livet. Positiv attityd till det mesta och har genomfört mycket "vid sidan av" aktiviteter (ex. SFI, engagemang i föreningar, ideelt arbete)',
      ],
    },

    grit: {
      questions: [
        {
          id: 'asd22w2',
          question:
            'Kan du ge exempel på saker du har gjort för att ta dig framåt i livet? Eller förändra en situation som du inte vill vara i?',
        },
        {
          id: 'fds33gds',
          question: 'Hur uppfattar andra människor dig?',
        },
        {
          id: 'hgh6j67jy',
          question: 'vad är du mest stolt över att ha åstadkommit?',
        },
      ],
      ratingHelp: [
        'Visar inget engagemang till att få ett arbete. Är svår att komma i kontakt med, och väldigt svag i intervjun. Inga extra egna insatser att visa upp.',
        'Säger sig vilja arbeta, är närvarande/deltagande på intervju, men känslan av att vilja arbeta känns inte stor. Inga extra insatser att visa upp.',
        'Vill arbeta, ger återkoppling på korrespondens/har fyllt i sin profil. Genomför intervju med tydligt intresse för jobb. Visar ev. på extra insatser vid sidan om.',
        'Visar starkt intresse för att arbeta. Ger snabb återkoppling på all korrespondens/har fyllt sin profil/gått utbildningar. ',
        'Visar starkt intresse för att arbeta. Ger snabb återkoppling på all korrespondens/har fyllt sin profil/gått utbildningar. Är aktiv och drivande i intervjun. ',
      ],
    },

    swedish: {
      questions: [
        {
          id: 'fds33gds',
          question: 'Vad har du gjort för att lära dig svenska?',
        },
        {
          id: 'hgh6j67jy',
          question:
            'Tittar du på svensk TV eller lyssnar på svensk radio? Om ja, vilket program?',
        },
      ],
      ratingHelp: [
        'Kan endast prata enstaka ord på svenska, och har svårt att förstå enkla meningar.',
        'Kan prata i korta meningar. Förstår enkla meningar, dock endast med enkla ord.',
        'Kan prata i meningar, dock med en del felsägningar och stakande. Har förståelse men ibland kan enklare ord och kortare meningar behöva användas för att kandidaten ska förstå.',
        'Kan prata obehindrat med långa meningar. Förstå och hänga med obehindrat i en diskussion',
        'Flytande i tal och förståelse',
      ],
    },

    conflict_management: {
      questions: [
        {
          id: 'fds33gds',
          question: 'Vad är dina bästa tips för att undvika en konflikt?',
        },
        {
          id: 'hgh6j67jy',
          question: 'Har du själv varit i någon konflikt?',
        },
        {
          id: 'ds2df33',
          question:
            'Vad gör du om du hamnar i en konflikt du själv inte kan lösa?',
        },
      ],
      ratingHelp: [
        'Kan inte på ett bra sätt argumentera för varför det är viktigt att undvika konflikter. Känns som en person som kan hamna i konflikt.',
        'Tydliggör att hen inte vill bli inblandad eller startar konflikter. Men kan inte ge några exempel på hur hen agerar för att undvika det.',
        'Tydliggör att hen inte vill bli inblandad eller startar konflikter. Kan ge exempel på hur hen agerar för att undvika det.',
        'Är en sansad och förtroendeingivande person. Tydliggör att hen inte vill vara i konflikter och beskriver hur hen agerar/agerat i en konflikt.',
        'Är en sansad och förtroendeingivande person. Tydliggör att hen inte vill vara i konflikter. Har tydliga förslag på hur konflikthantering bäst undviks, och ger bra exempel på när hen har löst konflikter med/åt andra.',
      ],
    },
    collaboration_skills: {
      questions: [
        {
          id: 'fds33gds',
          question: 'Tycker du om att arbeta tillsammans med andra människor?',
        },
        {
          id: 'hgh6j67jy',
          question:
            'Kan du ge ett exempel på när du har samarbetat bra med andra människor?',
        },
        {
          id: 'sll2s93',
          question: 'Varför är samarbete viktigt för dig?',
        },
      ],
      ratingHelp: [
        'En extremt introvert person som säger sig gärna jobba ensam. Kan få tydliga svårigheter att samarbeta med andra.',
        'En introvert person där man får känslan av att den sociala kompetensen inte riktigt finns där. Kan ha svårt att samarbeta med andra.',
        'En person som säger sig inte ha svårigheter att samarbeta med andra. ',
        'En social/glad person som tydligt visar sig tycka om att prata och umgås med andra människor. Har dock svårt att visa på konkreta exempel på bra samarbete.',
        'En social/glad person som tydligt visar sig tycka om att prata och umgås med andra människor. Kan visa på exempel när hen har haft ett framgångsrikt samarbete med andra.',
      ],
    },
    education: {
      questions: [
        {
          id: 'fds33gds',
          question: 'Har du någon utbildning?',
        },
      ],
      ratingHelp: [
        'Ej gått klart grundskola',
        'Grundskola avklarad',
        'Gymnasium avklarat',
        'Enstaka kurser på högskola eller universitet',
        'Universitetsexamen',
      ],
    },
    experience: {
      questions: [
        {
          id: 'fds33gds',
          question: 'Har du någon tidigare arbetslivserfarenhet?',
        },
      ],
      ratingHelp: [
        'Saknar helt erfarenhet från arbete och studier',
        'Har begränsad arbetslivserfarenhet dock ej i närtid.',
        'Har arbetslivserfarenhet under längre tid, dock ej i närtid.',
        'Har relevant arbetslivserfarenhet i närtid.',
        'Har många års relevant arbetslivserfarenhet, varav senast i närtid. ',
      ],
    },
    overall_impression: {
      ratingHelp: ['Sammantaget intryck baserat på ovan parametrar'],
    },
  };

  const {
    candidate,
    candidateId,
    application,
    appId,
    jobSecrecy,
    interviewData,
    updateLocalApplicationStatus,
    isExistingInterview,
    onSubmit,
  } = useContext(InterviewContext) as any;

  const candidateLanguages =
    candidate?.languages && disassembleFirestoreMap(candidate.languages);
  const candidateEducation =
    candidate?.educations && disassembleFirestoreMap(candidate.educations);
  const candidateExperience =
    candidate?.experiences && disassembleFirestoreMap(candidate.experiences);

  return (
    <>
      <InterviewHeader
        candidateId={candidateId}
        appId={appId}
        confirmBeforeNavigate={!isExistingInterview}
      />

      <AppOverview />

      {!isExistingInterview && jobSecrecy?.startInfo && (
        <InterviewSection
          leftColumn={<SectionTitle title="startinfo" faIcon="briefcase" />}
          rightColumn={<StartInfo info={jobSecrecy?.startInfo} />}
        />
      )}

      <InterviewSection
        leftColumn={
          <SectionTitle
            title="Svar från ansökan"
            faIcon="briefcase"
            hoverTitle="Jobbspecifikt"
          />
        }
        rightColumn={
          <CandidateAnswers answers={application?.candidateAnswers} />
        }
      />
      <Form
        onSubmit={onSubmit}
        defaultValues={interviewData}
        isExistingInterview={isExistingInterview}
      >
        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Eget driv" />
              <SectionRating
                mapRef="grit"
                ratingHelp={templates.grit.ratingHelp}
                tooltipTitle="eget driv"
              />
            </>
          }
          rightColumn={
            <QuestionsList mapRef="grit" questions={templates.grit.questions} />
          }
        />
        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Engagemang" />
              <SectionRating
                mapRef="commitment"
                ratingHelp={templates.commitment.ratingHelp}
                tooltipTitle="engagemang"
              />
            </>
          }
          rightColumn={
            <QuestionsList
              mapRef="commitment"
              questions={templates.commitment.questions}
            />
          }
        />
        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Erfarenhet" />
              <SectionRating
                mapRef="experience"
                ratingHelp={templates.experience.ratingHelp}
                tooltipTitle="erfarenhet"
              />
              {candidateExperience ? ( // TODO: separate into own component
                <>
                  <br />
                  <ColumnList>
                    {candidateExperience.map(({ data, id }: any) => {
                      const text = `${data.location.geoData?.postal_town}, ${
                        data.startDate
                      } - ${data.endDate || 'nuvarande'}`;
                      return (
                        <ColumnListItem
                          key={id}
                          title={data.title}
                          text={text}
                        />
                      );
                    })}
                  </ColumnList>
                </>
              ) : (
                <b>
                  <p>Ingen erfarenhet tillagd</p>
                </b>
              )}
            </>
          }
          rightColumn={
            <QuestionsList
              mapRef="experience"
              questions={templates.experience.questions}
            />
          }
        />
        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Samarbetsförmåga" />
              <SectionRating
                mapRef="collaboration_skills"
                ratingHelp={templates.collaboration_skills.ratingHelp}
                tooltipTitle="samarbetsförmåga"
              />
            </>
          }
          rightColumn={
            <QuestionsList
              mapRef="collaboration_skills"
              questions={templates.collaboration_skills.questions}
            />
          }
        />
        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Konflikthantering" />
              <SectionRating
                mapRef="conflict_management"
                ratingHelp={templates.conflict_management.ratingHelp}
                tooltipTitle="konflikthantering"
              />
            </>
          }
          rightColumn={
            <QuestionsList
              mapRef="conflict_management"
              questions={templates.conflict_management.questions}
            />
          }
        />
        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Svenska" />
              <SectionRating
                mapRef="swedish"
                ratingHelp={templates.swedish.ratingHelp}
                tooltipTitle="svenska"
              />

              {candidateLanguages ? ( // TODO: separate into own component
                <ul className="list--tags">
                  {candidateLanguages.map(({ data, id }: any) => (
                    <TagListItem
                      key={id}
                      title={data.title}
                      level={data.level}
                    />
                  ))}
                </ul>
              ) : (
                <b>
                  <p>Inget språk tillagt</p>
                </b>
              )}
            </>
          }
          rightColumn={
            <QuestionsList
              mapRef="swedish"
              questions={templates.swedish.questions}
            />
          }
        />

        <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Utbildning" />
              <SectionRating
                mapRef="education"
                ratingHelp={templates.education.ratingHelp}
                tooltipTitle="utbildning"
              />
              {candidateEducation ? ( // TODO: separate into own component
                <>
                  <br />
                  <ColumnList>
                    {candidateEducation.map(({ data, id }: any) => {
                      const title = `${data.degree}${
                        data.school && `, ${data.school}`
                      }`;
                      const text = `${data.startDate} - ${
                        data.endDate || 'nuvarande'
                      }`;
                      return (
                        <ColumnListItem key={id} title={title} text={text} />
                      );
                    })}
                  </ColumnList>
                </>
              ) : (
                <b>
                  <p>Ingen utbildning tillagd</p>
                </b>
              )}
            </>
          }
          rightColumn={
            <QuestionsList
              mapRef="education"
              questions={templates.education.questions}
            />
          }
        />
        <InterviewSection
          leftColumn={<SectionTitle title="Övrigt" />}
          rightColumn={<Other interviewExists={isExistingInterview} />}
        />
        <InterviewSection
          noBorderBottom
          leftColumn={
            <>
              <SectionTitle title="Helhetsintryck" />
              <SectionRating
                mapRef="overall_impression"
                ratingHelp={templates.overall_impression.ratingHelp}
                tooltipTitle="helhetsintryck"
              />
            </>
          }
          rightColumn={
            <OverallImpression interviewExists={isExistingInterview} />
          }
        />

        <InterviewSection
          leftColumn={<SectionTitle title="Rating" />}
          rightColumn={<TotalRating templates={templates} />}
          isGrey
        />

        {/* <InterviewSection
          leftColumn={
            <>
              <SectionTitle title="Jobbmatchning" />

              {application?.distanceInKm && (
                <ul className="list--tags">
                  <TagListItem>
                    <i className="fa-solid fa-location-arrow" />
                    <span>{application.distanceInKm} km</span>
                  </TagListItem>
                </ul>
              )}
            </>
          }
          rightColumn={<FinalComment interviewExists={isExistingInterview} />}
          isGrey
        /> */}
        {!isExistingInterview && (
          <div className="grey-10">
            <ApplicationStatusForm
              candidateId={application.candidateId}
              appId={appId}
              currentStatus={application?.status?.name || application?.status}
              label="Ändra ansökningsstatus"
              buttonTitle="Spara status"
              onSetStatus={updateLocalApplicationStatus}
            />
          </div>
        )}
      </Form>
    </>
  );
};

export default Index;
