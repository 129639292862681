import { useForm } from 'react-hook-form';

// components
import BaseModal from '../../../../common/BaseModal/BaseModal';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

// styles
import css from './SendSmsModal.module.scss';
import BaseInput from '../../../../common/inputs/BaseInput/BaseInput';
import BaseButton from '../../../../common/BaseButton/BaseButton';
import { useMemo, useRef, useState } from 'react';

import sv from 'date-fns/locale/sv';
import InputAutocomplete from '../../../../common/inputs/InputAutocomplete/InputAutocomplete';
registerLocale('sv', sv);

const SendSmsModal = ({
  isOpen,
  onClose,
  selectedCandidates,
  isSelectedCandidates,
  isAllSelected,
  onSend,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [location, setLocation] = useState<any>(null);

  const isValidDateRange = useMemo(() => {
    const isValidStartDate =
      startDate instanceof Date && !isNaN(startDate as any);
    const isValidEndDate =
      (endDate as any) instanceof Date && !isNaN(endDate as any);
    return isValidStartDate && isValidEndDate;
  }, [startDate, endDate]);

  const validEndDate = useMemo(() => {
    return endDate.getTime() < startDate.getTime() ? startDate : endDate;
  }, [startDate, endDate]);

  const formRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const onSendHandler = async (data: any) => {
    setIsLoading(true);
    await onSend({
      ...data,
      location,
      period: { from: startDate, to: endDate },
    });
    setIsLoading(false);
  };

  const sAllSelected = '(alla)';
  const sMarkedCandidates =
    isSelectedCandidates && !isAllSelected
      ? 'markerad kandidat'
      : 'markerade kandidater';
  const sSelectedText = `${sMarkedCandidates}${
    isAllSelected && ` ${sAllSelected}`
  }`;
  const selectedTextEl = <p>{sSelectedText}</p>;

  const formEl = (
    <form ref={formRef}>
      <div className={css['input-group']}>
        <BaseInput label="Tjänst" {...register('role', { required: true })} />
        <BaseInput
          label="Maxgräns"
          type="number"
          hidestep
          {...register('maxParticipants', {
            required: true,
            valueAsNumber: true,
          })}
        />
      </div>
      <fieldset>
        <legend>Start- och slutdatum</legend>
        <div className="flex flex-row normal-gap">
          <DatePicker
            locale="sv"
            selected={startDate}
            onChange={(date: any) => setStartDate(new Date(date))}
          />

          <DatePicker
            locale="sv"
            selected={validEndDate}
            onChange={(date: any) => setEndDate(new Date(date))}
            minDate={startDate}
          />
        </div>
      </fieldset>

      <InputAutocomplete
        label="Plats"
        onSelectLocation={(oLocation: any) => setLocation(oLocation)}
        componentRestrictions={{ country: 'se' }}
      />
    </form>
  );

  const selectedCandidatesList = (
    <ul>
      {selectedCandidates.map((candidate: any) => (
        <li key={candidate.id}>
          {candidate.data.firstName} {candidate.data.lastName}{' '}
          <span
            className={`${css['color-mark']} ${
              css['color-mark--' + candidate.colorMark]
            }`}
          ></span>
        </li>
      ))}
    </ul>
  );

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} heading="Nytt jobbutskick">
      {isSelectedCandidates && selectedTextEl}
      {formEl}

      {selectedCandidatesList}
      <footer>
        <BaseButton
          title="Skicka"
          onClick={handleSubmit(onSendHandler)}
          isLoading={isLoading}
          disabled={
            Object.keys(errors).length > 0 ||
            !isValidDateRange ||
            !location ||
            isLoading
          }
        />
      </footer>
    </BaseModal>
  );
};

export default SendSmsModal;
