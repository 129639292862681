import React from 'react';

// styles
import css from './EditPen.module.scss';

type Props = {
  onTriggerEdit: () => void;
};

const EditPen = ({ onTriggerEdit }: Props) => {
  return (
    <button
      type="button"
      className={css['edit-button']}
      onClick={onTriggerEdit}
    >
      <i className="fa fa-pen" />
    </button>
  );
};

export default EditPen;
