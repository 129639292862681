import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// styles
import css from './Checkbox.module.scss';

type Props = {
  label: string;
  name: string;
};

const Checkbox = ({ label, name }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const uuid = uuidv4();
  return (
    <div className={css['checkbox-wrapper']}>
      <input
        type="checkbox"
        id={uuid}
        name={name}
        checked={isChecked}
        onChange={() => setIsChecked((prev) => !prev)}
      />
      <label htmlFor={uuid}>{label}</label>
    </div>
  );
};

export default Checkbox;
