import React, { useEffect, useRef } from 'react';
import useScreenHeight from '../../../hooks/UI/useScreenHeight';

import ProgressBar from '../../ProgressBar/ProgressBar';
import css from './BaseCard.module.scss';

const BaseCard = (props: any) => {
  const sectionRef = useRef<HTMLInputElement>(null);
  const [height] = useScreenHeight();
  useEffect(() => {
    if (!props.strictFit && sectionRef.current) {
      const clientHeight = sectionRef.current.clientHeight;
      const headerHeight = (
        document.getElementById('app-header') as HTMLHeadElement
      ).clientHeight as any;
      let marginTop = height / 2 - clientHeight / 2;

      if (marginTop < headerHeight + 18) {
        marginTop = headerHeight + 18;
      }
      sectionRef.current.style.marginTop = `${marginTop}px`;
    }
  }, [sectionRef, height]);
  return (
    <div className={css.card}>
      <section
        ref={sectionRef}
        style={props.minHeight && { minHeight: props.minHeight }}
        className={css.section}
      >
        {(props.progressFil || props.title) && (
          <div className={css.cardHeader}>
            {props.title && (
              <div className={css.flexPos}>
                <h1 className={css.title}>{props.title}</h1>
              </div>
            )}
            {props.logo && (
              <div className={css.flexPos}>
                <img src={props.logo} alt="" />
              </div>
            )}
            {props.progressFil && (
              <div className={css.flexPos}>
                <ProgressBar filler={props.progressFil} />
              </div>
            )}
          </div>
        )}

        <div
          className={`${css.cardBody} ${
            props.alignContentEnd ? css.alignContentEnd : ''
          }`}
        >
          {props.bodyTitle && <h1 className={css.title}>{props.bodyTitle}</h1>}

          {props.bodyDescription && (
            <p className={css.bodyDescription}>{props.bodyDescription}</p>
          )}

          {props.children}
        </div>
      </section>
    </div>
  );
};
export default BaseCard;
