import { useState, useMemo, useRef, useEffect, Fragment } from 'react';

import { Column } from 'react-table';

import { SelectColumnFilter } from '../../../components/dashboard/TableFilter/ColumnFilter';
import ApplicationModal from '../../../components/dashboard/Modals/ApplicationModal/ApplicationModal';
import Table from '../../../components/dashboard/Table/Table';

import css from './Table.module.scss';
import NewJobModal from '../modals/NewJobModal/NewJobModal';

type Props = {
  initRouteData: (collectionName: string) => Promise<any>;
  jobs: {}[];
};

const JobsTable = (props: Props) => {
  const componentMounted = useRef(true);
  const [data, setData] = useState<any>([]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [newJobModalIsOpen, setNewJobModalIsOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<any>(null);

  const columns = useMemo<Column[]>(
    () =>
      !!data.length
        ? [
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'rowRedirectId',
              id: 'rowRedirectId',
              accessor: 'id',
              disableFilters: true,
            },
            {
              Header: 'Företag',
              accessor: 'data.companyName',
            },
            {
              Header: 'Jobb',
              accessor: 'data.title',
            },
            {
              Header: 'Typ av anställning',
              accessor: 'data.formOfEmployment',
            },
            {
              Header: 'Adress',
              accessor: ({ data }: any) => {
                const { postal_code, postal_town, route } = data.location;
                return `${postal_town}, ${postal_code}, ${route}`;
              },
            },
          ].map((column: any): any => {
            return {
              Filter: SelectColumnFilter,
              filter: 'includes',
              ...column,
            };
          })
        : [],
    [data]
  );

  const hiddenColumns = useMemo(() => ['id'], []);

  const { jobs, initRouteData } = props;
  useEffect(() => {
    if (!componentMounted.current) return;
    (async () => {
      const jobsSet = jobs || (await initRouteData('jobs'));
      if (!componentMounted.current) return;
      setData(jobsSet);
    })();

    return () => {
      componentMounted.current = false;
    };
  }, [jobs, initRouteData]);

  return (
    <div className={css.dashboardTable}>
      <NewJobModal
        isOpen={newJobModalIsOpen}
        onClose={() => setNewJobModalIsOpen(false)}
      />
      {modalIsOpen && selectedJob && (
        <ApplicationModal
          modalIsOpen={modalIsOpen}
          closeModal={() => setIsOpen(false)}
          application={selectedJob}
        />
      )}

      {data && (
        <Fragment>
          <button
            className="button--cta button--add"
            onClick={() => setNewJobModalIsOpen(true)}
          >
            Skapa jobb
          </button>
          <Table
            data={data}
            definedColumns={columns}
            hiddenColumns={hiddenColumns}
            onClickRow={() => {}}
            rowRedirectCollection="applications"
            rowRedirectTitle="Visa ansökningar"
          />
        </Fragment>
      )}
    </div>
  );
};

export default JobsTable;
