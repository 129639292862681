import { useState } from 'react';
import { ColumnList } from '../../../ColumnList/ColumnList';
import TagListItem from '../../../Candidate/TagList/TagListItem';
import LanguageForm from '../../../Candidate/Forms/LanguageForm';
import BaseModal from '../../../common/BaseModal/BaseModal';
import EditPen from '../../../EditPen/EditPen';
import SmallButton from '../../../common/buttons/SmallButton/SmallButton';

const LanguageList = ({
  isLoading,
  languages,
  onUpdateLanguage,
  onAddLanguage,
  onDeleteLanguage,
}: any) => {
  const [formType, setFormType] = useState<'add' | 'edit' | null>(null);

  const clearFormState = () => {
    setFormType(null);
  };

  const modalIsOpen = !!formType && !isLoading;

  const hasLanguages = !!languages?.length;
  return (
    <>
      <BaseModal
        isOpen={modalIsOpen}
        onClose={clearFormState}
        heading="Redigera språk"
      >
        {formType === 'edit' && (
          <LanguageForm
            actionType="edit"
            languages={languages}
            buttonText="spara"
            updateCandidateLanguages={(data: any) => {
              onUpdateLanguage(data);
              clearFormState();
            }}
            onRemoveLanguage={(id: string) => {
              onDeleteLanguage(id);
              clearFormState();
            }}
          />
        )}
        {formType === 'add' && (
          <LanguageForm
            actionType="add"
            languages={languages}
            buttonText="skapa"
            onAddLanguage={(data: any) => {
              onAddLanguage(data);
              clearFormState();
            }}
          />
        )}
      </BaseModal>
      <ColumnList
        title={
          <>
            Språk
            {hasLanguages && (
              <EditPen onTriggerEdit={() => setFormType('edit')} />
            )}
          </>
        }
      >
        {hasLanguages && (
          <div className="list--tags">
            {languages.map(({ data }: any, idx: number) => (
              <TagListItem key={idx} title={data.title} level={data?.level} />
            ))}
          </div>
        )}
        <SmallButton
          styleType="text"
          title="Lägg till"
          onClick={() => setFormType('add')}
        />
      </ColumnList>
    </>
  );
};

export default LanguageList;
