import { useFieldArray } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import BaseButton from '../../../../components/common/BaseButton/BaseButton';
import AnswerOptions from './AnswerOptions';
import Checkbox from './Checkbox';

import { v4 as uuid } from 'uuid';

// styles
import css from './NewJobModal.module.scss';

const FieldGroups = ({ nestIndex, isValid, control, register }: any) => {
  const { fields, update, remove, append } = useFieldArray({
    control,
    name: `form[${nestIndex}].fields`, // form => page => fields
  });

  const addFieldGroup = () => {
    const fieldGroup = {
      _uid: uuid(),
      component: 'field_group',
      child_type: 'radio',
      label: '',
      fields: [],
      required: true,
    };
    append(fieldGroup);

    return fieldGroup;
  };

  return (
    <>
      {!!fields.length && (
        <div className={css['job-page']}>
          {fields.map((field: any, index: any) => (
            <>
              <div className="adaptable-flex-direction space-between">
                <h2 className="h4">Fråga {index + 1}</h2>
                <BaseButton
                  title="Ta bort"
                  type="button"
                  styleType="delete"
                  onClick={() => remove(index)}
                />
              </div>

              <TextareaAutosize
                minRows={5}
                key={field.id} // important to include key with field's id
                {...register(`form[${nestIndex}].fields[${index}].label`, {
                  required: true,
                })}
              />
              <Checkbox
                label="Obligatorisk"
                name={`form[${nestIndex}].fields[${index}].required`}
                register={register}
              />
              <AnswerOptions
                pageIndex={nestIndex}
                formGroupIndex={index}
                isValid={isValid}
                {...{ control, register }}
              />
            </>
          ))}
        </div>
      )}
      <BaseButton
        title="Lägg till fråga"
        type="button"
        styleType="text button--small"
        onClick={addFieldGroup}
        disabled={!isValid}
      />
    </>
  );
};

export default FieldGroups;
