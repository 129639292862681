import { useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { DashboardCandidateContext } from '../../../../context/dashboard/dashboard-candidate-context';
import { orderBy } from 'firebase/firestore';

import { CandidateService } from '../../../../services/database-service';
import { Comment } from '../../../../models/Event';

import useEffectOnce from '../../../../hooks/useEffectOnce';

import { CardSection } from '../../../../components/Card/Card';
import CommentsForm from '../../../../components/EventsList/CommentsForm/CommentsForm';
import EventsList from '../../../../components/EventsList/EventsList';

// styles
import css from './Events.module.scss';
import { multipleSort } from '../../../../utils/sort/array';

const Events = () => {
  const [editState, setEditState] = useState<{ id: string | null }>({
    id: null,
  });

  // context
  const {
    candidateEvents: events,
    setCandidateEvents: setEvents,
    isLoading,
  } = useContext(DashboardCandidateContext);

  // candidate id from URL
  const { candidateId }: any = useParams();

  // Events collection from firestore
  const eventsCollection = useMemo(
    () => CandidateService.go(candidateId, 'events'),
    [candidateId]
  );

  const getEvents = async () => {
    const userEvents = await eventsCollection
      .query(orderBy('createdAt', 'desc'))
      .getAll();

    return userEvents;
  };

  const addCommentToState = (comment: any, id: any) => {
    setEvents((prevEvents: any) => {
      return [
        {
          id,
          data: comment,
        },
        ...prevEvents,
      ];
    });
  };

  const onAddCommentHandler = async (data: any) => {
    const { title, body } = data;
    const comment = new Comment(title, body);

    try {
      const { id: commentId } = await eventsCollection.create(comment);
      addCommentToState(comment, commentId);
    } catch (err) {
      console.error(err);
    }
  };

  const updateCommentState = (id: string, content: any) => {
    setEvents((prevEvents: any) => {
      return prevEvents.map((event: any) => {
        return event.id === id
          ? { id, data: { ...event.data, ...content } }
          : event;
      });
    });
  };

  const onUpdateCommentHandler = async (data: {
    id: string;
    data: {
      title: string;
      body: string;
    };
  }) => {
    try {
      const { id, data: content } = data;
      eventsCollection.set(id, content, { merge: true });
      updateCommentState(id, content);
    } catch (err: any) {
      console.error(err);
    } finally {
      setEditState({ id: null });
    }
  };

  const deleteCommentFromState = (id: string) => {
    setEvents((prevEvents: any) => {
      return prevEvents.filter((event: any) => event.id !== id);
    });
  };

  const onDeleteCommentHandler = async (id: string) => {
    try {
      await eventsCollection.remove(id);
      deleteCommentFromState(id);
    } catch (err) {
      console.error(err);
    }
  };

  useEffectOnce(() => {
    getEvents().then((events) => {
      const sorted = multipleSort(events, (obj: any) => {
        return [obj.data.createdAt.toDate()];
      }).reverse();

      setEvents(sorted);
    });
  });

  return (
    <>
      <CardSection>
        <h2 className={`no-vertical-margin ${css['section-heading']}`}>
          Ny kommentar
        </h2>

        <CommentsForm onAddComment={onAddCommentHandler} />
      </CardSection>
      <CardSection>
        {isLoading || events?.length ? (
          <EventsList
            events={events}
            setEditState={setEditState}
            editState={editState}
            onUpdateEvent={onUpdateCommentHandler}
            onDeleteEvent={onDeleteCommentHandler}
          />
        ) : (
          <h2 className="h4">Kandidaten har inte några tidigare händelser.</h2>
        )}
      </CardSection>
    </>
  );
};

export default Events;
