import React from 'react';
import AuthorLoading from '../Author/AuthorLoading/AuthorLoading';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// styles
import css from '../EventsListItem.module.scss';

type Props = {};

const LoadingPlaceholder = (props: Props) => {
  return (
    <article className={`${css['comment']}`}>
      <header>
        <h2 className={`no-vertical-margin ${css['comment__title']}`}>
          <Skeleton style={{ width: '100px' }} />
        </h2>

        <div className={`list--tags ${css['flex-item']}`}>
          <Skeleton style={{ width: '100px' }} />
        </div>
        <div className={css['comment__actions']}>
          <div className={css['ellipsis-wrapper']}>
            <i className="fa-solid fa-ellipsis"></i>
          </div>
        </div>
      </header>
      <p className={css['comment__body']}>
        <Skeleton style={{ width: '100%' }} count={4} />
      </p>
      <AuthorLoading />
    </article>
  );
};

export default LoadingPlaceholder;
