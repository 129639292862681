import { ReactNode } from 'react';
import classNames from 'classnames';

// styles
import css from './InterviewSection.module.scss';

type Props = {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
  isGrey?: boolean;
  noBorderBottom?: boolean;
};

const InterviewSection = ({
  leftColumn,
  rightColumn,
  isGrey,
  noBorderBottom,
}: Props) => {
  const flexContainerClasses = classNames({
    [css['flex-container']]: true,
    'border-bottom': !noBorderBottom,
  });
  const sectionJsx = (
    <section className="container">
      <div className={flexContainerClasses}>
        <div className={css['flex-container__item']}>{leftColumn}</div>
        <div className={css['flex-container__item']}>{rightColumn}</div>
      </div>
    </section>
  );
  return isGrey ? <div className="grey-10">{sectionJsx}</div> : sectionJsx;
};

export default InterviewSection;
