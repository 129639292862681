import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type Props = {};

const AuthorLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '.5rem',
      }}
    >
      <h3 className="no-vertical-margin">
        <Skeleton circle style={{ width: '25px', height: '25px' }} />
      </h3>
      <p className="no-vertical-margin">
        <Skeleton style={{ width: '45px' }} />
      </p>
      <p className="no-vertical-margin">
        <Skeleton style={{ width: '100px' }} />
      </p>
    </div>
  );
};

export default AuthorLoading;
