import { useContext } from 'react';
import { CandidateProfileContext } from '../../../context/candidate-profile-context';
import TagListItem from '../TagList/TagListItem';
import LoadingText from '../../Progress/LoadingText/LoadingText';

const LanguagesList = () => {
  const { mapToIterableArray, setAddState, isLoading } = useContext(
    CandidateProfileContext
  );

  const iterableLanguages = !isLoading ? mapToIterableArray('languages') : [];

  const listLanguages = iterableLanguages?.map((item: any, key: any) => {
    const { title, level } = item.data;
    return <TagListItem key={key} title={title} level={level} />;
  });

  const ctaButton = (
    <button
      className="button--cta button--add"
      onClick={() => {
        setAddState({ contentRef: 'language', type: 'add' });
      }}
    >
      Lägg till språk
    </button>
  );

  return (
    <>
      {!!iterableLanguages && iterableLanguages.length ? (
        <ul className="list--tags">
          <LoadingText maxWidth={80}>{listLanguages}</LoadingText>
        </ul>
      ) : !isLoading ? (
        ctaButton
      ) : null}
    </>
  );
};

export default LanguagesList;
