import css from './NoMatch.module.scss';
import { useNavigate } from 'react-router-dom';

const NoMatch = () => {
  const navigate = useNavigate();
  return (
    <div className={css.root}>
      <div className={css.noMatchSection}>
        <h1>404</h1>
        <p>Vi kan inte hitta sidan du söker efter</p>
        <button className="button--default" onClick={() => navigate('/')}>
          Gå till startsidan
        </button>
      </div>
    </div>
  );
};

export default NoMatch;
