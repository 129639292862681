
import React from "react";

import css from './TextBlocksSection.module.scss';
import classNames from 'classnames';

import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';
import List from '../../../website/components/atoms/List/List';

type Props = {
  copy: Array<
    {
      heading: {
        heading: string,
        level: string
      },
      content?: Array<{
        type: string,
        listItems?: Array<{
          type: string,
          text: string,
          path?: string
        }>,
        text? : string,
        path?: string,
        openInNewTab?: string
      }>
    }
  >
};

const TextBlocksSection = ({ copy }: Props) => {
  const textBlocks = copy;

  const sectionClasses = classNames(
    css['root'],
    'website__section',
  );

  return (
    <section className={sectionClasses}>

        {textBlocks.map((textBlock, textBlockIndex) => {
          const {heading, content} = textBlock;
          let headingLevel;

          /*
            Heading level from json doesn't work directly with typescript.
            https://stackoverflow.com/questions/37978528/typescript-type-string-is-not-assignable-to-type
          */
          switch (heading.level) {
            case 'h1':
              headingLevel = "h1" as const;
              break;
            case 'h2':
              headingLevel = "h2" as const;
              break;
            case 'h3':
              headingLevel = "h3" as const;
              break;
            case 'h4':
              headingLevel = "h4" as const;
              break;
            case 'h5':
              headingLevel = "h5" as const;
              break;
            case 'h6':
              headingLevel = "h6" as const;
              break;
            default:
              headingLevel = "h2" as const;
          }

          return (
            <React.Fragment key={textBlockIndex}>

              <Heading level={headingLevel}>{heading.heading}</Heading>

              {content?.map((item, contentIndex) => {

                switch(item.type) {
                  case 'paragraph':
                    return (<Paragraph key={contentIndex}>{item.text}</Paragraph>);

                  case 'link':
                    return (<Paragraph key={contentIndex}><a href={item.path} target={item.openInNewTab === 'true' ? '_blank' : '_self'}>{item.text}</a></Paragraph>)

                  case 'unorderedList':
                    return (
                      <List type="bullets" key={contentIndex}>
                        {item.listItems?.map((listItem: any, listIndex: number) => {
                          let isLink = listItem.path;

                          return (
                            <>
                              {!isLink && <li key={listIndex}>{listItem.text}</li>}
                              {isLink && <li key={listIndex}><a href={listItem.path} target={listItem.openInNewTab ? '_blank' : '_self'}>{listItem.text}</a></li>}
                            </>
                          )
                        })}
                      </List>
                    );
                }

              })}

            </React.Fragment>
          )
        })}
      </section>
  );
};

export default TextBlocksSection;
