import { useState, useEffect } from 'react';

/**
 * Useful for conditional rendering and/or advanced UI/UX handling
 */

const useScrollY = () => {
  const [scrollY, setScrollY] = useState(window.scrollY);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return [scrollY];
};

export default useScrollY;
