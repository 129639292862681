import React from 'react';
import css from './Submenu.module.scss';

type Props = {
  children: React.ReactNode;
};

const Submenu = ({children}: Props) => {

  return (
    <>
    <button className={`${css['submenu-button']}`}><i className={`fa-solid fa-ellipsis`}></i><span>Menu</span></button>
    <nav className={css['submenu']}>
      <ul>
        {children}
      </ul>
    </nav>
    </>
  );
};

export default Submenu;
