import { useState } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

// context
import InterviewContextProvider from '../../../context/dashboard/interview-context';

// component building blocks
import GoRoute from '../../../components/GoRoute/GoRoute';
import Card from '../../../components/Card/Card';
import PersonalInfo from '../../../components/dashboard/candidate/PersonalInfo/PersonalInfo';
import CurriculumVitae from './CurriculumVitae/CurriculumVitae';
import NavigationTabs from '../../../components/NavigationTabs/NavigationTabs';

// containers
import Interview from '../Interview';

// styles
import css from './Candidate.module.scss';
import Applications from './Applications';
import Events from './Events/Events';
import BaseButton from '../../../components/common/BaseButton/BaseButton';
import useScreenWidth from '../../../hooks/UI/useWindowWidth';

const Candidate = () => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [width] = useScreenWidth();

  return (
    <>
      <div className={css['colored-background']}>
        <div className={css['container']}>
          <div className={css['go-previous']}>
            <GoRoute title="visa alla kandidater" path=".." />
            <div>
              <BaseButton
                title="Radera"
                styleType="text"
                noMargin
                onClick={() => setShowDeleteModal(true)}
              />
              <BaseButton
                title="Redigera huvudinformation"
                styleType="text"
                noMargin
                onClick={() => setShowEditModal(true)}
              />
            </div>
          </div>
          <div className={css['cards']}>
            <PersonalInfo
              showEditModal={showEditModal}
              showDeleteModal={showDeleteModal}
              setShowEditModal={setShowEditModal}
              setShowDeleteModal={setShowEditModal}
            />

            {/* MORE INFO WITH TABS */}
            <div className={css['tabs-card']}>
              <NavigationTabs
                tabs={[
                  { to: 'cv', title: 'CV' },
                  { to: 'applications', title: 'ansökningar' },
                  { to: 'events', title: 'Händelser', notifications: null },
                ]}
              />
              <Routes>
                <Route
                  path="/*"
                  element={
                    <Card direction={width < 800 ? 'column' : 'row'}>
                      <Outlet />
                    </Card>
                  }
                >
                  <Route path="" element={<Navigate to="cv" replace />} />
                  <Route path="cv" element={<CurriculumVitae />} />
                  <Route path="applications" element={<Applications />} />
                  <Route path="events" element={<Events />} />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Candidate;
