import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { InterviewContext } from '../../../context/dashboard/interview-context';

// components
import Rating from '../../../components/Rating/Rating';
import Tooltip from '../../Tooltip';
import TooltipItem from '../../../components/Tooltip/Item';
import SectionRatingComponent from '../../../components/interview/SectionRating/SectionRating';
import IconTooltip from '../../../components/interview/InterviewSection/IconTooltip/IconTooltip';

type Props = {
  mapRef: string;
  ratingHelp: string[];
  tooltipTitle: string;
  tooltipPosition?: 'left' | 'right';
  before?: boolean;
};

const SectionRating = ({
  mapRef,
  ratingHelp,
  tooltipTitle,
  tooltipPosition,
  before,
}: Props) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const { isExistingInterview }: any = useContext(InterviewContext);

  const rating = watch(`criterias.${mapRef}.rating`);

  const ratingError = errors[mapRef] as any;
  const isError = ratingError && ratingError?.rating;

  const ratingType = !isExistingInterview ? 'dynamic' : 'static';

  // jsx

  const errorMsg = (
    <span className="error__summary">
      Ange minst en stjärna för att fortsätta.
    </span>
  );

  const tooltip = (
    <IconTooltip
      faIcon="circle-info"
      tooltip={
        <Tooltip
          type="success"
          position={tooltipPosition || 'left'}
          title={`Kriterier för ${tooltipTitle}`}
        >
          {ratingHelp.map((tip) => (
            <TooltipItem key={tip} tip={tip} />
          ))}
        </Tooltip>
      }
    />
  );

  return (
    <>
      <SectionRatingComponent>
        {before && tooltip}
        <Rating
          type={ratingType}
          value={rating}
          registerPath={`criterias.${mapRef}.rating`}
        />
        {!before && tooltip}
        {isError && errorMsg}
      </SectionRatingComponent>
    </>
  );
};

export default SectionRating;
