import React, { useState } from 'react';
import { NavLink, Routes, Route, Outlet, useParams } from 'react-router-dom';

import css from '../../../../../components/Pools/lists/DispatchList/DispatchList.module.scss';

import SelectedMessage from '../../../../../components/Pools/SelectedMessage/SelectedMessage';
import ListItem from '../../../../../components/Pools/common/ListItem/ListItem';
import useEffectOnce from '../../../../../hooks/useEffectOnce';
import { PoolService } from '../../../../../services/database-service';
import DispatchList from '../../../../../components/Pools/lists/DispatchList/DispatchList';
import Dispatch from '../../../../../components/Pools/lists/DispatchList/Dispatch/Dispatch';

type Props = {};

const Dispatches = (props: Props) => {
  const [dispatches, setDispatches] = useState([]);

  const { poolId, dispatchId }: any = useParams();

  const getPreviousDispatches = async () => {
    const data = await PoolService.go(poolId, 'messages').getAll();
    setDispatches(data);
  };

  useEffectOnce(() => {
    getPreviousDispatches();
  });
  return (
    <div
      style={{ padding: '1rem', display: 'flex', gap: '1rem' }}
      className={css['messages']}
    >
      <div style={{ flex: 2 }}>
        <h3>Jobbutskick</h3>
        <DispatchList>
          {dispatches.map(
            ({
              id,
              data: {
                companyName,
                role,
                period,
                maxParticipants,
                participantCount,
                createdAt,
              },
            }: any) => (
              <Dispatch
                key={id}
                id={id}
                companyName={companyName}
                role={role}
                period={period}
                maxParticipants={maxParticipants}
                participantCount={participantCount}
                createdAt={createdAt}
                isSelected={dispatchId === id}
              />
            )
          )}
        </DispatchList>
      </div>
      <div style={{ flex: 3 }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dispatches;
