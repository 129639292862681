import { useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import css from './Pools.module.scss';

import BaseModal from '../../../components/common/BaseModal/BaseModal';
import PoolList from '../../../components/Pools/lists/PoolList/PoolList';
import HeadingRow from '../../../components/Pools/common/HeadingRow/HeadingRow';
import { PoolContext } from '../../../context/dashboard/pool-context';
import BaseInput from '../../../components/common/inputs/BaseInput/BaseInput';
import BaseButton from '../../../components/common/BaseButton/BaseButton';

// container
const AddPoolModal = ({ isOpen, onCloseModal, onSave }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCloseModal}
      heading="Lägg till ny pool"
    >
      <form onSubmit={handleSubmit(onSave)}>
        <BaseInput label="Titel" {...register('title')} />
        <BaseInput label="Företag" {...register('companyName')} />
        <footer>
          <button className="button--text button--outlined" type="button">
            Avbryt
          </button>
          <button className="button--text--solid" type="submit">
            Spara
          </button>
        </footer>
      </form>
    </BaseModal>
  );
};

const Pools = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { pools, addNewPool } = useContext(PoolContext);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const addNewPoolHandler = (data: any) => {
    addNewPool(data);
    closeModal();
  };

  const isPools = useMemo(() => !!pools.length, [pools]);
  return (
    <div className={css['flex-layout']}>
      <section>
        {/*TODO: Add dynamic number*/}
        <HeadingRow
          heading="Pooler"
          headingLevel={1}
          badgeNumber={2}
          buttonText="+ Ny pool"
          onButtonClick={openModal}
        />

        <AddPoolModal
          isOpen={modalIsOpen}
          onCloseModal={closeModal}
          onSave={addNewPoolHandler}
        />

        {isPools ? <PoolList pools={pools} /> : <p>Det finns inga pooler.</p>}
      </section>

      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default Pools;
