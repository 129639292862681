import React from 'react';
import HeadingRow from '../../common/HeadingRow/HeadingRow';
import Submenu from '../../common/Submenu/Submenu';

type Props = {};

const Header = ({ heading, badgeNumber, openDeleteModal }: any) => {
  return (
    <header>
      {/*TODO: dynamic data*/}
      <HeadingRow heading={heading} headingLevel={2} badgeNumber={badgeNumber}>
        <Submenu>
          <li>
            <button onClick={openDeleteModal}>Radera pool</button>
          </li>
        </Submenu>
      </HeadingRow>

      {/* <SendSmsModal pool={poolId} /> */}
    </header>
  );
};

export default Header;
