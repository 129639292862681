import { useCallback, useMemo } from 'react';
import BaseSelect from '../BaseSelect/BaseSelect';

import css from './MonthYearPicker.module.scss';

type Props = {
  label: string;
  yearRange: number;
  defaultDate?: string | null;
  disablePicker?: boolean;
  month: any;
  setMonth: (month: string | number | null) => void;
  year: any;
  setYear: (fullYear: string | number | null) => void;
  beginMonthAt?: any;
};

const MonthYearPicker = ({
  label,
  yearRange,
  disablePicker,
  month,
  setMonth,
  year,
  setYear,
  beginMonthAt,
}: Props) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const rangeOfYears = useCallback(
    (start: number, stop: number, step: number) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      ),
    []
  );

  const yearOptions = rangeOfYears(currentYear, currentYear - yearRange, -1);

  const monthOptions = useMemo(
    () => [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    []
  );

  const setSelectedMonthHandler = (month: string) => {
    let monthPosition: any;
    if (!!month) {
      monthPosition = monthOptions.findIndex(
        (option) => option.toLowerCase() === month.toLowerCase()
      );
    }

    setMonth(!!month ? monthPosition : null);
  };

  const setSelectedYearHandler = (year: number) => {
    setYear(!!year ? Number(year) : null);
  };

  return (
    <div
      className={`${css['picker-container']} ${
        disablePicker ? css['select--disabled'] : ''
      }`}
    >
      <legend>{label}</legend>
      <fieldset>
        <div className={css['select-wrapper']}>
          <BaseSelect
            accessibilityLabel
            noVerticalMargin
            label={label}
            options={yearOptions}
            defaultOption={{ option: 'år', value: 'år' }}
            defaultSelected={!disablePicker ? year : 'år'}
            onChange={setSelectedYearHandler}
            disabled={disablePicker}
          />
        </div>

        <div className={css['select-wrapper']}>
          <BaseSelect
            accessibilityLabel
            noVerticalMargin
            label={label}
            options={monthOptions}
            defaultOption={{ option: 'månad', value: 'månad' }}
            defaultSelected={!disablePicker ? monthOptions[month] : 'månad'}
            disableOptionsPrior={beginMonthAt}
            onChange={setSelectedMonthHandler}
            disabled={disablePicker}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default MonthYearPicker;
