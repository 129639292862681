import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { verifyEmailExistence } from '../../utils/validation';
import { v4 as uuidv4 } from 'uuid';
import { getTodaysDate } from '../../utils/dates';

import useWindowWidth from '../../hooks/UI/useWindowWidth';

import Header from '../../components/Header/Header';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Copyright from '../../components/Copyright/Copyright';
import InputAcceptTerms from '../../components/common/inputs/InputAcceptTerms/InputAcceptTerms';
import BaseInput from '../../components/common/inputs/BaseInput/BaseInput';

import css from './Apply.module.scss';
import { CandidateService } from '../../services/database-service';
import EmailField from '../inputs/Email';

const ApplyEmail = () => {
  const [width] = useWindowWidth();
  const navigate = useNavigate();

  type FormValues = {
    phone: string | null;
    email: string | null;
    acceptTerms: boolean | null;
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: localStorage.getItem('email'),
      acceptTerms: null,
    },
  });

  const enteredEmail = watch('email');

  const userExists = useCallback(async () => {
    const verificationRes: any = await verifyEmailExistence(enteredEmail);
    const userExists = verificationRes?.userExists;
    return userExists;
  }, [enteredEmail]);

  const getCorrectLocationObject = () => {
    const location = localStorage.getItem('location') as any;
    const geoData = localStorage.getItem('geoData') as any;
    const parsedLocation = JSON.parse(location || '{}');
    if (!!Object.keys(parsedLocation)) {
      return parsedLocation;
    } else {
      return {
        formatted: location,
        geoData: geoData,
      };
    }
  };

  const onSubmit = async (data: any) => {
    if (await userExists()) return;
    const auth = getAuth();
    const password = uuidv4();
    createUserWithEmailAndPassword(auth, data.email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await createCandidateDocs(user.uid, data);
        //Lägg till email verifiering
        if (auth.currentUser) await sendEmailVerification(auth.currentUser);
        navigate('/apply-done');
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        alert('Fel har uppstått, kontakta Kila Support för att få hjälp');
      });
  };

  const createCandidateDocs = async (id: any, data: any) => {
    try {
      await CandidateService.set(id, {
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        email: data.email,
        phone: data.phone,
        location: getCorrectLocationObject(),
        accepetedTerms: data.acceptTerms ? getTodaysDate() : null,
      });
      await CandidateService.go(id, 'secrecy').set('data', {});
    } catch (err) {
      console.error(err);
    }
  };

  const formSubmitBtn = (
    <button
      className="button--cta button--full-width"
      disabled={!isValid || Object.keys(errors).length > 0}
      type="submit"
    >
      Nästa
    </button>
  );

  return (
    <>
      {width < 481 && <ProgressBar filler="66" />}
      <div className={css.root}>
        <Header showLogoOnly hasDarkText isStatic />
        <div className={css.formBox}>
          {width > 481 && <ProgressBar filler="66" />}
          <section className={css.section}>
            <h3>
              Hej {localStorage.getItem('firstName')}
              <br />
              Hur kan vi nå dig?
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <BaseInput
                  customProps={{ noBottomMargin: true }}
                  type="number"
                  htmlFor="phone"
                  label="Telefon"
                  placeholder="070-XXX XX XX"
                  {...register('phone', { required: true })}
                />
                <EmailField
                  name="email"
                  watch={watch}
                  register={register}
                  setError={setError}
                  clearErrors={clearErrors}
                  redirectToPreviousRoute={false}
                />

                <InputAcceptTerms
                  {...register('acceptTerms', {
                    required: true,
                  })}
                />

                {errors?.acceptTerms && (
                  <span className={css.error}>
                    Du måste godkänna att vi hanterar dina personuppgifter.
                  </span>
                )}

                <p className={`footnote`}>
                  Kila värdesätter säkerhet i hanteringen av dina
                  personuppgifter. Genom att godkänna har du tagit del av Kilas
                  integritetspolicy.
                </p>
              </div>
              {formSubmitBtn}
            </form>
          </section>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default ApplyEmail;
