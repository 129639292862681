import classNames from 'classnames';
import { FC, forwardRef } from 'react';

// styles
import css from './Tooltip.module.scss';

type Props = {
  tip: string;
  type: 'success' | 'warning' | 'danger';
  showTooltip: boolean;
  position: 'left' | 'right';
};

const Tooltip: FC<any> = forwardRef(
  ({ type, showTooltip, position, title, children }, ref: any) => {
    const classes = classNames({
      [css['tooltip']]: true,
      ...(css[type] && { [css[type]]: true }),
    });
    return (
      <div
        className={classes}
        ref={ref}
        style={{ display: showTooltip ? 'block' : 'none', [position]: '2rem' }}
      >
        <h3 className="no-vertical-margin">{title}</h3>
        <ul className={css['tips-container']}>{children}</ul>
      </div>
    );
  }
);

export default Tooltip;
