import { useState, useEffect, useContext, useMemo } from 'react';
import {
  useNavigate,
  useLocation,
  Route,
  useParams,
  Routes,
  useMatch,
  Outlet,
} from 'react-router-dom';

import { ApplicationService } from '../../../services/database-service';

// vital firebase imports
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

import { useForm, FormProvider } from 'react-hook-form';

import { useAuthState } from '../../../firebase';

import { GlobalContext } from '../../../context/global/GlobalContextProvider';

// components
import Header from '../../../components/Header/Header';
import BaseButton from '../../../components/common/BaseButton/BaseButton';

import SpecificJobInfo from '../SpecificJobInfo/SpecificJobInfo';
import PersonalInfoFormStep from '../SpecificJobPrerequisites/PersonalInfoFormStep';
import SpecificJobFormStep from '../SpecificJobFormStep/SpecificJobFormStep';
import SpecificJobApplied from '../SpecificJobApplied/SpecificFormApplied';

import css from '../SpecificJobInfo/SpecificJob.module.scss';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

// TODO: create utils folder and add this as  an export func
const getTodaysDate = () => {
  return new Date().toISOString();
};

const createCandidate = async (uid: any, candidateObj: any) => {
  const dateOfTermsAccepted: string = getTodaysDate();
  setDoc(doc(db, 'candidates', uid), {
    ...candidateObj,
    acceptedTerms: dateOfTermsAccepted,
  });
};

const createApplication = async (
  jobId: string,
  candidateId: number,
  answers: any
) => {
  return new Promise((resolve, reject) => {
    ApplicationService.create({
      candidateId: candidateId,
      date: getTodaysDate(),
      jobId: jobId,
      status: {
        comment: '',
        name: 'ej hanterad',
      },
      candidateAnswers: Object.values(answers),
    })

      .then((docRef) => {
        resolve(docRef.id);
      })
      .catch((error) => {
        console.error('ERROR ADDING APPLICATION', error);
        reject(new Error(`ERROR ADDING APPLICATION: ${error}`));
      });
  });
};

const createCandidateAndApplication = async (
  userUid: any,
  { candidateObj, applicantAnswers }: any,
  jobId: string
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    createCandidate(userUid, candidateObj)
      .then(() => {
        createApplication(jobId, userUid, applicantAnswers).then(() => {
          resolve(userUid);
        });
      })
      .catch((err) => reject(err));
  });
};

const getSpecificJob = async (specifiedId: string) => {
  const docRef = doc(db, 'jobs', specifiedId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) throw new Error('JobNotFound');
  return docSnap.data();
};

const createAuthenticatedUser = (auth: any, credentials: any) => {
  const { email, password } = credentials;

  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        resolve(user);
      })
      .catch((err) => reject(err));
  });
};

const SpecificJobRoutes = () => {
  // context
  const { setValue, isLoading } = useContext(GlobalContext);

  // hooks
  const { id }: any = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user: authenticatedUser } = useAuthState();
  let query = useQuery();

  // state
  const [jobData, setJobData] = useState<any>(null);
  const [jobForm, setJobForm] = useState<any>(null);

  const [currentFormStep, setCurrentFormStep] = useState<any>(
    Number(query.get('step'))
  );

  const [currentFormFields, setCurrentFormFields] = useState<any>(null);
  const [isNextStep, setIsNextStep] = useState<any>(null);
  const [formProgress, setFormProgress] = useState<any>(null);

  // react-form-hook methods, will be passed to cmp children.
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { watch } = methods;
  const { isDirty, isValid } = methods.formState;

  const candidateLocation = watch('applicantInfo.location');

  const onSubmit = async (data: any) => {
    setValue('isLoading', true);

    const auth = getAuth();
    const password = uuidv4();
    const { answers, applicantInfo } = data;
    try {
      localStorage.setItem(id, JSON.stringify(methods.getValues()));

      if (!!authenticatedUser) {
        await createApplication(id, authenticatedUser.uid, answers);
        return;
      }

      const user: any = await createAuthenticatedUser(auth, {
        email: applicantInfo.email,
        password,
      });

      await createCandidateAndApplication(
        user.uid,
        { candidateObj: applicantInfo, applicantAnswers: answers },
        id
      );

      if (auth.currentUser) await sendEmailVerification(auth.currentUser);
    } catch (err) {
      console.error(err);
    } finally {
      navigate('applied');
    }
  };

  const createUserDataCollector = (fields: string[], label: string) => {
    return {
      _uid: uuidv4(),
      component: 'collect_user_data_snippet',
      label,
      fields,
    };
  };

  const getData = async (id: string) => {
    try {
      const data = await getSpecificJob(id);
      setJobData(data);

      // if user is logged in, don't ask for personal information..
      if (!!authenticatedUser) {
        setJobForm(Object.values(data.form));
        return;
      }

      const lastSlide = createUserDataCollector(
        ['location', 'phone', 'acceptedTerms'],
        'ett sista steg!'
      );

      const formSlides = [...Object.values(data.form), lastSlide];
      setJobForm(formSlides);
    } catch (error) {
      console.error(error);
      navigate('/404');
    }
  };

  useEffect(() => {
    getData(id);
  }, []);

  const calcCurrentFormProgress = () => {
    return currentFormStep < 1 ? '0' : (currentFormStep / jobForm.length) * 100;
  };

  const goNextStep = (e: any) => {
    e.preventDefault();
    if (!isNextStep) {
      navigate(`applicant?afterLastStep`);
      return;
    }

    localStorage.setItem(id, JSON.stringify(methods.getValues()));

    const newStepValue = currentFormStep + 1;

    navigate(`apply?step=${newStepValue}`);
    setCurrentFormStep((prevStep: any) => {
      return prevStep + 1;
    });
  };

  const populateFormValues = (valuesObj: any) => {
    for (const property in valuesObj as any) {
      methods.setValue(property, valuesObj[property], {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  };

  useEffect(() => {
    if (currentFormFields && id) {
      const prevValuesInLs = JSON.parse(localStorage.getItem(id) || '{}');
      if (!prevValuesInLs) return;
      populateFormValues(prevValuesInLs);
    }
  }, [currentFormFields]);

  useEffect(() => {
    if (jobForm) {
      const nextFormStep = !!jobForm[currentFormStep + 1];
      setIsNextStep(nextFormStep);
      setFormProgress(calcCurrentFormProgress());
      setCurrentFormFields(jobForm[currentFormStep]);
    }
  }, [jobForm, currentFormStep]);

  const { search } = location;
  useEffect(() => {
    const newRouteStep: any = query.get('step');
    setCurrentFormStep(parseInt(newRouteStep));
  }, [search]);

  const requiresPrerequisites = !useMatch('jobs/:id');

  const [fulfilledPrerequisites, setFulfilledPrerequisites] = useState(false);
  useEffect(() => {
    if (
      requiresPrerequisites &&
      !authenticatedUser &&
      !fulfilledPrerequisites
    ) {
      navigate(`prerequisites?redirect=apply`);
    }
  }, [
    authenticatedUser,
    fulfilledPrerequisites,
    navigate,
    requiresPrerequisites,
  ]);

  return (
    <Routes>
      {jobData && (
        <Route
          path="/"
          element={
            <FormProvider {...methods}>
              <div className={css.root}>
                <Header hasDarkText />
                <form
                  className={css.form}
                  onSubmit={methods.handleSubmit(onSubmit)}
                >
                  <Outlet />
                </form>
              </div>
            </FormProvider>
          }
        >
          <Route
            path="/"
            element={
              <SpecificJobInfo
                companyName={jobData.companyName}
                title={jobData.title}
                description={jobData.description}
                formOfEmployment={jobData.formOfEmployment}
                route={jobData.location?.route}
                postalTown={jobData.location?.postal_town}
                postalCode={jobData.location?.postal_code}
                streetNumber={jobData.location?.street_number}
              />
            }
          />
          <Route
            path="prerequisites"
            element={
              <PersonalInfoFormStep
                setFulfilledPrerequisites={setFulfilledPrerequisites}
                jobId={id}
              />
            }
          />
          <Route
            path="apply"
            element={
              currentFormFields?.fields.length && formProgress ? (
                <SpecificJobFormStep
                  card={{
                    title: currentFormFields.label,
                    progressFil: formProgress,
                  }}
                  component={currentFormFields?.component}
                  fields={currentFormFields.fields}
                >
                  {isNextStep ? (
                    <button
                      className={`button--cta`}
                      disabled={!isDirty || !isValid}
                      onClick={goNextStep}
                    >
                      Nästa
                    </button>
                  ) : (
                    <BaseButton
                      type="submit"
                      title="Skicka in ansökan"
                      disabled={
                        !isDirty ||
                        !isValid ||
                        isLoading ||
                        (!authenticatedUser && !candidateLocation)
                      }
                      isLoading={isLoading}
                    />
                  )}
                </SpecificJobFormStep>
              ) : (
                <></>
              )
            }
          />
          <Route
            path="applied"
            element={
              <SpecificJobApplied
                applicantName={
                  methods.getValues('firstName') ||
                  JSON.parse(localStorage.getItem(id) as any)?.applicantInfo
                    ?.firstName ||
                  ''
                }
                applicantEmail={
                  methods.getValues('email') ||
                  JSON.parse(localStorage.getItem(id) as any)?.applicantInfo
                    ?.email ||
                  authenticatedUser?.email ||
                  ''
                }
              />
            }
          />
        </Route>
      )}
    </Routes>
  );
};

export default SpecificJobRoutes;
