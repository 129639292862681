import classNames from 'classnames';
import css from './List.module.scss';

type Props = {
  children: React.ReactNode;
  type: 'numbers' | 'bullets' | 'checks';
  customClasses?: string;
};

const List = ({
  children,
  type,
  customClasses
}: Props) => {

  const listClasses = classNames(
    customClasses,
    css['root'],
    {
      [css['list--checks']]: type === 'checks',
    });

  let CustomTag = `${(type === 'numbers') ? 'ol' : 'ul'}` as keyof JSX.IntrinsicElements;

  return (
    <CustomTag className={listClasses}>{children}</CustomTag>
  );
};

export default List;
