import { forwardRef } from 'react';
import classNames from 'classnames';
import css from './BaseInput.module.scss';
import LoadingDots from '../../../Progress/LoadingDots/LoadingDots';

const BaseInput = forwardRef<HTMLInputElement, any>(
  ({ customProps, ...props }, ref) => {
    const inputClasses = classNames({
      [css['radio']]: props.type === 'radio',
      [css['input']]: props.type !== 'radio',
      [css['inputError']]: props?.options?.hasError,
      [css['inputValid']]: props.options?.value?.length > 0,
      [css['hideStep']]: props.hidestep,
    });

    const renderLabel = () => {
      return <label htmlFor={props?.inputid}>{props.label}</label>;
    };
    const renderInput = () => {
      return (
        <>
          <input
            placeholder={props.placeholder}
            className={inputClasses}
            ref={ref}
            {...props}
            id={props?.inputid}
          />
          {customProps?.isLoading && (
            <div className={css['input__loading-spinner']}>
              <LoadingDots />
            </div>
          )}
          {customProps?.faIcon && (
            <div className={css['input__fa-icon']}>{customProps.faIcon}</div>
          )}
        </>
      );
    };
    const renderInputAndLabel = () => {
      if (props.type === 'radio' || props.type === 'checkbox') {
        return (
          <>
            {renderInput()} {renderLabel()}
          </>
        );
      } else {
        return (
          <>
            {renderLabel()}
            <div className={css['input']}>{renderInput()}</div>
          </>
        );
      }
    };
    return (
      <div className={`${css.inputWrapper}`}>
        {renderInputAndLabel()}
        {props.error && <span className={css.error}>{props.error.text}</span>}
      </div>
    );
  }
);
BaseInput.displayName = 'BaseInput';
export default BaseInput;
