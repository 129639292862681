import css from './Blurbs.module.scss';
import classNames from 'classnames';

import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';

import HeadingWithLabel from '../../../website/components/molecules/HeadingWithLabel/HeadingWithLabel';

type Props = {
  copy: {
    headingLabel: string;
    heading: string;
    preamble?: string;
    blurbs: Array<{
      title: string;
      text: string;
    }>;
  };
  images: Array<string>;
};

const Blurbs = ({ copy, images }: Props) => {
  const { headingLabel, heading, preamble, blurbs } = copy;

  const sectionClasses = classNames('website__section', css['root']);

  const listClasses = classNames(
    css['blurbs-list'],
    css['blurbs-list--' + blurbs.length]
  );

  return (
    <section className={sectionClasses}>
      <div className={css['section__inner']}>
        <HeadingWithLabel
          heading={heading}
          headingLabel={headingLabel}
          customClasses={css.centered}
        />
        {preamble && (
          <Paragraph type="preamble" customClasses={css.centered}>
            {preamble}
          </Paragraph>
        )}

        <ul className={listClasses}>
          {blurbs.map((blurb, index) => {
            const { title, text } = blurb;
            return (
              <li key={index}>
                <img src={images[index]} alt="" loading="lazy" />
                <span className={css['blurbs-content']}>
                  <Heading level="h3" customClasses={css['blurbs__heading']}>
                    {title}
                  </Heading>
                  <Paragraph customClasses={css['blurbs__text']}>
                    {text}
                  </Paragraph>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Blurbs;
