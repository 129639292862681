import React from 'react';

import { toLocaleString, getTimelineDate } from '../../../../../utils/dates';

import css from './Dispatch.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

type Props = {
  id: any;
  role: any;
  companyName: any;
  period: any;
  maxParticipants: any;
  participantCount: any;
  createdAt: any;
  isSelected: boolean;
};

const Dispatch = ({
  id,
  companyName,
  role,
  period,
  maxParticipants,
  participantCount,
  createdAt,
  isSelected,
}: Props) => {
  const title = `${companyName}, ${role}`;
  const sentAtText = `Sms skickat: ${getTimelineDate(createdAt.toDate())}`;
  const confirmedParticipantsText = `${participantCount} av ${maxParticipants} kandidater`;
  const jobPeriodText = `${toLocaleString(period.from, 'sv-SE', {
    dateStyle: 'long',
  })} - ${toLocaleString(period.to, 'sv-SE', {
    dateStyle: 'long',
  })}`;

  const liClasses = classNames(css['dispatch'], {
    [css['highlighted']]: isSelected,
  });
  return (
    <li className={liClasses}>
      <Link to={id}>
        <div className={css['dispatch__content']}>
          <h2 className="h5 no-vertical-margin">{title}</h2>
          <p>{sentAtText}</p>
          <p>{confirmedParticipantsText}</p>
          <p className={css['job-period']}>{jobPeriodText}</p>
        </div>
      </Link>
    </li>
  );
};

export default Dispatch;
