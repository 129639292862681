import React, { PropsWithChildren } from "react";

import css from "./ProgressBar.module.scss";

export interface Props {
  filler: string;
}

const ProgressBar = (props: PropsWithChildren<Props>) => {
  return (
    <div className={css.progress}>
      <div style={{ width: `${props.filler}%` }}></div>
    </div>
  );
};

export default ProgressBar;
