
import copy from './candidatesCopy.json';

// Images
import ImageSubpageHeroDesktop from '../../../website/assets/images/for-clients-desktop.webp';
import ImageSubpageHeroTablet from '../../../website/assets/images/for-clients-tablet.webp';
import ImageSubpageHeroMobile from '../../../website/assets/images/for-clients-mobile.webp';
import ImageTextAndImage from '../../../website/assets/images/faces.webp';

// Shared header with dashboard
import Header from '../../../components/Header/Header';

// Sections
import SubpageHeroSection from '../../../website/sections/SubpageHeroSection/SubpageHeroSection';
import NumberedSteps from '../../../website/sections/NumberedSteps/NumberedSteps';
import Testimonials from '../../../website/sections/Testimonials/Testimonials';
import Cta from '../../../website/sections/Cta/Cta';
import TextWithIconsSection from '../../../website/sections/TextWithIconsSection/TextWithIconsSection';
import ImageAndTextSection from '../../../website/sections/ImageAndTextSection/ImageAndTextSection';
import Footer from '../../../website/sections/Footer/Footer';

const Candidates = () => {

  const imagesSubpageHero = {
    mobile: ImageSubpageHeroMobile,
    tablet: ImageSubpageHeroTablet,
    desktop: ImageSubpageHeroDesktop
  }

  return (
    <>
      <Header />
      <SubpageHeroSection copy={copy.subpageHero} textColor="white" backgroundImages={imagesSubpageHero}/>
      <NumberedSteps copy={copy.numberedSteps} />
      {/*<Testimonials copy={copy.testimonials}/>*/}
      <TextWithIconsSection copy={copy.textWithIcons} />
      <Cta copy={copy.cta} />
      <ImageAndTextSection copy={copy.imageAndText} image={ImageTextAndImage} imagePosition='right' />
      <Footer />
    </>
  );
};

export default Candidates;
