import { useContext, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { ApplicationService } from '../../../../services/database-service';
import { getDocOfCollection } from '../../../../firebase';
import { where } from 'firebase/firestore';

// context
import { DashboardCandidateContext } from '../../../../context/dashboard/dashboard-candidate-context';

// components
import { CardSection } from '../../../../components/Card/Card';
import ApplicationsList from '../../../../components/dashboard/candidate/applications/List';
import ApplicationsItem from './ApplicationsItem';

const Applications = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    candidateApplications: applications,
    setCandidateApplications: setApplications,
  }: any = useContext(DashboardCandidateContext);

  const navigate = useNavigate();
  const { candidateId }: any = useParams();

  const appId = useMemo(() => searchParams.get('appId'), [searchParams]);

  const getApplications = async (id: any) => {
    const allApps = await ApplicationService.query(
      where('candidateId', '==', id)
    ).getAll();

    return Promise.all(
      allApps.map(async ({ data: app, id }: any) => {
        const job = await getDocOfCollection('jobs', app.jobId);
        return { id, data: { ...app, ...job } };
      })
    );
  };

  // update application status, needs refactoring.
  const setStateApplicationStatus = (id: string, newStatus: any) => {
    // update status of local copy of application
    setApplications((prevEvents: any) => {
      return prevEvents.map((event: any) => {
        let eventCopy = event;

        if (event.id === id) {
          eventCopy.data.status = newStatus;
        }
        return eventCopy;
      });
    });
  };

  // go to interview view when button is clicked
  const onNavigateInterviewHandler = (e: any, id: string) => {
    e.stopPropagation();
    navigate(`/dashboard/interviews/${id}`);
  };

  const appendParamIdHandler = (id: string) => {
    setSearchParams({ appId: id });
  };

  useEffectOnce(() => {
    getApplications(candidateId).then(setApplications);
  });
  return (
    <CardSection gap>
      {!!applications?.length ? (
        <ApplicationsList appId={appId}>
          {applications.map(({ id, data }: any, idx: number) => (
            <ApplicationsItem
              key={idx}
              id={id}
              data={data}
              setLocalStatus={(newStatus: any) => {
                setStateApplicationStatus(id, newStatus);
              }}
              onNavigateInterview={(e: any) => {
                onNavigateInterviewHandler(e, id);
              }}
              appendParamId={() => appendParamIdHandler(id)}
            />
          ))}
        </ApplicationsList>
      ) : (
        <h2 className="h4">Kandidaten har inte några ansökningar.</h2>
      )}
    </CardSection>
  );
};

export default Applications;
