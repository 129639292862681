import { FC } from 'react';

// styles
import css from './index.module.scss';

type Props = {
  onSubmit: () => void;
};

const Form: FC<Props> = ({ onSubmit, children }) => {
  return (
    <form className={css['form']} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
