const calcIncrement = (p1: number, p2: number, percent: number) => {
  return ((p1 - p2) / 100) * percent;
};

export const rgb_col_perc = ({
  percent,
  start: { r: r1, g: g1, b: b1 },
  end: { r: r2, g: g2, b: b2 },
}: any) => {
  const red =
    r1 > r2
      ? r1 - calcIncrement(r1, r2, percent)
      : r1 + calcIncrement(r2, r1, percent);

  const green =
    g1 > g2
      ? g1 - calcIncrement(g1, g2, percent)
      : g1 + calcIncrement(g2, g1, percent);

  const blue =
    b1 > b2
      ? b1 - calcIncrement(b1, b2, percent)
      : b1 + calcIncrement(b2, b1, percent);

  return `rgb(${Math.round(red)},${Math.floor(green)},${Math.round(blue)})`;
};

export const hsla_col_perc = ({
  percent,
  start: { h: h1, s: s1, l: l1, a: a1 },
  end: { h: h2, s: s2, l: l2, a: a2 },
}: any) => {
  const red =
    h1 > h2
      ? h1 - calcIncrement(h1, h2, percent)
      : h1 + calcIncrement(h2, h1, percent);

  const green =
    s1 > s2
      ? s1 - calcIncrement(s1, s2, percent)
      : s1 + calcIncrement(s2, s1, percent);

  const blue =
    l1 > l2
      ? l1 - calcIncrement(l1, l2, percent)
      : l1 + calcIncrement(l2, l1, percent);

  const alpha =
    a1 > a2
      ? a1 - calcIncrement(a1, a2, percent)
      : a1 + calcIncrement(a2, a1, percent);
  // Return a CSS HSL string
  return `hsla(${Math.round(red)},${Math.floor(green)}%,${Math.round(
    blue
  )}%, ${alpha})`;

  //Change the start and end values to reflect the hue map
  //Refernece : http://www.ncl.ucar.edu/Applications/Images/colormap_6_3_lg.png

  /*
Quick ref:
    0 – red
    60 – yellow
    120 – green
    180 – turquoise
    240 – blue
    300 – pink
    360 – red
*/
};
