import Heading from '../../atoms/Heading/Heading';

import { Link } from 'react-router-dom';

type Props = {
  heading: string;
  headingLabel: string;
  customClasses?: string;
};

const HeadingWithLabel = ({
  heading,
  headingLabel,
  customClasses,
  ...props
}: Props) => {

  return (
    <>
        <Heading level="h2" looksLike="label" customClasses={customClasses}>{headingLabel}</Heading>
        <Heading level="h3" looksLike="h2" customClasses={customClasses}>{heading}</Heading>
    </>
  );
};

export default HeadingWithLabel;



