import { useState, createContext, FC, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// TODO: create createReference method in DatabaseService to prevent having to import firestore everywhere.
import { doc } from 'firebase/firestore';
import { db } from '../../firebase';

import useEffectOnce from '../../hooks/useEffectOnce';
import {
  InterviewService,
  CandidateService,
  JobService,
  ApplicationService,
} from '../../services/database-service';
import LoadingSpinner from '../../components/Progress/LoadingSpinner/LoadingSpinner';

const initialCtx = {
  candidate: {},
  candidateId: '',
  application: {},
  appId: '',
  job: {},
  jobSecrecy: {},
  onSubmit: (data: any) => {},
  updateLocalApplicationStatus: ({ status, string }: any) => {},
  isLoading: true,
  isExistingInterview: false,
};
export const InterviewContext = createContext(initialCtx) as any;

const getRelationalAppData = async (appId: string) => {
  try {
    const application = await ApplicationService.getOne(appId);
    if (!application) return;

    const candidate = await CandidateService.getOne(application.candidateId);
    const job = await JobService.getOne(application.jobId);
    const jobSecrecy = await JobService.go(application.jobId, 'secrecy').getOne(
      'data'
    );

    return { application, candidate, job, jobSecrecy };
  } catch (err) {
    console.error(err);
  }
};

const getExistingInterview = async (appId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const interviewData: any = await InterviewService.getOne(appId);
      const { applicationRef, candidateRef, jobRef } = interviewData;
      const application = await InterviewService.getReference(applicationRef);
      const candidate = await InterviewService.getReference(candidateRef);
      const job = await InterviewService.getReference(jobRef);
      resolve({ ...interviewData, application, candidate, job });
    } catch (error) {
      reject(error);
    }
  });
};

const InterviewContextProvider: FC = ({ children }) => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isExistingInterview, setIsExistingInterview] = useState<any>(false);

  const { appId } = useParams() as any;
  const navigate = useNavigate();

  const updateLocalApplicationStatus = (newStatus: {
    status: string;
    comment: string;
  }) => {
    setData((prevData: any) => {
      const dataCopy = { ...prevData };
      dataCopy.application.status = newStatus;
      return dataCopy;
    });
  };

  const onSubmitHandler = async (interviewData: any) => {
    const {
      application: { candidateId, jobId },
    } = data;

    const combinedData = {
      ...interviewData,
      applicationRef: doc(db, 'applications', appId),
      candidateRef: doc(db, 'candidates', candidateId),
      jobRef: doc(db, 'jobs', jobId),
    };
    try {
      setIsLoading(true);

      await InterviewService.set(appId, combinedData, {});
      await CandidateService.go(candidateId, 'secrecy').set(
        'data',
        { rating: interviewData.totalRating },
        { merge: true }
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      navigate(-1);
    }
  };

  useEffectOnce(() => {
    (async () => {
      const interviewExists = await InterviewService.exists(appId);
      if (!interviewExists) {
        try {
          const stateObj = await getRelationalAppData(appId);
          setData(stateObj);
        } catch (err) {
          console.error(err);
          navigate(-1);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(true);
        setIsExistingInterview(true);
        getExistingInterview(appId)
          .then(setData)
          .catch((err) => {
            console.error(err);
            navigate('/404');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    })();
  });
  const { candidate, application, job, jobSecrecy, criterias, totalRating } =
    useMemo(() => data || {}, [data]);
  return (
    <InterviewContext.Provider
      value={{
        candidate,
        candidateId: application?.candidateId,
        application,
        interviewData: {
          criterias,
          totalRating,
        },
        appId,
        job,
        jobSecrecy,
        onSubmit: onSubmitHandler,
        updateLocalApplicationStatus,
        isLoading,
        isExistingInterview,
      }}
    >
      {!isLoading ? children : <LoadingSpinner />}
    </InterviewContext.Provider>
  );
};

export default InterviewContextProvider;
