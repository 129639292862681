import { useContext } from 'react';

import { CandidateProfileContext } from '../../../context/candidate-profile-context';
import IconButton from '../../common/IconButton/IconButton';
type Props = {
  isProfileCard?: boolean;
  heading: any;
  buttons?: any;
};

const CandidateCardHeader = ({ isProfileCard, heading, buttons }: Props) => {
  const { setAddState, setEditState } = useContext(CandidateProfileContext);
  const hasButtons = !!buttons?.length;
  const inlineButtons =
    hasButtons &&
    buttons.map(({ faIcon, iconButtonText, modalConfig }: any, idx: number) => {
      const { type, contentRef } = modalConfig;
      return (
        <IconButton
          key={idx}
          faIcon={faIcon}
          text={iconButtonText}
          onClick={() => {
            if (type === 'edit') {
              setEditState({
                typeOfEdit: contentRef,
              });
            } else if (type === 'add') {
              setAddState(modalConfig);
            }
          }}
        />
      );
    });
  return (
    <header>
      <h2 className="h3" style={{ width: '100%' }}>
        {heading}
      </h2>
      {inlineButtons}
    </header>
  );
};

export default CandidateCardHeader;
