import { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
// styles
import classNames from 'classnames';
import css from './Rating.module.scss';
import { hsla_col_perc } from '../../utils/colors';

type Props = {
  type?: 'static' | 'dynamic';
  value?: number;
  registerPath?: string;
};

const getPercentageColor = (value: any) => {
  const color = hsla_col_perc({
    percent: (value / 5) * 100,
    start: {
      h: 0,
      s: 100,
      l: 50,
      a: 1,
    }, // red
    end: {
      h: 159,
      s: 100,
      l: 22,
      a: 1,
    }, // green
  });
  return color;
};

// TODO: create Rating component and separate functionality from visuals
const Rating = ({ type, value, registerPath }: Props) => {
  const RANGE_LIMIT = useMemo(() => 5, []);
  const [forcedLimit, setForcedLimit] = useState<number | null>(null);

  const isDyanmicRating = type === 'dynamic';

  const getClasses = () => {
    return classNames({
      'fa-solid': true,
      'fa-star': true,
      [css['fade']]: true,
      [css['cursor']]: isDyanmicRating,
    });
  };

  const getStarPlacement = (e: any) => {
    const placement = e.target.getAttribute('data-placement');
    return Number(placement);
  };

  const getStarColor = (v: any) => {
    return {
      ...(!forcedLimit
        ? v <= parseInt(value as any) && {
            color: getPercentageColor(value),
          }
        : v <= forcedLimit && {
            color: getPercentageColor(forcedLimit),
          }),
    };
  };

  const onMouseOverHandler = (e: any) => {
    const starPlacement = getStarPlacement(e);
    setForcedLimit(starPlacement);
  };
  const onMouseLeaveHandler = (e: any) => {
    setForcedLimit(value || null);
  };

  useEffect(() => {
    if (value) setForcedLimit(value);
  }, [value]);

  const iconAttributes = {
    ...(isDyanmicRating && {
      onMouseOver: onMouseOverHandler,
    }),
  };
  const parentAttributes = {
    ...(isDyanmicRating && {
      onMouseLeave: onMouseLeaveHandler,
    }),
  };

  // context

  const { register } = useFormContext();
  return (
    <div className={css['rating']} {...parentAttributes}>
      {Array.from({ length: RANGE_LIMIT }, (_, i) => i + 1).map((v: number) => {
        const uuid = uuidv4();
        return (
          <div key={v} className={css['rating-container']}>
            <input
              disabled={type === 'static'}
              className="rating"
              type="radio"
              id={uuid}
              value={v}
              {...(!!registerPath && {
                ...register(registerPath, {
                  required: true,
                  valueAsNumber: true,
                }),
              })}
            />
            <label
              htmlFor={uuid}
              data-placement={v}
              className={getClasses()}
              style={getStarColor(v)}
              {...iconAttributes}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
