import { useContext, useState } from 'react';

// context
import { PoolContext } from '../../../../context/dashboard/pool-context';

// components
import Header from '../../../../components/Pools/SelectedPool/Header/Header';
import Navigation from '../../../../components/Pools/SelectedPool/Navigation/Navigation';
import ConfirmPoolRemoval from '../../../../components/Pools/SelectedPool/modals/ConfirmPoolRemoval';

type Props = {};

const Pool = (props: Props) => {
  const { deletePool, currentPoolTitle, candidates } = useContext(PoolContext);
  const [modal, setModal] = useState('');

  const closeModal = () => setModal('');

  const openDeleteModalHandler = () => {
    setModal('CONFIRM_POOL_REMOVAL');
  };

  return (
    <>
      <ConfirmPoolRemoval
        onConfirm={deletePool}
        isOpen={modal === 'CONFIRM_POOL_REMOVAL'}
        onClose={closeModal}
      />

      <Header
        heading={currentPoolTitle}
        badgeNumber={candidates.length}
        openDeleteModal={openDeleteModalHandler}
      />
      <Navigation />
    </>
  );
};

export default Pool;
