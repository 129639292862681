import { Fragment, useContext, useEffect, useState } from 'react';
import { CandidateProfileContext } from '../../../context/candidate-profile-context';
import LoadingText from '../../Progress/LoadingText/LoadingText';

const PersonalInfoList = () => {
  const [personalInfo, setPersonalInfo] = useState<{}[]>([]);
  const ctx = useContext(CandidateProfileContext);
  const candidate = ctx?.candidate;

  useEffect(() => {
    if (!candidate) return;
    const dateOfBirth =
      !!candidate.dateOfBirth &&
      new Date(candidate.dateOfBirth).toLocaleDateString('sv-SE');
    setPersonalInfo([
      {
        title: 'Födelsedatum',
        data: dateOfBirth || 'Inget födelsedatum tillagt',
      },
      { title: 'Telefonnummer', data: candidate.phone },
      { title: 'Mejladress', data: candidate.email },
      { title: 'Adress', data: candidate.location.formatted },
    ]);
  }, [candidate]);

  return (
    <dl>
      {personalInfo?.map(({ title, data }: any, idx: number) => {
        return (
          <Fragment key={idx}>
            <dt>
              <LoadingText maxWidth={40}>{title}</LoadingText>
            </dt>
            <dd>
              <LoadingText maxWidth={80}>{data}</LoadingText>
            </dd>
          </Fragment>
        );
      })}
    </dl>
  );
};

export default PersonalInfoList;
