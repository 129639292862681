import prettyMilliseconds from 'pretty-ms';

export const getTodaysDate = () => {
  return new Date().toISOString();
};

export const toLocaleString = (
  ISOString?: string,
  ISOType: string = 'sv-SE',
  options?: object
) => {
  return new Date(ISOString as any).toLocaleString([ISOType], options);
};

export const getAge = (dateString: any) => {
  const todaysDate = new Date() as any;
  const providedDate = new Date(dateString) as any;
  const ageInMilliseconds = todaysDate - providedDate;
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
};

export const getTimelineDate = (date: any) => {
  const MS_IN_A_WEEK = 604800000;
  const unixTime = new Date().getTime();
  const dateInMs = new Date(date).getTime();
  const msElapsed = unixTime - dateInMs;

  const isWithinWeek = msElapsed <= MS_IN_A_WEEK;
  return isWithinWeek
    ? prettyMilliseconds(msElapsed, { compact: true })
        .replaceAll('h', 't')
        .replaceAll('m', ' min') + ' sedan'
    : toLocaleString(date).split(' ')[0];
};
