import { useState } from 'react';

import Submenu from '../../common/Submenu/Submenu';

import css from './CandidateList.module.scss';

type Props = {
  candidates: any;
  isSelectedCandidate: any;
  toggleCandidate: any;
  removeCandidate: any;
};

const CandidateList = ({
  candidates,
  isSelectedCandidate,
  toggleCandidate,
  removeCandidate,
}: Props) => {
  const closedModalValues = { modalType: '', candidateId: null };
  const [candidatemodalIsOpen, setCandidateModalIsOpen] =
    useState(closedModalValues);

  return (
    <>
      <ul className={`block-list ${css['block-list']}`}>
        {candidates.map(({ id, data }: any) => {
          return (
            <li
              key={id}
              className={`${css['candidate']} ${
                candidatemodalIsOpen.candidateId == data
                  ? css['candidate--open-modal']
                  : ''
              }`}
            >
              <div className={css['input-wrapper']}>
                <input
                  id={id}
                  type="checkbox"
                  checked={isSelectedCandidate(id)}
                  onChange={() => toggleCandidate(id)}
                />
                <label htmlFor={id}>
                  <span className={css['candidate__details-container']}>
                    <span className={css['candidate__name']}>
                      {data.firstName} {data.lastName}
                    </span>
                    <span className={css['candidate__contact-container']}>
                      <span
                        className={css['candidate__social-security-number']}
                      ></span>
                    </span>
                  </span>
                  <span className={css['candidate__contact-details']}>
                    <span>{data.phone}</span>

                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </span>
                </label>
              </div>
              <Submenu>
                <li>
                  <a href={`/dashboard/candidates/${id}`} target="_blank">
                    Visa profil (ny flik)
                  </a>
                </li>

                <li>
                  <button
                    onClick={() => {
                      removeCandidate(id);
                    }}
                  >
                    Ta bort från pool
                  </button>
                </li>
              </Submenu>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CandidateList;
