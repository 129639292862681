import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Star from './Star/Star';

// utils
import { hsla_col_perc } from '../../../utils/colors';

// styling
import css from './index.module.scss';

const getPercentageColor = (value: any) => {
  const color = hsla_col_perc({
    percent: (value / 5) * 100,
    start: {
      h: 0,
      s: 100,
      l: 50,
      a: 1,
    }, // red
    end: {
      h: 159,
      s: 100,
      l: 22,
      a: 1,
    }, // green
  });
  return color;
};

const Stars = ({ type, filled, max }: any) => {
  const [forcedLimit, setForcedLimit] = useState<number | null>(null);

  const isDyanmicRating = type === 'dynamic';

  const getStarPlacement = (e: any) => {
    const placement = e.target.getAttribute('data-placement');
    return Number(placement);
  };
  const onMouseOverHandler = (e: any) => {
    const starPlacement = getStarPlacement(e);
    setForcedLimit(starPlacement);
  };
  const onMouseLeaveHandler = (e: any) => {
    setForcedLimit(filled || null);
  };

  useEffect(() => {
    if (filled) setForcedLimit(filled);
  }, [filled]);

  const iconAttributes = {
    ...(isDyanmicRating && {
      onMouseOver: onMouseOverHandler,
    }),
  };
  const parentAttributes = {
    ...(isDyanmicRating && {
      onMouseLeave: onMouseLeaveHandler,
    }),
  };

  const getClasses = () => {
    return classNames({
      'fa-solid': true,
      'fa-star': true,
      [css['fade']]: true,
      [css['cursor']]: type === 'dynamic',
    });
  };

  const getStarColor = (v: any) => {
    return {
      ...(!forcedLimit
        ? v <= parseInt(filled as any) && {
            color: getPercentageColor(filled),
          }
        : v <= forcedLimit && {
            color: getPercentageColor(forcedLimit),
          }),
    };
  };
  return (
    <div className={css['rating']} {...parentAttributes}>
      {Array.from({ length: max }, (_, i) => i + 1).map((value) => {
        return (
          <Star
            key={value}
            type={type}
            value={value}
            className={getClasses()}
            color={getStarColor(value)}
            iconAttributes={iconAttributes}
          />
        );
      })}
    </div>
  );
};

export default Stars;
