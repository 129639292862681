import { forwardRef } from 'react';
import { getTimelineDate } from '../../../utils/dates';
import classNames from 'classnames';

// components
import DistanceBadge from '../../../containers/DistanceBadge';

// styles
import css from './ApplicationOverview.module.scss';
import BaseBadge from '../../common/BaseBadge/BaseBadge';

type Props = {
  dateOfSubmission: any;
  jobTitle: string;
  title: string;
  isSticky: boolean;
  isSticking: boolean | undefined;
  jobDistance: number;
};

const ApplicationOverview = forwardRef(
  (
    {
      dateOfSubmission,
      jobTitle,
      title,
      isSticky,
      jobDistance,
      isSticking,
    }: Props,
    ref: any
  ) => {
    return (
      <div
        ref={ref}
        className={classNames({
          ['bg-white']: true,
          ['header--sticky']: true,
          [css['bottom-shadow']]: isSticking,
        })}
      >
        <div className="container">
          <div className={css['overview']}>
            <h2 className="no-vertical-margin h4">{title}</h2>
            <div className={css['group']}>
              <p className={`no-vertical-margin ${css['submission-date']}`}>
                Sökt tjänst: <span>{getTimelineDate(dateOfSubmission)}</span>
              </p>
              <BaseBadge
                title={jobTitle}
                className="grey-50"
                color={{ text: 'white' }}
              />
              {isSticky && <DistanceBadge distance={jobDistance} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ApplicationOverview;
