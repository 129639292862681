import css from './index.module.scss';
import { toLocaleString } from '../../../../../utils/dates';

// components
import TagListItem from '../../../../Candidate/TagList/TagListItem';
import BaseButton from '../../../../common/BaseButton/BaseButton';

const ApplicationsListItem = ({ question, answer }: any) => {
  return (
    <li className={css['answers-list__item']}>
      <p>{question}</p>
      <p>- {answer}</p>
    </li>
  );
};

export const ApplicationPreview = ({
  jobTitle,
  location,
  locationDistance,
  dateOfSubmission,
  tags,
  appendParamId,
  onNavigateInterview,
  interviewExists,
}: any) => {
  const openInterviewButton = (
    <BaseButton
      title={interviewExists ? 'Öppna intervju' : 'Skapa intervju'}
      onClick={onNavigateInterview}
      styleType={`default ${!interviewExists ? 'button--add' : ''}`}
      noMargin
    />
  );
  return (
    <div className={`flex-row ${css['container']}`} onClick={appendParamId}>
      <div className={`${css['job-details']}`}>
        <p className={`no-vertical-margin ${css['title']}`}>{jobTitle}</p>
        <div className={css['location']}>
          <p className={`no-vertical-margin ${css['city']}`}>{location}</p>

          <div className={css['distance']}>
            <i className="fa-solid fa-location-arrow"></i>
            <span>{locationDistance} km</span>
          </div>
        </div>
        <p className={`no-vertical-margin ${css['submission-date']}`}>
          {toLocaleString(dateOfSubmission)}
        </p>
      </div>
      {tags && (
        <div className="list--tags">
          {tags.map((tag: any, idx: number) => (
            <TagListItem key={idx} title={tag} />
          ))}
        </div>
      )}
      <div className={css['flex-items']}>{openInterviewButton}</div>
    </div>
  );
};

export const ApplicationExpanded = ({ answers }: any) => {
  return (
    <div className={css['scrollable-container']}>
      <div
        className={`${css['application-section']} ${css['answers-container']}`}
      >
        {answers && (
          <ul className={`no-vertical-margin ${css['answers-list']}`}>
            {answers.map(({ question, answer }: any, idx: number) => (
              <ApplicationsListItem
                key={idx}
                question={question}
                answer={answer}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

// const Item = ({ id, data, onNavigateInterview }: any) => {
//   return (
//     <BaseAccordionItem
//       uuid={id}
//       preview={
//         <ApplicationPreview
//           jobTitle={data.title}
//           location={data?.location?.geoData?.postal_town}
//           locationDistance={data.distanceInKm}
//           dateOfSubmission={data.date}
//           tags={data.tags}
//           onNavigateInterview={onNavigateInterview}
//           onClick={() => {
//             setApplicationIdParam(id);
//           }}
//         />
//       }
//       expanded={
//         <>
//           <ApplicationExpanded answers={data.candidateAnswers} />
//           <ApplicationStatus
//             currentStatus={data?.status?.name || data?.status}
//             setApplicationStatus={async (newStatus: any) => {
//               await onUpdateStatus(id, newStatus);
//             }}
//           />
//         </>
//       }
//     />
//   );
// };

// export default Item;
