import { FC, LegacyRef, forwardRef } from 'react';

import css from './PredictionList.module.scss';
import PoweredByGoogle from './PoweredByGoogle';

type Props = {
  isLocationAutocomplete: boolean;
};

const PredictionList: FC<Props> = forwardRef(
  ({ isLocationAutocomplete, children }, ref: LegacyRef<HTMLUListElement>) => {
    return (
      <div className={css['autocomplete-container']}>
        <ul className={css['predictions-list']} ref={ref}>
          {children}
        </ul>
        {isLocationAutocomplete && <PoweredByGoogle />}
      </div>
    );
  }
);

export default PredictionList;
