import { useEffect } from 'react';

import css from './LoadingSpinner.module.scss';

const LoadingSpinner = () => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';

    return () => {
      body.style.overflow = 'visible';
    };
  }, []);
  return (
    <div className={css['spinner-backdrop']}>
      <div className={css['lds-ellipsis']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
