import { useState, useEffect } from "react";
import css from './SubpageHeroSection.module.scss';
import classNames from 'classnames';

import Button from '../../../website/components/atoms/Button/Button';
import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';

type Props = {
  copy: {
    heading: string;
    text?: string,
    buttons?: Array<
      {
        text: string,
        link: string,
      }
    >,
  },
  backgroundImages?: {
    mobile: string,
    tablet: string,
    desktop: string
  };
  textColor? : 'white' | 'black' | 'green';
  leftAlignedText?: boolean;
};

const SubpageHeroSection = ({ copy, backgroundImages, textColor = 'green', leftAlignedText = false }: Props) => {
  const { heading, text, buttons } = copy;
  const [width, setWidth] = useState(window.innerWidth);
  const isSmall = width <= 767;
  const isMedium = (width > 768) && (width < 1024);
  const isLarge = !isSmall && !isMedium;
  let backgroundImage = undefined;

  // Select correct background image when resizing the window.
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  if(backgroundImages) {
    backgroundImage = classNames({
      [backgroundImages.mobile]: isSmall,
      [backgroundImages.tablet]: isMedium,
      [backgroundImages.desktop]: isLarge
    })
  }

  const sectionClasses = classNames(
    css['root'],
    css['text-color--' + textColor],
    {[css['text-left-aligned']]: leftAlignedText}
  );

  const styles = {
    root: {
      'backgroundImage': backgroundImage? `url(${backgroundImage})` : '',
    }
  };

  return (
    <section className={sectionClasses}  style={styles.root}>
        <Heading level="h1">
          {heading}
        </Heading>
        {text && <Paragraph type="preamble">{text}</Paragraph>}
        {buttons &&
          <div className={css['button-wrapper']}>
            {buttons.map((button, index) => {
              return <Button key={index} label={button.text} link={button.link} type="cta" />
            })}
          </div>
        }
    </section>
  );
};
export default SubpageHeroSection;
