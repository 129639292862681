export const googleAutocomplete = async (
  text: string,
  sessionToken: any,
  { componentRestrictions, types }: any
) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject('Need valid text input');
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === 'undefined') {
      return reject('Need valid window object');
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: text,
          componentRestrictions: componentRestrictions,
          language: 'sv',
          types: types,
          sessionToken: sessionToken,
        } as any,
        resolve
      );
    } catch (e) {
      reject(e);
    }
  });

/**
 * places.AutocompleteService us to do everything programmatically to
 * our needs, not what places.Autocomplete has pre-defined.
 */
