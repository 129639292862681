import { useState, Fragment } from 'react';
import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';
import { v4 as uuidv4 } from 'uuid';

import useScreenWidth from '../../../hooks/UI/useWindowWidth';
import { ISO_LANGS } from '../../../constants/options/available-languages';
import { alphabeticalSort } from '../../../utils/sort/array';
import BaseInput from '../../common/inputs/BaseInput/BaseInput';
import BaseSelect from '../../common/BaseSelect/BaseSelect';

import css from './Forms.module.scss';

const LanguageForm = ({
  actionType,
  languages,
  updateCandidateLanguages,
  onAddLanguage,
  onRemoveLanguage,
}: any) => {
  const [width] = useScreenWidth();
  const [isValidLanguage, setIsValidLanguage] = useState(false);
  const [isValidDifficulty, setIsValidDifficulty] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState<string | null>(
    null
  );

  const onSelectLanguageHandler = (lang: string) => {
    setIsValidLanguage(!!lang && lang !== '---');
    setSelectedLanguage(lang);
  };

  const onSelectDifficultyHandler = (difficulty: string) => {
    setIsValidDifficulty(!!difficulty && difficulty !== '---');
    setSelectedDifficulty(difficulty);
  };

  const onSaveUpdatedLanguages = (e: any) => {
    updateCandidateLanguages(e, languages);
  };

  const onDeleteLanguageHandler = (id: string) => {
    onRemoveLanguage(id);
  };

  const onDetermineActionType = (data: any) => {
    if (actionType === 'edit') {
      onSaveUpdatedLanguages(data);
    } else if (actionType === 'add') {
      if (!selectedLanguage || !selectedDifficulty) return;
      const language = {
        title: selectedLanguage,
        level: selectedDifficulty,
      };
      onAddLanguage(language);
    }
  };

  const sortedLangs = alphabeticalSort(ISO_LANGS, 'name');

  const componentTypes = {
    add: (
      <FormGroup
        buttonText="spara"
        onSaveData={onDetermineActionType}
        FormIsValid={isValidLanguage && isValidDifficulty}
      >
        <fieldset className="adaptable-flex-direction">
          <legend>Språk och Nivå</legend>
          <BaseSelect
            defaultOption={{ option: '---', value: '---' }}
            options={sortedLangs.map(
              (lang: any) => `${lang.name} (${lang.nativeName})`
            )}
            onChange={onSelectLanguageHandler}
            noVerticalMargin
          />
          <BaseSelect
            defaultOption={{ option: '---', value: '---' }}
            options={['Nybörjare', 'Medioker', 'Avancerad/Modersmål']}
            onChange={onSelectDifficultyHandler}
            noVerticalMargin={width >= 768}
          />
        </fieldset>
      </FormGroup>
    ),
    edit: (
      <FormGroup
        buttonText="Spara"
        onSaveData={(data: any) => {
          onSaveUpdatedLanguages(data);
        }}
        FormIsValid={true} // constant true because yet no validation in place for edit lang.
      >
        {languages.map(({ data, id }: any, idx: number) => {
          return (
            <Fragment key={idx}>
              <div className={css['language-container']}>
                <fieldset className="adaptable-flex-direction">
                  <legend>{data.title}</legend>

                  {['Nybörjare', 'Medioker', 'Avancerad/Modersmål'].map(
                    (level: any) => {
                      const levelUid = uuidv4();
                      const isChecked =
                        data.level?.toLowerCase() === level?.toLowerCase();
                      return (
                        <GroupInstance key={levelUid}>
                          <BaseInput
                            type="radio"
                            key={idx}
                            inputid={`language-level-${levelUid}`}
                            label={level}
                            id={`language-level-${levelUid}`}
                            value={level}
                            name={data.title}
                            defaultChecked={isChecked}
                          ></BaseInput>
                        </GroupInstance>
                      );
                    }
                  )}
                </fieldset>

                <div className={css['button-wrapper']}>
                  <button
                    type="button"
                    className="button--text button--small button--delete no-vertical-margin"
                    onClick={() => {
                      onDeleteLanguageHandler(id);
                    }}
                  >
                    Ta bort
                  </button>
                </div>
              </div>
            </Fragment>
          );
        })}
      </FormGroup>
    ),
  } as any;

  const selectedComponent = componentTypes[actionType];

  return <>{selectedComponent}</>;
};

export default LanguageForm;
