import { useState } from 'react';

import qs from 'query-string';

import { useForm } from 'react-hook-form';
import { signInWithEmailAndRedirect } from '../../firebase';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from '@firebase/auth';
import { verifyEmailExistence } from '../../utils/validation';
import useQuery from '../../hooks/useQuery';
import { ReactComponent as Logo } from '../../assets/icons/logo-black.svg';

import Header from '../../components/Header/Header';
import Copyright from '../../components/Copyright/Copyright';

import css from './Login.module.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import BaseInput from '../../components/common/inputs/BaseInput/BaseInput';
import useEffectOnce from '../../hooks/useEffectOnce';

const LoginWithEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);

  const query = useQuery();
  const email: any = query.get('email');
  const redirect = query.get('redirect');
  const reconstructedRedirect = redirect?.split(' ').join('/');
  const nextRoute = reconstructedRedirect || '/candidate';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
    },
  });

  const auth: any = getAuth();

  useEffectOnce(() => {
    if (!email || !isSignInWithEmailLink(auth, window.location.href)) {
      return;
    }

    setIsLoading(true);
    signInWithEmailLink(auth, email, window.location.href)
      .then(() => navigate(nextRoute))
      .catch((error) => {
        console.error(error);
        alert('Kontakta Kila Support');
      })
      .finally(() => setIsLoading(false));
  });

  const onSubmit = (data: any) => {
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, email: data.email };
    const locationSearch = qs.stringify(newQueries);

    verifyEmailExistence(data.email).then(({ userExists }: any) => {
      if (!userExists) return setMessage(true);
      signInWithEmailAndRedirect(data.email, locationSearch)
        .then(() => setMessage(true))
        .catch((err) => alert(err));
    });
  };

  return (
    <>
      {isLoading ? (
        'Loading...'
      ) : (
        <div className={css.root}>
          <Header isStatic hasDarkText />
          <div className={css.formBox}>
            {message ? (
              <div className={css.messageBox}>
                <Logo />
                <h1 className={css.loginHeader}>Gå till din mejl.</h1>
                <div>
                  Förutsatt att du har ett konto hos oss har du nu fått en länk
                  på din mejl som du klickar på för att logga in.
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className={css.loginHeader}>Logga in med email</h1>
                <BaseInput
                  label="Mejladress"
                  type="email"
                  options={{ hasError: errors.email }}
                  className={`${errors.email ? 'input--error' : ''}`}
                  placeholder="zlatan@kila.se"
                  customProps={{ isLoading: isLoading }}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                />
                {errors.email && (
                  <p className={css.error}>Du måste ange en mejl.</p>
                )}
                <div className={css.buttons}>
                  <input
                    type="submit"
                    value="SKICKA LÄNK"
                    className="button--cta"
                  />
                </div>
                <Link className={css.loginLink} to="/login">
                  Logga in med lösenord?
                </Link>
              </form>
            )}
          </div>
          <Copyright />
        </div>
      )}
    </>
  );
};

export default LoginWithEmail;
