import React from 'react';
import css from './NumberBadge.module.scss';

type Props = {
  number: number;
};

const NumberBadge = ({number}: Props) => {
  return <span className={css['number-badge']}>{number}</span>;
};

export default NumberBadge;
