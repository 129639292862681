import { useState, useMemo, useEffect, useRef, Fragment } from 'react';
import { Column } from 'react-table';
import { SelectColumnFilter } from '../../../components/dashboard/TableFilter/ColumnFilter';
import AddCompanyModal from '../../../components/dashboard/Modals/AddCompanyModal/AddCompanyModal';
import CompanyModal from '../../../components/dashboard/Modals/CompanyModal/CompanyModal';
import Table from '../../../components/dashboard/Table/Table';
import css from './Table.module.scss';

type Props = {
  initRouteData: (collectionName: string) => Promise<any>;
  addNewCompanyToState: (companyObj: object) => void;
  companies: {}[];
};

const CompaniesTable = (props: Props) => {
  const componentMounted = useRef(true);
  const [data, setData] = useState<any>([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);

  const columns = useMemo<Column[]>(
    () =>
      !!data.length
        ? [
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'rowRedirectId',
              id: 'rowRedirectId',
              accessor: 'id',
              disableFilters: true,
            },
            {
              Header: 'Företag',
              accessor: 'data.name',
            },
            {
              Header: 'Skapad',
              accessor: ({ data }: any) => {
                const dateObj = new Date(data.createdAt.seconds * 1000);

                const normalizedDate = dateObj.toLocaleString('sv-SE', {
                  year: 'numeric',
                  month: 'long',
                });
                return normalizedDate;
              },
              Cell: (cell: any) => {
                const createdAt = cell.row.original.data.createdAt;
                const dateObj = new Date(createdAt.seconds * 1000);
                const timeString = dateObj.toLocaleTimeString(['sv-SE'], {
                  hour: '2-digit',
                  minute: '2-digit',
                });
                const normalizedDate = dateObj.toLocaleString('sv-SE', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                });
                return (
                  <div className={css['date-column']}>
                    <span>{normalizedDate}</span>
                    <span>{timeString}</span>
                  </div>
                );
              },
            },
          ].map((column: any): any => {
            return {
              Filter: SelectColumnFilter,
              filter: 'includes',
              ...column,
            };
          })
        : [],
    [data]
  );

  const hiddenColumns = useMemo(() => ['id'], []);

  const onShowCompanyHandler = (company: object) => {
    setSelectedCompany(company);
    setModalIsOpen(true);
  };

  const { companies, initRouteData } = props;
  useEffect(() => {
    if (companies?.length) {
      return setData([...companies]);
    }
    if (!componentMounted.current) return;
    (async () => {
      const companiesSet = companies || (await initRouteData('companies'));
      if (!componentMounted.current) return;
      setData(companiesSet);
    })();

    return () => {
      componentMounted.current = false;
    };
  }, [companies, initRouteData]);

  const addCompanyModal = showAddCompany && (
    <AddCompanyModal
      addNewCompanyToState={props.addNewCompanyToState}
      modalIsOpen={showAddCompany}
      closeModal={() => setShowAddCompany(false)}
    />
  );

  const companyModal = modalIsOpen && selectedCompany && (
    <CompanyModal
      modalIsOpen={modalIsOpen}
      closeModal={() => setModalIsOpen(false)}
      company={selectedCompany}
    />
  );

  return (
    <div className={css.dashboardTable}>
      {addCompanyModal}
      {companyModal}

      {data && (
        <Fragment>
          <button
            className="button--cta button--add"
            onClick={() => setShowAddCompany(true)}
          >
            Skapa företag
          </button>
          <Table
            data={data}
            definedColumns={columns}
            hiddenColumns={hiddenColumns}
            onClickRow={onShowCompanyHandler}
            rowRedirectCollection="jobs"
            rowRedirectTitle="Visa jobb för företaget"
          />
        </Fragment>
      )}
    </div>
  );
};

export default CompaniesTable;
