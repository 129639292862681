
import copy from './privacyPolicyCandidatesCopy.json';

// Shared header with dashboard
import Header from '../../../../components/Header/Header';

// Sections
import SubpageHeroSection from '../../../../website/sections/SubpageHeroSection/SubpageHeroSection';
import TextBlocksSection from '../../../../website/sections/TextBlocksSection/TextBlocksSection';
import Footer from '../../../../website/sections/Footer/Footer';

const PrivacyPolicyCandidates = () => {

  return (
    <>
      <Header hasDarkText={true}/>
      <SubpageHeroSection copy={copy.subpageHero} leftAlignedText={true} />
      <TextBlocksSection copy={copy.textBlocks} />
      <Footer />
    </>
  );
};

export default PrivacyPolicyCandidates;
