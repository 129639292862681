import { useState } from 'react';
import { ColumnList } from '../../../ColumnList/ColumnList';
import ForkliftLicenseForm from '../../../Candidate/Forms/ForkliftLicensesForm';
import BaseModal from '../../../common/BaseModal/BaseModal';
import TagListItem from '../../../Candidate/TagList/TagListItem';
import EditPen from '../../../EditPen/EditPen';
import SmallButton from '../../../common/buttons/SmallButton/SmallButton';

const ForkliftLicenseList = ({
  isLoading,
  forkliftLicenses,
  onUpdateLicenses,
}: any) => {
  const [formType, setFormType] = useState<'add' | 'edit' | null>(null);

  const clearFormState = () => {
    setFormType(null);
  };

  const modalIsOpen = !!formType && !isLoading;

  const hasLicenses = !!forkliftLicenses?.length;

  const listJsx = hasLicenses && (
    <div className="list--tags">
      {forkliftLicenses.map(({ data: { title } }: any, idx: number) => (
        <TagListItem key={idx} title={title} />
      ))}
    </div>
  );
  return (
    <>
      <BaseModal
        isOpen={modalIsOpen}
        onClose={() => clearFormState()}
        heading="Redigera truckkort"
      >
        {formType === 'edit' && (
          <ForkliftLicenseForm
            forkliftLicenses={forkliftLicenses}
            onUpdateCandidateLicenses={(licenses: any) => {
              onUpdateLicenses(licenses);
              clearFormState();
            }}
            buttonText="Spara"
          />
        )}
        {formType === 'add' && (
          <ForkliftLicenseForm
            forkliftLicenses={forkliftLicenses}
            onUpdateCandidateLicenses={(licenses: any) => {
              onUpdateLicenses(licenses);
              clearFormState();
            }}
            buttonText="Spara"
          />
        )}
      </BaseModal>

      <ColumnList
        title={
          <>
            Truckkort
            {hasLicenses && (
              <EditPen onTriggerEdit={() => setFormType('edit')} />
            )}
          </>
        }
      >
        {listJsx}

        <SmallButton
          styleType="text"
          title="Lägg till"
          onClick={() => setFormType('add')}
        />
      </ColumnList>
    </>
  );
};

export default ForkliftLicenseList;
