// components
import { ColumnList, ColumnListItem } from '../../../ColumnList/ColumnList';

type Props = { answers: { question: string; answer: string }[] };

const CandidateAnswers = ({ answers }: Props) => {
  return (
    answers && (
      <ColumnList>
        {answers.map(({ question, answer }: any, key: any) => (
          <ColumnListItem key={key} title={question} text={answer} />
        ))}
      </ColumnList>
    )
  );
};

export default CandidateAnswers;
