import { useState, useEffect } from 'react';
import { googleAutocomplete } from '../services/google-autocomplete';

export function usePlacesAutocomplete(
  text = '',
  debounceTimeout = 0,
  {
    componentRestrictions,
    types,
  }: {
    componentRestrictions?: string[];
    types: string[];
  }
) {
  const [session, setSession] = useState({
    token: null,
    predictions: [],
  });

  function extractTargetByTypes(arr: any, wantedTypes: any) {
    const tempObj = Object.create(null);
    for (let obj of arr as any) {
      const { long_name, types } = obj;
      let hasCommonTypes = types.some((type: any) =>
        wantedTypes.includes(type)
      );
      if (!hasCommonTypes) continue;
      tempObj[types[0]] = long_name;
    }
    return tempObj;
  }

  const { token }: any = session;
  const getGeolocation: any = (
    place_id: string
  ): Promise<object> | undefined => {
    if (!place_id || place_id.length < 1) return;

    return new Promise((resolve, reject) => {
      const placesInstance = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      placesInstance.getDetails(
        { sessionToken: token, placeId: place_id },
        (place: any, status: string) => {
          if (status === 'OK') {
            const { lat, lng } = place?.geometry?.location;
            const { ...extracted } = extractTargetByTypes(
              place.address_components,
              ['route', 'street_number', 'postal_code', 'postal_town']
            );

            resolve({ ...extracted, coordinates: { lat: lat(), long: lng() } });
          } else {
            reject(
              'PlacesServiceStatus is not OK, please contact a customer service representative'
            );
          }
        }
      );
    });
  };

  const { google } = window;
  // handles initialization of session token for autocomplete
  useEffect(() => {
    if (!google) return;

    const token = new google.maps.places.AutocompleteSessionToken();
    setSession((session) => {
      return { ...session, token } as any;
    });
  }, [google]);

  // handles getting new predictions

  useEffect(() => {
    const handleDebounce = setTimeout(async () => {
      try {
        if (!text || !token) return;

        const nextPredictions: any = await googleAutocomplete(text, token, {
          componentRestrictions,
          types: types || ['address'],
        });

        setSession((session) => {
          return { ...session, predictions: nextPredictions };
        });
      } catch (e) {
        console.error(e);
      }
    }, debounceTimeout);

    return () => {
      clearTimeout(handleDebounce);
    };
  }, [text, debounceTimeout, token]);

  return [session.predictions, getGeolocation];
}
