import css from './ImageAndTextSection.module.scss';
import classNames from 'classnames';

import Heading from '../../../website/components/atoms/Heading/Heading';
import Paragraph from '../../../website/components/atoms/Paragraph/Paragraph';
import Button from '../../../website/components/atoms/Button/Button';
import List from '../../../website/components/atoms/List/List';

import HeadingWithLabel from '../../../website/components/molecules/HeadingWithLabel/HeadingWithLabel';


type Props = {
  copy: {
    headingLabel: string,
    heading: string,
    text: string,
    bullets?: Array<string>,
    button?: {
      text: string,
      link: string,
    }
  },
  image: string,
  imagePosition?: 'left' | 'right',
};

const ImageAndTextSection = ({ copy, image, imagePosition = 'left' }: Props) => {
  const {headingLabel, heading, text, bullets, button} = copy;

  const sectionClasses = classNames(
    'website__section',
    css['root'],
    css['image--' + imagePosition],
  );

  const content = (
    <>
      <Paragraph>{text}</Paragraph>

      {
        bullets &&
        <List type="checks">
          {
            bullets.map((text, index) => {
              return (
                <li key={index}>{text}</li>
              )
            })
          }
        </List>
      }

      {
        button && <Button label={button.text} link={button.link} />
      }
    </>
  );

  return (
    <section className={sectionClasses}>
      <div className={css['section__inner']}>

        <img src={image} alt="" loading="lazy" />

        <div className={`${css['text-wrapper']}`}>
          <HeadingWithLabel
            heading={heading}
            headingLabel={headingLabel}
            customClasses={css.centered}
          />

          {content}
        </div>

      </div>
    </section>
  );
};

export default ImageAndTextSection;
