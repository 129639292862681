import { FC } from 'react';

// styles
import css from './QuestionsList.module.scss';

const FormControl: FC = ({ children }) => {
  return <div className={css['container']}>{children}</div>;
};

export default FormControl;
