import { useState, useEffect } from 'react';

/**
 * Useful for conditional rendering and/or advanced UI/UX handling
 */

const useScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenHeight(window.screen.height);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return [screenHeight];
};

export default useScreenHeight;
