import Author from './Author/Author';
import TagListItem from '../../Candidate/TagList/TagListItem';
import Toolbox, { ToolboxItem } from '../../Toolbox/Toolbox';
import FormGroup, { GroupInstance } from '../../FormGroup/FormGroup';
import ConfirmationBox from '../../Confirmation/ConfirmationBox/ConfirmationBox';

// styles
import css from './EventsListItem.module.scss';
import { useState } from 'react';

type Props = {
  inEditState?: boolean;
  setEditState: () => void;
  onDeleteEvent: () => void;
  onUpdateEvent: ({}: any) => void;
  onOpenToolbox: () => void;
  onCloseToolbox: () => void;
  showToolbox: boolean;
  eventType: string;
  title: string;
  body: string;
  date: string;
  author: {
    firstName: string;
    lastName: string;
    userId: string;
  };
  isOwnEvent?: boolean;
  tags?: string[] | false;
};

const EventsListItem = ({
  inEditState,
  setEditState,
  onOpenToolbox,
  onCloseToolbox,
  onDeleteEvent,
  onUpdateEvent,
  showToolbox,
  eventType,
  title,
  body,
  date,
  author,
  isOwnEvent,
  tags,
}: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const onRequestDeleteEvent = () => {
    setShowConfirm(true);
  };

  const titleJsx = (
    <h2 className={`no-vertical-margin ${css['comment__title']}`}>
      {!inEditState ? (
        title
      ) : (
        <div className={css['editable-field']}>
          <GroupInstance>
            <input name="title" defaultValue={title} />
          </GroupInstance>
        </div>
      )}
    </h2>
  );

  const autoResize = ({ target }: any) => {
    target.style.height = '5px';
    target.style.height = target.scrollHeight + 'px';
  };

  const bodyJsx = (
    <p className={css['comment__body']}>
      {!inEditState ? (
        body
      ) : (
        <div className={css['editable-field']}>
          <GroupInstance>
            <textarea
              name="body"
              defaultValue={body}
              onInput={(e: any) => autoResize(e)}
            />
          </GroupInstance>
        </div>
      )}
    </p>
  );

  const articleJsx = (
    <article className={`${css['comment']}`}>
      <header>
        {titleJsx}
        {tags && (
          <div className={`list--tags ${css['flex-item']}`}>
            {tags.map((tag: any, idx: number) => (
              <TagListItem key={idx} title={tag} />
            ))}
          </div>
        )}

        {eventType === 'comment' && isOwnEvent && (
          <div
            className={css['comment__actions']}
            onClick={(e: any) => {
              e.stopPropagation();
              onOpenToolbox();
            }}
          >
            <div className={css['ellipsis-wrapper']}>
              <i className="fa-solid fa-ellipsis"></i>
              {showToolbox && (
                <Toolbox closeToolbox={onCloseToolbox}>
                  <ToolboxItem title="Ändra" onClick={setEditState} />
                  <ToolboxItem title="Radera" onClick={onRequestDeleteEvent} />
                </Toolbox>
              )}
            </div>
          </div>
        )}
      </header>

      {bodyJsx}
      <Author author={author} date={date} />
    </article>
  );
  return (
    <>
      {inEditState && <div className={css['glass-effect']}></div>}
      <li className={inEditState ? css['bleed-through'] : ''}>
        {!inEditState ? (
          articleJsx
        ) : (
          <FormGroup onSaveData={onUpdateEvent} buttonText="Spara" FormIsValid>
            {articleJsx}
          </FormGroup>
        )}
      </li>
      {/* CONFIRM DELETE */}

      <ConfirmationBox
        type="danger"
        title="OBS! Oåterkallelig åtgärd."
        body="Genom att radera en kommentar så försvinner den från kandidatens profil, och om du vid ett senare tillfälle behöver den igen måste återskapningen manuellt. Vill du fortsätta?"
        isOpen={showConfirm}
        onConfirm={() => {
          onDeleteEvent();
          setShowConfirm(false);
        }}
        onClose={() => setShowConfirm(false)}
      />
    </>
  );
};

export default EventsListItem;
