import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

// components
import TextareaAutosize from 'react-textarea-autosize';
import BaseButton from '../../../components/common/BaseButton/BaseButton';

const OptionalOther = ({ mapRef }: any) => {
  const [showField, setShowField] = useState(false);

  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  const fieldError = useMemo(
    () => errors.criterias && !!errors.criterias[mapRef]?.other,
    [errors]
  );

  const hideField = () => {
    const hasDenied = !window.confirm(
      'Är du säker på att du vill ta bort övrigt-fältet?'
    );
    if (hasDenied) return;
    unregister(`criterias.${mapRef}.other`);
    setShowField(false);
  };

  // jsx
  const showFieldButton = (
    <BaseButton
      title="lägg till övrigt"
      styleType="text button--small button--add"
      onClick={() => setShowField(true)}
    />
  );

  const hideFieldButton = (
    <BaseButton
      title="ta bort övrigt"
      styleType="text button--small button--delete"
      onClick={hideField}
    />
  );

  const conditionalButton = useMemo(
    () => (showField ? hideFieldButton : showFieldButton),
    [showField]
  );

  const withFormError = (Textarea: any) => {
    const fieldClasses = classNames({
      error__input: fieldError,
    });
    return (
      <div className="error">
        <Textarea
          minRows={5}
          className={fieldClasses}
          {...register(`criterias.${mapRef}.other`, { required: true })}
        />
        {fieldError && (
          <p className="error__message">Övrigt-fältet får inte lämnas tomt</p>
        )}
      </div>
    );
  };

  const renderTextarea = () => {
    return !fieldError ? (
      <TextareaAutosize
        minRows={5}
        {...register(`criterias.${mapRef}.other`, { required: true })}
      />
    ) : (
      withFormError(TextareaAutosize)
    );
  };

  return (
    <>
      <div>{conditionalButton}</div>
      {showField && renderTextarea()}
    </>
  );
};

export default OptionalOther;
