
import copy from './companiesCopy.json';

// Images
import ImageSubpageHeroDesktop from '../../../website/assets/images/people-standing-desktop.webp';
import ImageSubpageHeroTablet from '../../../website/assets/images/people-standing-tablet.webp';
import ImageSubpageHeroMobile from '../../../website/assets/images/people-standing-mobile.webp';
import ImageTextAndImage from '../../../website/assets/images/faces.webp';
import ImageBlurb1 from '../../../website/assets/images/recruiting.webp';
import ImageBlurb2 from '../../../website/assets/images/education.webp';
import ImageBlurb3 from '../../../website/assets/images/internship.webp';

// Shared header with dashboard
import Header from '../../../components/Header/Header';

// Sections
import SubpageHeroSection from '../../../website/sections/SubpageHeroSection/SubpageHeroSection';
import Blurbs from '../../../website/sections/Blurbs/Blurbs';
import Cta from '../../../website/sections/Cta/Cta';
import Testimonials from '../../../website/sections/Testimonials/Testimonials';
import TextWithIconsSection from '../../../website/sections/TextWithIconsSection/TextWithIconsSection';
import Quote from '../../../website/sections/Quote/Quote';
import ImageAndTextSection from '../../../website/sections/ImageAndTextSection/ImageAndTextSection';
import Footer from '../../../website/sections/Footer/Footer';

const Companies = () => {
  const imagesSubpageHero = {
    mobile: ImageSubpageHeroMobile,
    tablet: ImageSubpageHeroTablet,
    desktop: ImageSubpageHeroDesktop
  }
  return (
    <>
      <Header />
      <SubpageHeroSection copy={copy.subpageHero} textColor="white" backgroundImages={imagesSubpageHero}/>
      <Blurbs copy={copy.blurbs} images={[ImageBlurb1, ImageBlurb2, ImageBlurb3]} />
      <Cta copy={copy.cta} />
      {/*<Quote copy={copy.quote} />*/}
      <TextWithIconsSection copy={copy.textWithIcons} />
      {/*<Testimonials copy={copy.testimonials}/>*/}
      <ImageAndTextSection copy={copy.imageAndText} image={ImageTextAndImage} imagePosition='right' />
      <Footer />
    </>
  );
};

export default Companies;
