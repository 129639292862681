import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 *  Scroll to top on new routes
 * @description place the component at the top of the app, but below the router.
 */

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
