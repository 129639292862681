import { useState } from 'react';
import { ColumnList, EditableListItem } from '../../../ColumnList/ColumnList';
import ExperienceForm from '../../../Candidate/Forms/ExperienceForm';
import BaseModal from '../../../common/BaseModal/BaseModal';
import SmallButton from '../../../common/buttons/SmallButton/SmallButton';

const ExperienceList = ({
  isLoading,
  experiences,
  onUpdateExperience,
  onAddExperience,
  onDeleteExperience,
}: any) => {
  const [formType, setFormType] = useState<'add' | 'edit' | null>(null);
  const [itemInEdit, setItemInEdit] = useState<any>(null);

  const clearFormState = () => {
    setFormType(null);
    setItemInEdit(null);
  };

  const modalIsOpen = !!formType && !isLoading;

  const hasExperiences = !!experiences?.length;

  const listJsx =
    hasExperiences &&
    experiences.map(({ data, id }: any) => (
      <EditableListItem
        key={id}
        title={`${data.title}, ${data.companyName}`}
        text={`${data.location.formatted}, ${data.startDate} - ${
          data.endDate || 'Nuvarande'
        }`}
        onTriggerEdit={() => {
          setItemInEdit({ data, id });
          setFormType('edit');
        }}
      />
    ));

  return (
    <>
      <BaseModal
        isOpen={modalIsOpen}
        onClose={clearFormState}
        heading="Redigera erfarenhet"
      >
        {formType === 'edit' && (
          <ExperienceForm
            experience={itemInEdit}
            buttonText="spara"
            onSaveNestedObject={(data: any) => {
              const { id } = itemInEdit;

              onUpdateExperience({ id, data });
              clearFormState();
            }}
            onRemoveExperience={() => {
              const { id } = itemInEdit;
              onDeleteExperience(id);
              clearFormState();
            }}
          />
        )}
        {formType === 'add' && (
          <ExperienceForm
            buttonText="Skapa"
            onSaveNestedObject={(data: any) => {
              onAddExperience(data);
              clearFormState();
            }}
          />
        )}
      </BaseModal>
      <ColumnList title="Erfarenhet">
        {listJsx}
        <SmallButton
          styleType="text"
          title="Lägg till"
          onClick={() => setFormType('add')}
        />
      </ColumnList>
    </>
  );
};

export default ExperienceList;
