// components
import { ColumnList } from '../../../ColumnList/ColumnList';
import Checkbox from '../Checkbox/Checkbox';

// styles
import css from './StartInfo.module.scss';

type Props = {
  info: string[];
};

const StartInfo = ({ info }: Props) => {
  return (
    <ColumnList noGap>
      {info?.map((info: string, key: number) => (
        <div key={key} className={css['checkbox-wrapper']}>
          <Checkbox name={info} label={info} />
        </div>
      ))}
    </ColumnList>
  );
};

export default StartInfo;
