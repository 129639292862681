// styles
import css from './Author.module.scss';

type Props = {
  author?: {
    firstName: string;
    lastName: string;
    displayName?: string;
    userId: string;
  };

  date: string;
};

const Author = ({ author, date }: Props) => {
  const displayName = author?.displayName
    ? author.displayName
    : `${author?.firstName} ${author?.lastName}`;
  return (
    <div className={css['comment__author']}>
      <i className={`fa-solid fa-${author ? 'user' : 'robot'}`}></i>
      <span>
        {author ? displayName : 'Systemet'}, {date}
      </span>
    </div>
  );
};

export default Author;
