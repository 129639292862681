import { useMemo } from 'react';
import { StatusUpdate } from '../../models/Event';
import {
  ApplicationService,
  CandidateService,
} from '../../services/database-service';
import SetStatus from '../SetStatus';

type Props = {
  candidateId: string;
  appId: string;
  currentStatus: string;
  label?: string;
  buttonTitle?: string;
  onSetStatus?: ({ status, comment }: any) => void;
};

interface Status {
  status: string;
  comment: string;
}

const ApplicationStatusForm = ({
  candidateId,
  appId,
  currentStatus,
  label,
  buttonTitle,
  onSetStatus,
}: Props) => {
  const onSetStatusHandler = async ({ status, comment }: Status) => {
    try {
      await updateApplicationStatus({ name: status, comment });
      await createStatusUpdateEvent({
        newStatus: status,
        previousStatus: currentStatus,
        comment,
      });

      onSetStatus && onSetStatus({ name: status, comment });
    } catch (err) {
      console.error(err);
    }
  };

  const updateApplicationStatus = async ({ name, comment }: any) => {
    try {
      const newStatus = { status: { name, comment } };
      await ApplicationService.update(appId, newStatus);
    } catch (error) {
      console.error(error);
    }
  };
  const createStatusUpdateEvent = async ({
    newStatus,
    previousStatus,
    comment,
  }: any) => {
    try {
      const event = new StatusUpdate({
        title: 'Statusuppdatering',
        body: comment,
        newStatus,
        previousStatus,
      });

      await CandidateService.go(candidateId, 'events').create(event);
    } catch (err) {
      console.error(err);
    }
  };

  const statusOptions = useMemo(() => {
    return [
      'ej hanterad',
      'kontaktad',
      'länk skickad',
      'bokad intervju',
      'intervjuad - prio 1',
      'intervjuad - prio 2',
      'presenterad',
      'inte relevant - för jobbet',
      'inte relevant - dök ej upp',
      'inte relevant - bokade inte intervju',
      'inte relevant - sökte för sent',
      'inte relevant - ingen kila-kandidat',
      'inte relevant - blacklist',
      'fått jobb',
    ];
  }, []);

  // const statusOptions = useMemo(() => {
  //   return [
  //     { option: 'ej hanterad', categories: null },
  //     { option: 'kontaktad', categories: null },
  //     { option: 'länk skickad', categories: null },
  //     { option: 'bokad intervju', categories: null },
  //     {
  //       option: 'intervjuad',
  //       categories: [
  //         { option: 'prio 1', categories: null },
  //         { option: 'prio 2', categories: null },
  //       ],
  //     },
  //     { option: 'presenterad', categories: null },
  //     {
  //       option: 'inte relevant',
  //       categories: [
  //         { option: 'för jobbet', categories: null },
  //         { option: 'dök ej upp', categories: null },
  //         { option: 'bokade inte intervju', categories: null },
  //         { option: 'sökte för sent', categories: null },
  //         { option: 'ingen kila-kandidat', categories: null },
  //         { option: 'blacklist', categories: null },
  //       ],
  //     },
  //     { option: 'fått jobb', categories: null },
  //   ];
  // }, []);

  return (
    <div className="container">
      <SetStatus
        options={statusOptions}
        currentStatus={currentStatus}
        onSetStatus={onSetStatusHandler}
        label={label}
        buttonTitle={buttonTitle}
        isForm={false}
      />
    </div>
  );
};

export default ApplicationStatusForm;
