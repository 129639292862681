import { FC, useContext } from 'react';
import Modal from 'react-modal';
import IconButton from '../../../components/common/IconButton/IconButton';
import CandidateProfileContext from '../../../context/candidate-profile-context';

import css from './BaseModal.module.scss';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  className?: string;
  contentLabel?: string;
  isCandidateModal?: boolean;
};

const BaseModal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  heading,
  className,
  isCandidateModal,
}) => {
  const { hasUnsavedChanges } = useContext(CandidateProfileContext);

  const confirmCloseRequest = () => {
    const hasConfirmed = window.confirm(
      'Observera att du har osparade ändringar. Om du återgår till kandidatvyn kommer dina ändringar att försvinna. '
    );
    if (hasConfirmed) {
      onClose();
    }
  };

  const onRequestCloseHandler = () => {
    if (!!hasUnsavedChanges && hasUnsavedChanges()) {
      confirmCloseRequest();
    } else {
      onClose();
    }
  };

  return (
    <Modal
      className={`modal ${className} ${
        isCandidateModal ? css['candidate-modal'] : ''
      }`}
      style={{ overlay: { background: 'rgba(0, 0, 0, 0.5)', zIndex: 999 } }}
      isOpen={isOpen}
      onRequestClose={onRequestCloseHandler}
      contentLabel="User Modal"
      ariaHideApp={false}
    >
      <header>
        <h3>{heading}</h3>
        <IconButton
          faIcon="close"
          text="Stäng modal"
          onClick={onRequestCloseHandler}
        />
      </header>
      <div className="modal__content">{children}</div>
    </Modal>
  );
};

export default BaseModal;
