import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import Copyright from '../../components/Copyright/Copyright';
import { db } from '../../firebase';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';

import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from '@firebase/auth';

import css from './Apply.module.scss';

const ApplyEmail = () => {
  const navigate = useNavigate();

  const getTodaysDate = () => {
    return new Date().toISOString();
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const uid = queryParams.get('uid');
    const email = queryParams.get('email');
    const auth = getAuth();

    if (isSignInWithEmailLink(auth, window.location.href) && !!email && !!uid) {
      if (!!auth.currentUser) {
        (async () => {
          let fetchedCandidate = await getCandidate(uid);
          await updateUser(fetchedCandidate, auth.currentUser?.uid);
          await deleteDoc(doc(db, 'candidates', uid));
        })();
      } else {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            (async () => {
              let fetchedCandidate = await getCandidate(uid);
              await updateUser(fetchedCandidate, auth.currentUser?.uid);
              await deleteDoc(doc(db, 'candidates', uid));
            })();
          })
          .catch((error) => {
            console.log('Error för login', error);
          });
      }
    }
  }, []);

  const getCandidate = async (uid: string) => {
    console.log('Uid', uid);
    try {
      const candidateRef = doc(db, 'candidates', uid);
      const candidateSnap = await getDoc(candidateRef);

      if (!candidateSnap.exists()) throw new Error('No candidate found.');

      return candidateSnap.data();
    } catch (err) {
      console.error(err);
    }
  };

  const updateUser = async (fetchedCandidate: any, uid: any) => {
    const candidateRef = doc(db, 'candidates', uid);
    await setDoc(candidateRef, {
      ...fetchedCandidate,
      verifiedEmail: getTodaysDate(),
    })
      .then(() => {
        console.log('Kandidaten är nu verifierad');
      })
      .catch((error) => {
        console.log('Error för update', error);
      });
  };

  return (
    <>
      <div className={css.root}>
        <div className={css.formBox}>
          <section className={css.section}>
            <h3>Kul, nu är du en av oss!</h3>
            <p>
              Börja din resa med att fylla på din profil för att öka
              sannolikheten till att just du ska få ett jobb.
            </p>
            <button
              className="button--full-width button--cta"
              onClick={() => navigate('/candidate')}
            >
              Gå till profil
            </button>
            <p>
              Om du vill komma i kontakt med oss så kan du klicka på chatikonen
              till höger eller mejla oss på info@kila.se
            </p>
          </section>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default ApplyEmail;
